import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Timeline,
  Icon,
  Form,
  Select,
  Row,
  Col,
  Button,
  Input,
  Upload,
  message,
  Radio,
  DatePicker,
  Modal,
  Alert,
  Tabs,
  Table,
  TimePicker,
} from "antd";
import "./index.less";
import ProcessGuide from "../../../components/processGuide";
import AuditRecord from "../../../components/auditRecord";
import SelectStaff from "../../../components/selectStaff";
import Withdraw from "../../../components/withdraw"; // 撤回
import { getQueryVariable } from "../../../utils/common";
import utils from "../../../utils/utils";
import http from "../../../api/fetch";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;
const { RangePicker } = TimePicker;

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: utils.localDb.get("userData").staffId,
      staffName: utils.localDb.get("userData").staffName,
      fileList: [],

      showType: 1, // 1,表单，2，详情
      previewVisible: false, // 预览弹框
      previewUrl: "", // 预览地址

      staffRow: {},
      positiveDetail: {},
      logList: [],
      submitDisabled: false,
    };
  }
  componentDidMount() {
    if (getQueryVariable("positiveProcId")) {
      this.queryPositiveById();
      this.queryProcessLogList();
    } else if (getQueryVariable("staffId")) {
      this.queryStaffDetail();
      this.setState({
        showType: 1,
        logList: [],
      });
    } else {
      this.setState({
        showType: 1,
        logList: [],
      });
    }
  }

  queryStaffDetail = () => {
    this.$http["getStaffPostInfoForStaffRoster"]({
      staffId: getQueryVariable("staffId"),
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          staffRow: res.data || {},
        });
      }
    });
  };

  queryPositiveById = () => {
    this.$http["queryPositiveById"]({
      positiveProcId: getQueryVariable("positiveProcId"),
    })
      .then((res) => {
        if (res.code === 200) {
          let showType = 2;
          let showWithdraw = false;
          let showOpinion = false;
          let showPass = false;
          let showComplete = false;
          let showWrite = false;

          let step2 = false;
          let step3 = false;
          let step4 = false;

          let taskDefKey = res.data.processData
            ? res.data.processData.taskDefKey
            : "";
          //
          if (res.data.procStatus === "STORAGE") {
            showType = 1;
          }
          if (
            res.data.procStatus == "REFUSE" ||
            res.data.procStatus == "RECALL"
          ) {
            if (res.data.creatorId == this.state.staffId) {
              showType = 1;
            }
          }
          if (res.data.procStatus == "AUDIT") {
            if (res.data.creatorId == this.state.staffId) {
              // 撤回操作
              showWithdraw = true;
            }
            if (res.data.auditId == this.state.staffId) {
              if (taskDefKey === "BEC0002") {
                // 第二步
                step2 = true;
              }
              if (taskDefKey === "BEC0003") {
                // 第三步
                step3 = true;
              }
              if (taskDefKey === "BEC0004") {
                // 第四步
                step4 = true;
                showComplete = true;
              }
            }
            // 审核状态
            // if (taskDefKey === "BEC0001") {
            //   if (res.data.auditId == this.state.staffId) {
            //     // 审核人id==登录人id，通过不通过操作
            //     // showPass = true;
            //     showType = 1;
            //   }
            // }
          }
          if (res.data.procStatus == "COMPLETE") {
            // 完成状态，归档
            showComplete = false;
          }

          // 抄送的详情没有操作权限
          if (getQueryVariable("copyToFlag") == 1) {
            step2 = false;
            step3 = false;
            step4 = false;
          }

          this.setState({
            positiveDetail: res.data || {},
            staffRow: res.data || {},
            showType,
            showWithdraw,
            showOpinion,
            showPass,
            showComplete,
            showWrite,
            step2,
            step3,
            step4,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  //流程日志
  queryProcessLogList = () => {
    this.$http["queryProcessLogList"]({
      businessKey: getQueryVariable("positiveProcId"),
      procTemplate: getQueryVariable("procTemplate"),
    })
      .then((res) => {
        if (res.code == 200) {
          this.setState({
            logList: res.data || [],
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  handleDelete = () => {
    const self = this;
    confirm({
      title: "删除",
      content: "您确定要删除该工作流吗？",
      onOk() {
        self.$http["deleteByPositiveId"]({
          positiveProcId: getQueryVariable(
            "positiveProcId"
          ),
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              self.props.history.go(-1);
            } else {
            }
          })
          .catch((err) => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleSubmit = (type) => {
    const { staffRow, fileList } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (!staffRow.staffCode) {
          message.destroy();
          message.warning("请选择员工");
          return;
        }
        // staffRow.onboardingTime = staffRow.onboardingTime.replace(/\//g, "-");
        let time = values["applyPositiveTime"].format(
          "YYYY/MM/DD"
        );
        if (
          moment(time).isBefore(
            moment(
              staffRow.onboardingTime ||
                staffRow.onBoardingTime
            )
          ) &&
          time !=
            (staffRow.onboardingTime ||
              staffRow.onBoardingTime)
        ) {
          message.destroy();
          message.warning("转正日期不能小于入职日期");
          return;
        }
        this.setState({
          submitDisabled: true,
        });
        console.log("values ->", values);
        const params = {
          applyPositiveTime: values["applyPositiveTime"]
            ? dislodgeZero(
                values["applyPositiveTime"].format(
                  "YYYY/MM/DD"
                )
              )
            : "",
          ...staffRow,
          onBoardingTime:
            staffRow.onboardingTime ||
            staffRow.onBoardingTime,
          attachmentKey: fileList[0] ? fileList[0].uid : "",
          procStatus: type,
        };
        console.log(params);
        if (getQueryVariable("positiveProcId")) {
          delete params.applyPositiveTime;
          delete params.onBoardingTime;
          delete params.onboardingTime;
          console.log(
            "params.gmtCreated ->",
            params.gmtCreated
          );
          params.gmtCreatedNew = params.gmtCreated;
          params.gmtModifiedNew = params.gmtModified;
          delete params.gmtCreated;
          delete params.gmtModified;
          params.positiveProcId = getQueryVariable(
            "positiveProcId"
          );
          params.applyPositiveTimeNew = values[
            "applyPositiveTime"
          ]
            ? dislodgeZero(
                values["applyPositiveTime"].format(
                  "YYYY/MM/DD"
                )
              )
            : "";
          params.onBoardingTimeNew =
            staffRow.onboardingTime ||
            staffRow.onBoardingTime;
        }
        this.$http[
          getQueryVariable("positiveProcId")
            ? "updateByPositiveId"
            : "addPositiveProcess"
        ](params)
          .then((res) => {
            if (res.code === 200) {
              // if (getQueryVariable("positiveProcId")) {
              //   window.close();
              // }
              this.props.history.push("/workbench/launch");
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });

        // 日期格式转换2020/1/1
        function dislodgeZero(str) {
          let strArray = str.split("-");
          strArray = strArray.map(function(val) {
            if (val[0] == "0") {
              return (val = val.slice(1));
            } else {
              return val;
            }
          });
          return strArray.join("-");
        }
      }
    });
  };

  handleAddSure = (values) => {
    console.log(values);
  };

  //
  handlePreview = () => {
    this.setState({
      previewVisible: true,
    });
  };
  //
  handleCancel = () => {
    this.setState({
      previewVisible: false,
    });
  };

  handleOk = (row) => {
    this.setState({
      staffRow: row[0] || {},
    });
    console.log(row);
  };

  handleSubmission = () => {
    const { positiveDetail, fileList } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (!fileList.length) {
          message.destroy();
          message.warning("请上传试用期绩效考核表");
          return;
        }
        this.setState({
          submitDisabled: true,
        });
        this.$http["positivePass"]({
          positiveProcId: getQueryVariable(
            "positiveProcId"
          ),
          taskId: positiveDetail.processData.taskId,
          ...values,
          attachmentKey: fileList[0] ? fileList[0].uid : "",
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              this.props.history.go(-1);
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };

  // 通过
  handlePass = () => {
    const { positiveDetail } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          submitDisabled: true,
        });
        this.$http["positivePass"]({
          positiveProcId: getQueryVariable(
            "positiveProcId"
          ),
          taskId: positiveDetail.processData.taskId,
          ...values,
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              this.props.history.go(-1);
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };
  // 不通过
  handleFail = () => {
    const self = this;
    const { form } = this.props;
    const { positiveDetail } = this.state;
    if (!form.getFieldValue("auditOpinion").trim()) {
      message.destroy();
      message.warning("请在审核意见中填写退回原因");
      document.getElementById("auditOpinion").focus();
      return;
    }
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        confirm({
          icon: (
            <Icon
              type="question-circle"
              style={{ color: "#4D91FA" }}
            />
          ),
          title: "您确定退回该工作流吗？",
          content: "",
          onOk() {
            self.$http["positiveRefuse"]({
              positiveProcId: getQueryVariable(
                "positiveProcId"
              ),
              taskId: positiveDetail.processData.taskId,
              ...values,
            })
              .then((res) => {
                if (res.code === 200) {
                  // window.close();
                  self.props.history.go(-1);
                } else {
                }
              })
              .catch((err) => {});
          },
          onCancel() {},
        });
      }
    });
  };
  validatorSummary = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };

  handleChange = ({ fileList }) => {
    if (fileList.length == 0) {
      this.setState({ fileList });
      this.triggerChange(fileList);
    }
  };
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.destroy();
      message.warning("只能上传word/excel文件，且不超过2M");
      return false;
    }
    const name = file.name;
    const index = name.lastIndexOf(".");
    const suffix = name.substr(index + 1).toLowerCase();
    if (
      ["doc", "docx", "xls", "xlsx"].indexOf(suffix) == -1
    ) {
      message.destroy();
      message.warning("只能上传word/excel文件，且不超过2M");
      return false;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // 使用ossupload覆盖默认的上传方法
      http.uploadFile(file, file.name).then((data) => {
        let fileList = [
          {
            url: data.url,
            uid: data.key,
            name: data.name,
          },
        ];
        this.setState({ fileList });
        this.triggerChange(fileList); //只返回id
      });
    };
    return false; // 不调用默认的上传方法
  };

  handleDownload = () => {
    this.$http.positiveTemplate();
  };

  handleWithdraw = () => {
    this.props.history.go(-1);
  };

  render() {
    const {
      fileList,
      previewVisible,
      previewUrl,
      showType,
      staffRow,
      positiveDetail,
      logList,
    } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };

    // 显示审核结果，判断右侧记录第一个的状态operStatus，有的话就显示第一个，没有的话显示第二个
    let log = {};
    if (logList.length > 1) {
      if (logList[0].operStatus) {
        log = logList[0];
      } else {
        log = logList[1];
      }
    }

    return (
      <div className="become-approval">
        <div className="approval-title">
          <span>
            转正申请流程
            {showType == 1 && <span>-新建</span>}
            {positiveDetail.procStatus == "AUDIT" ? (
              positiveDetail.processData ? (
                <span>{`-${positiveDetail.processData.taskName}`}</span>
              ) : null
            ) : null}
          </span>
          <ProcessGuide
            title="转正申请流程"
            temp="BECOME_PROC"
          />
        </div>
        <div className="approval-wrap">
          <div className="wrap-left" style={{ flex: 1 }}>
            {log && log.operDescription ? (
              <div>
                {log.operStatus == "PASS" && (
                  <Alert
                    message={`${log.taskName}通过`}
                    description={log.operDescription}
                    type="success"
                    showIcon
                    style={{ marginBottom: 12 }}
                  />
                )}
                {log.operStatus == "REFUSE" && (
                  <Alert
                    message={
                      <span className="alert-process-refuse">{`${log.taskName}不通过`}</span>
                    }
                    description={
                      <span className="alert-process-desc">
                        {log.operDescription}
                      </span>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="close-circle"
                        style={{
                          fontSize: 18,
                          color: "#E5537B",
                          top: "18px",
                        }}
                      />
                    }
                    style={{ marginBottom: 12 }}
                  />
                )}
              </div>
            ) : null}

            <Form
              {...formItemLayout}
              className="process-form-readonly"
              style={{ height: "100%" }}
            >
              <Row className="wrap-block">
                <Row>
                  <Col span={8}>
                    <FormItem label="员工">
                      {showType == 1 ? (
                        getQueryVariable("staffId") ? (
                          <span>
                            {staffRow.staffName || "--"}
                          </span>
                        ) : (
                          <SelectStaff
                            isProbation={true}
                            form={this.props.form}
                            handleOk={this.handleOk}
                            organizationalItem={{
                              departmentPrincipalId:
                                staffRow.staffId,
                              departmentPrincipalName:
                                staffRow.staffName,
                            }}
                          />
                        )
                      ) : (
                        <span>
                          {positiveDetail.staffName || "--"}
                        </span>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    {showType == 1 ? (
                      <FormItem label="申请转正时间">
                        {getFieldDecorator(
                          "applyPositiveTime",
                          {
                            initialValue: positiveDetail.applyPositiveTime
                              ? moment(
                                  positiveDetail.applyPositiveTime,
                                  "YYYY/MM/DD"
                                )
                              : undefined,
                            rules: [
                              {
                                required: true,
                                message: "申请转正时间必填",
                              },
                            ],
                          }
                        )(
                          <DatePicker
                            format="YYYY/MM/DD"
                            getCalendarContainer={(
                              triggerNode
                            ) => triggerNode.parentNode}
                          />
                        )}
                      </FormItem>
                    ) : (
                      <FormItem label="申请转正时间">
                        <span>
                          {positiveDetail.applyPositiveTime ||
                            "--"}
                        </span>
                      </FormItem>
                    )}
                  </Col>
                  <Col span={8}>
                    <FormItem label="工号">
                      <span>
                        {staffRow.staffCode || "--"}
                      </span>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <FormItem label="入职时间">
                      <span>
                        {staffRow.onBoardingTime ||
                          staffRow.onboardingTime ||
                          "--"}
                      </span>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="直属上级">
                      <span>
                        {staffRow.belongDeliverManager ||
                          "--"}
                      </span>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="部门名称">
                      <span>
                        {staffRow.departmentName || "--"}
                      </span>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <FormItem label="岗位">
                      <span>
                        {staffRow.postName || "--"}
                      </span>
                    </FormItem>
                  </Col>
                </Row>
                {showType == 2 ? (
                  this.state.step2 ? (
                    <Row>
                      <Row>
                        <Col span={16}>
                          <FormItem
                            label="绩效考核"
                            required
                            labelCol={{ span: 6 }}
                          >
                            <div>
                              <Upload
                                name="file"
                                accept=".xlsx,.xls,.csv,.doc,.docx"
                                fileList={fileList}
                                onChange={this.handleChange}
                                beforeUpload={
                                  this.beforeUpload
                                }
                              >
                                <Button>
                                  <Icon type="upload" />{" "}
                                  上传文件
                                </Button>
                              </Upload>
                              <a
                                onClick={
                                  this.handleDownload
                                }
                              >
                                下载试用期绩效考核表模板
                              </a>
                            </div>
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <FormItem
                            label="试用期总结"
                            labelCol={{ span: 4 }}
                          >
                            {getFieldDecorator(
                              "probationSummary",
                              {
                                initialValue: "",
                                rules: [
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "试用期总结必填",
                                  },
                                  {
                                    validator: this
                                      .validatorSummary,
                                  },
                                ],
                              }
                            )(
                              <TextArea
                                rows={4}
                                maxLength={128}
                                placeholder="请输入试用期总结"
                                allowClear
                              />
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    </Row>
                  ) : (
                    <Row>
                      <Row>
                        <Col span={16}>
                          {positiveDetail.attachmentKey ? (
                            <FormItem
                              label="绩效考核"
                              labelCol={{ span: 6 }}
                            >
                              <a
                                onClick={() => {
                                  window.open(
                                    positiveDetail.attachmentUrl
                                  );
                                }}
                              >
                                {positiveDetail.attachmentKey.substr(
                                  positiveDetail.attachmentKey.lastIndexOf(
                                    "/"
                                  ) + 1
                                )}
                              </a>
                            </FormItem>
                          ) : (
                            <FormItem
                              label="绩效考核"
                              labelCol={{ span: 6 }}
                            >
                              <span>--</span>
                            </FormItem>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          {positiveDetail.probationSummary ? (
                            <FormItem
                              label="试用期总结"
                              labelCol={{ span: 4 }}
                            >
                              <span>
                                {positiveDetail.probationSummary ||
                                  "--"}
                              </span>
                            </FormItem>
                          ) : null}
                        </Col>
                      </Row>
                    </Row>
                  )
                ) : null}
              </Row>
              {(this.state.step3 || this.state.step4) && (
                <Row
                  className="wrap-block"
                  style={{ marginBottom: 0 }}
                >
                  <div className="block-title">
                    流程审核
                  </div>
                  <Row>
                    <FormItem
                      label="审核意见"
                      labelCol={{ span: 2 }}
                    >
                      {getFieldDecorator("auditOpinion", {
                        initialValue: "",
                        rules: [
                          {
                            validator: this
                              .validatorSummary,
                          },
                        ],
                      })(
                        <TextArea
                          rows={4}
                          maxLength={128}
                          placeholder="请输入"
                          allowClear
                          style={{ width: 600 }}
                        />
                      )}
                    </FormItem>
                  </Row>
                </Row>
              )}
            </Form>
          </div>
          <div className="wrap-right">
            <AuditRecord data={logList || []} />
          </div>
        </div>
        <div className="footer-fixed-wrap">
          <div className="footer-fixed-btn">
            {showType == 1 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // if (getQueryVariable("positiveProcId")) {
                    //   window.close();
                    // }
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() =>
                    this.handleSubmit("INITIATE")
                  }
                  disabled={this.state.submitDisabled}
                >
                  提交
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() =>
                    this.handleSubmit("STORAGE")
                  }
                  disabled={this.state.submitDisabled}
                >
                  保存
                </Button>
                {(positiveDetail.procStatus === "STORAGE" ||
                  positiveDetail.procStatus ===
                    "REFUSE") && (
                  <Button
                    type="danger"
                    onClick={this.handleDelete}
                  >
                    删除
                  </Button>
                )}
              </div>
            )}
            {showType == 2 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // window.close();
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                {this.state.step2 && (
                  <Button
                    type="primary"
                    style={{ marginRight: 16 }}
                    onClick={this.handleSubmission}
                    disabled={this.state.submitDisabled}
                  >
                    提交
                  </Button>
                )}
                {/* {this.state.showWithdraw && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      this.setState(
                        {
                          visible: true
                        },
                        () => {
                          this.refs.withdrawRef.handleShow();
                        }
                      );
                    }}
                  >
                    撤回
                  </Button>
                )} */}
                {(this.state.step3 || this.state.step4) && (
                  <span>
                    <Button
                      type="primary"
                      style={{ marginRight: 16 }}
                      onClick={this.handlePass}
                      disabled={this.state.submitDisabled}
                    >
                      {this.state.showComplete
                        ? "归档"
                        : "通过"}
                    </Button>
                    <Button
                      type="danger"
                      style={{ marginRight: 16 }}
                      onClick={this.handleFail}
                    >
                      不通过
                    </Button>
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.visible && (
          <Withdraw
            ref="withdrawRef"
            form={this.props.form}
            data={{
              positiveProcId: positiveDetail.positiveProcId,
              taskId:
                (positiveDetail.processData &&
                  positiveDetail.processData.taskId) ||
                "",
            }}
            url="positiveWithdraw"
            handleOk={this.handleWithdraw}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Form.create()(MainManagement));
