/**
* @fileoverview 系统管理---角色管理
* @author  蒋西园
*/
import React,{ Component } from 'react'
import './index.less'

import { Button, message } from 'antd';
import SearchForm from './component/searchForm' // 搜索条件
import Table from "./component/tableList"  		// 数据列表
import AddModal from './component/addAccount'	// 新增或修改角色


class RoleManagement extends Component{
	constructor(props){
		super(props)
		this.state = {
			loading: false,
            dataList: [],     	// 角色列表数据
            total: 100,       	// 角色列表总数
            pageSize: 10,     	// 分页Size
            pageNo: 1,        	// 分页页码
			searchVale: {},   	// 搜索条件
			addModalShow: false,// 新增弹框显隐
			modalType: 0,		// 0：添加角色弹框 1：修改角色弹框
			roleItem: {},		// 编辑角色弹框回显数据
			disabled: false,	// 防重复点击
			sorter: 'DESC',		// 排序方式 ASC： 正序 DESC: 倒叙
			meanuList: []
		}
	}
	componentDidMount(){
		this.queryTableList();
		this.queryMenuListForTree();
	}
	addModel = (ref) => {
		this.addRoleModel = ref;
	}
	/**
	 * 函数描述: 查询菜单树
	 */
	queryMenuListForTree = () => {
		this.$http.menuListForTree({}).then((res) => {
			if (res.code === 200) {
				res.data.map((v,index)=>{
					v.key = v.menuId
					v.title = v.menuName
					if(v.chirdren){
						v.chirdren.map((l,i)=>{
							l.key =l.menuId
							l.title = l.menuName
							if(l.chirdren){
								l.chirdren.map((li,inx)=>{
									li.key =li.menuId
									li.title = li.menuName
								})
							}
						})
					}

				})
				this.setState({
					meanuList:res.data
				})
			}
		})
	}
    /**
     * 函数描述: 查询角色数据
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} rolesName   角色名称
     * @param {string} sortParam    排序方式 ASC： 正序 DESC: 倒叙
     * @return {Object} 
     */
    queryTableList = (pageNo=1, pageSize=10) =>{
        this.setState({
			loading: true
		},() => {
			const params = {
				...this.state.searchVale,
				pageNo,
				pageSize,
				sortParam: this.state.sorter
			}
			this.$http.roleList(params).then((res) => {
				if(res.code == 200){
					const data = res.data;
					this.setState({
						loading: false,
						dataList: data.list,
						total: data.total
					})
				}
			}).catch((err) => {
				this.setState({
					loading: false,
				})
			})
		})
	}
    /**
     * 函数描述: 高级搜索
     * @param {Object} value   高级搜索条件
     * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
     */
    handleSearchBtn = (value) =>{
        this.setState({
			searchVale: {...value},
			pageNo:1
        },() => {
            this.queryTableList();
        })
	}
    /**
     * 函数描述: 切换列表页码
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
     * @return {Object} 请求pageNo页列表数据
     */
    handleChangePage = (page,sorter) => {
		let sorterOrder = this.state.sorter
		if (sorter.order) {
			sorterOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}
		this.setState({
			sorter: sorterOrder,
			pageNo:page.current,
			pageSize:page.pageSize
		},() => {
			this.queryTableList(page.current,page.pageSize);
		})
	}
    /**
     * 函数描述: 新增角色
     * @return 打开新增弹框
     */
	handleAddSubmit = () => {
		this.setState({
			addModalShow: true,
			modalType: 0,
			roleItem: {}
		})
	}
	// 关闭弹框
	handleCancelModal = () => {
		this.setState({
			addModalShow: false
		})
	}
    /**
     * 函数描述: 删除角色
     * @param {Object} rolesId 角色id
     * @return {Object} 刷新当前页数据
     */
	handleDelete = (rolesId) => {
		this.$http.deleteRoles({rolesId}).then(res => {
			if (res.code === 200) {
				const { pageNo, pageSize } = this.state;
				this.queryTableList(pageNo,pageSize);
			}
		})
	}
    /**
     * 函数描述: 编辑角色
     * @return 打开新增弹框
     */
	handleEditSubmit = (item) => {
		// this.refs.addRoleModal.resetFields(); // 避免数据闪现
		// item.id = Math.random();
		// item.permissionList={
		// 	0: ["dbsx", "ybsx", "lcdb", "wfqd"],
		// 	1: ["yghmc"],
		// 	2: []
		// }
		console.log(item)
		this.$http.selectByPrimaryKey({
			rolesId:item.rolesId
		}).then((res) => {
			this.setState({
				disabled: false
			},() => {
				if (res.code === 200){
					this.setState({
						addModalShow: true,
						modalType: 1,
						roleItem: res.data
					})
				}
			})
		})
	}
    /**
     * 函数描述: 修改或新增确认
     * @param {string} type 弹框类型 0：新增 1：修改
     * @param {Object} value 弹框各项值
     * @return 
     */
	handleAddSure = (type,value) => {
		const { disabled } = this.state;
		if(!disabled) {
			this.setState({
				disabled: true
			},() => {
				if (type === 0) { // 新增弹框
					this.$http.addRoles({
						...value
					}).then((res) => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								this.queryTableList();
							}
						})
					})
				}else{
					this.$http.updateRoles({
						...value,
						rolesId: this.state.roleItem.rolesId
					}).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								const { pageNo, pageSize } = this.state;
								this.queryTableList(pageNo, pageSize);
							}
						})
					})
				}
			})
		}
	}
	render(){
		const { dataList, total, pageNo, pageSize, loading, addModalShow, modalType, roleItem,meanuList } = this.state;
		return (
		<div className="role-management">
			<div className="crumbs">角色管理</div>
			{/* 搜索框 */}
			<SearchForm handleSearchBtn={this.handleSearchBtn} />
			{/* 角色数据 */}
			<div className="role-box wrap-card">
				<Button className="addButton" type="primary"  onClick={this.handleAddSubmit}>添加</Button>
				<Table 
					loading={loading} 
					data={dataList} 
					total={total}
					pageNo={pageNo}
					pageSize={pageSize}
					handleChangePage={this.handleChangePage} // 切换页码
					handleDelete={this.handleDelete}		 // 删除角色
					handleEditSubmit={this.handleEditSubmit} // 编辑角色
				/>
			</div>
			{/* 新增或修改角色 */}
			{addModalShow && <AddModal
				addModalShow={addModalShow}
				type={modalType}
				roleItem={roleItem}
				meanuList={meanuList}
				handleCancel={this.handleCancelModal}
				handleAddSure={this.handleAddSure}
			/>}
		</div>
		)
	}
}

export default RoleManagement
