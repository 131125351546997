/**
 * @fileoverview 工作台-未完善名单-搜索
 * @author  赵娟
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  TreeSelect,
} from "antd";
import moment from "moment";
import "./index.less";

const FormItem = Form.Item;
const Option = Select.Option;
const MonthPicker = DatePicker.MonthPicker;
const { SHOW_PARENT, TreeNode } = TreeSelect;
class SearchAttendancelist extends Component {
  // 提交表单
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll(
      (err, values) => {
        if (err) {
          return false;
        }
        this.props.handleSearchBtn(values);
      }
    );
  };
  handleReset = () => {
    this.props.form.resetFields();
    this.props.handleSearchBtn({});
  };
  querydepartmentId = (open) => {
    if (open) {
      this.props.querydepartmentId();
    }
  };

  // 部门查询树状结构
  getTreeNode = (item) => {
    return (
      <TreeNode
        key={item.departmentSonId}
        value={item.departmentSonId}
        title={item.departmentSonName}
      >
        {item.dsTree
          ? item.dsTree.map((c_item) => {
              return this.getTreeNode(c_item);
            })
          : ""}
      </TreeNode>
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="search-form">
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
        >
          <Row gutter={24}>
            <Col span={5}>
              <FormItem label="部门">
                {getFieldDecorator("departmentId")(
                  <TreeSelect
                    showSearch
                    style={{ height: 32 }}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="请选择"
                    allowClear
                    // treeDefaultExpandAll
                    onChange={this.onChange}
                    showCheckedStrategy={SHOW_PARENT}
                    treeNodeFilterProp="title"
                  >
                    {(this.props.departmentList || []).map(
                      (item) => {
                        return this.getTreeNode(item);
                      }
                    )}
                  </TreeSelect>
                  // <Select
                  //   showSearch
                  //   placeholder="请选择"
                  //   allowClear
                  //   onDropdownVisibleChange={
                  //     this.querydepartmentId
                  //   }
                  // >
                  //   {(this.props.departmentList || []).map(
                  //     (item) => {
                  //       return (
                  //         <Option
                  //           key={item.departmentId}
                  //           value={item.departmentId}
                  //         >
                  //           {item.departmentName}
                  //         </Option>
                  //       );
                  //     }
                  //   )}
                  // </Select>
                )}
              </FormItem>
            </Col>
            <Col span={5}>
              <Button onClick={this.handleReset}>
                重置
              </Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 14 }}
              >
                查询
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(
  Form.create()(SearchAttendancelist)
);
