import React, { Component } from "react";

export default class CalendarFooter extends Component {
  render() {
    return (
      <div className="calendarFooter">
        <span className="footer-span footer-today">今天</span>
        <span className="footer-span footer-leave">请假</span>
      </div>
    );
  }
}
