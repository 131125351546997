/**
* @fileoverview 个人中心---个人档案
* @author  蒋西园
*/
import React,{ Component } from 'react'
import './index.less'
import { Table, Modal } from 'antd';
import defaultPicture from '../../../assets/image/default-picture.png';
import equipment from '../../../assets/image/equipment.png';
import { personalInformation } from '../../personnelMatters/data'
import moment from 'moment'

class PersonalFiles extends Component{
	constructor(props){
		super(props)
		this.state = {
            previewVisible: false, // 预览弹框
            previewUrl: '',        // 预览地址
            jobDetail:null,
            information: {
                name: '蒋小园',
                phone: '18437980170',
                email: '1572720803@qq.com',
                zhanghao: '123654',
                time: '2020-02-06',
                bumen: '流程与IT',
                post: '鼓励师',
                dataList: [{
                    time: '2018/01/01 ~ 2019/12/23',
                    name: '不知名',
                    post: '小白',
                    money: '100000',
                    people: '仙女',
                    phone: '18437980170'
                }]
            },
            tabelData:null,
        }
        this.columns= [{
            title: '时间',
            dataIndex: 'time'
        },{
            title: '所在公司',
            dataIndex: 'company'
        },{
            title: '工作岗位',
            dataIndex: 'job'
        },{
            title: '薪资',
            dataIndex: 'salary'
        },{
            title: '证明人',
            dataIndex: 'witness'
        },{
            title: '联系方式',
            dataIndex: 'contactWay'
        }]
	}
	componentDidMount(){
        console.log(JSON.parse(localStorage.getItem('userData')))
        this.setState({
            staffId:JSON.parse(localStorage.getItem('userData')).staffId
        },()=>{
            this.queryStaffInfoDetail()
            this.queryWorkExperience()
            this.queryStaffAnnex()
        })
    }
/**
     * 函数描述: 个人信息
     * @param {String} staffId 员工id this.props.location.state.staffId
     */
    queryStaffInfoDetail = () => {
        const { staffId } = this.state;
        this.$http.getStaffInfoForRoster({ staffId }).then(res => {
            if (res.code === 200) {
                const { jobDetail } = this.state;
                this.setState({
                    jobDetail: {...jobDetail,...res.data}
                })
            }
        })
    }
    /**
     * 函数描述: 工作经验
     * @param {String} staffId 员工id this.props.location.state.staffId
     */
    queryWorkExperience = () => {
        const { staffId } = this.state;
        this.$http.listStafWorkExperienceByStaffId({ staffId }).then(res => {
            if (res.code === 200) {
                res.data.map((v,i)=>{
                    v.time=moment(v.startWorkTime).format('YYYY-MM-DD')+'-'+moment(v.endWorkTime).format('YYYY-MM-DD')
                    // v.key = i
                })
                this.setState({
                    tabelData: res.data
                })
            }
        })
    }
    /**
     * 函数描述: 查询附件
     * @param {String} staffId 员工id this.props.location.state.staffId
     */
    queryStaffAnnex = () => {
        const { staffId } = this.state;
        this.$http.listStaffAnnexByStaffId({ staffId }).then(res => {
            if (res.code === 200) {
                this.setState({
                    imgList: res.data
                })
            }
        })
    }
    // 打开预览弹框
    handlePreview = (url) => {
        this.setState({
            previewVisible: true,
            previewUrl: url
        })
    }
    // 取消预览弹框
    handleCancel = () => {
        this.setState({
            previewVisible: false
        })
    }
	render(){
        const { information, previewVisible, previewUrl,jobDetail } = this.state;
		return (
            <div className="personal-files">
                <div className="crumbs">个人档案</div>
                <div className="personal-wrap">
                    {/* 基本信息 */}
                    <div className="if-card">
                        {/* tip */}
                        <div className="if-card-tip">
                            基本信息
                        </div>
                        <div className="if-card-wrap">
                            <li className="item">
                                <span className="item-label">
                                    姓名：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.staffName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    性别：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.sexName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    婚姻状况：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.marriageStatusName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    年龄：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.age}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    出生日期：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.birthday}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    实际生日：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.actualBirthday}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    证件类型：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.certificatesTypeName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    证件号码：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.certificatesNumber}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    星座：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.constellationName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    邮箱：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.email}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    手机：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.phone}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    政治面貌：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.politicalOutlookName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    籍贯：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.nativePlaceProvinceName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    户口性质：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.residenceNatureName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    民族:
                                </span>
                                <span className="item-value">
                                    {jobDetail===null?'':jobDetail.nationName}
                                </span>
                            </li>
                            <li className="item only-line">
                                <span className="item-label">
                                    户籍地址：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.registrationAddress}
                                </span>
                            </li>
                            
                            <li className="item only-line">
                                <span className="item-label">
                                    现地址：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.nowAddress}
                                </span>
                            </li>
                        </div>
                    </div>
                    {/* 紧急联系人 */}
                    <div className="if-card">
                        <div className="if-card-tip">
                            紧急联系人
                        </div>
                        <div className="if-card-wrap">
                            <li className="item">
                                <span className="item-label">
                                    紧急联系人：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.urgentContactPeople}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    关系：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.relationshipName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    电话：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.urgentContacPhone}
                                </span>
                            </li>
                        </div>
                    </div>
                    {/* 银行信息 */}
                    <div className="if-card">
                        <div className="if-card-tip">
                            银行信息
                        </div>
                        <div className="if-card-wrap">
                            <li className="item">
                                <span className="item-label">
                                    银行卡号：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.bankNumber}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    开户行信息：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.bankInformation}
                                </span>
                            </li>
                        </div>
                    </div>
                    {/* 教育 */}
                    <div className="if-card">
                        <div className="if-card-tip">
                            教育
                        </div>
                        <div className="if-card-wrap">
                            <li className="item">
                                <span className="item-label">
                                    毕业时间：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.graduationTime}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    毕业院校：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.graduationSchool}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    最高学历：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.highestEducationName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    学位：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.bachelorScienceName}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    专业：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.profession}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    学历证书编号：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.educationCertificateNumber}
                                </span>
                            </li>
                            <li className="item">
                                <span className="item-label">
                                    资格证书：
                                </span>
                                <span className="item-value">
                                    {jobDetail==null?'':jobDetail.qualificationsCertificate}
                                </span>
                            </li>
                        </div>
                    </div>
                    {/* 工作经历 */}
                    <div className="if-card">
                        <div className="if-card-tip">
                            工作经历
                        </div>
                        <Table
                            className="card-table"
                            rowKey={(record) => { return `${Math.random(10)}` }}
                            columns={this.columns}
                            dataSource={this.state.tabelData}
                            pagination={false}
                        />
                    </div>
                    
                    {/* 附件 */}
                    <div className="if-card">
                        <div className="if-card-tip">
                            附件（5）
                        </div>
                        <div className="if-card-wrap">
                            {
                                this.state.imgList==null?'':this.state.imgList.map((v,index)=>{
                                    return<div className="image-item">
                                    <div className="image flex flex-align-center flex-justify-center">
                                        <img onClick={() => this.handlePreview(v.annexUrl)} className="upload-image" src={v.annexUrl} alt="upload"/>
                                    </div>
                                    <p className="image-name">{v.annexType==1?'毕业证':v.annexType==2?'学位证':v.annexType==3?'身份证正面':v.annexType==4?'身份证反面':v.annexType==5?'其他附件':'其他附件'}</p>
                                </div>
                                })
                            }
                            
                        </div>
                    </div>
                </div>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewUrl} />
                </Modal>
            </div>
		)
	}
}

export default PersonalFiles
