/**
 * @fileoverview 人事管理---员工花名册
 * @author  蒋西园
 */
import React, { Component } from "react";
import "./index.less";
import axios from "axios";

import { Button, message, Upload, Modal, Spin } from "antd";
import LeftTree from "../../../components/leftTreeTwo"; // 左侧树
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表
const { confirm } = Modal;

class EmployeeRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDelate: true,
      loading: false,
      loadSpin: false,
      loadSpinCHU: false,
      dataList: [], // 花名册列表数据
      total: 0, // 花名册总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {}, // 搜索条件
      disabled: false, // 防重复点击
      sorter: "", // 排序方式 ASC： 正序 DESC: 倒叙
    };
  }
  componentDidMount() {
    const routerData = this.props.history.location.state;
    if (routerData) {
      this.setState({ searchVale: routerData.searchVale });
    }
  }

  /**
   * 函数描述: 查询考勤数据
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} staffName 姓名
   * @param {string} postId    岗位id
   * @param {string} isLeave   是否离职 true-离职 false-未离职
   * @param {string} sorter    排序方式 asc： 正序 desc: 倒叙
   * @return {Object}
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    const departmentIds = JSON.parse(
      JSON.stringify(this.state.searchVale).replace(
        /departmentId/g,
        "departmentIds"
      )
    );
    console.log("departmentIds ->", departmentIds);
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
          departmentId:
            sessionStorage.employeeRosterSelectedKeys,
          departmentIdStr:
            (sessionStorage.employeeRosterSelectedKeys &&
              sessionStorage.employeeRosterSelectedKeys.split(
                "&"
              )[0]) ||
            this.state.checkedTreeId,
          pageNo,
          pageSize,
          sort: this.state.sorter,
        };
        this.$http
          .personnelMattersList(params)
          .then((res) => {
            if (res.code == 200) {
              const data = res.data;
              this.setState({
                loading: false,
                pageNo: data.pageNum,
                pageSize: data.pageSize,
                dataList: data.list || [],
                total: data.total,
              });
            } else {
              res.message && message.error(res.message);
              this.setState({
                loading: false,
                dataList: res.data.list || [],
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    // if (!Object.values(value).join("").length) {
    //   console.log(123);
    // }
    this.setState(
      {
        searchVale: { ...this.state.searchVale, ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };
  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (page, sorter) => {
    let sorterOrder = "";
    if (sorter.order) {
      sorterOrder =
        sorter.order === "ascend" ? "asc" : "desc";
    }
    this.setState(
      {
        sorter: sorterOrder,
      },
      () => {
        this.queryTableList(page.current, page.pageSize);
      }
    );
  };
  /**
   * 新增跳转新增页面
   */
  handleAddSubmit = () => {
    // var example = window.location.protocol;
    // var example1 = window.location.host;
    // let url = example+'//'+example1+'/personnelMatters/addEmployee'
    // window.open(url)

    this.props.history.push({
      pathname: "addEmployee",
      state: { searchVale: this.state.searchVale },
    });
  };
  // handleAddSubmitTwo = (isDelate) => {
  // 	this.props.history.push({ pathname: 'addEmployee', state: { isDelate } });
  // }

  /**
   * 函数描述: 删除花名册
   * @param {Object} staffId 员工id
   * @return {Object} 刷新当前页数据
   */
  handleDelete = (staffId) => {
    this.$http
      .deleteStaffRosterByStaffId({ staffId })
      .then((res) => {
        if (res.code === 200) {
          const { pageNo, pageSize } = this.state;
          this.queryTableList(pageNo, pageSize);
        }
      });
  };
  // 下载花名册模板
  handleDownload = () => {
    this.$http.downloadStaffTemplate();
  };
  // 导出axios
  axiosExport = (params, url) => {
    this.setState({
      loadSpinCHU: true,
    });
    // url = url + "?";
    // Object.getOwnPropertyNames(params).forEach(function(key) {
    //   url = url + key + "=" + params[key] + "&";
    // });
    console.log(params);
    // axios({
    //   method: "post",
    //   url,
    //   params,
    //   responseType: "blob",
    // })
    axios
      .post(url, params, { responseType: "blob" })
      .then((res) => {
        let headers = res.headers;
        let contentType = headers["content-type"];
        if (!res.data) {
          this.setState({
            loadSpin: false,
          });
          return false;
        } else {
          this.setState({
            loadSpinCHU: false,
          });
          const blob = new Blob([res.data], {
            type: contentType,
          });
          let contentDisposition =
            res.headers["content-disposition"];
          let fileName = "unknown";
          if (contentDisposition) {
            fileName = window.decodeURIComponent(
              res.headers["content-disposition"].split(
                "="
              )[1]
            );
          }
          // 非IE下载
          if (window.navigator.msSaveOrOpenBlob) {
            // IE10+下载
            window.navigator.msSaveBlob(blob, fileName);
          } else {
            // 非IE下载
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob); // 创建下载的链接
            link.download = fileName; // 下载后文件名
            link.style.display = "none";
            document.body.appendChild(link);
            link.click(); // 点击下载
            window.URL.revokeObjectURL(link.href); // 释放掉blob对象
            document.body.removeChild(link); // 下载完成移除元素
          }
        }
      });
  };
  // 导出花名册
  handleExport = () => {
    const { searchVale } = this.state;
    searchVale.departmentId =
      searchVale.departmentId == "all"
        ? ""
        : searchVale.departmentId;
    searchVale.sort = this.state.sorter;
    console.log(searchVale);
    const params = {
      departmentIdStr: this.state.checkedTreeId,
      ...searchVale,
    };
    this.axiosExport(
      params,
      this.$http.exportStaffRoster()
    );
  };
  // 导入花名册
  handleUploadFile = (uploadFile) => {
    let formdata = new FormData(); //创建文件流
    formdata.append("file", uploadFile.file);
    let _this = this;
    this.setState({
      loadSpin: true,
    });
    this.$http
      .staffRosterImport(formdata)
      .then((res) => {
        const reader = new FileReader();
        reader.readAsText(res, "utf-8");
        reader.onload = () => {
          let result = JSON.parse(reader.result);
          if (result.data && result.code == 200) {
            console.log(result.data);
            this.setState({
              loadSpin: false,
            });
            if (result.data.success == false) {
              confirm({
                title: "部分数据导入失败!",
                content: "请下载查看未导入的数据",
                okText: "下载",
                cancelButtonProps: null,
                onOk() {
                  // this.$http.exportErrorData({salaryErrorCacheKey: result.data})
                  _this.axiosExport(
                    { importId: result.data.importId },
                    _this.$http.exportErrorData()
                  );
                },
                onCancel() {},
              });
            } else {
              Modal.success({
                content: "导入成功!",
                onOk() {
                  message.destroy();
                  message.success(result.message);
                  _this.queryTableList();
                },
              });
            }
          } else {
            message.destroy();
            message.error(result.message);
          }
        };
      })
      .catch((err) => {});
  };
  flat(arr) {
    return [].concat(
      ...arr.map((item) =>
        [].concat(item, ...this.flat(item.distree))
      )
    );
  }

  getTreeCheckedTreeId = (val, list, node) => {
    // const arr = [];
    // let curCheckedIdsArr = [];
    // let curChecked;
    // list.map((item) => {
    //   arr.push(JSON.stringify(item));
    // });
    // arr.map((item) => {
    //   if (item.includes(val)) {
    //     curChecked = item;
    //   }
    // });
    // const fn = (data) => {
    //   data.map((item) => {
    //     curCheckedIdsArr.push(item.departmentSonId);
    //     item.dsTree && fn(item.dsTree);
    //   });
    // };
    // const values = curChecked
    //   ? [JSON.parse(curChecked)]
    //   : [];
    // fn(values);
    // return curCheckedIdsArr;
  };

  changeDepartmentId = (val, list) => {
    // let result = []
    // if (list&&list.length) {
    //   result = this.flat(list)
    // }
    const value = list.filter(
      (item) => item.departmentSonId === val[0]
    );
    let id = [];
    if (value.length) {
      id = value[0].dsTree
        ? value[0].dsTree.map(
            (item) => item.departmentSonId
          )
        : [];
    }
    id.unshift(val[0]);
    console.log(id);
    this.state.searchVale.departmentId = val[0];
    this.setState(
      {
        checkedTreeId: val[0] === "all" ? "" : id.join(","),
        searchVale: this.state.searchVale,
      },
      () => {
        this.queryTableList();
      }
    );
  };
  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
      isDelate,
      loadSpin,
      searchVale,
    } = this.state;
    return (
      <Spin tip="导入中..." spinning={this.state.loadSpin}>
        <Spin
          tip="导出中..."
          spinning={this.state.loadSpinCHU}
        >
          <div className="employee-roster">
            <div className="crumbs">员工花名册</div>
            <div className="employee-wrap">
              {/* 左侧树 */}
              <LeftTree
                onSelect={this.changeDepartmentId}
                type="员工花名册"
                selectedKeys="employeeRosterSelectedKeys"
                selectedDepartmentIds="employeeRosterSelectedDepartmentIds"
                expandedKeys="employeeRosterExpandedKeys"
              />
              <div className="right-wrap">
                <SearchForm
                  handleSearchBtn={this.handleSearchBtn}
                  searchVale={searchVale} // 保存搜索条件
                />
                <div className="employee-box wrap-card">
                  <Button
                    className="addButton"
                    type="primary"
                    onClick={this.handleAddSubmit}
                  >
                    添加
                  </Button>
                  {/* <span style={{ color: "#647fe9", cursor: 'pointer' }} onClick={this.handleAddSubmitTwo.bind(isDelate)}>编辑</span> */}
                  {/* <Button className="addButton"  onClick={this.handleAddSubmit}>导入</Button> */}
                  <Upload
                    name="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    customRequest={this.handleUploadFile}
                    showUploadList={false}
                    onChange={this.handleChangeFile}
                  >
                    <Button className="addButton">
                      导入
                    </Button>
                  </Upload>
                  <Button
                    className="addButton"
                    onClick={this.handleExport}
                  >
                    导出
                  </Button>
                  <span
                    className="download"
                    onClick={this.handleDownload}
                  >
                    花名册导入模板下载
                  </span>
                  <Table
                    loading={loading}
                    history={this.props.history}
                    data={dataList}
                    total={total}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    handleChangePage={this.handleChangePage} // 切换页码
                    handleDelete={this.handleDelete} // 删除
                    searchVale={searchVale} // 保存搜索条件
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Spin>
    );
  }
}

export default EmployeeRoster;
