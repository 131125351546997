/**
* @fileoverview 人事管理---合同管理 -- 续签合同
* @author  蒋西园
*/
import React,{Component} from 'react';
import './index.less'
import { Modal, Form, DatePicker, Select } from "antd";
import moment from 'moment';
const FormItem = Form.Item;
const Option = Select.Option;

class RenewContract extends Component{
    constructor(props) {
        super(props);
        this.state = {
            dataSource: null,
           
        }
        
    }
    componentDidMount =()=>{
    }
    // 点击确定
    handleAddSure = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const data = {...values};
                let arr = data.contractType.split('&')
                data.contractType = arr[0]
                data.hireNature = arr[1]
                data.contractStartday = moment(Date.parse(values.contractStartday)).format('YYYY/MM/DD')
                data.contractMaturityday = moment(Date.parse(values.contractMaturityday)).format('YYYY/MM/DD')
                this.props.handleAddSure(data)
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    disabledDate = (current) => {
		return current &&  current && current < moment().subtract(1, "days");
    }
    
    // 新合同开始
    disabledStartDate = startValue => {
        const endValue = this.props.form.getFieldValue('contractMaturityday');
        const newStart = moment(startValue).format('YYYY/MM/DD');
        const contractMaturityday = moment(this.props.contractItem.contractMaturityday).format('YYYY/MM/DD');
        const { contractTypeName } = this.props.contractItem
        
        const contractType = this.props.form.getFieldValue('contractType')

        if((contractTypeName==="劳务合同"||contractTypeName==="实习合同")&&contractType==="LABOR&FULL_TIME"){
            return false
        }
    
        if (newStart < contractMaturityday) {
            return true;
        }
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };

    // 新合同结束
    disabledEndDate = endValue => {
        const startValue = this.props.form.getFieldValue('contractStartday')
        const contractType = this.props.form.getFieldValue('contractType')
        const { contractStartday, contractTypeName } = this.props.contractItem
        if((contractTypeName==="劳务合同"||contractTypeName==="实习合同")&&contractType==="LABOR&FULL_TIME"){
            return false
        }
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };
    queryQuitType = () => {
        this.$http["selectListByGroup"]({
          dicGroup: "CONTRACT_NATURE"
        }).then(res => {
          if (res.code === 200) {
            res.data.map((v,i)=>{
                if(v.dicKey=='LABOR'){
                    v.dicKey=v.dicKey+'&FULL_TIME'
                }else if(v.dicKey=='SERVICE'){
                    v.dicKey=v.dicKey+'&PART_TIME'
                }else if(v.dicKey=='INTERNSHIP'){
                    v.dicKey=v.dicKey+'&INTERNSHIP'
                }
            })
            this.setState({
                dataSource: res.data || []
            });
          }
        });
      };

     // select选中后huidiao
     onInputKeyDown = (value,) => {
        let arr = value?value.split('&'):[]
        if(arr[1]=='FULL_TIME'){
            this.props.contractItem.hireNature=arr[1]
            this.props.contractItem.hireNatureName='全职'
        }else if(arr[1]=='PART_TIME'){
            this.props.contractItem.hireNature=arr[1]
            this.props.contractItem.hireNatureName='兼职'
        }else if(arr[1]=='INTERNSHIP'){
            this.props.contractItem.hireNature=arr[1]
            this.props.contractItem.hireNatureName='实习'
        }
    }
    onChange = (field, value) => {
        this.setState({
          [field]: value,
        });
      };
    
      onStartChange = value => {
        this.onChange('startValue', value);
      };
    
      onEndChange = value => {
        this.onChange('endValue', value);
      };
    render(){
        const { renewModalShow, form, contractItem } = this.props;
        const { getFieldDecorator } = form;
        return(
            <Modal
                className="renewContractModal"
                title="续签合同"
                visible={renewModalShow}
                onCancel={this.handleCancel}
                onOk={this.handleAddSure}
                maskClosable={false}
            >
                <Form labelCol={{ span: 13 }} wrapperCol={{ span: 11 }}>
                    <div className="renewContract-box">
                        <FormItem 
                            className='width50Item'
                            label="员工">
                                <span>{contractItem.staffName}</span>
                        </FormItem>
                        <FormItem 
                            className='width50Item'
                            label="工号">
                                <span>{contractItem.staffCode}</span>
                        </FormItem>
                        <FormItem 
                            className='width50Item'
                            label="部门名称">
                                <span>{contractItem.departmentName || '--'}</span>
                        </FormItem>
                        <FormItem 
                            className='width50Item'
                            label="岗位">
                                <span>{contractItem.postName}</span>
                        </FormItem>
                        <FormItem 
                            className='width50Item'
                            label="合同编号">
                                <span>{contractItem.contractCode}</span>
                        </FormItem>
                        
                        <FormItem 
                            className='width50Item'
                            label="合同类型">
                                <span>{contractItem.contractTypeName}</span>
                        </FormItem>
                        <FormItem 
                            className='width50Item'
                            label="签订总次数">
                                <span>{contractItem.signTotalFrequency}</span>
                        </FormItem>
                        <FormItem 
                            className='width50Item'
                            label="是否为他公司转签">
                                <span>{{true: '是',false: '否'}[contractItem.isOtherCompanyTransfer]}</span>
                        </FormItem>
                        <FormItem 
                            className='width50Item'
                            label="上次合同起始日">
                                <span>{contractItem.contractStartday}</span>
                        </FormItem>
                        <FormItem 
                            className='width50Item'
                            label="上次合同到期日">
                                <span>{contractItem.contractMaturityday}</span>
                        </FormItem>
                        <FormItem 
                            labelAlign='left'
                            className='width50Item only-line'
                            label="合同类型">
                            {getFieldDecorator('contractType', {
                                rules: [
                                    { required: true, message: '请选择合同类型' },
                                ],
                            })(
                                <Select placeholder="请选择"
                                onDropdownVisibleChange={this.queryQuitType}

                                onChange={(value) => this.onInputKeyDown(value)}
                                allowClear >
                                    {/* <Option value="实习合同">实习合同</Option>
                                    <Option value="劳务合同">劳务合同</Option> */}
                                   
                                    {
                                        this.state.dataSource==null?'':this.state.dataSource.map((v,inx)=>{
                                            return <Option value={v.dicKey} key={inx}>{v.dicValue}</Option>
                                        })
                                    }
                                </Select>
                            )}
                        </FormItem>
                        <FormItem 
                            labelAlign='left'
                            className='width50Item only-line'
                            label=" 用工性质">
                            {getFieldDecorator('hireNature',{
                                initialValue: contractItem.hireNature,
                                rules: [
                                    { required: true }
                                ],
                            })(
                                <span>{this.props.form.getFieldValue('contractType')==null?'--':contractItem.hireNatureName}</span>
                            )}
                        </FormItem>
                        <FormItem 
                            labelAlign='left'
                            className='width50Item only-line'
                            label="合同起始日">
                            {getFieldDecorator('contractStartday', {
                                rules: [
                                    { required: true, message: '请选择合同起始日', }
                                ],
                            })(
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                    format="YYYY/MM/DD"
                                    placeholder="请选择"
                                    onChange={this.onStartChange}
                                />
                            )}
                        </FormItem>
                        <FormItem 
                            labelAlign='left'
                            className='width50Item only-line'
                            label="合同到期日">
                            {getFieldDecorator('contractMaturityday', {
                                rules: [
                                    { required: true, message: '请选择合同到期日' }
                                ],
                            })(
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                    onChange={this.onEndChange}
                                    format="YYYY/MM/DD"
                                    placeholder="请选择"
                                />
                            )}
                        </FormItem>
                    </div>
                </Form>
            </Modal>
        )
    }
}
export default Form.create()(RenewContract)