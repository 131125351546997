/**
 * @fileoverview 人事管理---薪资管理
 * @author  蒋西园
 */
import React, { Component } from "react";
import "./index.less";
import { Button, message, Upload } from "antd";
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表

class SalaryManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataList: [], // 薪资列表数据
      total: 0, // 薪资总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {departmentIds: []}, // 搜索条件
      disabled: false, // 防重复点击
      departmentList: [], // 部门下拉
    };
  }
  componentDidMount() {
    this.queryTableList();
    this.querydepartmentId();
  }

  /**
   * 函数描述: 查询部门名称
   * @param {string} type   类型（1：项目 2：部门）
   */
  querydepartmentId = () => {
    this.$http["departmentPermissions"]({}).then((res) => {
      if (res.code === 200) {
        this.setState({
          departmentList: res.data || [],
        });
      }
    });
  };
  /**
   * 函数描述: 查询薪资数据
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} departmentId     部门id
   * @param {string} cycleMonth   	薪资年月（格式：2019/9）
   * @param {string} staffName   员工姓名
   * @return {Object}
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
          pageNo,
          pageSize,
        };
        this.$http
          .salaryList(params)
          .then((res) => {
            if (res.code == 200) {
              this.setState({
                loading: false,
                pageNo,
                pageSize,
                dataList: res.data.list,
                total: res.data.total,
              });
              if (!res.data.list.length) {
                this.setState({
                loading: false,
                dataList: [],
              });
              }
            } else {
              this.setState({
                loading: false,
                dataList: [],
              })
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };
  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (pageNo, pageSize) => {
    this.queryTableList(pageNo, pageSize);
  };

  // 薪资导入模板下载
  handleDownload = () => {
    this.$http.downloadSalaryTemplate();
  };

  // 导入
  handleUploadFile = (uploadFile) => {
    let formdata = new FormData(); //创建文件流
    formdata.append("file", uploadFile.file);
    this.$http
      .importSalary(formdata)
      .then((res) => {
        const reader = new FileReader();
        reader.readAsText(res, "utf-8");
        reader.onload = () => {
          let result = JSON.parse(reader.result);
          if (result.data) {
            this.$http.exportSalary({
              salaryErrorCacheKey: result.data,
            });
            message.destroy();
            message.error("操作失败错误信息已经导出");
          } else if (result.code == 200) {
            message.destroy();
            message.success(result.message);
            this.queryTableList();
          } else {
            message.destroy();
            message.error(result.message);
          }
        };
      })
      .catch((err) => {});
  };
  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
      departmentList,
    } = this.state;
    return (
      <div className="salary-management">
        <div className="crumbs">薪资管理</div>
        {/* 搜索框 */}
        <SearchForm
          handleSearchBtn={this.handleSearchBtn}
          departmentList={departmentList}
          querydepartmentId={this.querydepartmentId}
        />
        {/* 薪资数据 */}
        <div className="salary-box wrap-card">
          <Upload
            name="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            customRequest={this.handleUploadFile}
            showUploadList={false}
            onChange={this.handleChangeFile}
          >
            <Button className="addButton" type="primary">
              导入
            </Button>
          </Upload>
          <span
            className="download"
            onClick={this.handleDownload}
          >
            薪资导入模板下载
          </span>
          <Table
            loading={loading}
            data={dataList}
            total={total}
            pageNo={pageNo}
            pageSize={pageSize}
            handleChangePage={this.handleChangePage} // 切换页码
          />
        </div>
      </div>
    );
  }
}

export default SalaryManagement;
