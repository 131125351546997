/**
 * @fileoverview 系统管理---账号设置 -- 搜索条件
 * @author  蒋西园
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
} from "antd";
import moment from "moment";
import "./index.less";

const FormItem = Form.Item;
const Option = Select.Option;
const MonthPicker = DatePicker.MonthPicker;

class SearchAttendancelist extends Component {
  // 提交表单
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll(
      (err, values) => {
        if (err) {
          return false;
        }
        const data = {};
        // 去除含有undefined的item
        Object.keys(values).forEach((res) => {
          if (values[res] && res === "cycleMonth") {
            data.cycleMonth = moment(
              Date.parse(values.cycleMonth)
            ).format("YYYY-MM");
          } else if (values[res]) {
            data[res] = values[res];
          }
        });
        this.props.handleSearchBtn(data);
      }
    );
  };
  handleReset = () => {
    this.props.form.resetFields();
    this.props.handleSearchBtn({});
  };
  querydepartmentId = (open) => {
    if (open) {
      this.props.querydepartmentId();
    }
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="search-form">
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
        >
          <Row gutter={24}>
            <Col span={5} style={{ marginLeft: "20px" }}>
              <FormItem label="姓名">
                {getFieldDecorator("staffName", {
                  rules: [
                    {
                      max: 32,
                      message: "请输入32字以内的名字",
                    },
                  ],
                })(
                  <Input
                    placeholder="请输入"
                    allowClear
                    autoComplete="new-password"
                  />
                )}
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem label="部门">
                {getFieldDecorator("departmentId")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    onDropdownVisibleChange={
                      this.querydepartmentId
                    }
                  >
                    {(this.props.departmentList || []).map(
                      (item) => {
                        return (
                          <Option
                            key={item.departmentId}
                            value={item.departmentId}
                          >
                            {item.departmentName}
                          </Option>
                        );
                      }
                    )}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={5}>
              <FormItem label="年月">
                {getFieldDecorator("cycleMonth")(
                  <MonthPicker format="YYYY-MM" />
                )}
              </FormItem>
            </Col>
            <Col span={5}>
              <Button onClick={this.handleReset}>
                重置
              </Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 14 }}
              >
                查询
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(
  Form.create()(SearchAttendancelist)
);
