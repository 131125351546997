/**
 * @description offer统计
 * @author zxz
 */
import React from "react";
import {
  Radio,
  Form,
  DatePicker,
  Row,
  Col,
  Button,
  Table,
  message,
} from "antd";
import TableModal from "./components/tableModal";
import ExportBtn from "../../../components/exportBtn";
import http from "../../../api/fetch";
import { listColumns } from "./columns";
import "./index.less";
import moment from "moment";
import "moment/locale/zh-cn";
import { map } from "lodash";

moment.locale("zh-cn");
const { MonthPicker, WeekPicker } = DatePicker;

class OfferStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curActiveWeekAndMonth: "week",
      modalTitle: "",
      modalColumns: "",
      modalDataSource: [],
      modalLoading: true,
      statisticsDataTableLoading: true,
      page: 1,
      pageSize: 10,
      total: 0,
      statisticsDataSource: [],
      curDateString: moment().format("YYYY-wo"),
      detailsPage: 1,
      detailsPageSize: 10,
      total: 0,
    };
  }
  componentDidMount() {
    this.queryOfferStatisticsList();
  }

  switchWeekAndMonth = (e) => {
    this.setState(
      {
        curActiveWeekAndMonth: e.target.value,
      },
      () => {
        this.setState({
          curDateString:
            e.target.value == "week"
              ? moment().format("YYYY-wo")
              : moment().format("YYYY-MM"),
        });
      }
    );
  };

  selectWeekAndMonth = (date, dateString) => {
    this.setState({ curDateString: dateString });
  };

  /**
   * @method 处理后台列表数据
   */
  handleParams = (list) => {
    let arr = [];
    map(list, (item) => {
      const {
        approvedQuantity,
        numberOfCandidates,
        numberOfEmployed,
        numberOfOffers,
        numberOfOverdueEmployees,
        postId,
        postName,
        quantityUnderApproval,
        staffId,
        staffName,
      } = item;
      const paramObj = {
        approvedQuantity: approvedQuantity
          ? approvedQuantity
          : 0,
        numberOfCandidates: numberOfCandidates
          ? numberOfCandidates
          : 0,
        numberOfEmployed: numberOfEmployed
          ? numberOfEmployed
          : 0,
        numberOfOffers: numberOfOffers ? numberOfOffers : 0,
        numberOfOverdueEmployees: numberOfOverdueEmployees
          ? numberOfOverdueEmployees
          : 0,
        postId: postId ? postId : "--",
        postName: postName ? postName : "--",
        quantityUnderApproval: quantityUnderApproval
          ? quantityUnderApproval
          : 0,
        staffId: staffId ? staffId : "--",
        staffName: staffName ? staffName : "--",
      };
      arr.push(paramObj);
    });
    return arr;
  };

  queryOfferStatisticsList = () => {
    this.setState({
      statisticsDataTableLoading: true,
      statisticsDataSource: [],
    });
    const {
      page,
      pageSize,
      curActiveWeekAndMonth,
      curDateString,
    } = this.state;
    this.$http
      .getOfferStatistics({
        type: curActiveWeekAndMonth,
        page,
        pageSize,
        time: curDateString,
      })
      .then((res) => {
        console.log("res->", res);
        if (
          res &&
          res.code == 200 &&
          res.data &&
          res.data.list
        ) {
          const { list, total } = res.data;
          console.log("list -->", list);
          console.log("total ->", total);
          const params = this.handleParams(list);
          this.setState({
            statisticsDataSource: params,
            total,
          });
        } else {
          res.code != 204 &&
            res.code != 200 &&
            message.warning(res.message);
        }
        this.setState({
          statisticsDataTableLoading: false,
        });
      });
  };

  /**
   * @method 打开modal
   */
  openTableModal = () => {
    this.refs.TableModalRef.openModal();
  };

  /**
   * @method 切换modal数据
   */
  switchModal = (params) => {
    const { title, columns, columnsType, staffId } = params;
    this.setState(
      {
        modalTitle: title,
        modalColumns: columns,
      },
      () => {
        this.openTableModal();
        this.queryDetails(columnsType, staffId);
      }
    );
  };

  /**
   * @method 查询详情
   */
  queryDetails = (columnType, staffId) => {
    this.setState({ modalLoading: true });
    const {
      detailsPage,
      detailsPageSize,
      curDateString,
      curActiveWeekAndMonth,
    } = this.state;
    console.log("详情Params ->", {
      columnType,
      detailsPage,
      detailsPageSize,
      staffId,
      time: curDateString,
      type: curActiveWeekAndMonth,
    });
    this.$http
      .getOfferDetails({
        columnType,
        detailsPage,
        detailsPageSize,
        staffId,
        time: curDateString,
        type: curActiveWeekAndMonth,
      })
      .then((res) => {
        console.log("getOfferDetails ->", res);
        if (res && res.code == 200) {
          this.setState({ modalDataSource: res.data.list });
        } else {
          this.setState({ modalDataSource: [] });
          message.warning(res.message);
        }
        this.setState({ modalLoading: false });
      });
  };

  /**
   * @method 生成报表
   */
  reportGeneration = () => {
    this.queryOfferStatisticsList();
  };

  handleOfferStatisticsTable = (pagination) => {
    this.setState({ page: pagination.current }, () => {
      this.queryOfferStatisticsList();
    });
  };

  /**
   * @method 文件导出
   */
  fileExport = () => {
    const url = this.$http.exportStatistics();
    const {
      curActiveWeekAndMonth,
      curDateString,
    } = this.state;
    console.log("params ->", {
      url,
      type: curActiveWeekAndMonth,
      time: curDateString,
    });
    http.downloadFile(url, {
      type: curActiveWeekAndMonth,
      time: curDateString,
    });
  };

  /**
   * @method 查看offer
   */
  viewOffers = (params) => {
    console.log("查看offer", params);
    console.log("props ->", this.props.history);
    const { businessKey, procTemplate } = params;
    this.navigationCheckin(businessKey, procTemplate);
  };

  /**
   * @method 导航到-入职流程-审核
   */
  navigationCheckin = (businessKey, procTemplate) => {
    var example = window.location.protocol;
    var example1 = window.location.host;

    if (procTemplate === "OFFER_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/offerApproval?offerProcId=${businessKey}&procTemplate=${procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/offerApproval?offerProcId=${businessKey}&procTemplate=${procTemplate}`
      );
    } else if (procTemplate === "ENTRY_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/checkin?inductionProcId=${businessKey}&procTemplate=${procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/checkin?inductionProcId=${businessKey}&procTemplate=${procTemplate}`
      );
    } else if (procTemplate === "BECOME_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/becomeWorker?positiveProcId=${businessKey}&procTemplate=${procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/becomeWorker?positiveProcId=${businessKey}&procTemplate=${procTemplate}`
      );
    } else if (procTemplate === "DIMI_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/quit?dimissionProcId=${businessKey}&procTemplate=${procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/quit?dimissionProcId=${businessKey}&procTemplate=${procTemplate}`
      );
    } else if (procTemplate === "SPON_DIMI_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/autoQuit?dimissionProcId=${businessKey}&procTemplate=${procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/autoQuit?dimissionProcId=${businessKey}&procTemplate=${procTemplate}`
      );
    } else if (procTemplate === "LEAVE_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/leave?leaveProcId=${businessKey}&procTemplate=${procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/leave?leaveProcId=${businessKey}&procTemplate=${procTemplate}`
      );
    } else if (procTemplate === "ATTE_PROC") {
      let url =
        example +
        "//" +
        example1 +
        `/workbench/monthlyAttendance?attendanceProcId=${businessKey}&procTemplate=${procTemplate}`;
      // window.open(url)
      this.props.history.push(
        `/workbench/monthlyAttendance?attendanceProcId=${businessKey}&procTemplate=${procTemplate}`
      );
    }
  };

  render() {
    const {
      curActiveWeekAndMonth,
      modalTitle,
      modalColumns,
      modalDataSource,
      modalLoading,
      statisticsDataSource,
      total,
      pageSize,
      page,
      statisticsDataTableLoading,
      curDateString,
    } = this.state;

    return (
      <div className="offer-statistics">
        <div className="title">Offer统计</div>
        <div className="search">
          {/* <Row> */}
          {/* <Col span={3}> */}
          <Radio.Group
            onChange={this.switchWeekAndMonth}
            defaultValue={curActiveWeekAndMonth}
            style={{ marginRight: 20 }}
          >
            <Radio.Button value="week">周报</Radio.Button>
            <Radio.Button value="month">月报</Radio.Button>
          </Radio.Group>
          {/* </Col> */}
          {/* <Col span={3}> */}
          {curActiveWeekAndMonth == "week" ? (
            <WeekPicker
              placeholder="请先择周"
              defaultValue={moment()}
              onChange={this.selectWeekAndMonth}
              style={{ marginRight: 20 }}
            />
          ) : (
            <MonthPicker
              placeholder="请选择月"
              defaultValue={moment()}
              onChange={this.selectWeekAndMonth}
              style={{ marginRight: 20 }}
            />
          )}
          {/* </Col> */}
          {/* <Col span={4}> */}
          <Button
            type="primary"
            className="login-form-button"
            onClick={this.reportGeneration}
          >
            生成报表
          </Button>
          <ExportBtn
            text={"导出"}
            className="btnColor"
            params={{
              type: curActiveWeekAndMonth,
              time: curDateString,
            }}
            url={this.$http.exportStatistics()}
          />
          {/* </Col> */}
          {/* </Row> */}
        </div>
        {console.log("total ->", total)}
        <div className="table">
          <Table
            dataSource={statisticsDataSource}
            total={total}
            columns={listColumns(
              this.switchModal,
              this.viewOffers
            )}
            pagination={{
              hideOnSinglePage: true,
              current: page,
              total: total,
              pageSize: pageSize || 10,
            }}
            onChange={this.handleOfferStatisticsTable}
            loading={statisticsDataTableLoading}
          />
        </div>
        <TableModal
          title={modalTitle}
          ref={"TableModalRef"}
          columns={modalColumns}
          dataSource={modalDataSource}
          loading={modalLoading}
        />
      </div>
    );
  }
}

export default Form.create()(OfferStatistics);
