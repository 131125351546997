import http from './fetch'
const baseUrl = process.env.API_URL ? process.env.API_URL : '';

export default {
    // 个人中心
    queryForPersonal(param) {
        return http.post(`${baseUrl}/personnel-bsm/pc/staff/queryForPersonal`, param)
    },
    // 个人中心修改
    updateStaffForPersonal(param) {
        return http.post(`${baseUrl}/personnel-bsm/pc/staff/updateStaffForPersonal`, param)
    },
}