import React from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Modal,
  Table,
  message,
} from "antd";
import { map } from "lodash";
const FormItem = Form.Item;
const Option = Select.Option;

class SelectStaffNo extends React.Component {
  constructor(props) {
    super(props);
    console.log("123123");
    this.state = {
      visible: false,
      departmentId: "",
      isQurey: true,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        onChange: this.pageChange,
      },
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      station: [],
    };
  }

  handleCancel = () => {
    const { form } = this.props;
    form.resetFields(["staffName", "postId"]);
    this.setState({
      visible: false,
      selectedRowKeys: [],
      selectedRows: [],
    });
  };

  handleOk = () => {
    const { selectedRowKeys, selectedRows } = this.state;
    if (!selectedRowKeys.length) {
      message.destroy();
      message.warning("请选择员工");
      return;
    }
    this.props.handleOk(selectedRows);
    console.log("handleOk ->", this.props.departmentId);
    // this.handleCancel();
    this.setState({ visible: false });
  };

  /**
   * @method 查询交接人
   */
  handleQuery = (pageNo, pageSize) => {
    this.setState({ loading: true });
    const { departmentId, staffId } = this.props;
    const { getFieldsValue } = this.props.form;
    const { staffName, postId } = getFieldsValue();
    const { pagination } = this.state;
    const params = {
      departmentId,
      staffName: staffName || "",
      postId: postId || "",
      pageNo,
      pageSize,
      staffId,
    };
    this.$http
      .listStaffForChoose(params)
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          pagination.total = res.data.total || 0;
          this.setState({
            tableData: res.data.list,
            pagination,
          });
        }
      })
      .catch((err) => {});
  };

  handleReset = () => {
    this.props.form.resetFields();
    // 重新查询数据
    this.handleQuery(1, 10);
  };
  showModal = () => {
    if (!this.props.departmentId || !this.props.staffId) {
      message.warning("请选择员工");
    } else {
      this.setState({ visible: true }, () => {
        this.handleQuery(1, 10);
        this.queryPost();
      });
    }
  };

  pageChange = (page, pageSize) => {
    const { pagination } = this.state;
    pagination.current = page;
    this.setState(
      {
        pagination,
      },
      () => {
        this.handleQuery(page, pageSize);
      }
    );
  };
  /**
   *@method 查询下拉岗位
   */
  queryPost = () => {
    this.$http["selectPost"]({}).then((res) => {
      if (res.code === 200) {
        this.setState({
          station: res.data || [],
        });
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const {
      visible,
      tableData,
      selectedRows,
      pagination,
      loading,
    } = this.state;
    const columns = [
      {
        title: "工号",
        dataIndex: "staffCode",
        key: "staffCode",
      },
      {
        title: "姓名",
        dataIndex: "staffName",
        key: "staffName",
      },
      {
        title: "部门",
        dataIndex: "departmentName",
        key: "departmentName",
      },
      {
        title: "岗位",
        dataIndex: "postName",
        key: "postName",
      },
    ];
    const rowSelection = {
      type: "radio",
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(
          "selectedRowKeys :",
          selectedRowKeys,
          "selectedRows :",
          selectedRows
        );
        this.setState({
          selectedRowKeys,
          selectedRows,
        });
      },
    };
    return (
      <div className="">
        {console.log("selectedRows ->", selectedRows)}
        <Button icon="search" onClick={this.showModal}>
          {selectedRows.length
            ? selectedRows[0].staffName
            : "选择人员"}
        </Button>
        <Modal
          visible={visible}
          maskClosable={false}
          title="选择人员"
          width="880px"
          onCancel={this.handleCancel}
          className="modal-select-staff"
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              取消
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleOk}
            >
              确定
            </Button>,
          ]}
        >
          <Row className="select-staff-wrap">
            <div className="wrap-right">
              <Form
                layout="inline"
                style={{ marginBottom: 20 }}
              >
                <FormItem label="姓名">
                  {getFieldDecorator("staffName")(
                    <Input
                      placeholder="请输入"
                      allowClear
                      style={{ width: 130 }}
                    />
                  )}
                </FormItem>
                <FormItem label="岗位">
                  {getFieldDecorator("postId")(
                    <Select
                      placeholder="请选择"
                      allowClear
                      style={{ width: 130 }}
                    >
                      {map(this.state.station, (item) => {
                        const { postId, postName } = item;
                        return (
                          <Option
                            key={postId}
                            value={postId}
                          >
                            {postName}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </FormItem>
                <FormItem>
                  <Button onClick={this.handleReset}>
                    重置
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginLeft: 14 }}
                    onClick={() => this.handleQuery(1, 10)}
                  >
                    查询
                  </Button>
                </FormItem>
              </Form>
              <Table
                rowKey="staffId"
                rowSelection={rowSelection}
                columns={columns}
                loading={loading}
                dataSource={tableData}
                pagination={pagination}
                locale={{
                  emptyText: (
                    <div>
                      <img
                        style={{ width: 182, height: 94 }}
                        src={require("../../assets/image/no-data.png")}
                        alt=""
                      />
                      <p
                        style={{
                          fontSize: 14,
                          color: "#717E99",
                          lineHeight: "20px",
                        }}
                      >
                        暂无数据
                      </p>
                    </div>
                  ),
                }}
              />
            </div>
          </Row>
        </Modal>
      </div>
    );
  }
}

export default SelectStaffNo;
