import React, { Component } from 'react'
import { Divider, Button, message } from 'antd'
import './index.less'
import AddForm from './addForm'
import { personalInformation, postSalary } from '../../../data'
import Api from '../../../../../api/apis'
import moment from 'moment'
import util from '../../../../../utils/utils'

class AddEmployee extends Component {
  constructor(props) {
    super(props)
    this.state = {
      staffId: util.getSearchString('staffId', window.location.search) || this.props.location.state.staffId,
      formType: this.props.location.state.formType ? parseInt(this.props.location.state.formType) : 1, // 1: 岗位薪资 2：个人信息
      formItemData: this.props.location.state.formType && parseInt(this.props.location.state.formType) === 1 ? personalInformation : postSalary, // 表单数据--劳动合同
      selectList: {}, // 数据字典关系
      jobDetail: { imgUrl: { byz: '', xwz: '', sfzz: '', sfzb: '' } },
      cityData: [],

      staffDetail: {}, // 岗位薪资
      staffInfo: {}, // 个人信息
      workExperience: [], // 工作经验
      staffAnnexList: [], // 附件

    }
  }
  componentDidMount() {
    this.querynation(true)
    this.querydepartmentId(true)
    this.querypostId(true)
    this.queryoccupationId(true)
    this.queryProvinceList()

    this.selectListByGroup(true, 'certificatesType', 'ID_TYPE')
    this.selectListByGroup(true, 'sex', 'GENDER')
    this.selectListByGroup(true, 'marriageStatus', 'MARITAL_STATUS')
    this.selectListByGroup(true, 'constellation', 'CONSTELLATION')
    this.selectListByGroup(true, 'politicalOutlook', 'POLITICAL_ORIENTATION')
    this.selectListByGroup(true, 'residenceNature', 'REGISTERED_RESIDENCE_NATURE')
    this.selectListByGroup(true, 'relationship', 'RELATION_EMERGENCY_CONTCAT')
    this.selectListByGroup(true, 'highestEducation', 'HIGHEST_DEGREE')
    this.selectListByGroup(true, 'bachelorScience', 'DEGREE')
    this.selectListByGroup(true, 'contractType', 'CONTRACT_NATURE')

    this.queryJobDetail()
    this.queryStaffInfoDetail()
    this.queryWorkExperience()
    this.queryStaffAnnex()
  }
  async querynation(flag) {
    try {
      const res = await this.$http.nationData()
      const { code, data } = res
      if (code === 200) {
        const { selectList } = this.state
        selectList.nationList = data.map(item => {
          return {
            dicKey: item.tbNationId,
            dicValue: item.nationName
          }
        })
        this.setState({
          selectList
        })
      }
    } catch {
      this.setState({
        selectList: []
      })
    }
    // }
  }
  querySelectList = (type, open) => {
    this[type](open)
  }
  /**
   * 函数描述: 花名册查询岗位&薪资
   * @param {String} staffId 员工id this.props.location.state.staffId
   */
  queryJobDetail = () => {
    const { staffId } = this.state
    this.$http.getStaffPostInfoForStaffRoster({ staffId }).then(res => {
      if (res.code === 200) {
        const { jobDetail } = this.state
        res.data.postIdOK = res.data.postId
        res.data.onboardingTime = res.data.onboardingTime == null ? undefined : moment(res.data.onboardingTime)
        res.data.turnTimeSave = res.data.turnTime || ''
        res.data.turnTime = res.data.turnTime == null ? undefined : moment(res.data.turnTime)
        res.data.leaveTime = res.data.leaveTime == null ? undefined : moment(res.data.leaveTime)
        res.data.staffName1 = res.data.staffName
        res.data.isLeave = res.data.isLeave == false ? '0' : '1'
        res.data.isProbation = res.data.isProbation == false ? '0' : '1'
        res.data.isSchoolRecruitment = res.data.isSchoolRecruitment == false ? '0' : '1'
        res.data.socialSecurityfundPayLocalProvince = [
          res.data.socialSecurityfundPayLocalProvince + '&' + res.data.socialSecurityfundPayLocalProvinceName,
          res.data.socialSecurityfundPayLocalCity + '&' + res.data.socialSecurityfundPayLocalCityName
        ]
        res.data.work = [res.data.workProvince + '&' + res.data.workProvinceName, res.data.workCity + '&' + res.data.workCityName]

        // res.data.departmentId = res.data.departmentId
        this.setState(
          {
            jobDetail: { ...jobDetail, ...res.data }
          },
          () => {
            this.queryContractDetail()
          }
        )
      }
    })
  }
  /**
   * 函数描述: 合同查询详情
   * @param {String} staffId 员工id this.props.location.state.staffId
   */
  queryContractDetail = () => {
    const { staffId } = this.state
    this.$http.getStaffInfoContract({ staffId }).then(res => {
      if (res.code === 200) {
        const { jobDetail } = this.state
        if (res.data) {
          const { contractType } = res.data
          res.data.firstContractStartday = res.data.firstContractStartday == null ? undefined : moment(res.data.firstContractStartday)
          res.data.turnTimeSave = contractType !== 'LABOR' ? '--' : res.data.turnTime
          res.data.turnTime = res.data.turnTime == null ? undefined : moment(res.data.turnTime)
          res.data.firstContractMaturityday = res.data.firstContractMaturityday == null ? undefined : moment(res.data.firstContractMaturityday)
          res.data.contractStartday = res.data.contractStartday == null ? undefined : moment(res.data.contractStartday)
          res.data.contractMaturityday = res.data.contractMaturityday == null ? undefined : moment(res.data.contractMaturityday)
          res.data.lastCompanyTime = res.data.lastCompanyTime == null ? undefined : moment(res.data.lastCompanyTime)
          res.data.isOtherCompanyTransfer = res.data.isOtherCompanyTransfer == false ? '0' : '1'
          // res.data.contractType = res.data.contractType
          let temp = res.data.hireNature
          res.data.hireNature = res.data.hireNatureName
          res.data.hireNatureName = temp
          res.data.turnTime1 = res.data.turnTimeSave
          // == null ? '--' : moment(res.data.turnTime).format('YYYY/MM/DD')
          this.setState({
            jobDetail: { ...jobDetail, ...res.data },
            contractId: res.data.contractId
          })
        }

      }
    })
  }
  /**
   * 函数描述: 个人信息
   * @param {String} staffId 员工id this.props.location.state.staffId
   */
  queryStaffInfoDetail = () => {
    const { staffId } = this.state
    this.$http.getStaffInfoForRoster({ staffId }).then(res => {
      if (res.code === 200) {
        const { jobDetail } = this.state
        res.data.departmentId = jobDetail.departmentId
        res.data.birthday = res.data.birthday == null ? undefined : moment(res.data.birthday)
        res.data.graduationTime = res.data.graduationTime == null ? undefined : moment(res.data.graduationTime)
        res.data.actualBirthday = res.data.actualBirthday == null ? undefined : moment(res.data.actualBirthday)
        res.data.nativePlaceProvince = res.data.nativePlaceProvinceName
        this.setState({
          jobDetail: { ...jobDetail, ...res.data }
        })
      }
    })
  }

  /**
   * 函数描述: 工作经验
   * @param {String} staffId 员工id this.props.location.state.staffId
   */
  queryWorkExperience = () => {
    const { staffId } = this.state
    this.$http.listStafWorkExperienceByStaffId({ staffId }).then(res => {
      if (res.code === 200) {
        const { jobDetail } = this.state
        res.data.map((v, i) => {
          v.time = [moment(v.startWorkTime), moment(v.endWorkTime)]
          v.key = i
        })
        this.setState({
          jobDetail: { ...jobDetail, ...{ workExperience: res.data } }
        })
      }
    })
  }
  /**
   * 函数描述: 查询附件
   * @param {String} staffId 员工id this.props.location.state.staffId
   */
  queryStaffAnnex = () => {
    const { staffId } = this.state
    this.$http.listStaffAnnexByStaffId({ staffId }).then(res => {
      if (res.code === 200) {
        const { jobDetail } = this.state
        let imgUrl = { byz: '', xwz: '', sfzz: '', sfzb: '' }
        let fileList = []
        let Arr = [
          {
            annexType: '1'
          },
          {
            annexType: '2'
          },
          {
            annexType: '3'
          },
          {
            annexType: '4'
          }
        ]
        res.data.map((v, inx) => {
          if (v.annexType == 1) {
            imgUrl.byz = v.annexUrl
            Arr[0].annexId = v.annexId
            Arr[0].annexKey = v.annexKey
            Arr[0].annexUrl = v.annexUrl
          } else if (v.annexType == '2') {
            imgUrl.xwz = v.annexUrl
            Arr[1].annexId = v.annexId
            Arr[1].annexKey = v.annexKey
            Arr[1].annexUrl = v.annexUrl
          } else if (v.annexType == '3') {
            imgUrl.sfzz = v.annexUrl
            Arr[2].annexId = v.annexId
            Arr[2].annexKey = v.annexKey
            Arr[2].annexUrl = v.annexUrl
          } else if (v.annexType == '4') {
            imgUrl.sfzb = v.annexUrl
            Arr[3].annexId = v.annexId
            Arr[3].annexKey = v.annexKey
            Arr[3].annexUrl = v.annexUrl
          } else {
            fileList.push(v)
          }
        })
        // let staffAnnex=res.data.length!=0? res.data:

        jobDetail.imgUrl = imgUrl
        jobDetail.fileList = fileList
        this.setState({
          jobDetail: { ...jobDetail, ...{ imgUrl: imgUrl, staffAnnex: Arr } }
        })
      }
    })
  }
  // 查询部门名称
  querydepartmentId = open => {
    if (open) {
      this.$http['departmentListAll']({}).then(res => {
        if (res.code === 200) {
          const { selectList } = this.state
          selectList.departmentIdList = res.data
          this.setState({
            selectList
          })
        }
      })
    }
  }
  // 查询岗位
  querypostId = open => {
    if (open) {
      this.$http['listForDropDownPost']().then(res => {
        if (res.code === 200) {
          const { selectList } = this.state
          selectList.postIdList = res.data.map(item => {
            return {
              dicKey: item.postId,
              dicValue: item.postName
            }
          })
          this.setState({
            selectList
          })
        }
      })
    }
  }
  // 查询职级
  queryoccupationId = open => {
    if (open) {
      this.$http['selectLevel']().then(res => {
        if (res.code === 200) {
          const { selectList } = this.state
          selectList.occupationIdList = res.data.map(item => {
            return {
              dicKey: item.occupationId,
              dicValue: item.occupationName
            }
          })
          this.setState({
            selectList
          })
        }
      })
    }
  }
  // 下拉框数据查询
  selectListByGroup = (open, key, dicGroup) => {
    if (open) {
      this.$http['selectListByGroup']({
        dicGroup
      }).then(res => {
        if (res.code === 200) {
          const { selectList } = this.state
          selectList[`${key}List`] = res.data
          // if (dicGroup == 'CONTRACT_NATURE') {
          //   selectList[`${key}List`].map((v, i) => {
          //     // if (v.dicKey == 'LABOR') {
          //     //   v.dicKey = v.dicKey + '&FULL_TIME'
          //     // } else if (v.dicKey == 'SERVICE') {
          //     //   v.dicKey = v.dicKey + '&PART_TIME'
          //     // } else if (v.dicKey == 'INTERNSHIP') {
          //     //   v.dicKey = v.dicKey + '&INTERNSHIP'
          //     // }
          //   })
          // }
          this.setState({
            selectList
          })
        }
      })
    }
  }
  // 查询省市区
  queryProvinceList = () => {
    this.$http['queryProvinceList']({}).then(res => {
      if (res.code === 200) {
        res.data.provinceList.map((v, index) => {
          v.key = v.addressCode + '&' + v.addressName
          if (v.cityList) {
            v.cityList.map((l, i) => {
              l.key = l.addressCode + '&' + l.addressName
            })
          }
        })
        this.setState({
          cityData: res.data.provinceList || []
        })
      }
    })
  }
  onRef = ref => {
    this.formData = ref
  }
  handleComeBack = () => {
    this.props.history.go(-1)
  }
  //点击下一步
  handleClickNext = () => {
    if (this.formData.handleAddSure()) {
      const value = this.formData.handleAddSure()
      this.setState(
        {
          formType: 2,
          formItemData: postSalary,
          jobDetail: { ...this.state.jobDetail, ...value }
        },
        () => { }
      )
    }
  }
  //点击上一步
  handleClickPrev = () => {
    this.setState(
      {
        formType: 1,
        formItemData: personalInformation,
      },
      () => {
      }
    )
  }
  //点击保存
  // 专门写个方法处理时间格式
  handleTime(time) {
    let val = ''
    if (time) {
      val = moment(time).format('YYYY/MM/DD')
    }
    return val
  }
  handleCity(city, index, idx) {
    let val = ''
    if (city) {
      if (city[index]) {
        val = city[index].split('&')[idx]
      }
    }
    return val
  }
  async savePersonalInfo() {
    const formType = this.state.formType
    const value = this.formData.handleAddSure()
    // value.staffName = value.staffName1
    const { staffId, staffCode, contractCode, departmentId, contractType, attendanceRemark, isLeave, hireNature, hireNatureName, belongDeliverManagerId } = this.state.jobDetail

    if (contractType !== 'LABOR') {
      this.state.jobDetail.turnTime = ''
    }
    if (value) {
      if (formType === 1) {
        const params = {
          ...value,
          tbNationId: value.nation,
          staffId,
          staffCode,
          staffAnnexs: value.staffAnnex,
          staffWorkExperience: value.workExperience,
          nativePlaceProvinceName: value.nativePlaceProvince,
          // departmentId: departmentId ? departmentId.split('&')[0] : '',
          departmentId,
        }
        params.staffAnnexs.push(...this.state.jobDetail.fileList)
        params.staffName = value.staffName1
        params.graduationTime = params.graduationTime == null ? '' : moment(params.graduationTime).format('YYYY/MM')
        const arr = ['birthday', 'actualBirthday']
        for (const item of arr) {
          params[item] = this.handleTime(params[item])
        }
        if (params.staffWorkExperience) {
          params.staffWorkExperience.forEach(item => {
            item.startWorkTime = this.handleTime(item.time[0])
            item.endWorkTime = this.handleTime(item.time[1])
          })
        }
        const res = await Api.updateForPersonal(params)
        if (res.code == '200') {
          this.props.history.go(-1)
        } else {
          message.error(res.message)
        }
      } else if (formType === 2) {
        // const {hireNature,hireNatureName} = this.state.staffDetail
        const params = {
          ...value,
          staffId,
          staffCode,
          belongDeliverManagerId,
          isLeave: isLeave === "0" ? false : true,
          contractCode,
          attendanceRemark,
          workProvince: this.handleCity(value.work, 0, 0),
          workProvinceName: this.handleCity(value.work, 0, 1),
          workCity: this.handleCity(value.work, 1, 0),
          workCityName: this.handleCity(value.work, 1, 1),
          socialSecurityfundPayLocalProvince: this.handleCity(value.socialSecurityfundPayLocalProvince, 0, 0),
          socialSecurityfundPayLocalProvinceName: this.handleCity(value.socialSecurityfundPayLocalProvince, 0, 1),
          socialSecurityfundPayLocalCity: this.handleCity(value.socialSecurityfundPayLocalProvince, 1, 0),
          socialSecurityfundPayLocalCityName: this.handleCity(value.socialSecurityfundPayLocalProvince, 1, 1),
          hireNature: hireNatureName,
          hireNatureName: hireNature,
          // hireNature:this.state.staffDetail.hireNatureName,
          // hireNatureName:this.state.staffDetail.hireNature,
        }
        const arr = ['contractMaturityday', 'contractStartday', 'firstContractMaturityday', 'firstContractStartday', 'lastCompanyTime', 'onboardingTime', 'turnTime']
        for (const item of arr) {
          params[item] = this.handleTime(params[item])
        }
        params.turnTime = params.turnTime1 === '--' ? '' : params.turnTime1
        params.staffName = value.staffName1
        params.isSchoolRecruitment = params.isSchoolRecruitment === 0 ? false : true
        params.isOtherCompanyTransfer = params.isOtherCompanyTransfer === 0 ? false : true
        params.postId = params.postIdOK
        params.contractId = this.state.contractId
        // params.departmentId = params.departmentId ? params.departmentId.split('&')[0] : ''
        // params.contractType = params.contractType.split('&')
        const res = await Api.updateStaffPostContract(params)
        if (res.code == '200') {
          this.props.history.go(-1)
        } else {
          message.error(res.message)
        }
      }
    }
  }

  render() {
    const { selectList, jobDetail, cityData, formItemData, formType } = this.state
    return (
      <div className="add-employee">
        <div className="crumbs">员工花名册-编辑</div>
        {/* <div className="if-card tip-line flex flex-align-center">
          <div className={formType === 1 ? 'circular blue-border' : 'circular'}>1</div>
          <div className={formType === 1 ? 'blue-text' : 'black-text'}>个人信息</div>
          <Divider dashed />
          <div className={formType === 2 ? 'circular blue-border' : 'circular'}>2</div>
          <div className={formType === 2 ? 'blue-text' : 'black-text'}>岗位&薪资</div>
        </div> */}
        <AddForm
          onRef={this.onRef}
          selectList={selectList}
          formItemData={formItemData}
          staffDetail={jobDetail}
          cityData={cityData}
          selectListByGroup={this.selectListByGroup}
          querySelectList={this.querySelectList}
          querydepartmentId={this.querydepartmentId}
        />
        <div className="footer flex flex-align-center flex-justify-center">
          <Button onClick={this.handleComeBack}>返回</Button>
          {/* {formType === 1 && (
            <Button type="primary" style={{ marginLeft: 16 }} onClick={this.handleClickNext}>
              下一步
            </Button>
          )}
          {formType === 2 && (
            <Button type="primary" style={{ marginLeft: 16 }} onClick={this.handleClickPrev}>
              上一步
            </Button>
          )} */}
          {
            <Button
              type="primary"
              style={{ marginLeft: 16 }}
              // onClick={this.handleClickSave}
              onClick={this.savePersonalInfo.bind(this)}
            >
              保存
            </Button>
          }
        </div>
      </div>
    )
  }
}

export default AddEmployee
