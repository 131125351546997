import React,{Component} from 'react'
import * as echarts from "echarts";
import { withRouter } from "react-router-dom";

class Index extends Component {
    constructor(props) {
      super(props);
      this.state = {
        personnelData:{}
      }
    }
  componentDidUpdate() {
    const { personnelData } = this.state 
    const option = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        orient: "vertical",
        align:"left",
        left: "50%",
        top: 15,
        itemGap:15,
        itemWidth: 10,
        itemHeight: 10,
        data: [
          {
            name: "正式员工", icon: "circle"
          },
          {
            name: "试用期员工", icon: "circle"
          },
          {
            name: "实习员工", icon: "circle"
          },
          {
            name: "兼职员工", icon: "circle"
          },
        ],
        formatter: (name) => {
            const data = option.series[0].data;
            let total = 0;
            let target;
            for (let i = 0; i < data.length; i++) {
                total += data[i].value;
                if (data[i].name === name) {
                    target = data[i].value;
                }
            }
            let arr = [
                '{a|' + name + '}{b|' + target + '人}'
            ];
            return arr.join('\n')
        },
        textStyle: {
          rich: {
            a: {
              verticalAlign: 'right',
              fontSize: 12,
              color: "#91A7BE",
              align: 'left',
              width: 110
            },
            b: {
              fontSize: 14,
              coloe:"#364A64",
              align: 'left',
              width: 30
            },
          }
        }
      },
      color :["#68CFAC","#4D91FA","#F98919","#9799D6"],
      series: [
        {
          name: "在职人员",
          type: "pie",
          radius: "80%",
          center: ["20%", "50%"],
          label: {
            show:false
          },
          data: [
            { value: personnelData.formalNum||0, name: "正式员工" },
            { value: personnelData.probationPeriod||0, name: "试用期员工" },
            { value: personnelData.internshipNum||0, name: "实习员工" },
            { value: personnelData.partTimeNum||0, name: "兼职员工" },
          ],
          emphasis: {
            itemStyle: {
              shadowBlur: 5,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    echarts.init(document.getElementById("main")).setOption(option);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ personnelData: nextProps.personnelData })
  }
 
  render() {
    return (<div id="main" style={{ width:500, height: 160, padding: 10,margin:"auto" }}></div>)
  }
}
export default withRouter(Index);
