/**
 * @fileoverview 人事管理---合同管理
 * @author  蒋西园
 */
import React, { Component } from "react";
import "./index.less";

import { message } from "antd";
import LeftTree from "../../../components/leftTreeTwo"; // 左侧树
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表
import RenewContract from "./component/renewContract"; // 续签合同

class EmployeeRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataList: [], // 合同列表数据
      total: 0, // 合同总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {}, // 搜索条件
      disabled: false, // 防重复点击
      contractId: "", // 合同编码
      renewModalShow: false, // 续签弹框
      contractItem: {}, // 合同项目
      sorter: "desc", // 排序 desc asc
    };
  }

  /**
   * 函数描述: 查询合同数据
   * @param {string} pageNo   	页码
   * @param {string} pageSize 	条数
   * @param {string} staffName  	 姓名
   * @param {string} postId  		 岗位id
   * @param {string} contractCode   	 合同编码
   * @param {string} contractStartTime 开始时间
   * @param {string} contractEndTime   结束时间
   * @param {string} sort  排序方式 asc： 正序 desc: 倒叙
   * @return {Object}
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
          departmentId:
            this.state.checkedTreeId == "all"
              ? ""
              : this.state.checkedTreeId,
          pageNo,
          pageSize,
          sort: this.state.sorter,
        };
        this.$http
          .staffContractList(params)
          .then((res) => {
            if (res.code == 200) {
              this.setState({
                loading: false,
                pageNo,
                pageSize,
                dataList: res.data.list || [],
                total: res.data.total,
              });
            } else {
              message.error(res.message);
              this.setState({
                loading: false,
                dataList: res.data.list || [],
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };
  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} sorter 	排序方式 asc： 正序 desc: 倒叙
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (page, sorter) => {
    let sorterOrder = "";
    if (sorter.order) {
      sorterOrder =
        sorter.order === "ascend" ? "asc" : "desc";
    }
    this.setState(
      {
        sorter: sorterOrder,
      },
      () => {
        this.queryTableList(page.current, page.pageSize);
      }
    );
  };
  /**
   * 函数描述: 续签弹框
   * @param {string} staffId  员工id
   */
  handleRenewContract = (staffId) => {
    this.refs.RenewContract.resetFields(); // 避免数据闪现
    this.$http
      .getStaffContractByStaffId({ staffId })
      .then((res) => {
        if (res.code === 200) {
          this.setState({
            contractItem: res.data,
            renewModalShow: true,
          });
        } else {
          message.error(res.message);
        }
      });
  };

  // 取消
  handleCancel = () => {
    this.setState({
      renewModalShow: false,
    });
  };
  /**
   * 函数描述: 续签合同
   * @param {string} staffId   	员工id
   * @param {string} contractType 合同类型
   * @param {string} hireNature 	用工性质
   * @param {string} contractStartday 	合同起始日
   * @param {string} contractMaturityday	合同到期日
   * @return {Object} 请求pageNo页列表数据
   */
  handleAddSure = (values) => {
    if (!this.state.disabled) {
      this.setState(
        {
          disabled: true,
        },
        () => {
          const params = {
            staffId: this.state.contractItem.staffId,
            ...values,
          };
          this.$http
            .renewStaffContract(params)
            .then((res) => {
              this.setState(
                {
                  disabled: false,
                },
                () => {
                  if (res.code === 200) {
                    this.handleCancel();
                    this.queryTableList();
                  }
                }
              );
            })
            .catch(() => {
              this.setState({
                disabled: false,
              });
            });
        }
      );
    }
  };

  // 选择部门
  changeDepartmentId = (val) => {
    this.setState(
      {
        checkedTreeId: val[0],
      },
      () => {
        this.queryTableList();
      }
    );
  };
  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
      renewModalShow,
      contractItem,
    } = this.state;
    return (
      <div className="contract-roster">
        <div className="crumbs">合同管理</div>
        <div className="contract-wrap">
          {/* 左侧树 */}
          <LeftTree
            onSelect={this.changeDepartmentId}
            type={"合同管理"}
            selectedKeys="contractManagementSelectedKeys"
            selectedDepartmentIds="contractManagementSelectedDepartmentIds"
            expandedKeys="contractManagementExpandedKeys"
          />
          <div className="right-wrap">
            <SearchForm
              handleSearchBtn={this.handleSearchBtn}
            />
            <div className="contract-box wrap-card">
              <Table
                loading={loading}
                history={this.props.history}
                data={dataList}
                total={total}
                pageNo={pageNo}
                pageSize={pageSize}
                handleChangePage={this.handleChangePage} // 切换页码
                handleRenewContract={
                  this.handleRenewContract
                }
              />
            </div>
          </div>
        </div>
        <RenewContract
          ref="RenewContract"
          renewModalShow={renewModalShow}
          contractItem={contractItem}
          handleCancel={this.handleCancel}
          handleAddSure={this.handleAddSure}
        />
      </div>
    );
  }
}

export default EmployeeRoster;
