/**
* @fileoverview 系统管理---角色管理 -- 新增弹框
* @author  蒋西园
*/
import React,{Component} from 'react';
import './index.less'
import { Modal, Form, Input, Checkbox, Tree } from "antd";
const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const TextArea = Input.TextArea;
const TreeNode = Tree.TreeNode;

class addRoleModal extends Component{
    constructor(props){
		super(props)
		this.state = {
            roleItemId: this.props.roleItem.id,     // 编辑时角色的id
            permissionList: this.props.meanuList,
            checkedKeys: ['roleManagement'],
            selectedKeys: [],
            checkedItem:this.props.roleItem.menuIdStrs
		}
    }
    componentWillMount(){
    }
    // 点击确定
    handleAddSure = (e) => {
        e.preventDefault();
        const { form, type } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.menuIds = this.state.checkedItem;
                console.log(values)
                this.props.handleAddSure(type,values)
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    /**
     * 函数描述: 多选按钮选择监控
     * @param {Array} value 多选组返回选中的集合
     * @param {string} index 位置信息
     * @return {Object} 返回选中状态，检测是否为全选状态，是，。跟新父级选中状态
     */
    onChangeCheckboxGroup = (value,index) => {
        const { permissionList, checkedItem, allItemChecked } = this.state;
        allItemChecked[permissionList[index].key] = value.length === permissionList[index].optionList.length;
        checkedItem[permissionList[index].key] = value;
        this.setState({
            allItemChecked,
            checkedItem
        })
    }
    /**
     * 函数描述: 全选按钮监控
     * @param {string} index 位置信息
     * @return {Object} 返回选中状态，检测是否为全选状态，是，子集全部为选中状态，否，子集全部取消选中
     */
    handleChangeAll = (index) => {
        const { permissionList, checkedItem, allItemChecked } = this.state;
        allItemChecked[permissionList[index].key] = !allItemChecked[permissionList[index].key];
        const checkedItemCopy = [];
        if (allItemChecked[permissionList[index].key]) {
            permissionList[index].optionList.forEach((item) => {
                checkedItemCopy.push(item.value);
            })
        }
        checkedItem[index] = checkedItemCopy;
        this.setState({
            checkedItem,
            allItemChecked
        })
    }
    renderTreeNodes = data =>
        data.map(item => {
            if (item.chirdren) {
                return <TreeNode title={item.title} key={item.key} dataRef={item}>
                        {this.renderTreeNodes(item.chirdren)}
                    </TreeNode>
        }
        return <TreeNode className="flexLine" key={item.key} {...item} />;
    });
    onCheck = (checkedKeys, info) => {
        console.log('onCheck', checkedKeys, info);
        this.setState({
            checkedItem:checkedKeys
        })
    };
    render(){
        const { addModalShow, form, type, roleItem } = this.props;
        const { getFieldDecorator } = form;
        const { permissionList } = this.state;
        return(
            <Modal
                className="setRoleModal"
                title={type === 0 ? '添加角色' : '修改角色'}
                visible={addModalShow}
                width={700}
                onCancel={this.handleCancel}
                onOk={this.handleAddSure}
                maskClosable={false}
            >
                <div className="setUser-box role-box">
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }} >
                        <FormItem 
                            style={{marginBottom:14}}
                            label="角色名称">
                            {getFieldDecorator('rolesName', {
                                initialValue: roleItem.rolesName,
                                rules: [
                                    { required: true,whitespace:true, message: '请输入角色' },
                                    { max: 16, message: '请输入16字以内的角色名称'}
                                ],
                            })(
                                <Input placeholder="请输入" allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <FormItem 
                            label="说明">
                            {getFieldDecorator('description', {
                                initialValue: roleItem.description,
                                rules: [
                                    { max: 128, message: '请输入128字以内的说明'}
                                ],
                            })(
                                <TextArea placeholder="请输入" allowClear autoComplete="new-password" autoSize={{ minRows: 3, maxRows: 5 }}/>
                            )}
                        </FormItem>
                        <FormItem 
                            label="后台菜单权限">
                            {getFieldDecorator('menuIds')(
                                <div className="permission-group">
                                    <Tree
                                        checkable
                                        defaultExpandAll={true}
                                        defaultCheckedKeys={roleItem.menuIdStrs}
                                        onCheck={this.onCheck}
                                    >
                                        {this.renderTreeNodes(permissionList)}
                                    </Tree>
                                </div>
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Form.create()(addRoleModal) 