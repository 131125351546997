/**
 * @description 级别
 */
import React from "react";
import { Select, Radio, Form } from "antd";
import { map } from "lodash";
const { Option } = Select;
class Level extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: [],
    };
  }

  componentDidMount() {
    this.queryLevelList();
  }

  queryLevelList = () => {
    this.$http.queryLevelList().then((res) => {
      if (res && res.code === 200) {
        this.setState({
          status: res.data,
        });
      }
    });
  };

  onChange = (value) => {
    const { onChange } = this.props;
    onChange({
      staffLevelIds: value,
    });
  };

  render() {
    const { status } = this.state;
    return (
      <Form.Item>
        <Select
          showSearch
          mode="multiple"
          placeholder="选择级别"
          onChange={this.onChange}
          style={{ width: "300px" }}
        >
          {map(status, (i) => {
            const { occupationId, occupationName } = i;
            return (
              <Option
                key={occupationId}
                value={occupationId}
              >
                {occupationName}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default Level;
