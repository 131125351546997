/**
 * @fileoverview 左侧树
 * @author  蒋西园
 */
import React, { Component } from "react";
import { Input, Tree, Tooltip } from "antd";
import "./index.less";
import Api from "../../api/apis";
import { split } from "lodash";
const { Search } = Input;
const { TreeNode } = Tree;

const dataList = [];
const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];
    const { departmentSonName } = node;
    dataList.push({
      departmentSonName,
      title: departmentSonName,
    });
    if (node.dsTree) {
      generateList(node.dsTree);
    }
  }
};
class LeftTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      searchValue: "",
      selectedKeys: [],
      expandedKeys: [],
    };
  }
  componentDidMount() {
    this.queryTreeList();
  }

  configData = (data) => {
    let newData = data.map((o) => {
      const { chirdren, departmentId, departmentName } = o;
      return {
        departmentSonId: departmentId,
        departmentSonName: departmentName,
        dsTree:
          chirdren && Array.isArray(chirdren)
            ? this.configData(chirdren || [])
            : null,
      };
    });
    return newData;
  };
  /**
   * 函数描述: 查询部门
   * @param {string} level   层级
   * @param {string} parentDepartmentId 上级部门id
   * @param {string} type   类型（1：项目 2：部门）
   * @return {Object}
   */
  queryTreeList = () => {
    if (this.props.type == "管辖部门") {
      Api.departmentPermissions({}).then((res) => {
        if (res.code === 200) {
          let list = [];
          if (this.props.isShow != "流程配置") {
            list = [
              {
                departmentSonId: "all",
                departmentSonName: "全部",
              },
            ].concat(res.data);
          } else {
            list = res.data;
          }
          this.setState({
            treeData: list,
          });
          if (this.props.type == "流程配置") {
            this.props.onSelect(
              res.data
                ? [
                  res.data[0].departmentSonId +
                  "&" +
                  res.data[0].departmentSonName +
                  "&" +
                  res.data[0].level,
                ]
                : ""
            );
          } else {
            if (this.props.typeA != "流程查询") {
              this.props.onSelect(
                res.data ? [list[1].departmentSonId] : ""
              );
            }
          }

          generateList(list);

          function setDepartmentId(value) {
            const departmentIds = [];
            departmentIds.push(value);
            list.map((item) => {
              if (
                item.departmentSonId === value &&
                item.dsTree
              ) {
                getChildDepartmentId(item.dsTree);
              } else if (item.dsTree) {
                mapItem(item.dsTree);
              }
            });

            function mapItem(item) {
              item.map((c_item) => {
                if (c_item.departmentSonId === value) {
                  if (c_item.dsTree) {
                    getChildDepartmentId(c_item.dsTree);
                  }
                } else if (item.dsTree) {
                  mapItem(item.dsTree);
                }
              });
            }

            function getChildDepartmentId(item) {
              if (item.departmentSonId) {
                departmentIds.push(item.departmentSonId);
              }
              item.map((c_item) => {
                departmentIds.push(c_item.departmentSonId);
                if (c_item.dsTree) {
                  getChildDepartmentId(c_item.dsTree);
                }
              });
            }
            return departmentIds;
          }
          this.props.getDepartmentId(
            setDepartmentId(list[0])
          );
        }
      });
    } else if (this.props.type === "流程配置") {
      Api.departmentListForTree({}).then((res) => {
        if (res.code === 200) {
          let list = [];
          let configData = this.configData(res.data, []);
          if (this.props.isShow != "流程配置") {
            list = [
              {
                departmentSonId: "all",
                departmentSonName: "全部",
              },
            ].concat(configData);
          } else {
            list = configData;
          }
          this.setState({
            treeData: list,
          });
          if (this.props.type == "流程配置") {
            this.props.onSelect(
              configData
                ? [
                  configData[0].departmentSonId +
                  "&" +
                  configData[0].departmentSonName +
                  "&" +
                  configData[0].level,
                ]
                : ""
            );
          } else {
            if (this.props.typeA != "流程查询") {
              this.props.onSelect(
                configData ? [list[0].departmentSonId] : ""
              );
            }
          }
          generateList(list);
        }
      });
    } else if (this.props.type != "员工花名册") {
      Api.departmentPermissions({}).then((res) => {
        if (res.code === 200) {
          let list = [];
          if (this.props.isShow != "流程配置") {
            list = [
              {
                departmentSonId: "all",
                departmentSonName: "全部",
              },
            ].concat(res.data);
          } else {
            list = res.data;
          }
          this.setState({
            treeData: list,
          });
          if (this.props.type == "流程配置") {
            this.props.onSelect(
              res.data
                ? [
                  res.data[0].departmentSonId +
                  "&" +
                  res.data[0].departmentSonName +
                  "&" +
                  res.data[0].level,
                ]
                : ""
            );
          } else {
            if (this.props.typeA != "流程查询") {
              this.props.onSelect(
                res.data ? [list[0].departmentSonId] : ""
              );
            }
          }
          generateList(list);
        }
      });
    } else {
      Api.departmentPermissions({}).then((res) => {
        if (res.code === 200) {
          let list = [];
          if (this.props.isShow != "流程配置") {
            list = [
              {
                departmentSonId: "all",
                departmentSonName: "全部",
              },
            ].concat(res.data);
          } else {
            list = res.data;
          }
          this.setState({
            treeData: list,
          });
          if (this.props.type == "流程配置") {
            this.props.onSelect(
              res.data
                ? [
                  res.data[0].departmentSonId +
                  "&" +
                  res.data[0].departmentSonName +
                  "&" +
                  res.data[0].level,
                ]
                : ""
            );
          } else {
            if (this.props.typeA != "流程查询") {
              this.props.onSelect(
                res.data ? [list[0].departmentSonId] : "",
                res.data
              );
            }
          }
          generateList(list);
        }
      });
    }
  };
  handleTreeNode = (keys, event) => {
    const departmentIdData = this.state.treeData;
    this.setState({ selectedKeys: keys });
    sessionStorage[this.props.selectedKeys] = keys;
    sessionStorage[this.props.selectedDepartmentIds] = "";

    if (this.props.type === "流程配置") {
      console.log(keys);
      sessionStorage.setItem("selectedKey", keys)
    }
    if (this.props.type === "员工花名册") {
      this.props.onSelect(
        keys[0] == "all" ? [""] : keys,
        this.state.treeData
      );
    } else {
      this.props.onSelect(keys[0] == "all" ? [""] : keys);
    }
    if (this.props.type === "管辖部门") {
      function setDepartmentId(value) {
        const departmentIds = [];
        departmentIds.push(value);
        departmentIdData.map((item) => {
          if (
            item.departmentSonId === value &&
            item.dsTree
          ) {
            getChildDepartmentId(item.dsTree);
          } else if (item.dsTree) {
            mapItem(item.dsTree);
          }
        });

        function mapItem(item) {
          item.map((c_item) => {
            if (c_item.departmentSonId === value) {
              if (c_item.dsTree) {
                getChildDepartmentId(c_item.dsTree);
              }
            } else if (item.dsTree) {
              mapItem(item.dsTree);
            }
          });
        }

        function getChildDepartmentId(item) {
          if (item.departmentSonId) {
            departmentIds.push(item.departmentSonId);
          }
          item.map((c_item) => {
            departmentIds.push(c_item.departmentSonId);
            if (c_item.dsTree) {
              getChildDepartmentId(c_item.dsTree);
            }
          });
        }
        return departmentIds;
      }
      console.log("keys ->", keys);
      if (keys[0] === "all" || !keys.length) {
        const departmentId = [];
        this.state.treeData.map((item) => {
          if (item.departmentSonId !== "all") {
            departmentId.push(
              ...setDepartmentId(item.departmentSonId)
            );
          }
        });
        this.props.getDepartmentId(departmentId);
      } else {
        debugger;
        this.props.getDepartmentId(
          setDepartmentId(keys[0])
        );
      }
    }
  };

  renderTreeNodes = (data) =>
    data.map((item) => {
      const { searchValue } = this.state;
      let f = false;
      if (item.departmentSonName.length > 12) {
        f = true;
      }
      let titleShow = item.departmentSonName;
      titleShow = titleShow.substr(0, 11);
      const index = titleShow.indexOf(searchValue);
      const beforeStr = titleShow.substr(0, index);
      const afterStr = titleShow.substr(
        index + searchValue.length
      );
      const title =
        index > -1 ? (
          <Tooltip
            title={f == true ? item.departmentSonName : ""}
          >
            <span>
              {beforeStr}
              <span style={{ color: "#f50" }}>
                {searchValue}
              </span>
              {afterStr}
            </span>
          </Tooltip>
        ) : (
            <Tooltip
              title={f == true ? item.departmentSonName : ""}
            >
              <span>{titleShow}</span>
            </Tooltip>
          );
      if (item.dsTree) {
        return (
          <TreeNode
            className="children"
            title={title}
            key={
              this.props.type == "流程配置"
                ? item.departmentSonId +
                "&" +
                item.departmentSonName +
                "&" +
                item.level
                : item.departmentSonId
            }
            dataRef={item}
          >
            {this.renderTreeNodes(item.dsTree)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          className="no-children"
          title={title}
          key={
            this.props.type == "流程配置"
              ? item.departmentSonId +
              "&" +
              item.departmentSonName +
              "&" +
              item.level
              : item.departmentSonId
          }
          dataRef={item}
          isLeaf={true}
        />
      );
    });
  getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.dsTree) {
        if (
          node.dsTree.some(
            (item) => item.departmentSonName === key
          )
        ) {
          parentKey =
            this.props.type == "流程配置"
              ? node.departmentSonId +
              "&" +
              node.departmentSonName +
              "&" +
              node.level
              : node.departmentSonId;
        } else if (this.getParentKey(key, node.dsTree)) {
          parentKey = this.getParentKey(key, node.dsTree);
        }
      }
    }
    return parentKey;
  };

  onChange = (e) => {
    const { value } = e.target;
    const expandedKeys = dataList
      .map((item) => {
        if (item.departmentSonName.indexOf(value) > -1) {
          return this.getParentKey(
            item.departmentSonName,
            this.state.treeData
          );
        }
        return null;
      })
      .filter(
        (item, i, self) => item && self.indexOf(item) === i
      );
    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  };

  onExpand = (expandedKeys) => {
    console.log("expandedKeys ->", expandedKeys);
    sessionStorage[this.props.expandedKeys] = expandedKeys;
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  render() {
    const {
      searchValue,
      expandedKeys,
      autoExpandParent,
      selectedKeys,
    } = this.state;
    return (
      <div className="left-tree">
        <Search
          placeholder="项目/部门"
          onChange={this.onChange}
        />
        <Tree
          key={`table-${this.state.treeData &&
            this.state.treeData.length}`}
          onSelect={this.handleTreeNode}
          className="treeList"
          onExpand={this.onExpand}
          expandedKeys={
            split(
              sessionStorage[this.props.expandedKeys],
              ","
            ) || expandedKeys
          }
          // defaultExpandAll
          selectedKeys={
            [sessionStorage[this.props.selectedKeys]] ||
            selectedKeys
          }
        >
          {this.renderTreeNodes(this.state.treeData)}
        </Tree>
      </div>
    );
  }
}
export default LeftTree;
