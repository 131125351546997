/**
 * @fileoverview 系统管理---账号设置 -- 搜索条件
 * @author  蒋西园
 */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, Form, Input, Select, Row, Col, TreeSelect } from 'antd'
import './index.less'

const FormItem = Form.Item
const Option = Select.Option
const { TreeNode } = TreeSelect

class Searchlist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      content: 'Hello, World',
      departmentIds: [],
      departmentIdList: []
    }
  }
  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
    this.querydepartmentId()
  }
  // 查询部门名称
  querydepartmentId() {
    this.$http['departmentlistForRoleTree']({}).then(res => {
      if (res.code === 200) {
        this.setState({
          departmentIdList: res.data
        })
      }
    })
  }
  // 提交表单
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) {
        return false
      }
      console.log(values, this.state.departmentIds)
      const data = { departmentId: this.state.departmentIds[0], ...values }
      // 去除含有undefined的item
      // Object.keys(values).forEach(res => {
      //   if (values[res]) {
      //     data[res] = values[res]
      //   }
      // })
      this.props.handleSearchBtn(data)
    })
  }
  onChange = value => {
    const departmentIds = []
    departmentIds.push(value)
    this.state.departmentIdList.map(item => {
      if (item.departmentId === value && item.chirdren) {
        getChildDepartmentId(item.chirdren)
      } else if (item.chirdren) {
        mapItem(item.chirdren)
      }
    })

    function mapItem(item) {
      item.map(c_item => {
        if (c_item.departmentId === value) {
          if (c_item.chirdren) {
            getChildDepartmentId(c_item.chirdren)
          }
        } else if (item.chirdren) {
          mapItem(item.chirdren)
        }
      })
    }

    function getChildDepartmentId(item) {
      if (item.departmentId) {
        departmentIds.push(item.departmentId)
      }
      item.map(c_item => {
        departmentIds.push(c_item.departmentId)
        if (c_item.chirdren) {
          getChildDepartmentId(c_item.chirdren)
        }
      })
    }
    this.setState({ departmentIds: departmentIds })
  }

  // 部门查询树状结构
  getTreeNode = item => {
    return (
      <TreeNode key={item.departmentId} value={item.departmentId} title={item.departmentName}>
        {item.chirdren
          ? item.chirdren.map(c_item => {
              return this.getTreeNode(c_item)
            })
          : ''}
      </TreeNode>
    )
  }
  handleReset = () => {
    this.props.form.resetFields()
    this.props.handleSearchBtn({})
  }
  render() {
    const { parentDepartmentList } = this.props
    const { getFieldDecorator } = this.props.form

    return (
      <div className="search-form">
        <Form>
          <Row gutter={24}>
            <Col span={9} style={{ marginLeft: 0 }}>
              <FormItem label="部门" labelCol={{ span: 3 }} wrapperCol={{ span: 16 }}>
                {/* {getFieldDecorator('departmentName')( */}
                <TreeSelect
                  showSearch
                  style={{ height: 32 }}
                  value={this.state.departmentIds}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="请选择"
                  allowClear
                  treeDefaultExpandAll
                  onChange={this.onChange}
                  showCheckedStrategy={'SHOW_PARENT'}
                  treeNodeFilterProp="title"
                >
                  {(this.state.departmentIdList || []).map(item => {
                    return this.getTreeNode(item)
                  })}
                </TreeSelect>
                {/* )} */}
              </FormItem>
            </Col>
            <Col span={9}>
              <FormItem label="负责人" labelCol={{ span: 3 }} wrapperCol={{ span: 16 }}>
                {getFieldDecorator('departmentPrincipalName')(<Input placeholder="请输入" allowClear />)}
              </FormItem>
            </Col>
            {/* <Col span={5} >
                            <FormItem label="类型"  labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                                {
                                    getFieldDecorator('type')(
                                        <Select placeholder="请选择" allowClear>
                                            <Option value="1">项目</Option>
                                            <Option value="2">部门</Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </Col> */}
            {/* <Col span={5} >
                            <FormItem label="上级部门" labelCol={{ span: 9 }} wrapperCol={{ span: 16 }}>
                                {
                                    getFieldDecorator('parentDepartmentId')(
                                        <Select placeholder="请选择" allowClear>
                                            {
                                                (parentDepartmentList || []).map(item => {
                                                    return <Option key={item.departmentId} value={item.departmentId}>{item.departmentName}</Option>
                                                })
                                            }
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </Col> */}
            <Col span={6}>
              <Button onClick={this.handleReset}>重置</Button>
              <Button type="primary" onClick={this.handleSubmit} style={{ marginLeft: 14 }}>
                查询
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default withRouter(Form.create()(Searchlist))
