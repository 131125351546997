import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Timeline,
  Icon,
  Form,
  Select,
  Row,
  Col,
  Button,
  Input,
  Upload,
  message,
  Radio,
  DatePicker,
  Modal,
  Alert,
  Tabs,
  Table,
  Cascader,
  Tooltip,
} from "antd";
import "./index.less";
import ProcessGuide from "../../../components/processGuide";
import AuditRecord from "../../../components/auditRecord";
import { getQueryVariable } from "../../../utils/common";
import utils from "../../../utils/utils";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;

let annexType = {
  1: "毕业证",
  2: "学位证",
  3: "身份证正面",
  4: "身份证反面",
  5: "附件",
};
const dateFormat = "YYYY/MM/DD";
// 校验，，合同的时间校验，

const formItemLayout = {
  labelCol: {
    span: 12,
  },
  wrapperCol: {
    span: 12,
  },
};

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: utils.localDb.get("userData").staffId,
      staffName: utils.localDb.get("userData").staffName,
      employeeInformation: {
        staffName: "姓名:",
        sex: "性别:",
        marriageStatus: "婚姻状况:",
        age: "年龄:",
        birthday: "出生日期:",
        actualBirthday: "实际生日:",
        certificatesType: "证件类型:",
        certificatesNumber: "证件号码:",
        constellation: "星座",
        email: "邮箱:",
        phone: "手机:",
        politicalOutlook: "政治面貌:",
        nativePlaceProvince: "籍贯:",
        residenceNature: "户口性质:",
        nation: "民族",
        registrationAddress: "户籍地址:",
        nowAddress: "现地址:",
      },

      salaryAndWelfare: {
        probationSalary: "试用期薪资:",
        trunSalary: "转正薪资:",
        clientRaise: "客户认可的加薪:",
        companyRaise: "公司加薪:",
        totalTurn: "合计转正:",
        remainingAnnualLeave: "剩余年假:",
        socialSecurityBase: "社保基数:",
        providentFundBase: "公积金基数:",
        businessSettlement: "商务结算",
        remark: "备注",
      },
      emergencyContact: {
        urgentContactPeople: "紧急联系人:",
        relationship: "关系:",
        urgentContacPhone: "电话:",
      },
      bankInformation: {
        bankNumber: "银行卡号:",
        bankInformation: "开户行信息:",
      },
      education: {
        graduationTime: "毕业时间",
        graduationSchool: "毕业院校:",
        highestEducation: "最高学历:",
        bachelorScience: "学位:",
        profession: "专业:",
        educationCertificateNumber: "学历证书编号:",
        qualificationsCertificate: "资格证书:",
      },
      incumbency: {
        isProbation: "是否试用期:",
        isLeave: "是否离职:",
        leaveTime: "离职时间:",
        leaveReason: "离职原因:",
      },

      tableData: [],
      fileList: [],
      showType: 1,
      previewVisible: false, // 预览弹框
      previewUrl: "", // 预览地址
      inductionDetail: {},
      tab: 1,
      logList: [],
      submitDisabled: false,
      showComplete: 0,
    };
  }
  componentDidMount() {
    if (getQueryVariable("inductionProcId")) {
      this.getInductionById();
      this.queryProcessLogList();
    } else {
      this.setState({
        showType: 1,
        logList: [],
      });
    }

    // document
    //   .getElementById("printBtn")
    //   .addEventListener("click", this.handlePrint, false);
  }

  getInductionById = () => {
    this.$http["getInductionById"]({
      inductionProcId: getQueryVariable("inductionProcId"),
    })
      .then((res) => {
        console.log(res, "22222");
        if (res.code === 200) {
          let showType = 2;
          let showPass = false;
          let showComplete = 0;
          let step2 = false;
          let step3 = false;
          let isAudit = false;

          if (res.data.procStatus == "REFUSE") {
            if (res.data.auditId == this.state.staffId) {
              showType = 1;
              step2 = true;
            }
          }
          if (res.data.procStatus == "AUDIT") {
            isAudit = true;
            console.log(
              "this.state.staffId->",
              this.state.staffId
            );
            console.log(
              "res.data.auditId->",
              res.data.auditId
            );
            if (res.data.auditId == this.state.staffId) {
              if (
                res.data.processData.taskDefKey == "ENT0002"
              ) {
                showType = 1;
                step2 = true;
                showComplete = 0;
                // step3 = true;
                // 部署需要修改
              } else if (
                res.data.processData.taskDefKey == "ENT0003"
              ) {
                step3 = true;
                showComplete = res.data.processData.status
                  ? 1
                  : 2;
              }
            }
          }
          if (res.data.procStatus == "COMPLETE") {
            showComplete = 0;
          }
          console.log("res.data---->", res.data);
          // 抄送的详情没有操作权限
          if (getQueryVariable("copyToFlag") == 1) {
            step2 = false;
            step3 = false;
          }
          if (res.data.staffAnnexs.length) {
            var arr1 = res.data.staffAnnexs.filter(
              (item) => item.annexType == 5
            );
            arr1.map((item, index) => {
              item.num = index + 1;
            });
            var arr2 = res.data.staffAnnexs.filter(
              (item) => item.annexType == 1
            );
            var arr3 = res.data.staffAnnexs.filter(
              (item) => item.annexType == 2
            );
            var arr4 = res.data.staffAnnexs.filter(
              (item) => item.annexType == 4
            );
            var arr5 = res.data.staffAnnexs.filter(
              (item) => item.annexType == 3
            );
          }
          var arr = [
            ...arr2,
            ...arr3,
            ...arr1,
            ...arr4,
            ...arr5,
          ];

          this.setState(
            {
              inductionDetail: res.data || {},
              tableData: res.data.staffWorkExperience || [],
              fileList: arr || [],
              showType,
              showPass,
              showComplete,
              isAudit,
              step2,
              step3,
            },
            () => {
              this.$http["queryProvinceList"]({}).then(
                (res) => {
                  if (res.code === 200) {
                    this.setState({
                      workProvince:
                        res.data.provinceList || [],
                      socialProvince:
                        res.data.provinceList || [],
                    });
                  }
                }
              );
            }
          );
        } else {
        }
      })
      .catch((err) => {});
  };

  //流程日志
  queryProcessLogList = () => {
    this.$http["queryProcessLogList"]({
      businessKey: getQueryVariable("inductionProcId"),
      procTemplate: getQueryVariable("procTemplate"),
    })
      .then((res) => {
        if (res.code == 200) {
          this.setState({
            logList: res.data || [],
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  // 二次确认框信息html
  confirmHTML = () => {
    const { showComplete, inductionDetail } = this.state;
    const { form } = this.props;
    let labelList = {};
    const formItemLayout = {
      labelCol: {
        span: 10,
      },
      wrapperCol: {
        span: 14,
      },
    };
    console.log(inductionDetail.businessSettlement);
    if (showComplete) {
      labelList = {
        staffName: "姓名",
        onboardingTime: "入职时间",
        contractStartday: "最新合同起始日",
        contractMaturityday: "最新合同到期日",
      };
    } else {
      labelList = {
        staffName: "姓名",
        onboardingTime: "入职时间",
      };
    }
    return (
      <Form {...formItemLayout}>
        {Object.keys(labelList).map((item) => {
          return (
            <Form.Item label={labelList[item]}>
              <span>
                {item === "staffName"
                  ? inductionDetail.staffName || "--"
                  : moment(form.getFieldValue(item)).format(
                      "YYYY/MM/DD"
                    ) || "--"}
              </span>
            </Form.Item>
          );
        })}
      </Form>
    );
  };

  //二次确认框
  confirm = () => {
    const { showComplete, inductionDetail } = this.state;
    const { form } = this.props;
    let modal = {};
    console.log(inductionDetail.businessSettlement);
    form.validateFieldsAndScroll((err) => {
      if (err) {
        return;
      }
      if (!inductionDetail.businessSettlement) {
        message.error("请输入商务结算");
        return;
      }
      switch (showComplete) {
        case 0:
          modal = {
            title: "请核对员工入职时间",
            content: inductionDetail.onboardingTime,
            onOk: this.handlePass,
          };
          break;
        case 1:
          modal = {
            title: "创建账号",
            content:
              "创建账号后，流程将不可退回，确定继续吗？",
            onOk: this.createAccount,
          };
          break;
        case 2:
          modal = {
            title: "请核对员工的入职时间",
            content: inductionDetail.onboardingTime,
            onOk: this.handlePass,
          };
      }
      Modal.confirm({
        ...modal,
        okText: "确认",
        cancelText: "取消",
      });
    });
  };

  //创建账号
  createAccount = () => {
    const { inductionDetail } = this.state;
    const { form } = this.props;
    const values = form.getFieldsValue();
    let params = {
      inductionProcId: getQueryVariable("inductionProcId"),
      taskId: inductionDetail.processData.taskId,
      lastCompanyTime: values["lastCompanyTime"]
        ? values["lastCompanyTime"].format("YYYY/MM/DD")
        : "",
      onboardingTime: values["onboardingTime"].format(
        "YYYY/MM/DD"
      ),
      turnTime: inductionDetail.turnTime,
      auditOpinion: inductionDetail.auditOpinion || "",
      businessSettlement: inductionDetail.auditOpinion,
      inductionProcId: inductionDetail.inductionProcId,
      isOtherCompanyTransfer:
        inductionDetail.isOtherCompanyTransfer,
      remark: values["remark"] || "",
      //probationTime: parseInt(values["probationTime"]),
    };
    this.$http["createAccount"](params).then((res) => {
      if (res.code === 200) {
        this.getInductionById();
      }
    });
  };

  // 通过
  handlePass = () => {
    const { inductionDetail, showType } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      console.log(err, values);
      if (!err) {
        let params = {};
        if (showType === 1) {
          params = {
            firstContractStartday:
              inductionDetail.firstContractStartday,
            firstContractMaturityday:
              inductionDetail.firstContractMaturityday,
            contractStartday:
              inductionDetail.contractStartday,
            contractMaturityday:
              inductionDetail.contractMaturityday,
            inductionProcId: getQueryVariable(
              "inductionProcId"
            ),
            taskId: inductionDetail.processData.taskId,
            ...values,
            lastCompanyTime: values["lastCompanyTime"]
              ? values["lastCompanyTime"].format(
                  "YYYY/MM/DD"
                )
              : "",
            turnTime: inductionDetail.turnTime,
            onboardingTime: inductionDetail.onboardingTime,
          };
        } else if (showType === 2) {
          params = {
            turnTime: inductionDetail.turnTime,
            inductionProcId: getQueryVariable(
              "inductionProcId"
            ),
            taskId: inductionDetail.processData.taskId,
            ...values,
            belongDeliverManagerId:
              inductionDetail.belongDeliverManagerId || "",
            belongDeliverManager:
              inductionDetail.belongDeliverManager,

            departmentId: values["departmentId"]
              ? values["departmentId"].key
              : "",
            departmentName: values["departmentId"]
              ? values["departmentId"].label
              : "",
            postId: values["postName"]
              ? values["postName"].key
              : "",
            postName: values["postName"]
              ? values["postName"].label
              : "",
            socialSecurityfundPayLocalProvince: values[
              "socialSecurityfundPayLocalProvinceName"
            ]
              ? values[
                  "socialSecurityfundPayLocalProvinceName"
                ][0]
              : inductionDetail.socialSecurityfundPayLocalProvince,

            socialSecurityfundPayLocalProvinceName: this
              .state.socialSecurityfundPayLocalProvinceName
              ? this.state
                  .socialSecurityfundPayLocalProvinceName
              : inductionDetail.socialSecurityfundPayLocalProvinceName,
            socialSecurityfundPayLocalCity: values[
              "socialSecurityfundPayLocalProvinceName"
            ]
              ? values[
                  "socialSecurityfundPayLocalProvinceName"
                ][1]
              : inductionDetail.socialSecurityfundPayLocalCity,

            socialSecurityfundPayLocalCityName: this.state
              .socialSecurityfundPayLocalCityName
              ? this.state
                  .socialSecurityfundPayLocalCityName
              : inductionDetail.socialSecurityfundPayLocalCityName,
            contractType: values["contractType"]
              ? values["contractType"].key
              : "",
            contractName: values["contractType"]
              ? values["contractType"].label
              : "",
            hireNature: values["hireNature"]
              ? values["hireNature"].key
              : "",
            firstContractStartday: values[
              "firstContractStartday"
            ].format("YYYY/MM/DD"),
            firstContractMaturityday: values[
              "firstContractMaturityday"
            ].format("YYYY/MM/DD"),
            contractStartday: values[
              "contractStartday"
            ].format("YYYY/MM/DD"),
            contractMaturityday: values[
              "contractMaturityday"
            ].format("YYYY/MM/DD"),
            onboardingTime: inductionDetail.onboardingTime,
            probationTime: values["probationTime"],
          };
        }

        this.setState({
          submitDisabled: true,
        });
        this.$http["inductionPass"]({ ...params })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              this.props.history.go(-1);
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };
  // 不通过
  handleFail = () => {
    const self = this;
    const { form } = this.props;
    const { inductionDetail } = this.state;
    if (!form.getFieldValue("auditOpinion").trim()) {
      message.destroy();
      message.warning("请在审核意见中填写退回原因");
      document.getElementById("auditOpinion").focus();
      return;
    }
    confirm({
      icon: (
        <Icon
          type="question-circle"
          style={{ color: "#4D91FA" }}
        />
      ),
      title: "您确定退回该工作流吗？",
      content: "",
      onOk() {
        self.$http["inductionRefuse"]({
          probationTime: inductionDetail.probationTime,
          onboardingTime: inductionDetail.onboardingTime,
          inductionProcId: getQueryVariable(
            "inductionProcId"
          ),
          taskId: inductionDetail.processData.taskId,
          auditOpinion: form
            .getFieldValue("auditOpinion")
            .trim(),
          // ...values,
          // departmentId: values["departmentId"]
          //   ? values["departmentId"].key
          //   : "",
          // departmentName: values["departmentId"]
          //   ? values["departmentId"].label
          //   : "",
          // postId: values["postName"] ? values["postName"].key : "",
          // postName: values["postName"] ? values["postName"].label : "",
          // contractType: values["contractType"]
          //   ? values["contractType"].key
          //   : "",
          // contractName: values["contractType"]
          //   ? values["contractType"].label
          //   : "",
          // hireNature: values["hireNature"] ? values["hireNature"].key : "",
          // firstContractStartday: values["firstContractStartday"]
          //   ? values["firstContractStartday"].format("YYYY-MM-DD")
          //   : "",
          // firstContractMaturityday: values["firstContractMaturityday"]
          //   ? values["firstContractMaturityday"].format("YYYY-MM-DD")
          //   : "",
          // contractStartday: values["contractStartday"]
          //   ? values["contractStartday"].format("YYYY-MM-DD")
          //   : "",
          // contractMaturityday: values["contractMaturityday"]
          //   ? values["contractMaturityday"].format("YYYY-MM-DD")
          //   : "",
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              self.props.history.go(-1);
            } else {
            }
          })
          .catch((err) => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
    // form.validateFieldsAndScroll((err, values) => {
    //   if (!err) {
    //   }
    // });
  };

  handleAddSure = (values) => {
    console.log(values);
  };

  // 打开预览弹框
  handlePreview = (url) => {
    this.setState({
      previewVisible: true,
      previewUrl: url,
    });
  };
  // 取消预览弹框
  handleCancel = () => {
    this.setState({
      previewVisible: false,
    });
  };

  // 部门名称下拉框数据查询
  selectDepartment = (open, key) => {
    if (open) {
      this.$http["selectDepartment"]({}).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data || [],
          });
        }
      });
    }
  };

  // 岗位下拉框
  queryPost = (open, key) => {
    if (open) {
      this.$http["selectPost"]({}).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data || [],
          });
        }
      });
    }
  };

  // 省市选择
  provinceChange = (value, selectedOptions, type) => {
    console.log(value, selectedOptions);
    if (type === "workProvince") {
      // 工作城市
      this.setState({
        socialSecurityfundPayLocalProvinceName: selectedOptions[0]
          ? selectedOptions[0].addressName
          : "",
        socialSecurityfundPayLocalCityName: selectedOptions[1]
          ? selectedOptions[1].addressName
          : "",
      });
    } else if (type === "socialProvince") {
      // 社保缴纳地
      this.setState({
        socialSecurityfundPayLocalProvinceName: selectedOptions[0]
          ? selectedOptions[0].addressName
          : "",
        socialSecurityfundPayLocalCityName: selectedOptions[1]
          ? selectedOptions[1].addressName
          : "",
      });
    }
  };

  // 查询省市列表
  queryProvinceList = (open, key) => {
    if (open) {
      this.$http["queryProvinceList"]({}).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data.provinceList || [],
          });
        }
      });
    }
  };

  // 下拉框数据查询
  selectListByGroup = (open, key) => {
    if (open) {
      this.$http["selectListByGroup"]({
        dicGroup: key,
      }).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data || [],
          });
        }
      });
    }
  };

  validatorRemark = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };
  validatorOpinion = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };
  validatorMoney = (rule, value, callback) => {
    const reg = /^[0-9]+(.[0-9]{1,2})?$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback("大于等于0，小于99999999 ，两位小数");
    } else {
      if (Number(value.trim()) > 99999999) {
        callback("大于等于0，小于99999999 ，两位小数");
      } else {
        callback();
      }
    }
  };

  handlePrint = () => {
    var el = document.getElementById("print-box");
    var el2 = document.getElementById("print-box2");
    var headDom = document.getElementsByTagName("head")[0];
    var iframe = document.createElement("iframe");
    iframe.setAttribute("id", "print-box3");
    iframe.setAttribute(
      "style",
      "position:absolute;width:1200px;height:0px;left:-500px;top:-500px;"
    );
    // 在页面插入iframe
    document.body.appendChild(iframe);
    let doc = iframe.contentWindow.document;
    let printMain = document.createElement("div");
    printMain.setAttribute("id", "print-box3");
    printMain.innerHTML = el.innerHTML + el2.innerHTML;
    doc.body.appendChild(printMain);
    doc.getElementsByTagName("head")[0].innerHTML =
      headDom.innerHTML;
    doc.close();
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
    setTimeout(() => {
      // document.body.removeChild(iframe);
    }, 0);
  };

  disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  // 签订总次数校验
  validatorNumber = (rule, value, callback) => {
    const { form } = this.props;
    const reg = /^([1-9][0-9]{0,2})$/;
    const reg2 = /^(0|([1-9]\d{0,2}))$/;
    if (rule.field == "probationTime") {
      if (
        value &&
        value.trim() &&
        !reg2.test(value.trim())
      ) {
        callback("输入整数，小于999");
      } else {
        callback();
      }
    } else {
      if (
        value &&
        value.trim() &&
        !reg.test(value.trim())
      ) {
        callback("输入整数，大于0，小于999");
      } else {
        callback();
      }
    }
  };

  // 签订总次数改变
  signTotalFrequencyChange = (e) => {
    console.log(e.target.value);
    const value = parseInt(e.target.value);
    const { form } = this.props;
    if (value == 1) {
      // let firstContractStartday = form.getFieldValue("firstContractStartday");
      // let firstContractMaturityday = ;
      // let contractStartday;
      // let contractMaturityday;
      // if (firstContractStartday) {
      //   contractStartday = firstContractStartday.format("YYYY-MM-DD");
      // }
      // if (firstContractMaturityday) {
      //   contractMaturityday = firstContractMaturityday.format("YYYY-MM-DD");
      // }
      form.setFieldsValue({
        contractStartday:
          form.getFieldValue("firstContractStartday") ||
          undefined,
        contractMaturityday:
          form.getFieldValue("firstContractMaturityday") ||
          undefined,
      });
    }
  };
  // 试用期时长改变
  probationTimeChange = (e) => {
    console.log(e.target.value);
    const value = parseInt(e.target.value.trim());
    const { form } = this.props;
    const { inductionDetail } = this.state;
    const onboardingTime = form.getFieldValue(
      "onboardingTime"
    );
    if (
      value != null &&
      typeof value == "number" &&
      onboardingTime
    ) {
      if (value < 999) {
        inductionDetail.turnTime = moment(onboardingTime)
          .add(value, "M")
          .format("YYYY/MM/DD");
        if (value == 0) {
          inductionDetail.isProbation = false;
        } else {
          inductionDetail.isProbation = true;
        }
      }
    }
  };

  // 首次合同起始日选择、校验
  FCSChange = (date, dateString) => {
    const { form } = this.props;
    const signTotalFrequency = form.getFieldValue(
      "signTotalFrequency"
    );
    if (signTotalFrequency && signTotalFrequency == 1) {
      form.setFieldsValue({
        contractStartday: dateString
          ? moment(dateString, "YYYY/MM/DD")
          : undefined,
      });
    }
  };
  // 首次合同起始日选择，校验
  disabledDateFCS = (current) => {
    const { form } = this.props;
    const FCS = form.getFieldValue(
      "firstContractMaturityday"
    );
    if (FCS) {
      var time = FCS.format("YYYY/MM/DD");
      return current && current > moment(time).endOf("day");
    }
  };
  // 首次合同到期日选择、校验
  FCMChange = (date, dateString) => {
    const { form } = this.props;
    const signTotalFrequency = form.getFieldValue(
      "signTotalFrequency"
    );
    if (signTotalFrequency && signTotalFrequency == 1) {
      form.setFieldsValue({
        contractMaturityday: dateString
          ? moment(dateString, "YYYY/MM/DD")
          : undefined,
      });
    }
  };
  // 首次合同到期日选择、校验
  disabledDateCM = (current) => {
    const { form } = this.props;
    const FCM = form.getFieldValue("firstContractStartday");
    if (FCM) {
      var time = FCM.format("YYYY/MM/DD");
      return current && current < moment(time).endOf("day");
    }
  };
  // 最新合同起始日选择、校验
  disabledDateFCS = (current) => {
    const { form } = this.props;
    const CS = form.getFieldValue("contractMaturityday");
    if (CS) {
      var time = CS.format("YYYY/MM/DD");
      return current && current > moment(time).endOf("day");
    }
  };
  // 最新合同到期日选择、校验
  disabledDateCM = (current) => {
    const { form } = this.props;
    const CM = form.getFieldValue("contractStartday");
    if (CM) {
      var time = CM.format("YYYY/MM/DD");
      return current && current < moment(time).endOf("day");
    }
  };

  // 入职日期选择、校验
  disabledDateBoar = (current) => {
    return current < moment("2021-01-01");
  };

  // 部门名称切换
  deptChange = (value, option) => {
    console.log(value, option);
    const { inductionDetail } = this.state;
    if (option) {
      if (inductionDetail.deptHead == 1) {
        this.$http["queryParentPrincipal"]({
          departmentId: value.key,
        })
          .then((res) => {
            if (res.code == 200) {
              inductionDetail.belongDeliverManagerId = res.data
                ? res.data.departmentPrincipalId
                : "";
              inductionDetail.belongDeliverManager = res.data
                ? res.data.departmentPrincipalName
                : "";
              this.setState({
                inductionDetail,
              });
            }
          })
          .catch((err) => {});
      } else {
        inductionDetail.belongDeliverManagerId =
          option.props.extra.departmentPrincipalId || "";
        inductionDetail.belongDeliverManager =
          option.props.extra.departmentPrincipalName || "";
        this.setState({
          inductionDetail,
        });
      }
    }
  };

  // 文本显示
  showTooltip = (text, max = 10) => {
    return text && text.length > max ? (
      <Tooltip placement="topLeft" title={text}>
        <span>{text.slice(0, 8) + "..."}</span>
      </Tooltip>
    ) : (
      <span>{text || "--"}</span>
    );
  };

  //入职时间联动更新转正时间
  onboardingTimeChange = (date, dateStr) => {
    const { inductionDetail, showComplete } = this.state;
    const { form } = this.props;
    const probationTime = showComplete
      ? form.getFieldValue("probationTime")
      : inductionDetail.probationTime;
    if (dateStr) {
      inductionDetail.turnTime = moment(dateStr)
        .add(probationTime, "M")
        .format("YYYY/MM/DD");
      form.setFieldsValue({
        firstContractStartday: moment(
          dateStr,
          "YYYY/MM/DD"
        ),
        contractStartday: moment(dateStr, "YYYY/MM/DD"),
      });
      inductionDetail.onboardingTime = dateStr;
      inductionDetail.firstContractStartday = dateStr;
      inductionDetail.contractStartday = dateStr;
      this.setState({ inductionDetail });
    }
  };

  //最新合同到期更新首次合同到期
  CMChange = (date, dateStr) => {
    const { form } = this.props;
    const signTotalFrequency = form.getFieldValue(
      "signTotalFrequency"
    );
    if (signTotalFrequency && signTotalFrequency == 1) {
      form.setFieldsValue({
        firstContractMaturityday: moment(
          dateStr,
          "YYYY/MM/DD"
        ),
      });
    }
  };

  changebusinessSettlement = (e) => {
    const { inductionDetail } = this.state;
    inductionDetail.businessSettlement = e.target.value;
    this.setState({ inductionDetail });
  };

  //按钮显示

  render() {
    const {
      employeeInformation,
      salaryAndWelfare,
      bankInformation,
      emergencyContact,
      education,
      tableData,
      fileList,
      previewVisible,
      previewUrl,
      showType,
      incumbency,
      inductionDetail,
      logList,
    } = this.state;

    const columns = [
      {
        title: "时间",
        dataIndex: "time",
        key: "time",
        width: 200,
        render: (text, record) => {
          return (
            <span>{`${record.startWorkTime}~${record.endWorkTime}`}</span>
          );
        },
      },
      {
        title: "所在公司",
        dataIndex: "company",
        key: "company",
        width: 200,
      },
      {
        title: "工作岗位",
        dataIndex: "job",
        key: "job",
        width: 200,
      },
      {
        title: "薪资",
        dataIndex: "salary",
        key: "salary",
        width: 100,
      },
      {
        title: "证明人",
        dataIndex: "witness",
        key: "witness",
        width: 200,
      },
      {
        title: "联系方式",
        dataIndex: "contactWay",
        key: "contactWay",
        width: 200,
      },
    ];

    const { form } = this.props;
    const { getFieldDecorator } = form;

    // 显示审核结果，判断右侧记录第一个的状态operStatus，有的话就显示第一个，没有的话显示第二个
    let log = {};
    if (logList.length > 1) {
      if (logList[0].operStatus) {
        log = logList[0];
      } else {
        log = logList[1];
      }
    }

    return (
      <div className="checkin-approval">
        <div className="approval-title">
          <span>
            入职流程
            {/* {showType == 1 && <span>-新建</span>} */}
            {inductionDetail.procStatus == "AUDIT" ? (
              inductionDetail.processData ? (
                <span>{`-${inductionDetail.processData.taskName}`}</span>
              ) : null
            ) : null}
          </span>
          <ProcessGuide
            title="入职流程"
            temp="ENTRY_PROC"
          />
          {this.state.tab == 2 && this.state.showType == 2 && (
            <a id="printBtn" onClick={this.handlePrint}>
              <span style={{ marginRight: 6 }}>|</span>
              打印个人信息
            </a>
          )}
        </div>
        <div className="approval-wrap">
          <div className="wrap-left" style={{ flex: 1 }}>
            {log && log.operDescription ? (
              <div>
                {log.operStatus == "PASS" && (
                  <Alert
                    message={`${log.taskName}通过`}
                    description={log.operDescription}
                    type="success"
                    showIcon
                    style={{ marginBottom: 12 }}
                  />
                )}
                {log.operStatus == "REFUSE" && (
                  <Alert
                    message={
                      <span className="alert-process-refuse">{`${log.taskName}不通过`}</span>
                    }
                    description={
                      <span className="alert-process-desc">
                        {log.operDescription}
                      </span>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="close-circle"
                        style={{
                          fontSize: 18,
                          color: "#E5537B",
                          top: "18px",
                        }}
                      />
                    }
                    style={{ marginBottom: 12 }}
                  />
                )}
              </div>
            ) : null}

            <div className="if-card tip-line">
              <span
                onClick={() => {
                  this.setState({
                    tab: 1,
                  });
                }}
                className={`${
                  this.state.tab === 1
                    ? "isActive"
                    : "isHover"
                }`}
              >
                岗位&薪资
              </span>
              <span
                onClick={() => {
                  this.setState({
                    tab: 2,
                  });
                }}
                className={`${
                  this.state.tab === 2
                    ? "isActive"
                    : "isHover"
                }`}
              >
                个人信息
              </span>
            </div>
            {this.state.tab == 1 && (
              <Form
                {...formItemLayout}
                className="process-form-readonly"
              >
                <Row className="wrap-block">
                  <div className="block-title">
                    岗位信息
                  </div>
                  <Row>
                    <Col span={8}>
                      <FormItem label="姓名">
                        <span>
                          {inductionDetail.staffName ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="工号">
                        <span>
                          {inductionDetail.staffCode ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="直属上级">
                        <span>
                          {inductionDetail.belongDeliverManager ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="部门名称">
                          {getFieldDecorator(
                            "departmentId",
                            {
                              initialValue: inductionDetail.departmentId
                                ? {
                                    key:
                                      inductionDetail.departmentId,
                                    label:
                                      inductionDetail.departmentName,
                                  }
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: "部门名称必填",
                                },
                              ],
                            }
                          )(
                            <Select
                              placeholder="请选择"
                              allowClear
                              labelInValue
                              showSearch
                              filterOption={(
                                input,
                                option
                              ) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(
                                    input.toLowerCase()
                                  ) >= 0
                              }
                              onDropdownVisibleChange={(
                                value
                              ) =>
                                this.selectDepartment(
                                  value,
                                  "department"
                                )
                              }
                              getPopupContainer={(
                                triggerNode
                              ) =>
                                triggerNode.parentElement
                              }
                              onChange={this.deptChange}
                            >
                              {this.state["department"] &&
                                this.state[
                                  "department"
                                ].map((item) => {
                                  return (
                                    <Option
                                      key={
                                        item.departmentId
                                      }
                                      value={
                                        item.departmentId
                                      }
                                      extra={item}
                                    >
                                      {item.departmentName}
                                    </Option>
                                  );
                                })}
                            </Select>
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="部门名称">
                          <span>
                            {inductionDetail.departmentName ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="岗位">
                          {getFieldDecorator("postName", {
                            initialValue: inductionDetail.postId
                              ? {
                                  key:
                                    inductionDetail.postId,
                                  label:
                                    inductionDetail.postName,
                                }
                              : undefined,
                            rules: [
                              {
                                required: true,
                                message: "岗位必填",
                              },
                            ],
                          })(
                            <Select
                              placeholder="请选择"
                              allowClear
                              labelInValue
                              showSearch
                              filterOption={(
                                input,
                                option
                              ) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(
                                    input.toLowerCase()
                                  ) >= 0
                              }
                              onDropdownVisibleChange={(
                                value
                              ) =>
                                this.queryPost(
                                  value,
                                  "position"
                                )
                              }
                              getPopupContainer={(
                                triggerNode
                              ) =>
                                triggerNode.parentElement
                              }
                            >
                              {this.state["position"] &&
                                this.state["position"].map(
                                  (item) => {
                                    return (
                                      <Option
                                        key={item.postId}
                                        value={item.postId}
                                      >
                                        {item.postName}
                                      </Option>
                                    );
                                  }
                                )}
                            </Select>
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="岗位">
                          <span>
                            {inductionDetail.postName ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      <FormItem label="职级">
                        <span>
                          {inductionDetail.occupationName ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      {this.state.isAudit ? (
                        <FormItem label="入职时间">
                          {getFieldDecorator(
                            "onboardingTime",
                            {
                              initialValue: inductionDetail.onboardingTime
                                ? moment(
                                    inductionDetail.onboardingTime,
                                    "YYYY/MM/DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  type: "object",
                                  message: "入职时间必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              onChange={
                                this.onboardingTimeChange
                              }
                              disabledDate={
                                this.disabledDateBoar
                              }
                              format={dateFormat}
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="入职时间">
                          <span>
                            {inductionDetail.onBoardingTime ||
                              inductionDetail.onboardingTime ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      <FormItem label="转正时间">
                        <span>
                          {inductionDetail.turnTime || "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="试用期时长">
                          {getFieldDecorator(
                            "probationTime",
                            {
                              initialValue:
                                inductionDetail.probationTime !=
                                null
                                  ? String(
                                      inductionDetail.probationTime
                                    )
                                  : "",
                              rules: [
                                {
                                  required: true,
                                  message: "试用期时长必填",
                                },
                                {
                                  validator: this
                                    .validatorNumber,
                                },
                              ],
                            }
                          )(
                            <Input
                              placeholder="请输入"
                              allowClear
                              style={{ width: 100 }}
                              onChange={
                                this.probationTimeChange
                              }
                            />
                          )}
                          <span>个月</span>
                        </FormItem>
                      ) : (
                        <FormItem label="试用期时长">
                          <span>
                            {inductionDetail.probationTime ||
                              "--"}
                            个月
                          </span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <FormItem label="工作城市">
                        <span>
                          {inductionDetail.workProvinceName
                            ? `${inductionDetail.workProvinceName}${inductionDetail.workCityName}`
                            : "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="社保公积金缴纳地">
                          {getFieldDecorator(
                            "socialSecurityfundPayLocalProvinceName",
                            {
                              initialValue: inductionDetail.socialSecurityfundPayLocalProvince
                                ? [
                                    inductionDetail.socialSecurityfundPayLocalProvince,
                                    inductionDetail.socialSecurityfundPayLocalCity,
                                  ]
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "社保公积金缴纳地必填",
                                },
                              ],
                            }
                          )(
                            <Cascader
                              fieldNames={{
                                label: "addressName",
                                value: "addressCode",
                                children: "cityList",
                              }}
                              options={
                                this.state.socialProvince ||
                                []
                              }
                              onChange={(
                                value,
                                selectedOptions
                              ) =>
                                this.provinceChange(
                                  value,
                                  selectedOptions,
                                  "socialProvince"
                                )
                              }
                              placeholder="省市二级"
                              onPopupVisibleChange={(
                                value
                              ) =>
                                this.queryProvinceList(
                                  value,
                                  "socialProvince"
                                )
                              }
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="社保公积金缴纳地">
                          <span>
                            {inductionDetail.socialSecurityfundPayLocalProvinceName
                              ? `${inductionDetail.socialSecurityfundPayLocalProvinceName}${inductionDetail.socialSecurityfundPayLocalCityName}`
                              : "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="是否校招">
                          {getFieldDecorator(
                            "isSchoolRecruitment",
                            {
                              initialValue:
                                inductionDetail.isSchoolRecruitment !=
                                null
                                  ? inductionDetail.isSchoolRecruitment
                                    ? 1
                                    : 0
                                  : "",
                              rules: [
                                {
                                  required: true,
                                  message: "是否校招必填",
                                },
                              ],
                            }
                          )(
                            <Radio.Group>
                              <Radio value={1}>是</Radio>
                              <Radio value={0}>否</Radio>
                            </Radio.Group>
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="是否校招">
                          <span>
                            {inductionDetail.isSchoolRecruitment
                              ? "是"
                              : "否"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                </Row>
                {inductionDetail.showJob == 1 && (
                  <Row className="wrap-block">
                    <div className="block-title">
                      在职情况
                    </div>
                    <Row>
                      {Object.keys(incumbency).map(
                        (item) => {
                          if (
                            item == "isProbation" ||
                            item == "isLeave"
                          ) {
                            return (
                              <Col span={8} key={item}>
                                <FormItem
                                  label={incumbency[item]}
                                >
                                  <span>
                                    {inductionDetail[item]
                                      ? "是"
                                      : "否"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          } else {
                            return (
                              <Col span={8} key={item}>
                                <FormItem
                                  label={incumbency[item]}
                                >
                                  <span>
                                    {inductionDetail[
                                      item
                                    ] || "--"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          }
                        }
                      )}
                    </Row>
                  </Row>
                )}
                <Row className="wrap-block">
                  <div className="block-title">
                    薪资福利
                  </div>
                  <Row>
                    {Object.keys(salaryAndWelfare).map(
                      (item) => {
                        if (
                          item == "businessSettlement" &&
                          showType == 1
                        ) {
                          return (
                            <Col span={8} key={item}>
                              <FormItem
                                label={
                                  salaryAndWelfare[item]
                                }
                              >
                                {getFieldDecorator(item, {
                                  initialValue:
                                    inductionDetail.businessSettlement ||
                                    "",
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "商务结算必填",
                                    },
                                    {
                                      validator: this
                                        .validatorMoney,
                                    },
                                  ],
                                })(
                                  <Input
                                    placeholder="请输入商务结算"
                                    allowClear
                                    style={{ width: "70%" }}
                                    onChange={
                                      this
                                        .changebusinessSettlement
                                    }
                                  />
                                )}
                                <span>元</span>
                              </FormItem>
                            </Col>
                          );
                        } else if (
                          item == "remark" &&
                          showType == 1
                        ) {
                          return (
                            <Col span={8}>
                              <FormItem
                                label={
                                  salaryAndWelfare[item]
                                }
                              >
                                {getFieldDecorator(item, {
                                  initialValue:
                                    inductionDetail.remark ||
                                    "",
                                  rules: [
                                    {
                                      validator: this
                                        .validatorRemark,
                                    },
                                  ],
                                })(
                                  <TextArea
                                    rows={4}
                                    maxLength={128}
                                    placeholder="请输入备注"
                                    allowClear
                                    style={{ width: 600 }}
                                  />
                                )}
                              </FormItem>
                            </Col>
                          );
                        } else {
                          if (
                            item == "probationSalary" ||
                            item == "trunSalary" ||
                            item == "clientRaise" ||
                            item == "companyRaise" ||
                            item == "totalTurn" ||
                            item == "socialSecurityBase" ||
                            item == "providentFundBase" ||
                            item == "businessSettlement"
                          ) {
                            return (
                              <Col span={8}>
                                <FormItem
                                  label={
                                    salaryAndWelfare[item]
                                  }
                                >
                                  <span>
                                    {inductionDetail[
                                      item
                                    ] != null
                                      ? `¥ ${inductionDetail[item]}元`
                                      : "--"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          } else if (item == "remark") {
                            return (
                              <Col span={24}>
                                <FormItem
                                  label={
                                    salaryAndWelfare[item]
                                  }
                                  labelCol={{ span: 4 }}
                                  wrapperCol={{ span: 16 }}
                                >
                                  <span
                                    style={{
                                      wordBreak:
                                        "break-all",
                                    }}
                                  >
                                    {inductionDetail[
                                      item
                                    ] || "--"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          } else {
                            return (
                              <Col span={8}>
                                <FormItem
                                  label={
                                    salaryAndWelfare[item]
                                  }
                                >
                                  <span>
                                    {inductionDetail[
                                      item
                                    ] || "--"}
                                  </span>
                                </FormItem>
                              </Col>
                            );
                          }
                        }
                      }
                    )}
                  </Row>
                </Row>
                <Row className="wrap-block">
                  <div className="block-title">合同</div>
                  <Row>
                    <Col span={8}>
                      <FormItem label="合同编号">
                        <span>
                          {inductionDetail.contractCode ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="合同类型">
                          {getFieldDecorator(
                            "contractType",
                            {
                              initialValue: inductionDetail.contractType
                                ? {
                                    key:
                                      inductionDetail.contractType,
                                    label:
                                      inductionDetail.contractTypeName,
                                  }
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: "合同类型必填",
                                },
                              ],
                            }
                          )(
                            <Select
                              placeholder="请选择"
                              allowClear
                              labelInValue
                              onDropdownVisibleChange={(
                                value
                              ) =>
                                this.selectListByGroup(
                                  value,
                                  "CONTRACT_NATURE"
                                )
                              }
                              getPopupContainer={(
                                triggerNode
                              ) =>
                                triggerNode.parentElement
                              }
                            >
                              {this.state[
                                "CONTRACT_NATURE"
                              ] &&
                                this.state[
                                  "CONTRACT_NATURE"
                                ].map((item) => {
                                  return (
                                    <Option
                                      key={item.dicKey}
                                      value={item.dicKey}
                                    >
                                      {item.dicValue}
                                    </Option>
                                  );
                                })}
                            </Select>
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="合同类型">
                          <span>
                            {inductionDetail.contractTypeName ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="用工性质">
                          {getFieldDecorator("hireNature", {
                            initialValue: inductionDetail.hireNature
                              ? {
                                  key:
                                    inductionDetail.hireNature,
                                  label:
                                    inductionDetail.hireNatureName,
                                }
                              : undefined,
                            rules: [
                              {
                                required: true,
                                message: "用工性质必填",
                              },
                            ],
                          })(
                            <Select
                              placeholder="请选择"
                              allowClear
                              labelInValue
                              onDropdownVisibleChange={(
                                value
                              ) =>
                                this.selectListByGroup(
                                  value,
                                  "EMPLOYMENT_NATURE"
                                )
                              }
                              getPopupContainer={(
                                triggerNode
                              ) =>
                                triggerNode.parentElement
                              }
                            >
                              {this.state[
                                "EMPLOYMENT_NATURE"
                              ] &&
                                this.state[
                                  "EMPLOYMENT_NATURE"
                                ].map((item) => {
                                  return (
                                    <Option
                                      key={item.dicKey}
                                      value={item.dicKey}
                                    >
                                      {item.dicValue}
                                    </Option>
                                  );
                                })}
                            </Select>
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="用工性质">
                          <span>
                            {inductionDetail.hireNatureName ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      <FormItem label="转正日期">
                        <span>
                          {inductionDetail.turnTime || "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="司龄">
                        <span>
                          {inductionDetail.secretary ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="签订总次数">
                          {getFieldDecorator(
                            "signTotalFrequency",
                            {
                              initialValue:
                                inductionDetail.signTotalFrequency !=
                                null
                                  ? String(
                                      inductionDetail.signTotalFrequency
                                    )
                                  : "",
                              rules: [
                                {
                                  required: true,
                                  message: "签订总次数必填",
                                },
                                {
                                  validator: this
                                    .validatorNumber,
                                },
                              ],
                            }
                          )(
                            <Input
                              placeholder="请输入"
                              allowClear
                              onChange={
                                this
                                  .signTotalFrequencyChange
                              }
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="签订总次数">
                          <span>
                            {inductionDetail.signTotalFrequency ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="首次合同起始日">
                          {getFieldDecorator(
                            "firstContractStartday",
                            {
                              initialValue: inductionDetail.firstContractStartday
                                ? moment(
                                    inductionDetail.firstContractStartday,
                                    "YYYY/MM/DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  type: "object",
                                  message:
                                    "首次合同起始日必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              disabled={
                                form.getFieldValue(
                                  "signTotalFrequency"
                                ) &&
                                form.getFieldValue(
                                  "signTotalFrequency"
                                ) == 1
                              }
                              onChange={this.FCSChange}
                              disabledDate={
                                this.disabledDateFCS
                              }
                              format={dateFormat}
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="首次合同起始日">
                          <span>
                            {inductionDetail.firstContractStartday ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="首次合同到期日">
                          {getFieldDecorator(
                            "firstContractMaturityday",
                            {
                              initialValue: inductionDetail.firstContractMaturityday
                                ? moment(
                                    inductionDetail.firstContractMaturityday,
                                    "YYYY/MM/DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  type: "object",
                                  message:
                                    "首次合同到期日必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              disabled={
                                form.getFieldValue(
                                  "signTotalFrequency"
                                ) &&
                                form.getFieldValue(
                                  "signTotalFrequency"
                                ) == 1
                              }
                              onChange={this.FCMChange}
                              disabledDate={
                                this.disabledDateFCM
                              }
                              format={dateFormat}
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="首次合同到期日">
                          <span>
                            {inductionDetail.firstContractMaturityday ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="最新合同起始日">
                          {getFieldDecorator(
                            "contractStartday",
                            {
                              initialValue: inductionDetail.contractStartday
                                ? moment(
                                    inductionDetail.contractStartday,
                                    "YYYY/MM/DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  type: "object",
                                  message:
                                    "最新合同起始日必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              /* disabled={
                                form.getFieldValue(
                                  "signTotalFrequency"
                                ) &&
                                form.getFieldValue(
                                  "signTotalFrequency"
                                ) == 1
                              } */
                              disabled
                              format={dateFormat}
                              disabledDate={
                                this.disabledDateCS
                              }
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="最新合同起始日">
                          <span>
                            {inductionDetail.contractStartday ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      {showType == 2 && this.state.step3 ? (
                        <FormItem label="最新合同到期日">
                          {getFieldDecorator(
                            "contractMaturityday",
                            {
                              initialValue: inductionDetail.contractMaturityday
                                ? moment(
                                    inductionDetail.contractMaturityday,
                                    "YYYY/MM/DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  type: "object",
                                  message:
                                    "最新合同到期日必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              /* disabled={
                                form.getFieldValue(
                                  "signTotalFrequency"
                                ) &&
                                form.getFieldValue(
                                  "signTotalFrequency"
                                ) == 1
                              } */
                              format={dateFormat}
                              disabledDate={
                                this.disabledDateCM
                              }
                              onChange={this.CMChange}
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="最新合同到期日">
                          <span>
                            {inductionDetail.contractMaturityday ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      {showType == 1 ? (
                        <FormItem label="是否为他公司转签">
                          {getFieldDecorator(
                            "isOtherCompanyTransfer",
                            {
                              initialValue:
                                inductionDetail.isOtherCompanyTransfer !=
                                null
                                  ? inductionDetail.isOtherCompanyTransfer
                                    ? 1
                                    : 0
                                  : "",
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "是否为他公司转签必填",
                                },
                              ],
                            }
                          )(
                            <Radio.Group>
                              <Radio value={1}>是</Radio>
                              <Radio value={0}>否</Radio>
                            </Radio.Group>
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="是否为他公司转签">
                          <span>
                            {inductionDetail.isOtherCompanyTransfer
                              ? "是"
                              : "否"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                    <Col span={8}>
                      {showType == 1 ? (
                        <FormItem label="上家公司时间">
                          {getFieldDecorator(
                            "lastCompanyTime",
                            {
                              initialValue: inductionDetail.lastCompanyTime
                                ? moment(
                                    inductionDetail.lastCompanyTime,
                                    "YYYY/MM/DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required:
                                    this.props
                                      .getFieldValue &&
                                    this.props.getFieldValue(
                                      "isOtherCompanyTransfer"
                                    )
                                      ? true
                                      : false,
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              placeholder="请选择上家公司入职时间"
                              disabledDate={
                                this.disabledDate
                              }
                              format={dateFormat}
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      ) : (
                        <FormItem label="上家公司时间">
                          <span>
                            {inductionDetail.lastCompanyTime ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                </Row>
              </Form>
            )}
            {this.state.tab == 2 && (
              <div>
                <Form
                  {...formItemLayout}
                  className="process-form-readonly"
                  style={{ height: "100%" }}
                >
                  <div id="print-box">
                    <Row>
                      <Row className="wrap-block">
                        <div className="block-title">
                          基本信息
                        </div>
                        <Row>
                          {Object.keys(
                            employeeInformation
                          ).map((item) => {
                            if (item == "sex") {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                  >
                                    <span>
                                      {inductionDetail.sexName ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else if (
                              item == "marriageStatus"
                            ) {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                  >
                                    <span>
                                      {inductionDetail.marriageStatusName ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else if (
                              item == "certificatesType"
                            ) {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                  >
                                    <span>
                                      {inductionDetail.certificatesTypeName ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else if (
                              item == "constellation"
                            ) {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                  >
                                    <span>
                                      {inductionDetail.constellation ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else if (
                              item == "politicalOutlook"
                            ) {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                  >
                                    <span>
                                      {inductionDetail.politicalOutlookName ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else if (
                              item == "nativePlaceProvince"
                            ) {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                  >
                                    <span>
                                      {inductionDetail.nativePlaceProvinceName
                                        ? `${
                                            inductionDetail.nativePlaceProvinceName
                                          }${inductionDetail.nativePlaceCityName ||
                                            ""}`
                                        : "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else if (
                              item == "residenceNature"
                            ) {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                  >
                                    <span>
                                      {inductionDetail.residenceNatureName ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else if (
                              item == "registrationAddress"
                            ) {
                              return (
                                <Col span={24} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{
                                      span: 12,
                                    }}
                                  >
                                    <span
                                      style={{
                                        wordBreak:
                                          "break-all",
                                      }}
                                    >
                                      {inductionDetail.registrationAddress ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else if (
                              item == "nowAddress"
                            ) {
                              return (
                                <Col span={24} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                    labelCol={{ span: 4 }}
                                    wrapperCol={{
                                      span: 12,
                                    }}
                                  >
                                    <span
                                      style={{
                                        wordBreak:
                                          "break-all",
                                      }}
                                    >
                                      {inductionDetail.nowAddress ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      employeeInformation[
                                        item
                                      ]
                                    }
                                  >
                                    <span>
                                      {inductionDetail[
                                        item
                                      ] || "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            }
                          })}
                        </Row>
                      </Row>

                      <Row className="wrap-block">
                        <div className="block-title">
                          紧急联系人
                        </div>
                        <Row>
                          {Object.keys(
                            emergencyContact
                          ).map((item) => {
                            if (item == "relationship") {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      emergencyContact[item]
                                    }
                                  >
                                    <span
                                      style={{
                                        wordBreak:
                                          "break-all",
                                      }}
                                    >
                                      {inductionDetail.relationshipName ||
                                        "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            } else {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      emergencyContact[item]
                                    }
                                  >
                                    <span
                                      style={{
                                        wordBreak:
                                          "break-all",
                                      }}
                                    >
                                      {inductionDetail[
                                        item
                                      ] || "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            }
                          })}
                        </Row>
                      </Row>
                      <Row className="wrap-block">
                        <div className="block-title">
                          银行信息
                        </div>
                        <Row>
                          {Object.keys(bankInformation).map(
                            (item) => {
                              return (
                                <Col span={8} key={item}>
                                  <FormItem
                                    label={
                                      bankInformation[item]
                                    }
                                  >
                                    <span
                                      style={{
                                        wordBreak:
                                          "break-all",
                                      }}
                                    >
                                      {inductionDetail[
                                        item
                                      ] || "--"}
                                    </span>
                                  </FormItem>
                                </Col>
                              );
                            }
                          )}
                        </Row>
                      </Row>
                      <Row className="wrap-block">
                        <div className="block-title">
                          教育
                        </div>
                        <Row>
                          {Object.keys(education).map(
                            (item) => {
                              if (
                                item == "highestEducation"
                              ) {
                                return (
                                  <Col span={8} key={item}>
                                    <FormItem
                                      label={
                                        education[item]
                                      }
                                    >
                                      {this.showTooltip(
                                        inductionDetail.highestEducationName
                                      )}
                                    </FormItem>
                                  </Col>
                                );
                              } else if (
                                item == "bachelorScience"
                              ) {
                                return (
                                  <Col span={8} key={item}>
                                    <FormItem
                                      label={
                                        education[item]
                                      }
                                    >
                                      {this.showTooltip(
                                        inductionDetail.bachelorScienceName
                                      )}
                                    </FormItem>
                                  </Col>
                                );
                              } else {
                                return (
                                  <Col span={8} key={item}>
                                    <FormItem
                                      label={
                                        education[item]
                                      }
                                    >
                                      {this.showTooltip(
                                        inductionDetail[
                                          item
                                        ]
                                      )}
                                    </FormItem>
                                  </Col>
                                );
                              }
                            }
                          )}
                        </Row>
                      </Row>
                      <Row className="wrap-block">
                        <div className="block-title">
                          工作经历
                        </div>
                        <Row>
                          <Table
                            rowKey="id"
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                          />
                        </Row>
                      </Row>
                    </Row>
                  </div>

                  <Row className="wrap-block">
                    <div className="block-title">
                      附件（{fileList.length}）
                    </div>
                    <Row>
                      <div className="file-item-wrap">
                        {fileList.map((item, index) => {
                          return (
                            <div
                              className="file-item-block"
                              onClick={() =>
                                this.handlePreview(
                                  item.annexUrl
                                )
                              }
                              key={index}
                            >
                              <div className="file-img-wrap">
                                <img
                                  className="file-item-img"
                                  src={
                                    item.annexUrl
                                      ? item.annexUrl
                                      : require("../../../assets/image/default-picture.png")
                                  }
                                  alt=""
                                />
                              </div>
                              <p className="file-item-desc">
                                {annexType[item.annexType]}
                                {item.annexType == 5 &&
                                  item.num}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </Row>
                  </Row>
                </Form>
                <Row id="print-box2">
                  <Row className="wrap-block">
                    <div className="block-title">
                      补充说明
                    </div>
                    <Row>
                      <p>
                        有否传染病或慢性病史，请具体说明:
                      </p>
                      <p>
                        {inductionDetail.isDisease === 0
                          ? `是`
                          : `否`}
                      </p>
                      {inductionDetail.diseaseDesc && (
                        <p>{inductionDetail.diseaseDesc}</p>
                      )}
                    </Row>
                    <Row>
                      <p>
                        有否接受过行政或者刑事处分，或与原公司存在劳动/财务纠纷，或因违章违纪遭辞退/开除等情况请具体说明:
                      </p>
                      <p>
                        {inductionDetail.isWarning === 0
                          ? `是`
                          : `否`}
                      </p>
                      {inductionDetail.warningDesc && (
                        <p>{inductionDetail.warningDesc}</p>
                      )}
                    </Row>
                  </Row>
                </Row>
              </div>
            )}
            {(this.state.step2 || this.state.step3) && (
              <Row
                className="wrap-block"
                style={{ marginBottom: 0 }}
              >
                <div className="block-title">流程审核</div>
                <Row>
                  <FormItem
                    label="审核意见"
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 16 }}
                    style={{ marginLeft: 16 }}
                  >
                    {getFieldDecorator("auditOpinion", {
                      initialValue: "",
                      rules: [
                        {
                          validator: this.validatorOpinion,
                        },
                      ],
                    })(
                      <TextArea
                        rows={4}
                        maxLength={128}
                        placeholder="请输入审核意见"
                        allowClear
                      />
                    )}
                  </FormItem>
                </Row>
              </Row>
            )}
          </div>
          <div className="wrap-right">
            <AuditRecord data={logList || []} />
          </div>
        </div>
        <div className="footer-fixed-wrap">
          <div className="footer-fixed-btn">
            <Button
              style={{ marginRight: 16 }}
              onClick={() => {
                // window.close();
                this.props.history.go(-1);
              }}
            >
              返回
            </Button>
            {(this.state.step2 || this.state.step3) && (
              <span>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={this.confirm}
                  disabled={this.state.submitDisabled}
                >
                  {this.state.showComplete
                    ? this.state.showComplete === 1
                      ? "创建账号"
                      : "归档"
                    : "通过"}
                </Button>
                {/* {this.state.step2 && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={this.handleFail}
                  >
                    不通过
                  </Button>
                )} */}
                <Button
                  type="danger"
                  style={{ marginRight: 16 }}
                  onClick={this.handleFail}
                >
                  不通过
                </Button>
              </span>
            )}
          </div>
        </div>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img
            style={{ width: "100%" }}
            src={previewUrl}
            alt=""
          />
        </Modal>
      </div>
    );
  }
}

export default withRouter(Form.create()(MainManagement));
