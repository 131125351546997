import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Menu, Dropdown, Button, message, Table, Form, Tooltip } from "antd";
import echarts from "echarts";
import utils from "../../../utils/utils";
import "./index.less";

import Withdraw from "../../../components/withdraw"; // 撤回

// 状态：审核，归档，驳回，保存，

const template = {
  SPON_DIMI_PROC: "dimissionProcId", // 自动离职
  DIMI_PROC: "dimissionProcId", // 离职
  // ENTRY_PROC: "inductionProcId", // 入职
  OFFER_PROC: "offerProcId", // offer审批
  BECOME_PROC: "positiveProcId", // 转正
  ATTE_PROC: "attendanceProcId", // 月度考勤确认
  LEAVE_PROC: "leaveProcId", // 请假
};
const url = {
  SPON_DIMI_PROC: "autoDimissionWithdraw", // 自动离职
  DIMI_PROC: "dimissionWithdraw", // 离职
  // ENTRY_PROC: "inductionProcId", // 入职
  OFFER_PROC: "withdraw", // offer审批
  BECOME_PROC: "positiveWithdraw", // 转正
  ATTE_PROC: "attendanceConfirWithdraw", // 月度考勤确认
  LEAVE_PROC: "leaveWithdraw", // 请假
};

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: utils.localDb.get("userData").staffId,
      loading: true,
      dataList: [], // 账号列表数据
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        onChange: (page, pageSize) => {
          this.paginationChange(page, pageSize);
        },
      },
      addModalShow: false, // 新增弹框显隐
      accountItem: {}, // 编辑账号弹框回显数据
      disabled: false, // 防重复点击
      statusList: [
        {
          id: -1,
          name: "全部",
          value: -1,
        },
        {
          id: "0",
          name: "未归档",
          value: "0",
        },
        {
          id: "1",
          name: "已归档",
          value: "1",
        },
      ],
      currentStatus: -1,
      processList: [],
      currentProcess: 0,
      record: {},
    };
  }
  componentDidMount() {
    this.queryDoneProcTemplate();
    this.queryLaunchProcessByUserId();
    if (sessionStorage.getItem("currentStatus") !== "") {
      this.setState({ currentStatus: sessionStorage.getItem("currentStatus") });
    }
    if (sessionStorage.getItem("currentProcess") !== "") {
      this.setState({
        currentProcess: sessionStorage.getItem("currentProcess"),
      });
    }
  }

  // queryDoneProcTemplate
  queryDoneProcTemplate = () => {
    const arr = [
      {
        procTemplateName: "全部",
        procTemplate: 0,
      },
    ];
    this.$http["queryDoneProcTemplate"]({
      staffId: this.state.staffId,
    })
      .then((res) => {
        if (res.code === 200) {
          this.setState({
            processList: res.data ? arr.concat(res.data) : arr,
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  queryLaunchProcessByUserId = (pageNo = 1, pageSize = 10) => {
    const { pagination, currentStatus, currentProcess } = this.state;
    this.$http["queryLaunchProcessByUserId"]({
      procStatus:
        currentStatus === -1
          ? sessionStorage.getItem("currentStatus")
            ? sessionStorage.getItem("currentStatus")
            : ""
          : currentStatus,
      procTemplate: currentProcess
        ? currentProcess
        : sessionStorage.getItem("currentProcess")
        ? sessionStorage.getItem("currentProcess")
        : "",
      pageNo,
      pageSize,
    })
      .then((res) => {
        if (res.code === 200) {
          pagination.total = res.data.total;
          this.setState({
            loading: false,
            dataList: res.data.list || [],
            pagination,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  paginationChange = (page, pageSize) => {
    const { pagination } = this.state;
    pagination.current = page;
    pagination.pageSize = pageSize;
    this.setState(
      {
        pagination,
      },
      () => {
        this.queryLaunchProcessByUserId(page, pageSize);
      }
    );
  };

  handleChangePage = (pageNo, pageSize) => {
    this.queryTableList(pageNo, pageSize);
  };

  handleProhibit = (item) => {
    this.refs.addModal.resetFields(); // 避免数据闪现
    this.setState({
      addModalShow: true,
      accountItem: item,
    });
  };

  handleDelete = (item) => {
    console.log(item, "-----------");
    message.success("删除成功");
    const { pageNo, pageSize } = this.state;
    this.queryTableList(pageNo, pageSize);
  };

  handleAddSubmit = () => {
    this.refs.addModal.resetFields(); // 避免数据闪现
    this.setState({
      addModalShow: true,
      modalType: 0,
      accountItem: {},
    });
  };
  handleCancelModal = () => {
    this.setState({
      addModalShow: false,
    });
  };

  handleLink = (record) => {
    if (record.procTemplate === "OFFER_PROC") {
      this.props.history.push(
        `/workbench/offerApproval?offerProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "ENTRY_PROC") {
      this.props.history.push(
        `/workbench/checkin?inductionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "BECOME_PROC") {
      this.props.history.push(
        `/workbench/becomeWorker?positiveProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "DIMI_PROC") {
      this.props.history.push(
        `/workbench/quit?dimissionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "SPON_DIMI_PROC") {
      this.props.history.push(
        `/workbench/autoQuit?dimissionProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "LEAVE_PROC") {
      this.props.history.push(
        `/workbench/leave?leaveProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    } else if (record.procTemplate === "ATTE_PROC") {
      this.props.history.push(
        `/workbench/monthlyAttendance?attendanceProcId=${record.businessKey}&procTemplate=${record.procTemplate}`
      );
    }
  };

  handleTableByStatus = (item) => {
    const { pagination } = this.state;
    pagination.current = 1;
    this.setState(
      {
        currentStatus: item.value,
        pagination,
      },
      () => {
        sessionStorage.setItem("currentStatus", item.value);
        this.queryLaunchProcessByUserId();
      }
    );
  };
  handleTableByTemplate = (item) => {
    const { pagination } = this.state;
    pagination.current = 1;
    this.setState(
      {
        currentProcess: item.procTemplate,
        pagination,
      },
      () => {
        sessionStorage.setItem("currentProcess", item.procTemplate);
        this.queryLaunchProcessByUserId();
      }
    );
  };

  handleOk = () => {
    this.queryLaunchProcessByUserId();
  };

  render() {
    const {
      dataList,
      pagination,
      total,
      pageNo,
      pageSize,
      loading,
      statusList,
      currentStatus,
      processList,
      currentProcess,
      addModalShow,
      accountItem,
      record,
    } = this.state;

    const columns = [
      {
        title: "标题",
        dataIndex: "",
        width: "45%",
        render: (text, record) => {
          const title = `${record.procTemplateName}-${record.entryStaff}-${record.departmentName}(单号:${record.businessKey})`;
          return (
            <Tooltip title={title}>
              <a
                style={{
                  width: "90%",
                  display: "inline-block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                onClick={() => this.handleLink(record)}
              >
                {title}
              </a>
            </Tooltip>
          );
        },
      },
      {
        title: "当前节点",
        width: 160,
        dataIndex: "taskDefName",
        render: (text, record) => {
          return (
            <span className={`status-item item-color-${record.taskDefKey}`}>
              {text || "待批"}
            </span>
          );
        },
      },
      {
        title: "发起人",
        width: 120,
        dataIndex: "staffName",
      },
      {
        title: "创建时间",
        width: 200,
        dataIndex: "lastUpdatedTimeStr",
      },
      {
        title: "处理人",
        width: 120,
        dataIndex: "assigneeName",
      },
      {
        title: "操作",
        width: 120,
        render: (record) => {
          if (
            record.procStatus === "INITIATE" ||
            record.procStatus === "AUDIT"
          ) {
            return (
              <a
                onClick={() => {
                  this.setState(
                    {
                      visible: true,
                      record: record,
                    },
                    () => {
                      this.refs.withdrawRef.handleShow();
                    }
                  );
                }}
              >
                撤回
              </a>
            );
          }
        },
      },
    ];
    return (
      <div className="workbench-launch">
        <div className="launch-title">我发起的</div>
        <div className="launch-content">
          <div className="content-top">
            <div className="top-status">
              <span className="value-label">状态：</span>
              {statusList.map((item) => {
                return (
                  <span
                    className={
                      item.value == currentStatus
                        ? "value-text value-active"
                        : "value-text"
                    }
                    key={item.value}
                    onClick={() => this.handleTableByStatus(item)}
                  >
                    {item.name}
                  </span>
                );
              })}
            </div>
            <div className="top-process">
              <span className="value-label">流程：</span>
              {processList.map((item) => {
                return (
                  <span
                    className={
                      item.procTemplate == currentProcess
                        ? "value-text value-active"
                        : "value-text"
                    }
                    key={item.procTemplate}
                    onClick={() => this.handleTableByTemplate(item)}
                  >
                    {item.procTemplate
                      ? `${item.procTemplateName}(${item.procTemplateNum})`
                      : `${item.procTemplateName}`}
                  </span>
                );
              })}
            </div>
          </div>
          <div className="content-main">
            <Table
              rowKey="businessKey"
              columns={columns}
              loading={loading}
              dataSource={dataList}
              pagination={pagination}
              locale={{
                emptyText: (
                  <div>
                    <img
                      style={{ width: 182, height: 94 }}
                      src={require("../../../assets/image/no-data.png")}
                      alt=""
                    />
                    <p
                      style={{
                        fontSize: 14,
                        color: "#717E99",
                        lineHeight: "20px",
                      }}
                    >
                      暂无数据
                    </p>
                  </div>
                ),
              }}
            />
          </div>
          {this.state.visible && (
            <Withdraw
              ref="withdrawRef"
              form={this.props.form}
              data={{
                [template[record.procTemplate]]: record.businessKey,
                taskId: record.taskId,
              }}
              url={url[record.procTemplate]}
              handleOk={this.handleOk}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Form.create()(MainManagement));
