import React from "react";

import moment from "moment";
import CityIcon from "../../../assets/image/city.png"; //CityEmployeesIcon数
import CityEmployeesIcon from "../../../assets/image/CityEmployees.png"; // 城市员工总数
import levelIcon from "../../../assets/image/level.png"; // 级别人数合计
import OnJobIcon from "../../../assets/image/OnJobIcon.png"; // 在职总人数
import MonthPostIcon from "../../../assets/image/MonthPostIcon.png"; //月到岗人数合计
import DepartmentIcon from "../../../assets/image/DepartmentIcon.png"; //部门数
import DepartmentStaffIcon from "../../../assets/image/DepartmentStaffIcon.png"; //部门员工总数
import RecruitIcon from "../../../assets/image/RecruitIcon.png"; //招聘人数合计
import QuitIcon from "../../../assets/image/QuitIcon.png"; //离职MonthlyTurnoverIcon数
import MonthlyTurnoverIcon from "../../../assets/image/MonthlyTurnoverIcon.png"; //月离职人数合计
//
export const deOrReTag = [
  {
    url: DepartmentIcon,
    lable: "部门数",
    key: "deptOrCityNmber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: DepartmentStaffIcon,
    lable: "部门员工总数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: RecruitIcon,
    lable: "招聘人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const deOrArrivalTag = [
  {
    url: DepartmentIcon,
    lable: "部门数",
    key: "deptOrCityNmber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: DepartmentStaffIcon,
    lable: "部门员工总数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: MonthPostIcon,
    lable: "月到岗人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];

export const deOrDepartureTag = [
  {
    url: DepartmentIcon,
    lable: "部门数",
    key: "deptOrCityNmber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: DepartmentStaffIcon,
    lable: "部门员工总数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: MonthlyTurnoverIcon,
    lable: "月离职人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const deOrLevelTag = [
  {
    url: DepartmentIcon,
    lable: "部门数",
    key: "deptOrCityNmber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: DepartmentStaffIcon,
    lable: "部门员工总数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: levelIcon,
    lable: "级别人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];

export const cityOrArrivalTag = [
  {
    url: CityIcon,
    lable: "城市数",
    key: "deptOrCityNmber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: CityEmployeesIcon,
    lable: "城市员工总数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: MonthPostIcon,
    lable: "月到岗人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const cityOrReTag = [
  {
    url: CityIcon,
    lable: "城市数",
    key: "deptOrCityNmber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: CityEmployeesIcon,
    lable: "城市员工总数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: RecruitIcon,
    lable: "招聘人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];

export const cityOrDepartureTag = [
  {
    url: CityIcon,
    lable: "城市数",
    num: 0,
    key: "deptOrCityNmber",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: CityEmployeesIcon,
    lable: "城市员工总数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: MonthlyTurnoverIcon,
    lable: "月离职人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const cityOrLevelTag = [
  {
    url: CityIcon,
    lable: "城市数",
    key: "deptOrCityNmber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: CityEmployeesIcon,
    lable: "城市员工总数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    url: levelIcon,
    lable: "级别人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];

export const statusOrArrivalTag = [
  {
    url: OnJobIcon,
    lable: "在职总人数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{`${text} (100%)`}</span> : "--";
    },
  },
  {
    url: MonthPostIcon,
    lable: "月到岗人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const statusOrReTag = [
  {
    url: OnJobIcon,
    lable: "在职总人数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{`${text} (100%)`}</span> : "--";
    },
  },
  {
    url: RecruitIcon,
    lable: "招聘人数合计",
    num: 0,
    key: "monthNumber",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];

export const statusOrDepartureTag = [
  {
    url: QuitIcon,
    lable: "离职总人数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{`${text} 100%`}</span> : "--";
    },
  },
  {
    url: MonthlyTurnoverIcon,
    lable: "月离职人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const statusOrLevelTag = [
  {
    url: OnJobIcon,
    lable: "在职总人数",
    key: "totalNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{`${text} (100%)`}</span> : "--";
    },
  },
  {
    url: levelIcon,
    lable: "级别人数合计",
    key: "monthNumber",
    num: 0,
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];

export const deOrArrivalColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "部门",
    dataIndex: "groupName",
    key: "groupName",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "在职总人数",
    dataIndex: "groupTotalNumber",
    key: "groupTotalNumber",
    render: (t, render) => {
      const obj = {
        children: <span>{`${t}人 (100%)`}</span>,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "到岗月份",
    dataIndex: "ononboardingTime",
    key: "ononboardingTime",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "月到岗人数",
    dataIndex: "onboardingNumber",
    key: "onboardingNumber",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "percentage",
    key: "percentage",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const deOrReColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "部门",
    dataIndex: "groupName",
    key: "groupName",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "在职总人数",
    dataIndex: "groupTotalNumber",
    key: "groupTotalNumber",
    render: (t, render) => {
      const obj = {
        children: <span>{`${t}人 (100%)`}</span>,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "招聘专员",
    dataIndex: "staffName",
    key: "staffName",
    render: (text, record) => {
      const { staffCode } = record;
      const str = staffCode ? `(${staffCode})` : "";
      return text ? <span>{`${text}${str}`}</span> : "--";
    },
  },
  {
    title: "招聘人数",
    dataIndex: "onboardingNumber",
    key: "onboardingNumber",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "percentage",
    key: "percentage",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const deOrDepartureColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "部门",
    dataIndex: "groupName",
    key: "groupName",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "离职总人数",
    dataIndex: "groupTotalNumber",
    key: "groupTotalNumber",
    render: (t, render) => {
      const obj = {
        children: <span>{`${t}人 (100%)`}</span>,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "离职月份",
    dataIndex: "ononboardingTime",
    key: "ononboardingTime",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "月离职人数",
    dataIndex: "onboardingNumber",
    key: "onboardingNumber",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "percentage",
    key: "percentage",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const deOrLevelColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "部门",
    dataIndex: "groupName",
    key: "groupName",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "在职总人数",
    dataIndex: "groupTotalNumber",
    key: "groupTotalNumber",
    render: (t, render) => {
      const obj = {
        children: <span>{`${t}人 (100%)`}</span>,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "级别",
    dataIndex: "staffLevel",
    key: "staffLevel",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "级别人数",
    dataIndex: "onboardingNumber",
    key: "onboardingNumber",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "percentage",
    key: "percentage",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const cityOrArrivalColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "城市",
    dataIndex: "cityName",
    key: "cityName",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "在职总人数",
    dataIndex: "peopleNUm",
    key: "peopleNUm",
    render: (t, render) => {
      const obj = {
        children: <span>{`${t}人 (100%)`}</span>,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "到岗月份",
    dataIndex: "month",
    key: "month",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "月到岗人数",
    dataIndex: "groupNum",
    key: "groupNum",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "rate",
    key: "rate",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const cityOrReColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "城市",
    dataIndex: "cityName",
    key: "cityName",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "在职总人数",
    dataIndex: "peopleNUm",
    key: "peopleNUm",
    render: (t, render) => {
      const obj = {
        children: <span>{`${t}人 (100%)`}</span>,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "招聘专员",
    dataIndex: "month",
    key: "month",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "招聘人数",
    dataIndex: "groupNum",
    key: "groupNum",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "rate",
    key: "rate",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const cityOrDepartureColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "城市",
    dataIndex: "cityName",
    key: "cityName",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "离职总人数",
    dataIndex: "peopleNUm",
    key: "peopleNUm",
    render: (t, render) => {
      const obj = {
        children: <span>{`${t}人 (100%)`}</span>,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "离职月份",
    dataIndex: "month",
    key: "month",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "月离职人数",
    dataIndex: "groupNum",
    key: "groupNum",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "rate",
    key: "rate",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const cityOrLevelColumns = [
  {
    title: "序号",
    dataIndex: "index",
    key: "index",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "城市",
    dataIndex: "cityName",
    key: "cityName",
    render: (t, render) => {
      const obj = {
        children: t,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "在职总人数",
    dataIndex: "peopleNUm",
    key: "peopleNUm",
    render: (t, render) => {
      const obj = {
        children: <span>{`${t}人 (100%)`}</span>,
        props: { rowSpan: render.rowSpan },
      };
      return obj;
    },
  },
  {
    title: "级别",
    dataIndex: "month",
    key: "month",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "级别人数",
    dataIndex: "groupNum",
    key: "groupNum",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "rate",
    key: "rate",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const statusOrArrivalColumns = [
  {
    title: "序号",
    key: "index",
    render: (value, record, index) => {
      return <span>{index + 1}</span>;
    },
  },
  {
    title: "到岗月份",
    dataIndex: "timeNameLevel",
    key: "timeNameLevel",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "月到岗人数/在职总人数",
    dataIndex: "staffNUm",
    key: "staffNUm",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "rate",
    key: "rate",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const statusOrReColumns = [
  {
    title: "序号",
    key: "index",
    render: (value, record, index) => {
      return <span>{index + 1}</span>;
    },
  },
  {
    title: "招聘专员",
    dataIndex: "timeNameLevel",
    key: "timeNameLevel",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "招聘人数/在职总人数",
    dataIndex: "staffNUm",
    key: "staffNUm",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "rate",
    key: "rate",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const statusOrDepartureColumns = [
  {
    title: "序号",
    key: "index",
    render: (value, record, index) => {
      return <span>{index + 1}</span>;
    },
  },
  {
    title: "离职月份",
    dataIndex: "timeNameLevel",
    key: "timeNameLevel",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "月离职人数/离职总人数",
    dataIndex: "staffNUm",
    key: "staffNUm",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "rate",
    key: "rate",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
export const statusOrLevelColumns = [
  {
    title: "序号",
    key: "index",
    render: (value, record, index) => {
      return <span>{index + 1}</span>;
    },
  },
  {
    title: "级别",
    dataIndex: "timeNameLevel",
    key: "timeNameLevel",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "级别人数/在职总人数",
    dataIndex: "staffNUm",
    key: "staffNUm",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
  {
    title: "占比",
    dataIndex: "rate",
    key: "rate",
    render: (text) => {
      return text ? <span>{text}</span> : "--";
    },
  },
];
