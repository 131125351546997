/**
 * @description 城市
 */
import React from "react";
import { Select, Radio, Form } from "antd";
import { map, uniqueId } from "lodash";
const { Option } = Select;
class CitySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.queryCityList();
  }

  queryCityList = () => {
    this.$http.queryCityList().then((res) => {
      console.log("city ->", res);
      if (res && res.code === 200) {
        this.setState({
          city: res.data,
          loading: false,
        });
      }
    });
  };

  onChange = (value) => {
    const { onChange } = this.props;
    onChange({
      cityIds: value,
    });
  };

  render() {
    const { city, loading } = this.state;

    return (
      <Form.Item>
        <Select
          mode="tags"
          placeholder="选择城市"
          onChange={this.onChange}
          style={{ width: "300px" }}
          loading={loading}
        >
          {map(city, (i) => (
            <Option
              key={i.workCity || uniqueId("contact_")}
            >
              {i.workCityName}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  }
}

export default CitySelect;
