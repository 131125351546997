import React,{ Component } from 'react'
import { Button, message } from 'antd';
import './index.less'
import LeftTree from './../../../components/leftTreeOne';
import Table from './component/dicTableList';
import AddModal from './component/addDic';

class Dictionary extends Component {
	constructor(props){
		super(props)
		this.state = {
			loading: false,
            treeList: [],           // 左侧tree数据
            searchValue: '',        // 左侧tree搜索框
            checkedTreeItem: {},    // 左侧tree默认选中
            dataList: [],     	    // 账号列表数据
            total: 0,       	    // 账号总数
            pageSize: 10,     	    // 分页Size
            pageNo: 1,        	    // 分页页码
            addModalShow: false,    // 新增数据弹框
			modalType: 0,		    // 0：添加 1：修改
            dicItem: {},            // 编辑数据字典初始化数据
            
			
            
		}
	}
	componentDidMount(){
        this.queryLeftTreeData();
        this.$http.login({
            staffCode: 'admin',
            password: 'e10adc3949ba59abbe56e057f20f883e'
        })
    }
    /**
     * 函数描述: 列表页左侧字典类型--搜索
     * @param {String} value   搜索的值
     */
    hansleSearch = (value) => {
        this.setState({
            searchValue: value
        },() => {
            this.queryLeftTreeData();
        })
    }
    /**
     * 函数描述: 列表页左侧字典类型
     * @param {String} dicValue   根据名称模糊查询
     */
    queryLeftTreeData = () => {
        const params = {
            dicValue: this.state.searchValue
        }
        this.$http.getDicGroups(params).then(res => {
            if (res.code === 200) {
                const list = res.data;
                this.setState({
                    treeList: list,
                    checkedTreeItem: res.data.length > 0 ? {dicKey: res.data[0].dicKey,dicValue:  res.data[0].dicValue} : ''
                },() => {
                    if(list.length>0){
                        this.queryTableList();

                    }else{
                        this.setState({
                            loading: false,
                            pageNo: 1,
                            pageSize: 10,
                            dataList: [],
                        })
                    }
                    console.log(this.state.treeList);
                })
            }
        })
    }
    /**
     * 函数描述: 点击左侧字典类型
     * @param {String} checkedTreeItem  类型key,value
     */
    handleTreeNode = (checkedTreeItem) => {
		this.setState({
			checkedTreeItem
		},() => {
            this.queryTableList();
        })
	}
    /**
     * 函数描述: 查询列表数据
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} dicGroup 字典类型 -- 来源于左侧字典类型的dicKey
     * @return {Object} 
     */
    queryTableList = (pageNo=1, pageSize=10) =>{
		this.setState({
			loading: true
		},() => {
			const params = {
				page: pageNo,
				pageSize
			}
            if (this.state.checkedTreeItem.dicKey) params['dicGroup'] = this.state.checkedTreeItem.dicKey
			this.$http.pageDictionary(params).then((res) => {
				if(res.code == 200){
					this.setState({
						loading: false,
						pageNo: res.data.pageNum,
						pageSize: res.data.pageSize,
						dataList: res.data.list,
						total: res.data.total
					})
				}
			}).catch((err) => {
				this.setState({
					loading: false,
				})
			})
		})
	}
    /**
     * 函数描述: 切换列表页码
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @return {Object} 请求pageNo页列表数据
     */
    handleChangePage = (pageNo,pageSize) => {
		this.queryTableList(pageNo,pageSize);
	}
    /**
     * 函数描述: 删除字典
     * @param {Object} id 主键
     * @return {Object} 刷新当前页数据
     */
	handleDelete = (id) => {
        this.$http.dictionaryDelete({id}).then((res) => {
            if (res.code === 200) {
                const { pageNo, pageSize } = this.state;
                this.queryTableList(pageNo,pageSize);
            }
        })
	}
    
    /**
     * 函数描述: 修改或新增确认
     * @param {string} type 弹框类型 0：新增 1：修改
     * @param {Object} value.dicKey 代码
     * @param {Object} value.dicValue 名称
     * @param {Object} value.des 描述
     * @param {Object} checkedTreeItem.dicGroup 类型
     * @return 
     */
	handleSubmit = (type,value) => {
		const { disabled, checkedTreeItem, dicItem } = this.state;
		if(!disabled) {
			this.setState({
				disabled: true
			},() => {
				if (type === 0) { // 新增弹框
					this.$http.dictionaryInsert({...value,dicGroup: checkedTreeItem.dicKey}).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								this.queryTableList();
							}
						})
					})
				}else{
					this.$http.dictionaryUpdate({
						id: dicItem.id,
						...value,
                        dicGroup: dicItem.dicGroup
					}).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								const { pageNo, pageSize } = this.state;
								this.queryTableList(pageNo, pageSize);
							}
						})
					})
				}
			})
		}
	}
    /**
     * 函数描述: 新增字典
     * @return 打开新增弹框
     */
	handleAddModal = () => {
        if (this.state.checkedTreeItem.dicKey) {
            this.refs.addModal.resetFields(); // 避免数据闪现
            this.setState({
                addModalShow: true,
                modalType: 0,
                dicItem: {}
            })
        }else{
            message.warning('请先选择具体的字典类型')
        }
		
	}
	// 关闭弹框
	handleCancelModal = () => {
		this.setState({
			addModalShow: false
		})
	}
    /**
     * 函数描述: 编辑字典
     * @return 打开新增弹框
     */
	handleEditSubmit = (item) => {
		this.refs.addModal.resetFields(); // 避免数据闪现
		this.setState({
			addModalShow: true,
			modalType: 1,
			dicItem: item
		})
	}
	render(){
		const {
            loading,
            dataList,
            total, 
            pageNo, 
            pageSize,
            addModalShow,
            modalType,
            dicItem,
            checkedTreeItem,
            treeList,

        } = this.state;
		return (
            <div className="dictionary-wrapper">
                <div className="crumbs">数据字典</div>
                <div className="contract-wrap">
                    {/* 左侧树 */}
					<LeftTree
						checkedTreeItem={checkedTreeItem}
						list={treeList}
                        mapping={{ id: 'dicKey', value: 'dicValue' }}
                        hansleSearch={this.hansleSearch}
						handleTreeNode={this.handleTreeNode}
					/>
                    <div className="right-wrap wrap-card">
                        <Button 
                            className="addBtn" 
                            type="primary"  
                            onClick={this.handleAddModal}>添加</Button>
                        <Table 
                            loading={loading} 
                            data={dataList} 
                            total={total}
                            pageNo={pageNo}
                            pageSize={pageSize}
                            handleChangePage={this.handleChangePage} // 切换页码
                            handleDelete={this.handleDelete}         // 删除
                            handleEditSubmit={this.handleEditSubmit} // 编辑账号
                        />
                    </div>
                </div>
                {/* 添加字典类型弹窗 */}
                <AddModal
                    ref="addModal"
                    addModalShow={addModalShow}
                    type={modalType}
                    dicItem={dicItem}
                    checkedTreeItem={checkedTreeItem}
                    handleCancel={this.handleCancelModal}
                    handleSubmit={this.handleSubmit}
                />
            </div>
        )
        
	}
}


export default Dictionary