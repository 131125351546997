/**
 * @description 部门树
 */
import React from "react";
import { TreeSelect, Form } from "antd";
import { map } from "lodash";
class DepartmentTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      treeDataSource: [],
    };
  }
  componentDidMount() {
    this.getDataRightsTree();
  }
  /***
   * @method 获取数据权限下拉树
   */
  getDataRightsTree = () => {
    this.$http.departmentPermissions().then((res) => {
      console.log("树数据", res);
      if (res.code == 200) {
        const treeDataSource = this.getTreeData(
          res.data,
          []
        );
        this.setState({
          treeDataSource,
        });
      }
    });
  };

  /**
   * @method 处理树数据
   */
  getTreeData = (data, treeData) => {
    treeData = map(data, (item) => {
      const {
        departmentSonName,
        departmentSonId,
        dsTree,
        level,
      } = item;
      return {
        title: departmentSonName,
        value: departmentSonId,
        key: departmentSonId,
        level,
        children:
          dsTree && Array.isArray(dsTree)
            ? this.getTreeData(dsTree, treeData)
            : null,
      };
    });
    return treeData;
  };

  render() {
    const { value, treeDataSource } = this.state;
    const { onChange } = this.props;
    return (
      <Form.Item>
        <TreeSelect
          style={{ width: 300 }}
          treeData={treeDataSource}
          value={value}
          searchPlaceholder={"请选择部门"}
          treeCheckable={true}
          treeCheckStrictly={true}
          onChange={(value, node, ex) => {
            this.setState({ value });
            let treeData = [];
            map(value, (item, index) => {
              console.log("values ==>", value);
              treeData.push({
                departmentId: item.value,
                departmentName: item.label,
              });
            });
            console.log("treeData ==>", treeData);
            onChange({
              departments: treeData,
            });
          }}
          showCheckedStrategy={TreeSelect.SHOW_PARENT}
        />
      </Form.Item>
    );
  }
}

export default DepartmentTree;
