import http from "./fetch";
const baseUrl = process.env.API_URL
  ? process.env.API_URL
  : "";

export default {
  // 工作台相关接口
  // 快捷流程列表
  queryQuickProcessTemplate(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/process/queryQuickProcessTemplate`,
      param
    );
  },
  // 管辖部门统计
  manageDepartmentStat(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/workConsole/manageDepartmentStat`,
      param
    );
  },
  // 请假信息
  leave(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/workConsole/leave`,
      param
    );
  },
  // 公告
  notification(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/workConsole/notification`,
      param
    );
  },
  // 系统公告详情
  getNotificationById(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/notification/getNotificationById`,
      param
    );
  },
  // 查询用户是否完善信息
  queryIsImperfect() {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/user/checkUserInfo`
    );
  },
  // 查询未完善名单
  queryImperfectList(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/governmentdepartment/pageGovernmentImperfectDepEmp`,
      param
    );
  },

  // 部门代办列表
  queryDeptTodoList(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/todo/queryDeptTodoList`,
      param
    );
  },
  // 按部门编号查询代办列表
  queryTaskTodoList(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/todo/queryTaskTodoList`,
      param
    );
  },
  // 待办事项之发起人流程模板
  queryTodoProcTemplate(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/todo/queryTodoProcTemplate`,
      param
    );
  },
  // 部门已办列表
  queryDeptDoneList(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/done/queryDeptDoneList`,
      param
    );
  },
  // 按部门编号查询已办列表
  queryTaskDoneList(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/done/queryTaskDoneList`,
      param
    );
  },
  // 已办事项之发起人流程模板
  queryDoneProcTemplate(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/done/queryDoneProcTemplate`,
      param
    );
  },
  // 新建流程
  queryProcessTemplateByRole(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/process/queryProcessTemplateByRole`,
      param
    );
  },
  // 我发起的
  queryLaunchProcessByUserId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/done/queryLaunchProcessByUserId`,
      param
    );
  },
  // 修改抄送为已办
  copyToDone(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/todo/copyToDone`,
      param
    );
  },

  // offer审批相关接口
  // 新增offer审批（提交、保存）
  departmentPermissions(param, callbak) {
    return http.post(
      `${baseUrl}/personnel-bsm//pc/department/departmentPermissions`,
      param,
      false,
      callbak
    );
  },

  addOfferProcess(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/offer/process/addOfferProcess`,
      param,
      true
    );
  },
  // 修改offer审批
  updateByOfferId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/offer/process/updateByOfferId`,
      param,
      true
    );
  },
  // offer详情
  getOfferByOfferId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/offer/process/getOfferByOfferId`,
      param
    );
  },
  // 删除offer审批
  deleteByOfferId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/offer/process/deleteByOfferId`,
      param,
      true
    );
  },
  // offer流程审批通过
  pass(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/offer/process/pass`,
      param,
      true
    );
  },
  // offer流程审批不通过
  refuse(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/offer/process/refuse`,
      param,
      true
    );
  },
  // offer流程撤回
  withdraw(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/offer/process/withdraw`,
      param,
      true
    );
  },

  // 入职审批相关接口
  // 入职流程详情
  getInductionById(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/induction/process/getInductionById`,
      param
    );
  },
  // 入职审批通过
  inductionPass(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/induction/process/pass`,
      param,
      true
    );
  },
  // 入职审批不通过
  inductionRefuse(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/induction/process/refuse`,
      param,
      true
    );
  },
  // 创建账号
  createAccount(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/induction/process/createAccountNumber`,
      param,
      true
    );
  },
  // 直属上级显示
  queryParentPrincipal(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/department/queryParentPrincipal`,
      param
    );
  },

  // 转正相关接口
  // 新增转正流程
  addPositiveProcess(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/positive/process/addPositiveProcess`,
      param,
      true
    );
  },
  // 修改转正流程
  updateByPositiveId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/positive/process/updateByPositiveId`,
      param,
      true
    );
  },
  // 转正流程详情
  queryPositiveById(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/positive/process/queryPositiveById`,
      param
    );
  },
  // 转正流程审批通过
  positivePass(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/positive/process/pass`,
      param,
      true
    );
  },
  // 转正流程审批不通过
  positiveRefuse(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/positive/process/refuse`,
      param,
      true
    );
  },
  // 转正流程撤回
  positiveWithdraw(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/positive/process/withdraw`,
      param,
      true
    );
  },
  // 删除转正流程
  deleteByPositiveId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/positive/process/deleteByPositiveId`,
      param,
      true
    );
  },
  // 删除转正流程
  positiveTemplate(param) {
    return http.downloadFile(
      `${baseUrl}/personnel-business/pc/positive/process/downloadTemplate`,
      param,
      true
    );
  },

  // 离职流程相关接口
  // 新增离职流程
  addDimissionProcess(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/dimission/process/addDimissionProcess`,
      param,
      true
    );
  },
  // 离职流程详情
  queryDimissionById(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/dimission/process/queryDimissionById`,
      param
    );
  },
  // 获取交接人（不根据权限显示）
  queryUserBelongDepartmentPermissions(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/department/userBelongDepartmentPermissions`,
      param
    );
  },
  // 修改离职流程
  updateByDimissionId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/dimission/process/updateByDimissionId`,
      param,
      true
    );
  },
  // 离职流程审核通过
  dimissionPass(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/dimission/process/pass`,
      param,
      true
    );
  },
  // 离职审批不通过
  dimissionRefuse(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/dimission/process/refuse`,
      param,
      true
    );
  },
  // 删除离职流程
  deleteByDimissionId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/dimission/process/deleteByDimissionId`,
      param,
      true
    );
  },
  // 离职流程撤回
  dimissionWithdraw(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/dimission/process/withdraw`,
      param,
      true
    );
  },

  // 自动离职相关接口
  // 新增离职流程
  addAutoDimissionProcess(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/autoDimission/process/addAutoDimissionProcess`,
      param,
      true
    );
  },
  // 修改自动离职
  updateByAutoDimissionId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/autoDimission/process/updateByDimissionId`,
      param,
      true
    );
  },
  // 自动离职详情
  queryAutoDimissionById(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/autoDimission/process/queryAutoDimissionById`,
      param
    );
  },
  // 删除自动离职
  deleteByAutoDimissionId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/autoDimission/process/deleteByDimissionId`,
      param,
      true
    );
  },
  // 自动离职审核通过
  autoDimissionPass(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/autoDimission/process/pass`,
      param,
      true
    );
  },
  // 自动离职审批不通过
  autoDimissionRefuse(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/autoDimission/process/refuse`,
      param,
      true
    );
  },
  // 自动离职撤回
  autoDimissionWithdraw(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/autoDimission/process/withdraw`,
      param,
      true
    );
  },

  // 请假相关接口
  // 保存请假
  saveLeaveProcess(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/leave/process/saveLeaveProcess`,
      param,
      true
    );
  },
  // 提交请假流程
  submitLeaveProcess(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/leave/process/submitLeaveProcess`,
      param,
      true
    );
  },
  // 查询请假详情
  getLeaveById(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/leave/process/getLeaveById`,
      param
    );
  },
  // 修改请假
  updateLeaveById(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/leave/process/updateLeaveById`,
      param,
      true
    );
  },
  // 审核通过
  leavePass(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/leave/process/pass`,
      param,
      true
    );
  },
  // 审核驳回
  leaveRefuse(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/leave/process/refuse`,
      param,
      true
    );
  },
  // 删除请假
  deleteLeaveById(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/leave/process/deleteLeaveById`,
      param,
      true
    );
  },
  // 请假撤回
  leaveWithdraw(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/leave/process/withdraw`,
      param,
      true
    );
  },
  // 月度请假信息汇总
  getLeaveByMonth(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/leave/process/getLeaveByMonth`,
      param
    );
  },
  // 月度请假、离职信息汇总
  getAttenLeaveByMonth(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/leave/process/getAttenLeaveByMonth`,
      param
    );
  },
  // 动态计算请假天数
  calLeaveDays(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/leave/process/calLeaveDays`,
      param
    );
  },

  // 考勤确认相关接口
  // 新增考勤确认
  addAttendanceConfirProcess(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/attendanceConfir/process/addAttendanceConfirProcess`,
      param,
      true
    );
  },
  // 考勤确认详情
  queryAttendanceConfirById(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/attendanceConfir/process/queryAttendanceConfirById`,
      param
    );
  },
  // 修改考勤确认
  updateByAttendanceConfirId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/attendanceConfir/process/updateByAttendanceConfirId`,
      param,
      true
    );
  },
  // 删除考勤确认
  deleteByAttendanceConfirId(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/attendanceConfir/process/deleteByAttendanceConfirId`,
      param,
      true
    );
  },
  // 考勤流程审核通过
  attendanceConfirPass(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/attendanceConfir/process/pass`,
      param,
      true
    );
  },
  // 审批不通过
  attendanceConfirRefuse(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/attendanceConfir/process/refuse`,
      param,
      true
    );
  },
  // 考勤流程撤回
  attendanceConfirWithdraw(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/attendanceConfir/process/withdraw`,
      param,
      true
    );
  },
  // 加班记录列表
  queryWorkOverTimeList(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/workOvertime/queryWorkOverTimeList`,
      param
    );
  },
  // 加班记录列表
  queryWorkOvertimeListByAttendanceDate(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/workOvertime/queryWorkOvertimeListByAttendanceDate`,
      param
    );
  },

  // 查询流程日志
  queryProcessLogList(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/process/queryProcessLogList`,
      param
    );
  },

  // 菜单树-通过登录人
  menulistForUser(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/menu/listForUser`,
      param
    );
  },

  // 流程模板查询
  queryTemplate(param) {
    return http.get(
      `${baseUrl}/personnel-bsm/pc/system/dictionary/process/queryTemplate`,
      param
    );
  },
  // 部门-一级（查询负责部门 HR与管理员可看全部
  listAll(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/department/listAll`,
      param
    );
  },
  // 人员选择查询
  listStaffForChoose(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/staff/listStaffForChoose`,
      param
    );
  },
  // 查询省市列表
  queryProvinceList(param) {
    return http.get(
      `${baseUrl}/personnel-bsm/pc/address/queryProvinceList`,
      param
    );
  },
  // 下拉框数据查询
  selectListByGroup(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/dictionary/listByGroup`,
      param
    );
  },
  // 岗位下拉框数据查询
  selectPost(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/post/listForDropDown`,
      param
    );
  },
  // 项目名称下拉框数据查询
  selectDepartment(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/department/listByParent`,
      param
    );
  },
  // 职级下拉框数据查询
  selectLevel(param) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/level/listForDropDown`,
      param
    );
  },
  // 查询全部发起的流程
  listProcessDoneTask(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/user/done/listProcessDoneTask`,
      param
    );
  },

  // ------------------------ 管辖部门 ------------------------
  // 管辖部门--查询部门
  queryStatisticsDep(param) {
    return http.get(
      `${baseUrl}/personnel-business/pc/governmentdepartment/statisticsDep`,
      param
    );
  },
  // 分页查询管辖部门下的员工信息
  queryPageGovernmentDepEmp(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/governmentdepartment/pageGovernmentDepEmp`,
      param
    );
  },
  // 发送考勤确认消息
  sendAttendanceRemind(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/governmentdepartment/sendAttendanceRemind`,
      param,
      true
    );
  },
  // 管辖部门下的人员统计接口
  queryStatisticsEmp(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/governmentdepartment/statisticsEmp`,
      param
    );
  },
  // 设置部门考勤天数接口
  setDepAttendance(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/governmentdepartment/setDepAttendance`,
      param
    );
  },
  // 未确认人员名单
  pageUnConfirmAttendanceEmp(param) {
    return http.post(
      `${baseUrl}/personnel-business/pc/governmentdepartment/pageUnConfirmAttendanceEmp`,
      param
    );
  },
  // 管辖部门-获取部门树
  getJurisdictionTree() {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/department/departmentPermissions
      `
    );
  },
  // ------------------------ 入职名单 ------------------------
  // 入职名单 -- 列表查询
  entrantList(param, callbak) {
    return http.post(
      `${baseUrl}/personnel-bsm/pc/pendingOnboarding/queryPendingOnboarding`,
      param
    );
  },
};
