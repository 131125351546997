/**
* @fileoverview 系统管理---数据字典 -- 新增字典项
* 
*/
import React,{Component} from 'react';
// import './index.less'
import { Modal, Form, Input } from "antd";
const FormItem = Form.Item;
const { TextArea } = Input;

class addRankModal extends Component{
    // 点击确定
    handleSubmit = (e) => {
        e.preventDefault();
        const { form, type } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleSubmit(type,values)
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    render(){
        const { addModalShow, form, type, dicItem, checkedTreeItem } = this.props;
        const { getFieldDecorator } = form;
        return(
            <Modal
                className="setUserModal"
                title={type === 0 ? '添加字典项' : '修改字典项'}
                visible={addModalShow}
                width={500}
                onCancel={this.handleCancel}
                onOk={this.handleSubmit}
                maskClosable={false}
            >
                <div className="setUser-box">
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        <FormItem 
                            label="名称">
                            {getFieldDecorator('dicValue', {
                                initialValue: dicItem.dicValue,
                                rules: [
                                    { required: true,whitespace:true, message: '请输入名称' },
                                    { max: 16, message: '请输入16字以内的名称'}
                                ],
                            })(
                                <Input placeholder="请输入" allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <FormItem 
                            label="代码">
                            {getFieldDecorator('dicKey', {
                                initialValue: dicItem.dicKey,
                                rules: [
                                    { required: true, message: '请输入代码' },
                                    { max: 16, message: '请输入16字以内的名称'},
                                    { pattern: new RegExp(/^[A-Za-z0-9]+$/, "g") , message: '代码只允许包含字母和数字' }
                                ],
                            })(
                                <Input disabled={type === 0 ?'':true} placeholder="请输入" allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <FormItem 
                            label="类型">
                            {type === 0 ? checkedTreeItem.dicValue : dicItem.groupName}
                        </FormItem>
                        <FormItem 
                            label="说明">
                            {getFieldDecorator('des', {
                                initialValue: dicItem.des,
                                rules: [
                                    { max: 128, message: '请输入128字以内的说明'}
                                ],
                            })(
                                <TextArea placeholder="请输入" allowClear autoComplete="new-password" autoSize={{ minRows: 3, maxRows: 5 }}/>
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Form.create()(addRankModal)