/**
* @fileoverview 系统管理---职级管理 -- 新增弹框
* @author  蒋西园
*/
import React,{Component} from 'react';
import './index.less'
import { Modal, Form, Input, Select } from "antd";
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class addRankModal extends Component{
    // 点击确定
    handleAddSure = (e) => {
        e.preventDefault();
        const { form, type } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleAddSure(type,values)
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    render(){
        const { addModalShow, form, type, rankItem } = this.props;
        const { getFieldDecorator } = form;
        return(
            <Modal
                className="setUserModal"
                title={type === 0 ? '添加职级' : '修改职级'}
                visible={addModalShow}
                width={500}
                onCancel={this.handleCancel}
                onOk={this.handleAddSure}
                maskClosable={false}
            >
                <div className="setUser-box">
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        <FormItem 
                            label="职级">
                            {getFieldDecorator('occupationName', {
                                initialValue: rankItem.occupationName,
                                rules: [
                                    { required: true,whitespace:true, message: '请输入职级' },
                                    { max: 16, message: '请输入16字以内的职级名称'},
                                    { 
                                        pattern: new RegExp(/^[A-Za-z0-9\u4e00-\u9fa5]+$/, "g"),
                                        message:'只能输入中英文数字'}
                                ],
                            })(
                                <Input placeholder="请输入" allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <FormItem 
                            label="说明">
                            {getFieldDecorator('description', {
                                initialValue: rankItem.description,
                                rules: [
                                    { max: 128, message: '请输入128字以内的说明'}
                                ],
                            })(
                                <TextArea placeholder="请输入" allowClear autoComplete="new-password" autoSize={{ minRows: 3, maxRows: 5 }}/>
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Form.create()(addRankModal)