/**
* @fileoverview 人事管理---薪资档案 -- 搜索条件
* @author  黄洪月
*/
import React,{ Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Select, Row, Col, TreeSelect} from 'antd';
import moment from 'moment';
import './index.less'

const FormItem = Form.Item;
const Option = Select.Option;
const { SHOW_PARENT,TreeNode  } = TreeSelect;


class SearchAttendancelist extends Component{
    constructor(props) {
        super(props)
        this.state = {
            departmentList: [],  // 部门id
            value: [],          // 树状
            job_status:"2"      // 在职状态
        }
    }

    componentDidMount() {
        this.querydepartmentId()
    }

    // 提交表单
    handleSubmit = () => {
        this.props.form.validateFieldsAndScroll((err,values)=>{
            if(err){
                return false
            }
            const data = {};
            // 去除含有undefined的item
            Object.keys(values).forEach(res => {
                if (values[res] && res === 'cycleMonth') {
                    data.cycleMonth = moment(values[res]).format('YYYY/MM');
                }else if(values[res]!==undefined){
                    data[res] = values[res]
                }
            })
            data.departmentIds=this.state.value
            this.props.handleSearchBtn(data);
        })
    }

    // 重置
    handleReset = () => {
        this.setState({job_status:undefined,value:[]})
        this.props.form.resetFields();
        this.props.handleSearchBtn();
        this.props.form.resetFields();
    }

    /**
     * 函数描述: 查询部门名称
     * @param {string} type   类型（1：项目 2：部门）
     */
    querydepartmentId = () => {
        this.$http["departmentPermissions"]({
		}).then(res => {
            if (res.code === 200) {
				this.setState({
					departmentList: res.data || []
				})
			}
		});
    }

    // 获取部门
    onChange = value => {
        const departmentIds = []
        departmentIds.push(value)
        this.state.departmentList.map(item => {
            if (item.departmentSonId===value&&item.dsTree ) {
                getChildDepartmentId(item.dsTree )
            } else if(item.dsTree ){
                mapItem(item.dsTree )
            }
        })

        function mapItem(item) {
            item.map(c_item => {
                if (c_item.departmentSonId === value) {
                    if (c_item.dsTree ) {
                        getChildDepartmentId(c_item.dsTree )
                    }   
                } else if(item.dsTree ){
                    mapItem(item.dsTree )
                }
            })
        }

        function getChildDepartmentId(item) {
            if (item.departmentSonId) {
                departmentIds.push(item.departmentSonId)
            }
            item.map(c_item => {
                departmentIds.push(c_item.departmentSonId)
                if (c_item.dsTree ) {
                    getChildDepartmentId(c_item.dsTree )
                }
            })
        }
        this.setState({ value:departmentIds });
    };

    

    // 部门查询树状结构
    getTreeNode = (item) => {
        return <TreeNode key={item.departmentSonId} value={item.departmentSonId} title={item.departmentSonName} >
               { item.dsTree ?item.dsTree .map(c_item => {
                   return this.getTreeNode(c_item)
               }):""
            }
        </TreeNode>
    }

	render(){
        const { getFieldDecorator } = this.props.form;
        
		return (
            <div className="search-form">
                <Form>
                    <Row >
                        <Col>
                            <FormItem label="姓名" style={{marginLeft:16}}>
                                {
                                    getFieldDecorator('staffName',{
                                        rules: [
                                            { max: 32, message: '请输入32字以内的名字'}
                                        ],
                                    })(
                                        <Input placeholder="请输入" allowClear style={{width:180}} />
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem label="部门" style={{marginLeft:16}}>
                                {
                                    getFieldDecorator('departmentIds')(
                                        <TreeSelect
                                            showSearch
                                            style={{ height:32,width:180 }}
                                            value={this.state.value}
                                            dropdownStyle={{ maxHeight: 400,overflow: 'auto' }}
                                            placeholder="请选择"
                                            allowClear
                                            onChange={this.onChange}
                                            showCheckedStrategy={SHOW_PARENT}
                                            treeNodeFilterProp='title'
                                        >
                                            {(this.state.departmentList || []).map(item => {return this.getTreeNode(item) })}
                                        </TreeSelect>
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem label={<span>在职状态</span>} style={{marginLeft:16}}>
                                {
                                    getFieldDecorator('jobStatus', {
                                        initialValue:this.state.job_status
                                    })(
                                        <Select
                                            placeholder="请选择" 
                                            allowClear
                                            style={{ width: 180}}
                                        >
                                            <Option value="2">在职</Option>
                                            <Option value="1">离职</Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem label={<span>合同类型</span>} style={{marginLeft:16}}>
                                {
                                    getFieldDecorator('contractType')(
                                        <Select
                                            placeholder="请选择" 
                                            allowClear
                                            style={{width:180}}
                                        >
                                            <Option value="">全部</Option>
                                            <Option value="LABOR">劳动合同</Option>
                                            <Option value="SERVICE">劳务合同</Option>
                                            <Option value="INTERNSHIP">实习合同</Option>
                                        </Select>
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col>
                            <Button onClick={this.handleReset} style={{ marginLeft: 16 }}>重置</Button>
                            <Button type="primary"  onClick={this.handleSubmit} style={{ marginLeft: 16 }}>查询</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
		)
	}
}

export default  withRouter( Form.create()(SearchAttendancelist))
