/**
* @fileoverview 系统管理---数据字典 -- 数据列表
* @author  蒋西园
*/
import React, { Component } from 'react';
import { Table, Modal,Icon } from 'antd';
import './index.less'
const { confirm } = Modal;

class DictableList extends Component {
    constructor(props) {
        super(props)
        this.columns = [{
                title: '代码',
                width:'22%',
                dataIndex: 'dicKey',
                render: (text) => text ? text : '--'
            },{
                title: '名称',
                width:'22%',
                dataIndex: 'dicValue',
                render: (text) => text ? text : '--'
            },{
                title: '类型',
                width:'22%',
                dataIndex: 'groupName',
                render: (text) => text ? text : '--'
            },{
                title: '说明',
                width:'22%',
                dataIndex: 'des',
                render: (text) => text ? text : '--'
            },{
                title: '操作',
                width:'12%',
                render: (record) => {
                    return (
                        <span>
                            <span 
                                onClick={() => this.props.handleEditSubmit(record)} 
                                style={{ color: "#4D91FA", cursor: 'pointer' }}>
                                    编辑
                                </span>
                            <span
                                onClick={() => this.handleDelete(record.id)} 
                                style={{ marginLeft: "16px", color: "#4D91FA", cursor: 'pointer' }}>
                                    删除
                                </span>
                        </span>
                    )
                }
            },
        ]
    }
    /**
     * 函数描述: 删除按钮事件
     * @param {object} id 该账号所有信息 -- 后期要精简参数更换为唯一标识
     * @return {Object} 二次确认后统一返回父组件处理请求
     */
    handleDelete = (id) => {
         confirm({
             title: '删除',
             content: '您确定要删除该数据吗？',
             icon:<Icon type="delete" style={{color:'#E5537B'}} />,
             onOk: () => {
               this.props.handleDelete(id);
             }
         });
    }
    render() {
        console.log(this.props.data);
        return (
            <Table
                rowKey={(record) => { return record.id }}
                loading={this.props.loading}
                columns={this.columns} 
                dataSource={this.props.data}
                locale={{
                    emptyText: (
                      <div>
                        <img
                          style={{ width: 182, height: 94 }}
                          src={require("../../../../../assets/image/no-data.png")}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: 14,
                            color: "#717E99",
                            lineHeight: "20px"
                          }}
                        >
                          暂无数据
                        </p>
                      </div>
                    )
                  }}
                pagination={{
                    hideOnSinglePage: true,
                    current: this.props.pageNo,
                    total: this.props.total,
                    pageSize: this.props.pageSize || 10,
                    onChange: (pageNo, pageSize) => this.props.handleChangePage(pageNo, pageSize)
                }}
                
            />
        )
    }
}
export default DictableList