/**
 * @description 一级维度select
 */
import React from "react";
import DepartmentTree from "../departmentTree"; // 部门
import CitySelect from "../citySelect"; // 城市
import PersonnelStatus from "../personnelStatus"; // 人员状态

class FirstLevelSelect extends React.Component {
  render() {
    const { onChange, type } = this.props;
    const TYPE = {
      department: <DepartmentTree onChange={onChange} />,
      city: <CitySelect onChange={onChange} />,
      personnelStatus: (
        <PersonnelStatus onChange={onChange} />
      ),
    };
    return type && TYPE[type];
  }
}

export default FirstLevelSelect;
