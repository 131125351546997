import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, Input, Select, Row, Modal, Table } from "antd";
import "./index.less";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;

const status = {
  PASS: "通过",
  REFUSE: "驳回",
  INITIATE: "发起",
  AUDIT: "审核",
  PIGEONHOLE: "归档",
  COMPLETE: "已归档",
  STORAGE: "保存",
  RECALL: "撤回",
};

class SearchUserlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      modalVisible: false,
      LEAVE_TYPE: [],
    };
  }
  //
  handleOk = () => {};

  handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  handleShow = () => {
    this.setState(
      {
        modalVisible: true,
      },
      () => {
        this.queryQuitType();
        this.getList();
      }
    );
  };

  getList = () => {
    const params = {
      attendanceDate: this.props.date || moment().format("YYYY-MM"),
    };
    if (this.props.staffId) {
      params.staffId = this.props.staffId;
    }
    this.$http["getLeaveByMonth"](params).then((res) => {
      if (res.code === 200) {
        this.setState({
          tableData: res.data.leaveListVO || [],
        });
      }
    });
  };
  queryQuitType = () => {
    this.$http["selectListByGroup"]({
      dicGroup: "LEAVE_TYPE",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          LEAVE_TYPE: res.data || [],
        });
      }
    });
  };

  render() {
    const { LEAVE_TYPE } = this.state;
    const columns = [
      {
        title: "单号",
        dataIndex: "leaveProId",
        key: "leaveProId",
      },
      {
        title: "请假类型",
        dataIndex: "leaveTypeName",
        key: "leaveTypeName",
        width: 200,
        // render: (text, record) => {
        //   return (
        //     <span>
        //       {LEAVE_TYPE.filter(item => item.dicKey == text)[0]
        //         ? LEAVE_TYPE.filter(item => item.dicKey == text)[0].dicValue
        //         : "--"}
        //     </span>
        //   );
        // }
      },
      {
        title: "请假日期",
        dataIndex: "leaveStartTime",
        key: "leaveStartTime",
        width: 200,
        render: (text, record) => {
          return (
            <span>{`${record.leaveStartTime}~${record.leaveEndTime}`}</span>
          );
        },
      },
      {
        title: "天数",
        dataIndex: "leaveDays",
        key: "leaveDays",
      },
      {
        title: "状态",
        dataIndex: "taskDefName",
        key: "taskDefName",
        render: (text) => {
          return <span>已完成</span>;
        },
      },
    ];

    const { modalVisible, tableData } = this.state;

    return (
      <div className="">
        <Button type="link" onClick={this.handleShow}>
          请假记录
        </Button>
        <Modal
          visible={modalVisible}
          title="请假记录"
          width="880px"
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              关闭
            </Button>,
          ]}
        >
          <Table columns={columns} dataSource={tableData} />
        </Modal>
      </div>
    );
  }
}

export default withRouter(SearchUserlist);
