import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Timeline,
  Icon,
  Form,
  Select,
  Row,
  Col,
  Button,
  Input,
  Upload,
  message,
  Radio,
  DatePicker,
  Modal,
  Alert,
  Tabs,
  Table,
  Calendar,
} from "antd";
import "./index.less";
import ProcessGuide from "../../../components/processGuide";
import AuditRecord from "../../../components/auditRecord";
import Withdraw from "../../../components/withdraw"; // 撤回
import { getQueryVariable } from "../../../utils/common";
import utils from "../../../utils/utils";
import moment from "moment";
import http from "../../../api/fetch";

// 控制年假天数显示，请假时间选择空间修改，天数输入框修改，附件图片显示

// console.log(
//   moment("2020-03-21 18:00").diff(moment("2020-03-18 09:00"), "days")
// );

function range(start, end) {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
}

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { confirm } = Modal;
const { TabPane } = Tabs;

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: utils.localDb.get("userData").staffId,
      staffName: utils.localDb.get("userData").staffName,

      fileList: [],
      showType: 1,
      previewVisible: false, // 预览弹框
      previewUrl: "",
      leaveDetail: {},
      staffDetail: {},
      isProbation: true,
      remainingAnnualLeave: 0,
      logList: [],
      submitDisabled: false,
    };
  }
  componentDidMount() {
    if (getQueryVariable("leaveProcId")) {
      this.getLeaveById();
      this.queryProcessLogList();
    } else {
      this.queryStaffDetail();
      this.queryLeaveType();
      this.setState({
        logList: [],
      });
    }
  }

  getLeaveById = () => {
    this.$http["getLeaveById"]({
      leaveProcId: getQueryVariable("leaveProcId"),
    }).then((res) => {
      if (res.code === 200) {
        let showType = 2;
        let showWithdraw = false;
        let step2 = false;
        let step3 = false;

        let showComplete = false;
        let taskDefKey = res.data.processData
          ? res.data.processData.taskDefKey
          : "";
        if (res.data.procStatus == "STORAGE") {
          showType = 1;
        }
        if (
          res.data.procStatus == "REFUSE" ||
          res.data.procStatus == "RECALL"
        ) {
          if (res.data.creatorId == this.state.staffId) {
            showType = 1;
          }
        }
        if (res.data.procStatus == "AUDIT") {
          if (res.data.creatorId == this.state.staffId) {
            // 撤回操作
            showWithdraw = true;
          }
          if (res.data.auditId == this.state.staffId) {
            if (taskDefKey === "LEA0002") {
              // 第二步
              step2 = true;
            }
            if (taskDefKey === "LEA0003") {
              // 第三步
              step3 = true;
              showComplete = true;
            }
          }
        }
        if (res.data.procStatus == "COMPLETE") {
          showComplete = false;
        }

        // 抄送的详情没有操作权限
        if (getQueryVariable("copyToFlag") == 1) {
          step2 = false;
          step3 = false;
        }

        let list = res.data.attachments
          ? res.data.attachments.map((item) => {
              return {
                url: item.attachmentKeyUrl,
                uid: item.attachmentKey,
                name: item.attachmentKey.substr(
                  item.attachmentKey.lastIndexOf("/") + 1
                ),
              };
            })
          : [];
        let selectDisabledEndTime = false;
        if (
          res.data.leaveStartTime &&
          res.data.leaveEndTime
        ) {
          if (
            moment(res.data.leaveStartTime).format(
              "YYYY-MM-DD"
            ) ==
              moment(res.data.leaveEndTime).format(
                "YYYY-MM-DD"
              ) &&
            moment(res.data.leaveStartTime)
              .format("YYYY-MM-DD HH:mm")
              .split(" ")[1] == "13:30"
          ) {
            selectDisabledEndTime = true;
          }
        }
        this.setState(
          {
            leaveDetail: res.data || {},
            leaveDays: res.data.leaveDays || "0",
            selectDisabledEndTime,
            staffDetail: res.data || {},
            fileList: list,
            showType,
            showWithdraw,
            step2,
            step3,

            showComplete,
          },
          () => {
            if (this.state.showType == 1) {
              this.$http["getStaffPostInfoForStaffRoster"]({
                staffId: this.state.staffId,
              }).then((res) => {
                if (res.code === 200) {
                  this.setState({
                    isProbation:
                      res.data.isProbation || false,
                    remainingAnnualLeave:
                      res.data.remainingAnnualLeave || "0",
                  });
                }
              });
            }
          }
        );
        this.queryLeaveType();
      }
    });
  };

  //流程日志
  queryProcessLogList = () => {
    this.$http["queryProcessLogList"]({
      businessKey: getQueryVariable("leaveProcId"),
      procTemplate: getQueryVariable("procTemplate"),
    })
      .then((res) => {
        if (res.code == 200) {
          this.setState({
            logList: res.data || [],
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  queryStaffDetail = () => {
    this.$http["getStaffPostInfoForStaffRoster"]({
      staffId: this.state.staffId,
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          staffDetail: res.data || {},
          isProbation: res.data.isProbation || false,
          remainingAnnualLeave:
            res.data.remainingAnnualLeave || "0",
        });
      }
    });
  };

  queryLeaveType = () => {
    this.$http["selectListByGroup"]({
      dicGroup: "LEAVE_TYPE",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          LEAVE_TYPE: res.data || [],
        });
      }
    });
  };

  handleDelete = () => {
    const self = this;
    confirm({
      title: "删除",
      content: "您确定要删除该工作流吗？",
      onOk() {
        self.$http["deleteLeaveById"]({
          leaveProcId: getQueryVariable("leaveProcId"),
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              self.props.history.go(-1);
            } else {
            }
          })
          .catch((err) => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  handleSubmit = (type) => {
    const { staffDetail, fileList } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let canUpload = true;
        let leaveType = values["leaveType"];
        if (
          leaveType == "AFFAIR_RECESS" ||
          leaveType == "FESTIVAL_RECESS" ||
          leaveType == "BEREFT_RECESS"
        ) {
          canUpload = false;
        }
        if (canUpload && !fileList.length) {
          message.destroy();
          message.warning("请上传附件");
          return;
        }
        this.setState({
          submitDisabled: true,
        });
        const params = {
          ...values,
          leaveStartTime:
            values["leaveStartTime"].format("YYYY-MM-DD") +
            " " +
            values["startTime"] +
            ":00",
          leaveEndTime:
            values["leaveEndTime"].format("YYYY-MM-DD") +
            " " +
            values["endTime"] +
            ":00",
          leaveDays: this.state.leaveDays || 0,
          staffName: staffDetail.staffName,
          staffId: staffDetail.staffId,
          departmentId: staffDetail.departmentId,
          departmentName: staffDetail.departmentName,
          postId: staffDetail.postId,
          postName: staffDetail.postName,
          belongDeliverManagerId:
            staffDetail.belongDeliverManagerId,
          attachmentKey: fileList.length
            ? fileList.map((item) => {
                return item.uid;
              })
            : [],
        };
        if (getQueryVariable("leaveProcId")) {
          params.leaveProcId = getQueryVariable(
            "leaveProcId"
          );
          params.procStatus =
            type == "submit" ? "INITIATE" : "STORAGE";
        }
        console.log(params);
        let url =
          type == "submit"
            ? "submitLeaveProcess"
            : "saveLeaveProcess";
        this.$http[
          getQueryVariable("leaveProcId")
            ? "updateLeaveById"
            : url
        ](params)
          .then((res) => {
            if (res.code === 200) {
              this.props.history.push("/workbench/launch");
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };

  // 打开预览弹框
  handlePreview = (url) => {
    this.setState({
      previewVisible: true,
      previewUrl: url,
    });
  };
  // 取消预览弹框
  handleCancel = () => {
    this.setState({
      previewVisible: false,
    });
  };

  handlePass = (type) => {
    const self = this;
    const { leaveDetail } = this.state;
    const { form } = this.props;
    const params = {
      leaveProcId: getQueryVariable("leaveProcId"),
      taskId: leaveDetail.processData.taskId,
    };
    if (type === "pass") {
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.setState({
            submitDisabled: true,
          });
          this.$http["leavePass"]({
            ...params,
            ...values,
          })
            .then((res) => {
              if (res.code === 200) {
                // window.close();
                this.props.history.go(-1);
                this.setState({
                  submitDisabled: false,
                });
              } else {
                this.setState({
                  submitDisabled: false,
                });
              }
            })
            .catch((err) => {
              this.setState({
                submitDisabled: false,
              });
            });
        }
      });
    } else if (type === "refuse") {
      if (!form.getFieldValue("auditOpinion").trim()) {
        message.destroy();
        message.warning("请在审核意见中填写退回原因");
        document.getElementById("auditOpinion").focus();
        return;
      }
      form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          confirm({
            icon: (
              <Icon
                type="question-circle"
                style={{ color: "#4D91FA" }}
              />
            ),
            title: "您确定退回该工作流吗？",
            content: "",
            onOk() {
              self.$http["leaveRefuse"]({
                ...params,
                ...values,
              })
                .then((res) => {
                  if (res.code === 200) {
                    // window.close();
                    self.props.history.go(-1);
                  } else {
                  }
                })
                .catch((err) => {});
            },
            onCancel() {},
          });
        }
      });
    }
  };

  handleChange = ({ fileList }) => {
    if (fileList.length == 0) {
      this.setState({ fileList });
      this.triggerChange(fileList);
    }
  };
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  beforeUpload = (file) => {
    const { fileList } = this.state;
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.destroy();
      message.warning(
        "只能上传jpg,jpeg,png图片，且不超过2M"
      );
      return false;
    }
    const name = file.name;
    const index = name.lastIndexOf(".");
    const suffix = name.substr(index + 1).toLowerCase();
    if (["jpg", "jpeg", "png"].indexOf(suffix) == -1) {
      message.destroy();
      message.warning(
        "只能上传jpg,jpeg,png图片，且不超过2M"
      );
      return false;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // 使用ossupload覆盖默认的上传方法
      http.uploadFile(file, file.name).then((data) => {
        // let fileList = [
        //   {
        //     url: data.url,
        //     uid: data.key,
        //     name: data.name
        //   }
        // ];
        fileList[fileList.length] = {
          url: data.url,
          uid: data.key,
          name: data.name,
        };
        this.setState({ fileList });
        this.triggerChange(fileList); //只返回id
      });
    };
    return false; // 不调用默认的上传方法
  };

  endTimeChange = (date, dateString) => {
    const { form } = this.props;
    // console.log(date, dateString);
    // const leaveStartTime = form.getFieldValue("leaveStartTime");
    // console.log(leaveStartTime.format("YYYY-MM-DD HH:mm"));
    // let startDateString = "";
    // if (leaveStartTime) {
    //   startDateString = leaveStartTime.format("YYYY-MM-DD HH:mm");
    // }
    // if (date && leaveStartTime) {
    //   let leaveDays = moment(dateString).diff(moment(startDateString), "days");
    //   console.log(leaveDays);
    //   let leaveHours = moment(dateString.split(" ")[1], "HH:mm").diff(
    //     moment(startDateString.split(" ")[1], "HH:mm"),
    //     "hour"
    //   );
    //   console.log(leaveHours);
    //   if (leaveHours > 4 || leaveHours < 0) {
    //     leaveDays += 1;
    //   } else {
    //     leaveDays += 0.5;
    //   }

    //   console.log(moment(leaveStartTime).format("H"));
    //   form.setFieldsValue({
    //     leaveDays: leaveDays + "",
    //   });
    // }
    this.setState({
      leaveDays: 0,
    });
    if (dateString) {
      console.log(dateString);
      const startDate = form.getFieldValue(
        "leaveStartTime"
      );
      const startTime = form.getFieldValue("startTime");
      const endTime = form.getFieldValue("endTime");
      if (!startDate || !startTime) {
        return;
      }
      if (
        startDate.format("YYYY-MM-DD") == dateString &&
        startTime == "13:30"
      ) {
        form.setFieldsValue({
          endTime: undefined,
        });
        this.setState({
          selectDisabledEndTime: true,
        });
        return;
      } else {
        this.setState({
          selectDisabledEndTime: false,
        });
      }
      const leaveStartTime =
        startDate.format("YYYY-MM-DD") +
        " " +
        startTime +
        ":00";
      const leaveEndTime =
        dateString + " " + endTime + ":00";

      this.$http["calLeaveDays"]({
        leaveStartTime: leaveStartTime,
        leaveEndTime: leaveEndTime,
      })
        .then((res) => {
          if (res.code === 200) {
            this.setState({
              leaveDays: res.data,
            });
          } else {
          }
        })
        .catch((err) => {});
    } else {
      this.setState({
        selectDisabledEndTime: false,
      });
    }
  };

  validateDays = (rule, value, callback) => {
    // const { form } = this.props;
    const reg = /^[0-9]+([.]{1}[0-9]{1})?$/;
    const day = value && value.trim();
    if (day && !reg.test(day)) {
      callback("支持大于0小于99的数值，最小单位0.5");
    } else {
      if (day && Number(day) == 0) {
        callback("支持大于0小于99的数值，最小单位0.5");
      } else if (day && day.indexOf(".") > -1) {
        if (Number(day) > 99) {
          callback("支持大于0小于99的数值，最小单位0.5");
        } else {
          if (
            day[day.indexOf(".") + 1] > 0 &&
            day[day.indexOf(".") + 1] != 5
          ) {
            callback("支持大于0小于99的数值，最小单位0.5");
          } else {
            callback();
          }
        }
      } else if (day && Number(day) > 99) {
        callback("支持大于0小于99的数值，最小单位0.5");
      } else {
        callback();
      }
    }
  };

  validateLength128 = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };

  handleWithdraw = () => {
    this.props.history.go(-1);
  };

  disabledStartDate = (current) => {
    // return current && current < moment().subtract(1, "days");
  };

  disabledEndDate = (current) => {
    const { form } = this.props;
    if (form.getFieldValue("leaveStartTime")) {
      return (
        current &&
        current < form.getFieldValue("leaveStartTime")
      );
    }
  };

  startTimeChange = (date, dateString) => {
    const { form } = this.props;
    // const hour = moment(dateString).format("HH");
    // if (hour == "09") {
    //   this.setState({
    //     minuteArr: [30],
    //   });
    //   const time = moment(dateString).format("YYYY-MM-DD HH") + " :00";
    //   setTimeout(() => {
    //     form.setFieldsValue({
    //       leaveStartTime: moment(time, "YYYY-MM-DD HH:mm"),
    //     });
    //   }, 60);
    // } else if (hour == "13") {
    //   this.setState({
    //     minuteArr: [0],
    //   });
    //   const time = moment(dateString).format("YYYY-MM-DD HH") + " :30";
    //   setTimeout(() => {
    //     form.setFieldsValue({
    //       leaveStartTime: moment(time, "YYYY-MM-DD HH:mm"),
    //     });
    //   }, 60);
    // }

    form.setFieldsValue({
      // startTime: undefined,
      leaveEndTime: undefined,
      // endTime: undefined,
      leaveDays: "",
    });
    this.setState({
      leaveDays: 0,
    });
  };

  startChange = (value) => {
    const { form } = this.props;
    form.setFieldsValue({
      endTime: undefined,
      leaveDays: "",
    });
    this.setState({
      leaveDays: 0,
    });
    const leaveStartTime = form.getFieldValue(
      "leaveStartTime"
    );
    const leaveEndTime = form.getFieldValue("leaveEndTime");
    const startTime = value;
    if (leaveStartTime && leaveEndTime) {
      if (
        leaveStartTime.format("YYYY-MM-DD") ==
          leaveEndTime.format("YYYY-MM-DD") &&
        startTime == "13:30"
      ) {
        this.setState({
          selectDisabledEndTime: true,
        });
      } else {
        this.setState({
          selectDisabledEndTime: false,
        });
      }
    } else {
      this.setState({
        selectDisabledEndTime: false,
      });
    }
  };

  disabledStartTime = () => {
    return {
      disabledHours: () =>
        range(0, 24)
          .splice(0, 9)
          .concat(range(0, 24).splice(10, 3))
          .concat(range(0, 24).splice(14, 10)),
      disabledMinutes: () => this.state.minuteArr || [30],
    };
  };
  disabledEndTime = () => {
    const { form } = this.props;
    let leaveStartTime = form.getFieldValue(
      "leaveStartTime"
    );
    let leaveEndTime = form.getFieldValue("leaveEndTime");
    if (leaveStartTime) {
      let startDay = leaveStartTime.format("YYYY-MM-DD");
      let hour = leaveStartTime.format("HH");
      let endDay;
      if (leaveEndTime) {
        endDay = leaveEndTime.format("YYYY-MM-DD");
      }
      if (hour == "13") {
        if (startDay == endDay) {
          return {
            disabledHours: () =>
              range(0, 24)
                .splice(0, 18)
                .concat(range(0, 24).splice(19, 5)),
          };
        } else {
          return {
            disabledHours: () =>
              range(0, 24)
                .splice(0, 12)
                .concat(range(0, 24).splice(13, 5))
                .concat(range(0, 24).splice(19, 5)),
          };
        }
      } else {
        return {
          disabledHours: () =>
            range(0, 24)
              .splice(0, 12)
              .concat(range(0, 24).splice(13, 5))
              .concat(range(0, 24).splice(19, 5)),
        };
      }
    } else {
      return {
        disabledHours: () =>
          range(0, 24)
            .splice(0, 12)
            .concat(range(0, 24).splice(13, 5))
            .concat(range(0, 24).splice(19, 5)),
      };
    }
  };

  endTimeDefault = () => {
    const { form } = this.props;
    let leaveStartTime = form.getFieldValue(
      "leaveStartTime"
    );
    if (leaveStartTime) {
      if (leaveStartTime.format("HH") == "13") {
        return moment("18:00", "HH:mm");
      } else {
        return moment("12:00", "HH:mm");
      }
    } else {
      return moment("12:00", "HH:mm");
    }
  };

  handleRemove = (file) => {
    // console.log(file);
    const { fileList } = this.state;
    fileList.splice(
      fileList.findIndex((item) => item.uid == file.uid),
      1
    );
    this.setState({
      fileList,
    });
  };

  calLeaveDays = (value) => {
    // debugger;
    const { form } = this.props;
    if (value) {
      console.log("value->", value);
      const startDate = form.getFieldValue(
        "leaveStartTime"
      );
      const startTime = form.getFieldValue("startTime");
      const endDate = form.getFieldValue("leaveEndTime");
      if (!startDate || !startTime || !endDate) {
        return;
      }
      const leaveStartTime =
        startDate.format("YYYY-MM-DD") +
        " " +
        startTime +
        ":00";
      const leaveEndTime =
        endDate.format("YYYY-MM-DD") + " " + value + ":00";

      this.$http["calLeaveDays"]({
        leaveStartTime: leaveStartTime,
        leaveEndTime: leaveEndTime,
      })
        .then((res) => {
          if (res.code === 200) {
            this.setState({
              leaveDays: res.data,
            });
          } else {
          }
        })
        .catch((err) => {});
    } else {
      this.setState({
        leaveDays: 0,
      });
    }
  };

  render() {
    const {
      fileList,
      previewVisible,
      previewUrl,
      showType,
      staffDetail,
      leaveDetail,
      logList,
    } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };

    // 显示审核结果，判断右侧记录第一个的状态operStatus，有的话就显示第一个，没有的话显示第二个
    let log = {};
    if (logList.length > 1) {
      if (logList[0].operStatus) {
        log = logList[0];
      } else {
        log = logList[1];
      }
    }

    let fileRequired = false;
    let leaveType = form.getFieldValue("leaveType");
    if (
      leaveType &&
      [
        "DISEASE_RECESS",
        "MARRIED_RECESS",
        "ACCOMPANY_ANTENATAL_RECESS",
        "PRENATAL_CHECK_UP_RECESS",
        "ANTENATAL_RECESS",
      ].indexOf(leaveType) > -1
    ) {
      fileRequired = true;
    }

    return (
      <div className="leave-main">
        <div className="leave-title">
          <span>
            请假申请流程
            {showType == 1 && <span>-新建</span>}
            {leaveDetail.procStatus == "AUDIT" ? (
              leaveDetail.processData ? (
                <span>{`-${leaveDetail.processData.taskName}`}</span>
              ) : null
            ) : null}
          </span>
          <ProcessGuide
            title="请假申请流程"
            temp="LEAVE_PROC"
          />
        </div>
        <div className="leave-wrap">
          <div className="wrap-left" style={{ flex: 1 }}>
            {log && log.operDescription ? (
              <div>
                {log.operStatus == "PASS" && (
                  <Alert
                    message={`${log.taskName}通过`}
                    description={log.operDescription}
                    type="success"
                    showIcon
                    style={{ marginBottom: 12 }}
                  />
                )}
                {log.operStatus == "REFUSE" && (
                  <Alert
                    message={
                      <span className="alert-process-refuse">{`${log.taskName}不通过`}</span>
                    }
                    description={
                      <span className="alert-process-desc">
                        {log.operDescription}
                      </span>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="close-circle"
                        style={{
                          fontSize: 18,
                          color: "#E5537B",
                          top: "18px",
                        }}
                      />
                    }
                    style={{ marginBottom: 12 }}
                  />
                )}
              </div>
            ) : null}

            <Form
              {...formItemLayout}
              className="process-form-readonly"
            >
              <Row className="wrap-block">
                <Alert
                  message={
                    <span className="alert-leave-message">
                      假期说明
                    </span>
                  }
                  description={
                    <div className="alert-leave-desc">
                      <div>
                        年假：转正后开始享有，根据当年度日历天数折算，余额结转至下一年度3月31日到期。
                      </div>
                      <div>
                        病假：必须上传医疗机构开具的有效病假单和病历本记录扫描件。
                      </div>
                      <div>
                        婚假：3天，入职后结婚登记的才享有，需在结婚登记日起一年内一次性休完，逾期未休的，视为自动放弃。须上传结婚证原件扫描件。
                      </div>
                      <div>
                        产检假：女员工妊娠期内，享受累计7个工作日的产前检查假，每月使用不超过2天，需上传文件。
                      </div>
                      <div>
                        产假：须上传出生证明或者住院证明原件扫描件。
                      </div>
                      <div>
                        陪产假：须上传结婚证、出生证明原件扫描件。
                      </div>
                    </div>
                  }
                  type="info"
                  showIcon
                  icon={
                    <Icon
                      type="info-circle"
                      style={{
                        fontSize: 18,
                        color: "#4D91FA",
                        left: "23px",
                        top: "23px",
                      }}
                    />
                  }
                  style={{
                    marginBottom: 30,
                    background: "#EAF2FF",
                    border: "1px solid rgba(199,221,255,1)",
                    padding: "20px 20px 10px 50px",
                  }}
                />
                <Row>
                  <Col span={8}>
                    <FormItem label="员工">
                      <span>
                        {staffDetail.staffName || "--"}
                      </span>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="工号">
                      <span>
                        {staffDetail.staffCode || "--"}
                      </span>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="部门名称">
                      <span>
                        {staffDetail.departmentName || "--"}
                      </span>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="岗位">
                      <span>
                        {staffDetail.postName || "--"}
                      </span>
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label="入职时间">
                      <span>
                        {staffDetail.entryTime
                          ? moment(
                              staffDetail.entryTime
                            ).format("YYYY/M/D")
                          : staffDetail.onboardingTime
                          ? staffDetail.onboardingTime
                          : "--"}
                      </span>
                    </FormItem>
                  </Col>
                  {showType == 2 && (
                    <Col span={8}>
                      <FormItem label="请假类型">
                        <span>
                          {this.state["LEAVE_TYPE"] &&
                            this.state["LEAVE_TYPE"].filter(
                              (item) =>
                                item.dicKey ==
                                leaveDetail.leaveType
                            ).length > 0 &&
                            this.state["LEAVE_TYPE"].filter(
                              (item) =>
                                item.dicKey ==
                                leaveDetail.leaveType
                            )[0].dicValue}
                        </span>
                      </FormItem>
                    </Col>
                  )}
                </Row>
                {showType == 1 && (
                  <div>
                    <Row>
                      <Col span={24}>
                        <FormItem
                          label="请假类型"
                          labelCol={{ span: 4 }}
                          wrapperCol={{ span: 20 }}
                          className="quit-form-item"
                        >
                          {getFieldDecorator("leaveType", {
                            initialValue:
                              leaveDetail.leaveType || "",
                            rules: [
                              {
                                required: true,
                                message: "请假类型必填",
                              },
                            ],
                          })(
                            <Radio.Group>
                              {this.state["LEAVE_TYPE"] &&
                                this.state[
                                  "LEAVE_TYPE"
                                ].map((item) => {
                                  return (
                                    <Radio
                                      key={item.dicKey}
                                      value={item.dicKey}
                                    >
                                      {item.dicValue}
                                    </Radio>
                                  );
                                })}
                            </Radio.Group>
                          )}
                          <span>
                            剩余年假{" "}
                            {this.state.isProbation
                              ? "0"
                              : this.state
                                  .remainingAnnualLeave
                              ? this.state
                                  .remainingAnnualLeave
                              : "0"}
                            天
                          </span>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="请假开始时间"
                          className="quit-form-item"
                        >
                          {getFieldDecorator(
                            "leaveStartTime",
                            {
                              initialValue: leaveDetail.leaveStartTime
                                ? moment(
                                    moment(
                                      leaveDetail.leaveStartTime
                                    ).format("YYYY/M/D"),
                                    "YYYY/M/D"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: "开始日期必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              // showTime={{
                              //   defaultValue: moment("09:00", "HH:mm"),
                              //   format: "HH:mm",
                              //   minuteStep: 30,
                              // }}
                              // disabledTime={this.disabledStartTime}
                              format="YYYY-MM-DD"
                              showToday={false}
                              disabledDate={
                                this.disabledStartDate
                              }
                              onChange={
                                this.startTimeChange
                              }
                              dropdownClassName="leave-datepicker"
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                              placeholder="开始日期"
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={4}>
                        <FormItem
                          label=""
                          className="quit-form-item"
                          wrapperCol={{ span: 16 }}
                        >
                          {getFieldDecorator("startTime", {
                            initialValue: leaveDetail.leaveStartTime
                              ? moment(
                                  leaveDetail.leaveStartTime
                                )
                                  .format(
                                    "YYYY-MM-DD HH:mm"
                                  )
                                  .split(" ")[1]
                              : undefined,
                            rules: [
                              {
                                required: true,
                                message: "开始时间必填",
                              },
                            ],
                          })(
                            <Select
                              allowClear
                              style={{ width: 120 }}
                              placeholder="开始时间"
                              onChange={this.startChange}
                            >
                              <Option value="09:00">
                                09:00
                              </Option>
                              <Option value="13:30">
                                13:30
                              </Option>
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="请假结束时间"
                          className="quit-form-item"
                        >
                          {getFieldDecorator(
                            "leaveEndTime",
                            {
                              initialValue: leaveDetail.leaveEndTime
                                ? moment(
                                    moment(
                                      leaveDetail.leaveEndTime
                                    ).format("YYYY/M/D"),
                                    "YYYY/M/D"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: "结束日期必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              // showTime={{
                              //   defaultValue: this.endTimeDefault(),
                              //   format: "HH:mm",
                              //   minuteStep: 60,
                              // }}
                              // disabledTime={this.disabledEndTime}
                              format="YYYY-MM-DD"
                              showToday={false}
                              disabledDate={
                                this.disabledEndDate
                              }
                              onChange={this.endTimeChange}
                              dropdownClassName="leave-datepicker"
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                              placeholder="结束日期"
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={4}>
                        <FormItem
                          label=""
                          className="quit-form-item"
                          wrapperCol={{ span: 16 }}
                        >
                          {getFieldDecorator("endTime", {
                            initialValue: leaveDetail.leaveEndTime
                              ? moment(
                                  leaveDetail.leaveEndTime
                                )
                                  .format(
                                    "YYYY-MM-DD HH:mm"
                                  )
                                  .split(" ")[1]
                              : undefined,
                            rules: [
                              {
                                required: true,
                                message: "结束时间必填",
                              },
                            ],
                          })(
                            <Select
                              allowClear
                              style={{ width: 120 }}
                              placeholder="结束时间"
                              onChange={this.calLeaveDays}
                            >
                              <Option
                                value="12:00"
                                disabled={
                                  this.state
                                    .selectDisabledEndTime
                                }
                              >
                                12:00
                              </Option>
                              <Option value="18:00">
                                18:00
                              </Option>
                            </Select>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={8}>
                        <FormItem
                          label="请假天数"
                          className="quit-form-item"
                        >
                          {/* {getFieldDecorator("leaveDays", {
                          initialValue: leaveDetail.leaveDays || "",
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "请假天数必填",
                            },
                            {
                              validator: this.validateDays,
                            },
                          ],
                        })(
                          <Input
                            placeholder="请输入"
                            allowClear
                            style={{ width: 100 }}
                          />
                        )} */}
                          <span>
                            {this.state.leaveDays || 0}天
                          </span>
                        </FormItem>
                      </Col>
                    </Row>
                  </div>
                )}
                {showType == 2 && (
                  <Row>
                    <Col span={8}>
                      <FormItem
                        label="请假开始时间"
                        className="quit-form-item"
                      >
                        <span>
                          {leaveDetail.leaveStartTime
                            ? moment(
                                leaveDetail.leaveStartTime
                              ).format("YYYY/M/D HH:mm:ss")
                            : "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="请假结束时间">
                        <span>
                          {leaveDetail.leaveEndTime
                            ? moment(
                                leaveDetail.leaveEndTime
                              ).format("YYYY/M/D HH:mm:ss")
                            : "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="请假天数">
                        <span>
                          {leaveDetail.leaveDays || "--"}天
                        </span>
                      </FormItem>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={16}>
                    {showType == 1 && (
                      <FormItem
                        label="请假原因"
                        labelCol={{ span: 6 }}
                      >
                        {getFieldDecorator("leaveReason", {
                          initialValue:
                            leaveDetail.leaveReason || "",
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "请假原因必填",
                            },
                            {
                              validator: this
                                .validateLength128,
                            },
                          ],
                        })(
                          <TextArea
                            rows={4}
                            maxLength={128}
                            placeholder="请输入"
                            allowClear
                          />
                        )}
                      </FormItem>
                    )}
                    {showType == 2 && (
                      <FormItem
                        label="请假原因"
                        labelCol={{ span: 6 }}
                      >
                        <span>
                          {leaveDetail.leaveReason || "--"}
                        </span>
                      </FormItem>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {showType == 1 && (
                      <FormItem
                        label="附件"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        required={fileRequired}
                      >
                        <Upload
                          name="file"
                          accept="image/jpg, image/jpeg, image/png"
                          fileList={fileList}
                          onChange={this.handleChange}
                          onRemove={this.handleRemove}
                          beforeUpload={this.beforeUpload}
                        >
                          <Button>
                            <Icon type="upload" /> 上传文件
                          </Button>
                        </Upload>
                      </FormItem>
                    )}
                    {showType == 2 && (
                      <FormItem
                        label="附件"
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                      >
                        <div className="file-item-wrap">
                          {fileList.map((item) => {
                            return (
                              <div
                                className="file-item-block"
                                onClick={() =>
                                  this.handlePreview(
                                    item.url
                                  )
                                }
                                key={item.url}
                              >
                                <div className="file-img-wrap">
                                  <img
                                    className="file-item-img"
                                    src={
                                      item.url
                                        ? item.url
                                        : require("../../../assets/image/default-picture.png")
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {/* <a
                          onClick={() => {
                            leaveDetail.attachmentKeyUrl &&
                              window.open(leaveDetail.attachmentKey);
                          }}
                        >
                          {leaveDetail.attachmentKey
                            ? leaveDetail.attachmentKey.substr(
                                leaveDetail.attachmentKey.indexOf("/") + 1
                              )
                            : "--"}
                        </a> */}
                      </FormItem>
                    )}
                  </Col>
                </Row>
              </Row>
              {showType == 2 ? (
                this.state.step2 || this.state.step3 ? (
                  <Row
                    className="wrap-block"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="block-title">
                      流程审核
                    </div>
                    <Row>
                      <FormItem
                        label="审核意见"
                        labelCol={{ span: 2 }}
                      >
                        {getFieldDecorator("auditOpinion", {
                          initialValue: "",
                          rules: [
                            {
                              validator: this
                                .validateLength128,
                            },
                          ],
                        })(
                          <TextArea
                            rows={4}
                            maxLength={128}
                            placeholder="请输入"
                            allowClear
                          />
                        )}
                      </FormItem>
                    </Row>
                  </Row>
                ) : null
              ) : null}
            </Form>
          </div>
          <div className="wrap-right">
            <AuditRecord data={logList || []} />
          </div>
        </div>
        <div className="footer-fixed-wrap">
          <div className="footer-fixed-btn">
            {showType == 1 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // if (getQueryVariable("leaveProcId")) {
                    //   window.close();
                    // }
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() =>
                    this.handleSubmit("submit")
                  }
                  disabled={this.state.submitDisabled}
                >
                  提交
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => this.handleSubmit("save")}
                  disabled={this.state.submitDisabled}
                >
                  保存
                </Button>
                {(leaveDetail.procStatus == "STORAGE" ||
                  leaveDetail.procStatus == "REFUSE") && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={this.handleDelete}
                  >
                    删除
                  </Button>
                )}
              </div>
            )}
            {showType == 2 ? (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // window.close();
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                {/* {this.state.showWithdraw && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      this.setState(
                        {
                          visible: true
                        },
                        () => {
                          this.refs.withdrawRef.handleShow();
                        }
                      );
                    }}
                  >
                    撤回
                  </Button>
                )} */}
                {this.state.step2 || this.state.step3 ? (
                  <span>
                    <Button
                      type="primary"
                      style={{ marginRight: 16 }}
                      onClick={() =>
                        this.handlePass("pass")
                      }
                      disabled={this.state.submitDisabled}
                    >
                      {this.state.showComplete
                        ? "归档"
                        : "通过"}
                    </Button>
                    <Button
                      type="danger"
                      style={{ marginRight: 16 }}
                      onClick={() =>
                        this.handlePass("refuse")
                      }
                    >
                      不通过
                    </Button>
                  </span>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        {this.state.visible && (
          <Withdraw
            ref="withdrawRef"
            form={this.props.form}
            data={{
              leaveProcId: leaveDetail.leaveProcId,
              taskId: leaveDetail.processData.taskId,
            }}
            url="leaveWithdraw"
            handleOk={this.handleWithdraw}
          />
        )}
        <Modal
          visible={previewVisible}
          footer={null}
          width="80%"
          onCancel={this.handleCancel}
          // className="leave-img-show"
        >
          <img
            style={{ width: "100%" }}
            src={previewUrl}
            alt=""
          />
        </Modal>
      </div>
    );
  }
}

export default withRouter(Form.create()(MainManagement));
