/**
 * @method 招聘专员
 */
import React from "react";
import { Select, Radio, Form } from "antd";
import { map } from "lodash";
const { Option } = Select;
class RecruitmentSpecialist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: [],
    };
  }

  componentDidMount() {
    this.queryRecruitmentSpecialist();
  }

  queryRecruitmentSpecialist = () => {
    this.$http.queryRecruitmentSpecialist().then((res) => {
      if (res && res.code === 200) {
        this.setState({
          status: res.data,
        });
      }
    });
  };

  onChange = (value) => {
    const { onChange } = this.props;
    onChange({
      staffIds: value,
    });
  };

  render() {
    const { status } = this.state;
    return (
      <Form.Item>
        <Select
          mode="multiple"
          placeholder="选择招聘专员"
          onChange={this.onChange}
          style={{ width: "300px" }}
          filterOption={(inputValue, option) => {
            if (option.props.children.includes(inputValue))
              return true;
          }}
        >
          {map(status, (i) => {
            const { staffId, staffName, staffCode } = i;
            return (
              <Option key={staffId} value={staffId}>
                {`${staffName}(${staffCode})`}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default RecruitmentSpecialist;
