import React, { Component } from "react";
import { Select, message, Divider, Button } from "antd";
import Process from "./component/technologicalProcess";
import ProcessForm from "./component/bottomForm";
import util from "../../../utils/utils";
import "./index.less";

const { Option } = Select;
class NodeConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      procDefKey: "",
      departmentId:
        util.getSearchString(
          "departmentId",
          window.location.search
        ) || this.props.location.state.departmentId,
      departmentName:
        util.getSearchString(
          "departmentName",
          window.location.search
        ) || this.props.location.state.departmentName,
      templateList: [], // 流程模板查询 -- 模板列表下拉框
      departmentList: [], // 部门查询列表
      checkedTemp: {}, // 选中的流程模板
      checkedProcessItem: {}, // 选中的流程模板节点
      configList: {}, // 模板信息配置
    };
  }
  componentDidMount() {
    this.queryTemplateList();
    this.querydepartmentId();
  }

  /**
   * 函数描述: 流程节点详情
   * @return {Object}
   */
  queryConfigDetail = (templateList) => {
    const { procDefKey, departmentId } = this.state;
    this.$http
      .queryConfigDetails({
        procDefKey,
        departmentId,
      })
      .then((res) => {
        if (res.code === 200 && res.data) {
          let checkedTemp = {};
          let checkedProcessItem = {};
          const configList = {};
          templateList.forEach((item) => {
            if (item.templateCode === res.data.procDefKey) {
              checkedTemp = item;
              checkedProcessItem = item.taskDefList[1];
            }
          });
          (res.data.configList || []).forEach(
            (list, index) => {
              configList[list.taskDefKey] = {
                taskDefKey: list.taskDefKey,
                procDefKey: list.procDefKey,
                copyEcho: list.copyEcho,
                assigneeEcho: list.assigneeEcho, // 处理人规则类型 -- STAFF-本人 SUPERIOR-直属上级
                groupId: list.groupId, // 处理人规则类型 -- STAFF-本人 SUPERIOR-直属上级
                assigneeId: {
                  key:
                    list.assigneeId +
                    "&" +
                    list.assigneeDepartmentId,
                  label: `${
                    list.assigneeDepartmentName
                  }(负责人：${
                    list.assigneeName == undefined
                      ? "未配置"
                      : list.assigneeName
                  })`,
                }, // 流程处理人规则 -- 选择自定义，指定部门负责人或者设置顶级部门代理人时填写
                assigneeName: list.assigneeName,
                copyToGroup: list.copyToGroup, // 选择抄送人类型 -- DEPT_HEAD-部门负责人 / '' -- 自定义
                copyToId: list.copyToId, // 选择抄送人类型选择自定义，抄送人编码 分号分隔
                //copyToList: list.copyToList, // 选择抄送人类型选择自定义，抄送人编码 分号分隔
                copyToIdList: list.copyToIdList,
                copyToName:
                  list.copyToList.length > 0
                    ? list.copyToList[0].copyToName
                    : "", // 选择抄送人类型选择自定义，抄送人编码 分号分隔
                copyNameIdList: list.copyToList,
              };
            }
          );
          this.setState({
            templateList,
            checkedTemp,
            checkedProcessItem,
            configList,
          });
        }
      });
  };
  /**
   * 函数描述: 流程模板查询
   * @return {Object}
   */
  queryTemplateList = () => {
    this.$http.queryTemplate().then((res) => {
      if (res.code == 200) {
        const templateList = res.data.templateList;
        if (this.props.location.state.procDefKey) {
          // 修改
          this.setState(
            {
              procDefKey: this.props.location.state
                .procDefKey,
            },
            () => {
              this.queryConfigDetail(templateList);
            }
          );
        } else {
          this.setState({
            templateList: templateList,
            checkedTemp:
              templateList.length > 0
                ? templateList[0]
                : {},
            checkedProcessItem:
              templateList.length > 0
                ? templateList[0].taskDefList[1]
                : {},
          });
        }
      }
    });
  };
  /**
   * 函数描述: 查询部门名称
   * @param {string} type   类型（1：项目 2：部门）
   */
  querydepartmentId = () => {
    this.$http["departmentListByParent"]({}).then((res) => {
      if (res.code === 200) {
        this.setState({
          departmentList: res.data || [],
        });
      }
    });
  };
  handleChange = (value) => {
    const { templateList } = this.state;
    templateList.forEach((item) => {
      if (item.templateCode === value) {
        this.setState(
          {
            checkedTemp: item,
            checkedProcessItem: item.taskDefList[1],
            configList: {},
          },
          () => {
            this.processForm.handleReset();
          }
        );
      }
    });
  };
  /**
   * 切换流程模板-- 校验数据
   */
  togglePiece = (item) => {
    this.processForm.handleAddSure((value) => {
      const { configList, checkedProcessItem } = this.state;
      configList[checkedProcessItem.dicValue] = {
        taskDefKey: checkedProcessItem.dicValue,
        procDefKey: checkedProcessItem.dicKey,
        ...value,
        // groupId: '',		// 处理人规则类型 -- STAFF-本人 SUPERIOR-直属上级
        // assigneeId: '', 	// 流程处理人规则 -- 选择自定义，指定部门负责人或者设置顶级部门代理人时填写
        // copyToGroup: '', 	// 选择抄送人类型 -- DEPT_HEAD-部门负责人 / '' -- 自定义
        // copyToId:'',   	// 选择抄送人类型选择自定义，抄送人编码 分号分隔
      };

      if (!configList[item.dicValue]) {
        configList[item.dicValue] = {};
        this.processForm.handleReset();
      } else {
        const { copyNameIdList } = configList[
          item.dicValue
        ];
        if (
          copyNameIdList &&
          Array.isArray(copyNameIdList) &&
          copyNameIdList.length > 0
        ) {
          const copyId = copyNameIdList.map((o) => {
            return o.staffId;
          });
          this.processForm.handleReset(copyId);
        } else {
          this.processForm.handleReset();
        }
      }

      this.setState({
        configList,
        checkedProcessItem: item,
      });
    });
  };
  // 流程模版改变
  handleProcessTmpChange = (val) => {
    const { processData } = this.state;
    let _activeProcessTemp = processData.filter(
      (v) => v.key === val
    )[0];
    this.setState({
      activeProcessTemp: _activeProcessTemp,
      activeFigure: _activeProcessTemp.figure[1],
      configList: {},
    });
    // console.log(_activeProcessTemp.figure[0],'111')
  };
  handleSureConfig = () => {
    // 先确认表单校验是否通过
    this.processForm.handleAddSure((value) => {
      const {
        configList,
        checkedProcessItem,
        checkedTemp,
        departmentId,
        procDefKey,
      } = this.state;
      configList[checkedProcessItem.dicValue] = {
        taskDefKey: checkedProcessItem.dicValue,
        procDefKey: checkedProcessItem.dicKey,
        ...value,
        // groupId: '',		// 处理人规则类型 -- STAFF-本人 SUPERIOR-直属上级
        // assigneeId: '', 	// 流程处理人规则 -- 选择自定义，指定部门负责人或者设置顶级部门代理人时填写
        // copyToGroup: '', 	// 选择抄送人类型 -- DEPT_HEAD-部门负责人 / '' -- 自定义
        // copyToId:'',   	// 选择抄送人类型选择自定义，抄送人编码 分号分隔
      };
      const params = {
        procDefKey: checkedTemp.templateCode, // 选择的流程编码
        departmentId, // 所属部门
        procType: "PERSONNEL", // 流程类型 -- 确认是否写死PERSONNEL--人事
        configList: [], // 配置类集合
      };
      console.log("configList-->", configList);
      params.configList = Object.keys(configList).map(
        (item) => configList[item]
      );
      params.configList.map((v) => {
        console.log(v);
        // if(v.assigneeEcho=='DEPT_HEAD'){
        if (
          Object.prototype.toString.call(v.assigneeId) ===
          "[object Object]"
        ) {
          let showId = v.assigneeId.key;
          let arr =
            v.assigneeId == ""
              ? []
              : v.assigneeId.key == undefined
              ? []
              : v.assigneeId.key.split("&");
          let showName = v.assigneeId.label;
          v.assigneeId =
            arr[0] == "undefined" ? "" : arr[0];
          v.assigneeDepartmentId = arr[1];
          v.showName = showName;
          v.showId = showId;
        } else {
          v.showName = v.assigneeName;
          v.showId = v.assigneeId;
        }
        // }
      });
      if (procDefKey) {
        this.$http.updateProcConfig(params).then((res) => {
          if (res.code === 200) {
            this.props.history.go(-1);
          } else {
            params.configList.map((v) => {
              console.log(v);
              v.assigneeId = {
                key: v.showId,
                label: v.showName,
              };
            });
          }
        });
      } else {
        this.$http.addProcConfig(params).then((res) => {
          if (res.code === 200) {
            this.props.history.go(-1);
          } else {
            params.configList.map((v) => {
              console.log(v);
              v.assigneeId = {
                key: v.showId,
                label: v.showName,
              };
            });
          }
        });
      }
    });
  };
  // 确认提交

  render() {
    const {
      departmentName,
      templateList,
      checkedTemp,
      checkedProcessItem,
      departmentList,
      configList,
      procDefKey,
    } = this.state;
    return (
      <div className="node-config-wrapper">
        <div className="crumbs">
          流程-节点配置-{departmentName}部门
        </div>
        <div className="contract-wrap wrap-card">
          {/* 头部流程模板选择 */}
          <div className="top-select flex flex-align-center">
            <div className="select">
              <span className="selectLable">流程模板</span>
              <Select
                placeholder="请选择"
                onChange={this.handleChange}
                value={checkedTemp.templateCode}
                disabled={procDefKey !== ""}
              >
                {templateList.map((item) => {
                  return (
                    <Option
                      key={item.templateCode}
                      value={item.templateCode}
                    >
                      {item.templateName}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className="right-btn">
              <Button
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 14 }}
                onClick={this.handleSureConfig}
              >
                确定
              </Button>
            </div>
          </div>
          <Divider />
          {/* 流程图 */}
          <div className="bottom-process">
            <Process
              processList={checkedTemp.taskDefList}
              checkedProcessItem={checkedProcessItem}
              configList={configList}
              togglePiece={this.togglePiece}
            />
            <ProcessForm
              wrappedComponentRef={(form) =>
                (this.processForm = form)
              }
              checkedProcessItem={checkedProcessItem}
              configItem={
                configList[checkedProcessItem.dicValue] ||
                {}
              }
              departmentList={departmentList}
              querydepartmentId={this.querydepartmentId}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default NodeConfig;
