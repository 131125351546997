/**
 * @description 空状态
 */

import React from "react";
import { Empty } from "antd";
import EmptyImg from "../../../../../assets/image/empty.png";
import "./index.less";
class EmptyPage extends React.Component {
  render() {
    return (
      <div className="empty">
        <Empty
          image={EmptyImg}
          description={
            "请选择一级维度和二级维度，点击【查询】查看报表"
          }
          imageStyle={{
            width: 256,
            height: 185,
            textAlign: "center",
            width: "100%",
            paddingTop: 60,
          }}
        />
      </div>
    );
  }
}

export default EmptyPage;
