/**
* @fileoverview 系统管理---账号设置 -- 数据列表
* @author  蒋西园
*/
import React, { Component } from 'react';
import { Table, Modal,Icon } from 'antd';
import './index.less'
import moment from 'moment'
const { confirm } = Modal;

class tableList extends Component {
    constructor(props) {
        super(props)
        this.columns = [{
                title: '年份',
                dataIndex: 'holidayYear',
                render: (text) => text ? text : '--'
            },{
                title: '日期',
                dataIndex: 'holidayDay',
                render: (text) => text ? text : '--'
            },{
                title: '类型',
                dataIndex: 'holidayTypeDesc',
            },{
                title: '备注',
                dataIndex: 'remarks',
                render: (text) => text ? text : '--'
            },{
                title: '操作',
                render: (record) => {
                    return (
                        <span style={{display:moment().endOf('month').format('X')>moment(record.holidayDay).format('X')?'none':''}}>
                           <span onClick={this.handleProhibit.bind(this,record)} style={{ color: "#4D91FA", cursor: 'pointer' }}>删除</span>
                           <span onClick={() => this.props.handleEditSubmit(record)} style={{ marginLeft: "16px", color: "#4D91FA", cursor: 'pointer' }}>编辑</span>
                        </span>
                    )
                }
            },
        ]
    }
   /**
    * 函数描述: 禁用按钮事件
    * @param {object} record 该账号所有信息 -- 后期要精简参数更换为唯一标识
    * @return {Object} 二次确认后统一返回父组件处理请求
    */
    handleProhibit = (record) => {
        confirm({
            title: '您确定删除该假期吗？',
            icon:<Icon type="question-circle" style={{color:'#4D91FA'}} />,
            onOk: () => {
                this.props.handleProhibit(record);
            }
        });
        
    }
    render() {
        return (
            <Table
                rowKey={(record) => { return record.staffId }}
                loading={this.props.loading}
                columns={this.columns} 
                dataSource={this.props.data}
                locale={{
                    emptyText: (
                      <div>
                        <img
                          style={{ width: 182, height: 94 }}
                          src={require("../../../../../assets/image/no-data.png")}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: 14,
                            color: "#717E99",
                            lineHeight: "20px"
                          }}
                        >
                          暂无数据
                        </p>
                      </div>
                    )
                  }}
                pagination={{
                    hideOnSinglePage: true,
                    current: this.props.pageNo,
                    total: this.props.total,
                    pageSize: this.props.pageSize || 10,
                }}
                onChange={(pageNo, {}, sorter) => this.props.handleChangePage(pageNo, sorter)}
            />
        )
    }
}
export default tableList