/**
 * @fileoverview 人事管理---入职名单
 * @author  黄洪月
 */
import React, { Component } from "react";
import "./index.less";
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      total: 0, // 档案总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      sorter: "", // 排序方式 ASC： 正序 DESC: 倒叙
      searchVale: {departmentIds: []}, // 搜索条件
      dataList: [],   // 列表数据
    };
  }
  componentDidMount() {
    
    if (this.props.history.location.searchVale) {
      this.setState({ searchVale: this.props.history.location.searchVale }, () => {
        this.queryTableList(this.state.searchVale);
      })
    } else {
      this.queryTableList();
    }
  }

  /**
   * 函数描述: 列表数据请求
   */
  queryTableList = (params) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this.$http
          .entrantList(
            params? !params.pageNo?{...params, pageNo: 1,pageSize: 10}:params: {
                  departmentIds: [],
                  pageNo: 1,
                  pageSize: 10,
                }
          )
          .then((data) => {
            if (data.code === 200) {
              this.setState({
                dataList: data.data.list || [],
                total: data.data.total,
                loading: false,
              });
              
            } else {
              this.setState({
                loading: false,
                dataList: [],
                total: 0,
              });
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
          });
      }
    );
  };

  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList(value);
      }
    );
  };

  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (page, sorter) => {
    let sorterOrder = "";
    if (sorter.order) {
      sorterOrder =
        sorter.order === "ascend" ? "asc" : "desc";
    }
    this.setState(
      {
        sorter: sorterOrder,
        pageNo:page
      },
      () => {
        this.queryTableList({...this.state.searchVale,pageNo:page,pageSize:sorter});
      }
    );
  };
  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
      searchVale,
    } = this.state;
    return (
      <div className="salar_file">
        <div className="salar-roster">
          <div className="crumbs">入职名单</div>
          <SearchForm
            handleSearchBtn={this.handleSearchBtn}
            searchVale={searchVale}
          />
          <div className="salar-box wrap-card">
            <Table
              loading={loading}
              history={this.props.history}
              data={dataList}
              total={total}
              pageNo={pageNo}
              pageSize={pageSize}
              handleChangePage={this.handleChangePage} // 切换页码
              searchVale={searchVale}
            />
          </div>
        </div>
      </div>
    );
  }
}
