/**
 * @description 薪资档案-编辑
 * @author zxz
 */
import React from "react";
import {
  Col,
  Modal,
  Row,
  Form,
  InputNumber,
  Select,
  Input,
  Spin,
  Button,
  message,
} from "antd";
import utils from "../../../../../utils/utils";
import { compact } from "lodash";
import "./index.less";

const { Option } = Select;

const selectAfter = (
  <Select defaultValue="1">
    <Option value="1">元</Option>
    <Option value="2">元/人天</Option>
  </Select>
);
const { limitDecimals, validateMinMaxText } = utils;
const SALARY_TYPE = {
  1: "日薪",
  2: "月薪",
};
const inputMoneyProps = {
  min: 0,
  max: 999999.99,
  precision: 2,
  formatter: limitDecimals,
  parser: limitDecimals,
  placeholder: "请输入",
};
const interestRateProps = {
  min: -999999.99,
  max: 999999.99,
  precision: 2,
  // formatter: limitDecimals,
  // parser: limitDecimals,
  placeholder: "请输入",
};
const CONTRACT_TYPE = {
  LABOR: "劳动合同",
  INTERNSHIP: "实习合同",
  SERVICE: "劳务合同",
};

class EditButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      isloading: true,
      info: {},
    };
  }

  closeModal = () => {
    this.setState({ visible: false });
  };

  openModal = () => {
    this.setState({ visible: true });
    this.request(this.props.id);
  };

  handleOk = () => {
    this.props.form.validateFields((errors, values) => {
      if (!errors) {
        const {
          staffId,
          salaryType,
          contractType,
        } = this.state.info;
        const {
          turnSalary,
          probationSalary = "",
          clientRaise = "",
          companyRaise = "",
          grossMargin = "",
          outBonusGrossMargin = "",
          socialGrossMargin = "",
          rate = "",
          salaryType: notLaborSalaryType,
        } = values;
        let params = {
          staffId,
          salaryType:
            contractType == "LABOR"
              ? salaryType
              : notLaborSalaryType,
          turnSalary,
          probationSalary,
          clientRaise,
          companyRaise,
          totalTurn:
            contractType == "LABOR"
              ? this.state.totalTurnState
              : "",
          grossMargin,
          outBonusGrossMargin,
          socialGrossMargin,
          rate,
        };

        this.$http
          .saveSalaryFileEdit(params)
          .then((res) => {
            console.log("编辑参数 -》", res);
            if (res.data) {
              message.success("编辑成功");
            } else {
              message.warning("编辑失败");
            }
            this.setState({ visible: false });
            this.props.refreshList();
          });
      }
    });
  };

  request = (id) => {
    console.log("id -->", id);
    this.$http
      .getSalaryFileDetails({ staffId: id })
      .then((res) => {
        console.log("edit 详情->", res);
        if (res.code == 200) {
          this.setState(
            {
              info: res.data,
            },
            () => {
              this.computeTotalPositive();
            }
          );
        } else {
          message.warning(res.message);
        }
        this.setState({ isloading: false });
      });
  };

  /**
   * @method 计算合计转正
   */
  computeTotalPositive = () => {
    const { getFieldsValue } = this.props.form;
    const {
      turnSalary,
      companyRaise,
      clientRaise,
    } = getFieldsValue();
    const computeArr = [
      Number(turnSalary),
      Number(companyRaise),
      Number(clientRaise),
    ];
    const compactComputeArr = compact(computeArr);
    const sum =
      compactComputeArr.length &&
      compactComputeArr.reduce((a, b) => a + b);
    this.setState({ totalTurnState: sum });
  };

  validatorMoney = (rule, value, callback) => {
    const reg = /^-?[0-9]+(.[0-9]{1,2})?$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback("小于999999.99 ，两位小数");
    } else {
      if (Number(value.trim()) > 999999.99) {
        callback("小于999999.99 ，两位小数");
      } else {
        callback();
      }
    }
  };
  /**
   * 劳动合同
   */
  renderLaborContract = () => {
    const { info, totalTurnState } = this.state;
    const {
      turnSalary,
      probationSalary,
      companyRaise,
      clientRaise,
      grossMargin,
      outBonusGrossMargin,
      socialGrossMargin,
      rate,
    } = info;
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="正式薪资：">
              {getFieldDecorator("turnSalary", {
                rules: [
                  {
                    required: true,
                    message: "请填写正式薪资",
                  },
                ],
                initialValue: turnSalary,
              })(
                <InputNumber
                  {...inputMoneyProps}
                  onBlur={() => this.computeTotalPositive()}
                />
              )}
              <span className="unit">元</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="试用期薪资：">
              {getFieldDecorator("probationSalary", {
                rules: [
                  {
                    required: true,
                    message: "请填写试用期薪资",
                  },
                ],
                initialValue: probationSalary,
              })(<InputNumber {...inputMoneyProps} />)}
              <span className="unit">元</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="公司加薪：">
              {getFieldDecorator("companyRaise", {
                rules: [
                  {
                    required: true,
                    message: "请填写公司加薪",
                  },
                ],
                initialValue: companyRaise,
              })(
                <InputNumber
                  {...inputMoneyProps}
                  onBlur={() => this.computeTotalPositive()}
                />
              )}
              <span className="unit">元</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="客户认可的加薪：">
              {getFieldDecorator("clientRaise", {
                rules: [
                  {
                    required: true,
                    message: "请填写客户认可的加薪",
                  },
                ],
                initialValue: clientRaise,
              })(
                <InputNumber
                  {...inputMoneyProps}
                  onBlur={() => this.computeTotalPositive()}
                />
              )}
              <span className="unit">元</span>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="合计转正：">
              <span>￥{totalTurnState}</span>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Rate：">
              {getFieldDecorator("rate", {
                initialValue: rate,
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Rate必填",
                  },
                  {
                    validator: this.validatorMoney,
                  },
                ],
              })(<Input addonAfter={selectAfter} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label=" 当前毛利率(%)：">
              {getFieldDecorator("grossMargin", {
                rules: [
                  {
                    required: true,
                    message: "请填写当前毛利率",
                  },
                ],
                initialValue: grossMargin,
              })(<InputNumber {...interestRateProps} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="不加年终奖毛利率(%)：">
              {getFieldDecorator("outBonusGrossMargin", {
                rules: [
                  {
                    required: true,
                    message: "请填写不加年终奖毛利率",
                  },
                ],
                initialValue: outBonusGrossMargin,
              })(<InputNumber {...interestRateProps} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="全额社保毛利率(%)：">
              {getFieldDecorator("socialGrossMargin", {
                rules: [
                  {
                    required: true,
                    message: "请填写全额社保毛利率",
                  },
                ],
                initialValue: socialGrossMargin,
              })(<InputNumber {...interestRateProps} />)}
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * 劳务合同或实习合同
   */
  renderServiceContract = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="薪资：">
            {getFieldDecorator("turnSalary", {
              rules: [
                {
                  required: true,
                  message: "请填写薪资",
                },
              ],
              initialValue: this.state.info.turnSalary,
            })(<InputNumber {...inputMoneyProps} />)}
            <span className="unit">元</span>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  /**
   * 编辑薪资档案
   */
  editSalaryFileRender() {
    const { visible, info, isloading } = this.state;
    const {
      contractType,
      staffName,
      staffCode,
      departmentName,
      positionName,
      salaryType,
    } = info;
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title={"编辑薪资档案"}
        onCancel={this.closeModal}
        visible={visible}
        okText={"保存"}
        onOk={this.handleOk}
        width={720}
      >
        <Spin spinning={isloading}>
          <Form
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="员工：">
                  <span>{staffName}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="工号：">
                  <span>{staffCode}</span>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="部门名称：">
                  <span>{departmentName}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="岗位：">
                  <span>{positionName}</span>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="合同类型：">
                  <span>{CONTRACT_TYPE[contractType]}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="薪资类型：">
                  {contractType === "LABOR" ? (
                    <span>
                      {SALARY_TYPE[salaryType]
                        ? SALARY_TYPE[salaryType]
                        : "--"}
                    </span>
                  ) : (
                    getFieldDecorator("salaryType", {
                      rules: [
                        {
                          required: true,
                          message: "请选择薪资类型",
                        },
                      ],
                      initialValue: "0",
                    })(
                      <Select allowClear>
                        <Option value={"0"}>月薪</Option>
                        <Option value={"1"}>日薪</Option>
                      </Select>
                    )
                  )}
                </Form.Item>
              </Col>
            </Row>
            {contractType === "LABOR"
              ? this.renderLaborContract()
              : this.renderServiceContract()}
          </Form>
        </Spin>
      </Modal>
    );
  }

  render() {
    return (
      <>
        {this.editSalaryFileRender()}
        <Button
          type="link"
          onClick={() => this.openModal()}
          style={{ padding: 0 }}
        >
          编辑
        </Button>
      </>
    );
  }
}

export default Form.create({ withRef: true })(EditButton);
