import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import {  Form, Input, Table,  message, Icon, Select } from 'antd'
import { date } from './dataConfig'
import './index.less'
import Api from '../../../../../api/apis'
import editPersonal from './../../../../../assets/image/edit-personal.png'
const { Search } = Input;
const { Option } = Select
// const baseUrl = process.env.API_URL ? process.env.API_URL : '';
class MainManagement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataSource: [],
            loadingTable: false,
            loading: false,
            dataList: [],     	// 工资设置日列表数据
            total: 0,       	// 工资设置日总数
            pageSize: 10,     	// 分页Size
            pageNo: 1,        	// 分页页码
			searchVale: '',   	// 搜索条件
            selectValue: '',    // 修改后的日期
        }
        //分页基础配置
        this.pagination = {//分页
            current: 1,
            pageSize: 10,
            total: 0,
            defaultCurrent: 1,
            pageSizeOptions: ['10', '20', '50'],
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: (total, range) => <span style={{ color: '#c5ccd5' }}>共<span style={{ color: '#c5ccd5' }}> {total}</span> 条记录</span>,
            onChange: (current, pageSize) => {
                this.paginationChange(current, pageSize);
            },
            onShowSizeChange: (current, pageSize) => {
                this.paginationChange(1, pageSize);
            }
        }
        //公共样式
        this.publicStyle = {
            // height: 32,
            paddingLeft: 20,
            paddingRight: 20,
            display: "inline-block",
            lineHeight: "32px",
            borderRadius: 18,
        }
        //成功样式
        this.successStyle = {
            color: "#4D91FA",
            background: "rgba(0,208,215,0.13)",
        }
        //失败样式
        this.errorStyle = {
            color: "#E77A39",
            background: "rgba(231,122,57,0.13)",
        }

    }
    componentDidMount() {
        // this.handleSearchList()
        this.queryTableList();
    }
    /**
     * 函数描述: 查询工资设置日列表
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} departmentName   部门名称
     * @return {Object} 
     */
    queryTableList = (pageNo=1, pageSize=10) =>{
		this.setState({
			loading: true
		},() => {
			const params = {
				departmentName: this.state.searchVale,
				page:pageNo,
				pageSize
			}
			this.$http.pageSalarySendDay(params).then((res) => {
				if(res.code == 200){
                    const list = res.data.list.map(item => {return {...item,editor: false,selectValue: item.salarySendDay}});
					this.setState({
						loading: false,
						pageNo,
						pageSize,
						dataList: list,
						total: res.data.total
					})
				}
			}).catch((err) => {
				this.setState({
					loading: false,
				})
			})
		})
	}	
    	
    /**
     * 函数描述: 高级搜索
     * @param {Object} value   高级搜索条件
     * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
     */
    handleSearchBtn = (value) =>{
        this.setState({
            searchVale: value
        },() => {
            this.queryTableList();
        })
	}
    /**
     * 函数描述: 切换列表页码
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @return {Object} 请求pageNo页列表数据
     */
    handleChangePage = (page,pageSize) => {
		this.queryTableList(page,pageSize);
	}
    handleEdtor = (i) => {
        const { dataList } = this.state
        dataList[i].editor = !dataList[i].editor
        dataList[i].selectValue = dataList[i].salarySendDay
        this.setState({
            dataList
        })
    }
    handleChange = (value,i) => {
        console.log(value)
        const { dataList } = this.state
        dataList[i].selectValue = value
        this.setState({
            dataList
        })
    }
    /**
     * 函数描述: 修改薪资发放
     * @param {string} departmentId  部门id
     * @param {string} salarySendDay 薪资单发放日
     * @return {Object} 请求pageNo页列表数据
     */
    updateSalarySendDay = (departmentId, salarySendDay) => {
        const params = {
            departmentId,
            salarySendDay
        }
        this.$http.updateSalarySendDay(params).then(res => {
            if (res.code === 200) {
                const { pageNo, pageSize } = this.state;
				this.queryTableList(pageNo,pageSize);
            }
        })
    }
    render() {
        
        const columns = [
            {
                title: '部门',
                dataIndex: 'departmentName',
                width:"40%"
            },
            {
                title: '工资单发放日设置',
                dataIndex: 'salarySendDay',
                width:"50%",
                render: (t, r, i) => {
                    if (r.editor) {
                        return <span>
                            <span className="wagePayTable-span width30">每月</span>
                            <Select
                                placeholder="请选择"
                                style={{width:100}}
                                defaultValue={r.selectValue}
                                onChange={(value) => this.handleChange(value,i)}
                            >
                                {date.map(item => (
                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                ))}
                            </Select>
                            <Icon type="close-circle" className="wagePayTableIcon1" onClick={this.handleEdtor.bind(this,i)}/>
                            <Icon type="check-circle" className="wagePayTableIcon2" onClick={this.updateSalarySendDay.bind(this,r.departmentId,r.selectValue)}/>
                        </span>
                    }else {
                        return (
                            <span>
                                <span className="wagePayTable-span">每月{t}号</span>
							    <img className="wagePayTable-edit" onClick={this.handleEdtor.bind(this,i)} src={editPersonal}></img>
                            </span>
                        )
                    }

                }
            },

        ]

        const { dataSource, loadingTable, 
        dataList, loading, pageNo, pageSize, 
        total } = this.state
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className='waitTest-title'>
                    <div className='waitTest-search'>
                        <Form>
                            <Form.Item>
                                {getFieldDecorator('departmentName', {
                                })(
                                    <Search
                                        placeholder="项目/部门"
                                        style={{ width: 200 }}
                                        onSearch={(value) => { this.handleSearchBtn(value) }}
                                    />
                                )}
                            </Form.Item>
                        </Form>

                    </div>
                </div>
                <Table 
                    rowKey={(record) => record.departmentId} 
                    loading={loading}
                    columns={columns} 
                    dataSource={dataList} 
                    locale={{
                        emptyText: (
                          <div>
                            <img
                              style={{ width: 182, height: 94 }}
                              src={require("../../../../../assets/image/no-data.png")}
                              alt=""
                            />
                            <p
                              style={{
                                fontSize: 14,
                                color: "#717E99",
                                lineHeight: "20px"
                              }}
                            >
                              暂无数据
                            </p>
                          </div>
                        )
                      }}
                    pagination={{
                        hideOnSinglePage: true,
                        current: pageNo,
                        total: total,
                        pageSize: pageSize || 10,
                        onChange: (pageNo, pageSize) => this.handleChangePage(pageNo, pageSize)
                    }}
                />
            </div>
        )
    }
}


export default withRouter(Form.create()(MainManagement))