/**
 * @description 员工分析报表
 * @author zxz
 */
import React from "react";
import { Select, Form, Button, Table, message } from "antd";
import { map } from "lodash";
import EmptyPage from "./components/empty";
import FirstLevelSelect from "./components/firstLevelSelect";
import TwoStageSelect from "./components/twoStageSelect";
import Card from "./components/card";
import { forEach } from "lodash";
import ExportBtn from "../../../components/exportBtn";
import {
  deOrArrivalTag,
  deOrReTag,
  deOrDepartureTag,
  deOrLevelTag,
  cityOrArrivalTag,
  cityOrReTag,
  cityOrDepartureTag,
  cityOrLevelTag,
  statusOrArrivalTag,
  statusOrReTag,
  statusOrLevelTag,
  statusOrDepartureTag,
  deOrArrivalColumns,
  deOrReColumns,
  deOrDepartureColumns,
  deOrLevelColumns,
  cityOrArrivalColumns,
  cityOrReColumns,
  cityOrDepartureColumns,
  cityOrLevelColumns,
  statusOrArrivalColumns,
  statusOrReColumns,
  statusOrDepartureColumns,
  statusOrLevelColumns,
} from "./data";
import "./index.less";

const { Option } = Select;
const FIRST_LEVEL = [
  {
    label: "部门",
    value: "department",
    key: "department",
  },
  {
    label: "城市",
    value: "city",
    key: "city",
  },
  {
    label: "人员状态",
    value: "personnelStatus",
    key: "personnelStatus",
  },
];
const TWO_DEGREES = [
  {
    label: "到岗月份",
    value: "monthOfArrival",
    key: "monthOfArrival",
    disabled: false,
  },
  {
    label: "招聘专员",
    value: "recruitmentSpecialist",
    key: "recruitmentSpecialist",
    disabled: false,
  },
  {
    label: "离职月份",
    value: "monthOfDeparture",
    key: "monthOfDeparture",
    disabled: true,
  },
  {
    label: "级别",
    value: "level",
    key: "level",
    disabled: false,
  },
];

const ARR = {
  // 部门
  department: {
    // 到岗月份
    monthOfArrival: {
      tag: deOrArrivalTag,
      columns: deOrArrivalColumns,
      dataSource: [],
      path: "reportForms/deptAndOnboarding",
      exportUrl: "reportForms/export",
    },
    // 招聘专员
    recruitmentSpecialist: {
      tag: deOrReTag,
      columns: deOrReColumns,
      dataSource: [],
      path: "reportForms/deptAndOnboarding",
      exportUrl: "reportForms/export",
    },
    //离职月份
    monthOfDeparture: {
      tag: deOrDepartureTag,
      columns: deOrDepartureColumns,
      dataSource: [],
      path: "reportForms/deptAndOnboarding",
      exportUrl: "reportForms/export",
    },
    //级别
    level: {
      tag: deOrLevelTag,
      columns: deOrLevelColumns,
      dataSource: [],
      path: "reportForms/deptAndOnboarding",
      exportUrl: "reportForms/export",
    },
  },
  //城市
  city: {
    //到岗月份
    monthOfArrival: {
      tag: cityOrArrivalTag,
      columns: cityOrArrivalColumns,
      dataSource: [],
      path: "report/city",
      exportUrl: "report/exportCity",
      type: 5,
    },
    //招聘专员
    recruitmentSpecialist: {
      tag: cityOrReTag,
      columns: cityOrReColumns,
      dataSource: [],
      path: "report/city",
      exportUrl: "report/exportCity",
      type: 6,
    },
    //离职月份
    monthOfDeparture: {
      tag: cityOrDepartureTag,
      columns: cityOrDepartureColumns,
      dataSource: [],
      path: "report/city",
      exportUrl: "report/exportCity",
      type: 7,
    },
    //级别
    level: {
      tag: cityOrLevelTag,
      columns: cityOrLevelColumns,
      dataSource: [],
      path: "report/city",
      exportUrl: "report/exportCity",
      type: 8,
    },
  },
  //人员状态
  personnelStatus: {
    //到岗月份
    monthOfArrival: {
      tag: statusOrArrivalTag,
      columns: statusOrArrivalColumns,
      dataSource: [],
      path: "report/status",
      exportUrl: "report/exportStatus",
      type: 9,
    },
    //招聘专员
    recruitmentSpecialist: {
      tag: statusOrReTag,
      columns: statusOrReColumns,
      dataSource: [],
      path: "report/status",
      exportUrl: "report/exportStatus",
      type: 10,
    },
    //离职月份
    monthOfDeparture: {
      tag: statusOrDepartureTag,
      columns: statusOrDepartureColumns,
      dataSource: [],
      path: "report/status",
      exportUrl: "report/exportStatus",
      type: 11,
    },
    //级别
    level: {
      tag: statusOrLevelTag,
      columns: statusOrLevelColumns,
      dataSource: [],
      path: "report/status",
      exportUrl: "report/exportStatus",
      type: 12,
    },
  },
};
class EmployeeAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLevelDataSource: FIRST_LEVEL,
      twoDegreesDataSource: TWO_DEGREES,
      curFirstLevel: "", // 当前所选一级维度
      curTwoDegrees: "", // 当前所选二级维度
      departmentValue: "", // 当前所选部门
      tableData: ARR,
      columns: null,
      dataSource: [],
      cardData: [],
      loading: false,
      searchValue1: {},
      searchValue2: {},
      path: "",
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      exportUrl: "",
      exportParams: {},
      twoValue: null,
      isShowTable: false,
    };
  }

  /**
   * @method 选择部门
   */
  selectedDepartment = (values) => {
    console.log("values ->", values);
    this.setState({ departmentValue: values });
  };

  /**
   * @method 设置当前第一维度
   */
  setCurFirstLevel = (value) => {
    const {
      curTwoDegrees,
      twoDegreesDataSource,
      searchValue1,
    } = this.state;
    if (value !== "personnelStatus") {
      twoDegreesDataSource.forEach((o) => {
        o.disabled = false;
      });
    } else {
      twoDegreesDataSource.forEach((o, i) => {
        o.disabled = i === 2 ? true : false;
      });
    }
    if (value === "city") {
      delete searchValue1.status;
      delete searchValue1.departments;
    } else if (value === "department") {
      delete searchValue1.cityIds;
      delete searchValue1.status;
    } else {
      delete searchValue1.departments;
      delete searchValue1.cityIds;
      searchValue1.status = "0";
    }
    delete searchValue1.type;
    this.setState({
      curFirstLevel: value,

      exportUrl:
        value && curTwoDegrees
          ? ARR[value][curTwoDegrees].exportUrl
          : "",
      twoValue: "",
      curTwoDegrees: "",
      isShowTable: false,
      searchValue2: {},
      searchValue1,
      path:
        value && curTwoDegrees
          ? ARR[value][curTwoDegrees].path
          : "",
      columns:
        value && curTwoDegrees
          ? ARR[value][curTwoDegrees].columns
          : null,
      cardData:
        value && curTwoDegrees
          ? ARR[value][curTwoDegrees].tag
          : [],
    });
  };

  /**
   * @method 设置当前第二维度
   */
  setCurTwoDegrees = (value, node) => {
    const {
      searchValue1,
      searchValue2,
      curFirstLevel,
    } = this.state;
    searchValue1.type = node.key.toUpperCase();
    if (value === "monthOfArrival") {
      searchValue2.onboardingTimeStart = `${new Date().getFullYear()}/01`;
      searchValue2.onboardingTimeEnd = `${new Date().getFullYear()}/12`;
    } else if (value === "monthOfDeparture") {
      searchValue2.separationTimeStart = `${new Date().getFullYear()}/01`;
      searchValue2.separationTimeEnd = `${new Date().getFullYear()}/12`;
    }
    this.setState({
      curTwoDegrees: value,
      searchValue1,
      twoValue: value,
      isShowTable: false,
      exportUrl:
        curFirstLevel && value
          ? ARR[curFirstLevel][value].exportUrl
          : "",
      path:
        curFirstLevel && value
          ? ARR[curFirstLevel][value].path
          : "",
      columns:
        curFirstLevel && value
          ? ARR[curFirstLevel][value].columns
          : null,
      cardData:
        curFirstLevel && value
          ? ARR[curFirstLevel][value].tag
          : [],
    });
  };

  /***表格数据重组 */
  getTableParmas = (arr) => {
    const newArr = [];
    forEach(arr, (value, n) => {
      let option = {};
      for (let key in value) {
        let obj = {
          [key]: value[key],
        };
        Object.assign(option, obj);
      }
      if (value.t) {
        forEach(value.t || [], (item, i) => {
          item.rowSpan = i ? 0 : value.t.length;
          item.index = n + 1;
          newArr.push({ ...option, ...item });
        });
      } else {
        option.index = n + 1;
        newArr.push(option);
      }
    });
    return newArr;
  };

  /**
   *  @method 查询表格
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const {
          searchValue1,
          searchValue2,
          curFirstLevel,
          path,
        } = this.state;
        const params = {
          ...searchValue1,
          ...searchValue2,
          pageNo,
          pageSize,
        };
        this.$http
          .queryEmployeeAnalysisList(params, path)
          .then((res) => {
            console.log("res -->", res);
            const { data, code, message } = res;
            if (res && code == 200 && data) {
              const { cardData } = this.state;
              forEach(cardData, (o) => {
                o.num = data[o.key] || 0;
              });
              const { lineInformation } = data;
              let param =
                curFirstLevel !== "personnelStatus"
                  ? this.getTableParmas(
                      lineInformation || []
                    )
                  : lineInformation;

              this.setState({
                pageNo,
                pageSize,
                cardData,
                dataSource: param,
              });
              console.log("param --->", param);
            } else {
              const { cardData } = this.state;
              cardData.forEach((o) => {
                o.num = 0;
              });
              this.setState({
                cardData,
                dataSource: [],
              });
              code != 500 && message.warning(message);
            }
            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };

  /**
   *  @method 提交表单
   */

  handleSubmit = () => {
    const {
      searchValue1,
      searchValue2,
      curFirstLevel,
      curTwoDegrees,
    } = this.state;
    const {
      departments,
      cityIds,
      status,
      type,
    } = searchValue1;
    const {
      onboardingTimeStart,
      onboardingTimeEnd,
      separationTimeStart,
      separationTimeEnd,
    } = searchValue2;
    if (!departments && !cityIds && !status) {
      message.warning("一级维度不能为空");
      this.setState({
        isShowTable: false,
      });
      return;
    } else if (!type) {
      message.warning("二级维度不能为空");
      this.setState({
        isShowTable: false,
      });
      return;
    } else if (
      type === "MONTHOFARRIVAL" &&
      (!onboardingTimeStart || !onboardingTimeEnd)
    ) {
      message.warning("二级维度不能为空");
      this.setState({
        isShowTable: false,
      });
      return;
    } else if (
      type === "MONTHOFDEPARTURE" &&
      (!separationTimeStart || !separationTimeEnd)
    ) {
      message.warning("二级维度不能为空");
      this.setState({
        isShowTable: false,
      });
      return;
    }
    if (curFirstLevel === "city") {
      searchValue1.status =
        curTwoDegrees === "monthOfDeparture" ? "1" : "0";
    }
    this.setState({
      isShowTable: true,
      searchValue1,
    });
    this.queryTableList();
  };

  render() {
    const {
      firstLevelDataSource,
      twoDegreesDataSource,
      curFirstLevel,
      columns,
      dataSource,
      loading,
      cardData,
      curTwoDegrees,
      exportUrl,
      searchValue1,
      searchValue2,
      twoValue,
      isShowTable,
    } = this.state;

    return (
      <div className="employee-analysis">
        <div className="title">员工分析</div>
        <div className="search">
          <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            layout="inline"
          >
            <Form.Item label="一级维度" required>
              <Select
                style={{ width: 160 }}
                onChange={this.setCurFirstLevel}
                placeholder={"请选择"}
              >
                {map(firstLevelDataSource, (item) => {
                  const { value, label, key } = item;
                  return (
                    <Option value={value} key={key}>
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <FirstLevelSelect
              type={curFirstLevel}
              onChange={(value) => {
                const { searchValue1 } = this.state;
                if (value && value.status) {
                  searchValue1.status = value.status;
                  const {
                    twoDegreesDataSource,
                  } = this.state;

                  if (value.status === "0") {
                    twoDegreesDataSource[0].disabled = false;
                    twoDegreesDataSource[1].disabled = false;
                    twoDegreesDataSource[2].disabled = true;
                    twoDegreesDataSource[3].disabled = false;
                  } else {
                    twoDegreesDataSource[0].disabled = true;
                    twoDegreesDataSource[1].disabled = true;
                    twoDegreesDataSource[2].disabled = false;
                    twoDegreesDataSource[3].disabled = true;
                  }
                  delete searchValue1.type;
                  this.setState({
                    twoDegreesDataSource,
                    searchValue1,
                    curTwoDegrees: "",
                    twoValue: "",
                    searchValue2: {},
                  });
                } else if (
                  value &&
                  value.cityIds &&
                  value.cityIds.length === 0
                ) {
                  delete searchValue1.cityIds;
                  delete searchValue1.status;
                  this.setState({
                    searchValue1,
                  });
                } else if (
                  value &&
                  value.departments &&
                  value.departments.length === 0
                ) {
                  delete searchValue1.departments;
                  this.setState({
                    searchValue1,
                  });
                } else {
                  this.setState({
                    searchValue1: {
                      ...searchValue1,
                      ...value,
                    },
                  });
                }
              }}
            />
            <Form.Item label="二级维度">
              <Select
                style={{ width: 160 }}
                onChange={this.setCurTwoDegrees}
                value={twoValue}
                placeholder={"请选择"}
              >
                {map(twoDegreesDataSource, (item) => {
                  const {
                    value,
                    key,
                    label,
                    disabled,
                  } = item;
                  return (
                    <Option
                      value={value}
                      key={key}
                      disabled={disabled}
                    >
                      {label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <TwoStageSelect
              type={curTwoDegrees}
              onChange={(value, option) => {
                const { searchValue2 } = this.state;
                this.setState({
                  searchValue2: {
                    ...searchValue2,
                    ...value,
                  },
                });
              }}
            />
            <Form.Item>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 14 }}
              >
                查询
              </Button>
            </Form.Item>
            <Form.Item>
              {exportUrl ? (
                <ExportBtn
                  text={"导出"}
                  className="btnColor"
                  params={{
                    ...searchValue1,
                    ...searchValue2,
                  }}
                  url={this.$http.exportEmployeeAnalysis(
                    exportUrl
                  )}
                />
              ) : (
                <Button
                  onClick={() => {
                    message.warning(
                      "请选择一级维度和二级维度"
                    );
                  }}
                >
                  导出
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
        <div className="content">
          {/* <EmptyPage /> */}
          {isShowTable ? (
            <div>
              <Card dataSource={cardData} />
              <div className="wrap-table">
                <Table
                  locale={{
                    emptyText: (
                      <div>
                        <img
                          style={{ width: 182, height: 94 }}
                          src={require("../../../assets/image/no-data.png")}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: 14,
                            color: "#717E99",
                            lineHeight: "20px",
                          }}
                        >
                          暂无数据
                        </p>
                      </div>
                    ),
                  }}
                  rowKey={(record, index) => {
                    return index;
                  }}
                  className="content-table"
                  loading={loading}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                />
              </div>
            </div>
          ) : (
            <EmptyPage />
          )}
        </div>
      </div>
    );
  }
}

export default Form.create()(EmployeeAnalysis);
