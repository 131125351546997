// 工作台
import CompletedItems from "../pages/workbench/completedItems"; //已办事项
import NewProcess from "../pages/workbench/newProcess"; //新建流程
import OfferApproval from "../pages/workbench/offerApproval"; //offer审批
import Checkin from "../pages/workbench/checkin"; //入职审核
import BecomeWorker from "../pages/workbench/becomeWorker"; //转正
import Quit from "../pages/workbench/quit"; //离职
import AutoQuit from "../pages/workbench/autoQuit"; //自动离职
import Leave from "../pages/workbench/leave"; //请假
import MonthlyAttendance from "../pages/workbench/monthlyAttendance"; //月度考勤
import Jurisdiction from "../pages/workbench/jurisdiction"; //管辖部门
import Launch from "../pages/workbench/launch"; //我发起的
import Schedule from "../pages/workbench/schedule"; //待办事项
import Workbench from "../pages/workbench"; //工作台
import UnconfirmedList from "../pages/workbench/jurisdiction/component/unconfirmedList"; // 管辖部门--未确认名单
import Imperfect from "../pages/workbench/Imperfect"; //未完善
import StaffEntry from "../pages/workbench/staffEntry"; // 待入职管理 -- 待入职管理

// 系统设置 ---- 西园的路由
import WagePayment from "../pages/system/wagePayment"; // 系统设置 -- 工资发放
import SystemBulletin from "../pages/system/systemBulletin"; // 系统设置 -- 工资发放
import AccountManagement from "../pages/system/accountManagement"; // 系统设置 -- 账号管理
import RankManagement from "../pages/system/rankManagement"; // 系统设置 -- 职级管理
import PostManagement from "../pages/system/postManagement"; // 系统设置 -- 岗位管理
import RoleManagement from "../pages/system/roleManagement"; // 系统设置 -- 角色管理
import OrganizationalStructure from "../pages/system/organizationalStructure"; // 系统设置 -- 组织架构
import ProcessConfig from "../pages/system/processConfig"; // 系统设置 -- 流程管理
import NodeConfig from "../pages/system/processNodeConfig"; // 系统设置 -- 流程管理 -- 添加
import Dictionary from "../pages/system/dictionary"; // 系统设置 -- 数据字典
import Holiday from "../pages/system/holiday"; // 系统设置 -- 假期管理

// 人事管理 ---- 西园的路由
import EmployeeRoster from "../pages/personnelMatters/employeeRoster"; // 人事管理 -- 员工花名册
import EmployeeDetail from "../pages/personnelMatters/employeeRoster/component/employeeDetails"; // 人事管理 -- 员工花名册 -- 员工详情
import AddEmployee from "../pages/personnelMatters/employeeRoster/component/addEmployee"; // 人事管理 -- 员工花名册 -- 新增、编辑
import EditEmployee from "../pages/personnelMatters/employeeRoster/component/editEmployee"; // 人事管理 -- 员工花名册 -- 新增、编辑

import ContractManagement from "../pages/personnelMatters/contractManagement"; // 人事管理 -- 合同管理
import ContractDetail from "../pages/personnelMatters/contractManagement/component/contractDetail"; // 人事管理 -- 合同管理 -- 合同详情
import AttendanceManagement from "../pages/personnelMatters/attendanceManagement"; // 人事管理 -- 考勤管理
import SalaryManagement from "../pages/personnelMatters/salaryManagement"; // 人事管理 -- 薪资管理
import QueryProcess from "../pages/personnelMatters/queryProcess"; // 人事管理 -- 流程查询
import SalaryFile from "../pages/personnelMatters/salaryFile"; // 人事管理 -- 薪资档案
import OfferStatistics from "../pages/personnelMatters/offerStatistics"; //人事管理 - offer统计
import EmployeeAnalysis from "../pages/personnelMatters/employeeAnalysis"; // 人事管理 - 员工分析报表

// 个人中心 --- 西园的路由
import PersonalInformation from "../pages/personalCenter/personalInformation"; // 个人中心 -- 个人信息
import EditPassword from "../pages/personalCenter/editPassword"; // 个人中心 -- 修改密码
import PersonalFiles from "../pages/personalCenter/personalFiles"; // 个人中心 -- 个人档案
export default [
  {
    name: "工资发放",
    link: "/systemSetup/wagePayment",
    component: WagePayment,
  },
  {
    name: "账号管理",
    link: "/systemSetup/accountManagement",
    component: AccountManagement,
  },
  {
    name: "已办事项",
    link: "/workbench/completedItems",
    component: CompletedItems,
  },
  {
    name: "新建流程",
    link: "/workbench/newProcess",
    component: NewProcess,
  },
  {
    name: "offer审批",
    link: "/workbench/offerApproval",
    component: OfferApproval,
  },
  {
    name: "入职审核",
    link: "/workbench/checkin",
    component: Checkin,
  },
  {
    name: "转正",
    link: "/workbench/becomeWorker",
    component: BecomeWorker,
  },
  {
    name: "离职",
    link: "/workbench/quit",
    component: Quit,
  },
  {
    name: "自动离职",
    link: "/workbench/autoQuit",
    component: AutoQuit,
  },
  {
    name: "请假",
    link: "/workbench/leave",
    component: Leave,
  },
  {
    name: "月度考勤",
    link: "/workbench/monthlyAttendance",
    component: MonthlyAttendance,
  },
  {
    name: "管辖部门",
    link: "/workbench/jurisdiction",
    component: Jurisdiction,
  },
  {
    name: "待入职管理",
    link: "/workbench/staffEntry",
    component: StaffEntry,
  },
  {
    name: "未确认名单",
    link: "/workbench/unconfirmedList",
    component: UnconfirmedList,
  },
  {
    name: "我发起的",
    link: "/workbench/launch",
    component: Launch,
  },
  {
    name: "待办事项",
    link: "/workbench/schedule",
    component: Schedule,
  },
  {
    name: "工作台",
    link: "/workbench/index",
    component: Workbench,
  },
  {
    name: "未完善名单",
    link: "/workbench/imperfect",
    component: Imperfect,
  },
  {
    name: "职级管理",
    link: "/systemSetup/rankManagement",
    component: RankManagement,
  },
  {
    name: "岗位管理",
    link: "/systemSetup/postManagement",
    component: PostManagement,
  },
  {
    name: "角色管理",
    link: "/systemSetup/roleManagement",
    component: RoleManagement,
  },
  {
    name: "组织架构",
    link: "/systemSetup/organizationalStructure",
    component: OrganizationalStructure,
  },
  {
    name: "流程配置",
    link: "/systemSetup/processConfig",
    component: ProcessConfig,
  },
  {
    name: "节点配置",
    link: "/systemSetup/nodeConfig",
    component: NodeConfig,
  },
  {
    name: "数据字典",
    link: "/systemSetup/dictionary",
    component: Dictionary,
  },
  {
    name: "系统公告",
    link: "/systemSetup/systemBulletin",
    component: SystemBulletin,
  },
  {
    name: "员工花名册",
    link: "/personnelMatters/employeeRoster",
    component: EmployeeRoster,
  },
  {
    name: "员工详情",
    link: "/personnelMatters/detail",
    component: EmployeeDetail,
  },
  {
    name: "员工花名册新增",
    link: "/personnelMatters/addEmployee",
    component: AddEmployee,
  },
  {
    name: "员工花名册编辑",
    link: "/personnelMatters/editEmployee",
    component: EditEmployee,
  },
  {
    name: "合同管理",
    link: "/personnelMatters/contractManagement",
    component: ContractManagement,
  },
  {
    name: "合同详情",
    link: "/personnelMatters/contractDetail",
    component: ContractDetail,
  },
  {
    name: "考勤管理",
    link: "/personnelMatters/attendanceManagement",
    component: AttendanceManagement,
  },
  {
    name: "薪资管理",
    link: "/personnelMatters/salaryManagement",
    component: SalaryManagement,
  },
  {
    name: "流程查询",
    link: "/personnelMatters/queryProcess",
    component: QueryProcess,
  },
  {
    name: "薪资档案",
    link: "/personnelMatters/salaryFile",
    component: SalaryFile,
  },
  {
    name: "offer统计",
    link: "/personnelMatters/offerStatistics",
    component: OfferStatistics,
  },
  {
    name: "offer统计",
    link: "/personnelMatters/employeeAnalysis",
    component: EmployeeAnalysis,
  },
  {
    name: "个人信息",
    link: "/personalInformation",
    component: PersonalInformation,
  },
  {
    name: "修改密码",
    link: "/editPassword",
    component: EditPassword,
  },
  {
    name: "个人档案",
    link: "/personalFiles",
    component: PersonalFiles,
  },
  {
    name: "假期管理",
    link: "/systemSetup/holiday",
    component: Holiday,
  },
];
