/**
* @fileoverview 人事管理---员工花名册---员工详情
* @author  蒋西园
*/
import React,{ Component } from 'react'
import './index.less'

import StaffDetail from './staffDetail'
import { personalInformation, postSalary } from '../../../data'
import util from '../../../../../utils/utils'
import { Button } from 'antd'

// import { Button, message } from 'antd';


class EmployeeDetails extends Component{
	constructor(props){
		super(props)
		this.state = {
            details:'员工详情',
            staffId: util.getSearchString('staffId',window.location.search) || this.props.location.state.staffId,
			isActive: 0,
            staffDetail: {}, // 岗位薪资
            staffInfo: {},   // 个人信息
            workExperience: [], // 工作经验
            staffAnnexList: [], // 附件
		}
	}
	componentDidMount(){
        this.queryJobDetail();
        this.queryStaffInfoDetail();
        this.queryWorkExperience();
        this.queryStaffAnnex();
    }
    /**
     * 函数描述: 花名册查询岗位&薪资
     * @param {String} staffId 员工id this.props.location.state.staffId
     */
    queryJobDetail = () => {
        const { staffId } = this.state;
        this.$http.getStaffPostInfoForStaffRoster({ staffId }).then(res => {
            if (res.code === 200) {
                this.setState({
                    staffDetail: res.data
                },() => {
                    this.queryContractDetail();
                })
            }
        })
    }
    /**
     * 函数描述: 合同查询详情
     * @param {String} staffId 员工id this.props.location.state.staffId
     */
    queryContractDetail = () => {
        const { staffId } = this.state;
        this.$http.getStaffInfoContract({ staffId }).then(res => {
            if (res.code === 200) {
                const { staffDetail } = this.state;
                this.setState({
                    staffDetail: {...staffDetail,...res.data}
                })
            }
        })
    }
    /**
     * 函数描述: 个人信息
     * @param {String} staffId 员工id this.props.location.state.staffId
     */
    queryStaffInfoDetail = () => {
        const { staffId } = this.state;
        this.$http.getStaffInfoForRoster({ staffId }).then(res => {
            if (res.code === 200) {
                res.data.turnTime1=res.data.turnTime
                this.setState({
                    staffInfo: res.data
                })
            }
        })
    }
    
    /**
     * 函数描述: 工作经验
     * @param {String} staffId 员工id this.props.location.state.staffId
     */
    queryWorkExperience = () => {
        const { staffId } = this.state;
        this.$http.listStafWorkExperienceByStaffId({ staffId }).then(res => {
            if (res.code === 200) {
                this.setState({
                    workExperience: res.data
                })
            }
        })
    }
    /**
     * 函数描述: 查询附件
     * @param {String} staffId 员工id this.props.location.state.staffId
     */
    queryStaffAnnex = () => {
        const { staffId } = this.state;
        this.$http.listStaffAnnexByStaffId({ staffId }).then(res => {
            if (res.code === 200) {
                this.setState({
                    staffAnnexList: res.data
                })
            }
        })
    }
    /**
     * 函数描述: 切换内容组件加载
     * @param {string} type 加载类型 0：岗位&薪资 1：个人信息
     * @return 
     */
    handleChangeActive(type) {
        this.setState({
            isActive: type
        })
    }
    // 跳转对应编辑页面
    handleEditPage(active,staffId){
        if (active === 0) {
            this.props.history.push({pathname:'/personnelMatters/editEmployee',state:{formType:2,staffId}})
        }else{
            this.props.history.push({pathname:'/personnelMatters/editEmployee',state:{formType:1,staffId}})
        }
    }
	render(){
		const { isActive, staffDetail, staffInfo,staffId, workExperience, staffAnnexList } = this.state;
		return (
            <div className="employee-detail">
                <div className="top-content">
                <div>{this.state.details}</div>
                <div className="edit"><Button type="primary" onClick={this.handleEditPage.bind(this,isActive,staffId)}>编辑</Button></div>
                </div>
                <div className="if-card tip-line">
                    <span onClick={this.handleChangeActive.bind(this,0)} className={`${isActive === 0 ? 'isActive' : 'isHover'}`}>岗位&薪资</span>
                    <span onClick={this.handleChangeActive.bind(this,1)} className={`${isActive === 1 ? 'isActive' : 'isHover'}`}>个人信息</span>
                </div>
                <StaffDetail 
                    isActive={isActive}
                    staffItem={isActive === 0 ? postSalary : personalInformation} 
                    staffDetail={isActive === 0 ? staffDetail : staffInfo}
                    workExperience={workExperience}
                    staffAnnexList={staffAnnexList}
                />
            </div>
		)
	}
}

export default EmployeeDetails
