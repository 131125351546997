import React from "react";
import { Tree, Input } from "antd";
import Api from "../../../../../api/apis";
import { map } from "lodash";
import "./index.less";
const { TreeNode } = Tree;
const { Search } = Input;

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};

class leftTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      treeDataSource: [],
      expandedKeys: [],
      searchValue: "",
      autoExpandParent: true,
      selectedKeys: [],
    };
    this.dataList = [];
  }
  componentDidMount() {
    this.queryTreeList();
  }
  queryTreeList = () => {
    Api.departmentPermissions({}).then((res) => {
      if (res && res.code == 200) {
        const dataSource = this.getTreeData(res.data, []);
        // 默认选中第一个
        const selectedDefaultKey = [dataSource[0].key];
        this.setState(
          {
            treeDataSource: dataSource,
            selectedKeys: selectedDefaultKey,
          },
          () => {
            // 调用默认选中的数据
            this.onSelect(selectedDefaultKey);
          }
        );
      }
    });
  };

  /**
   * @method 处理树数据
   */
  getTreeData = (data, treeData) => {
    treeData = map(data, (item) => {
      const {
        departmentSonName,
        departmentSonId,
        dsTree,
        level,
        id,
        staffCode,
        parentDepartmentId,
      } = item;
      return {
        title: departmentSonName,
        value: departmentSonId,
        key: departmentSonId,
        level,
        id,
        staffCode,
        parentDepartmentId,
        children:
          dsTree && Array.isArray(dsTree)
            ? this.getTreeData(dsTree, treeData)
            : null,
      };
    });
    return treeData;
  };

  renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            title={item.title}
            key={item.key}
            dataRef={item}
          >
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  generateList = (data) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      const { key, title } = node;
      this.dataList.push({ key, title });
      if (node.children) {
        this.generateList(node.children);
      }
    }
    return this.dataList;
  };

  onChange = (e) => {
    const { value } = e.target;
    const list = this.generateList(
      this.state.treeDataSource
    );
    this.dataList = [];
    const expandedKeys = list
      .map((item) => {
        if (item.title.indexOf(value) > -1) {
          return getParentKey(
            item.key,
            this.state.treeDataSource
          );
        }
        return null;
      })
      .filter(
        (item, i, self) => item && self.indexOf(item) === i
      );
    this.setState({
      expandedKeys,
      searchValue: value,
      autoExpandParent: true,
    });
  };

  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };

  /**
   *@method 选中左侧树调用
   * @param {string[]} selectedKeys 选中的key
   * @param {*} e
   */
  onSelect = (selectedKeys, e) => {
    this.props.onSelect(selectedKeys);
    this.setState({
      selectedKeys: selectedKeys,
    });
  };

  render() {
    const {
      searchValue,
      expandedKeys,
      autoExpandParent,
      selectedKeys,
    } = this.state;
    return (
      <div className="left-tree">
        <Search
          style={{ marginBottom: 8 }}
          placeholder="项目/部门"
          onChange={this.onChange}
        />
        <Tree
          onExpand={this.onExpand}
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
          autoExpandParent={autoExpandParent}
          onSelect={this.onSelect}
        >
          {this.renderTreeNodes(this.state.treeDataSource)}
        </Tree>
      </div>
    );
  }
}

export default leftTree;
