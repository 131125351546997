/**
 * @description 表格modal
 * @author zxz
 */
import React from "react";
import { Modal, Button, Table, Divider, Tag } from "antd";
import "./index.less";
class tableModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  openModal = () => {
    this.setState({ visible: true });
  };

  closeModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { visible } = this.state;
    const { title, ...rest } = this.props;
    return (
      <Modal
        visible={visible}
        width={932}
        footer={null}
        onCancel={this.closeModal}
        title={title}
      >
        <Table {...rest} />
      </Modal>
    );
  }
}

export default tableModal;
