import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Icon,
  Form,
  Row,
  Col,
  Button,
  Input,
  message,
  Radio,
  DatePicker,
  Modal,
  Alert,
  Tabs,
  Table,
  Calendar,
  Checkbox,
} from "antd";
import "./index.less";
import ProcessGuide from "../../../components/processGuide";
import AuditRecord from "../../../components/auditRecord";
import Withdraw from "../../../components/withdraw"; // 撤回
import SelectStaff from "../../../components/selectStaff";
import SelectStaffNo from "../../../components/SelectStaffNo";
import { getQueryVariable } from "../../../utils/common";
import utils from "../../../utils/utils";
import moment from "moment";

const FormItem = Form.Item;
const { TextArea } = Input;
const { confirm } = Modal;

// 1、表单页面（showtype=1），底部按钮（step1）
// 2、各状态详情页面（showtype=2），页面内容（step2，step3...，和详情字段是否有值共同判断显示表单控件还是回显内容），底部按钮（step2，step3，step4，step5去控制）

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: utils.localDb.get("userData").staffId,
      staffName: utils.localDb.get("userData").staffName,
      showType: 1,
      staffDetail: {},
      staffRow: {},
      dimissionDetail: {},
      logList: [],
      quitList: [
        {
          key: "leaveVacation",
          label: "事假(天)",
          num: 0,
        },
        {
          key: "yearVacation",
          label: "年假(天)",
          num: 0,
        },
        {
          key: "produceVacation",
          label: "产假(天)",
          num: 0,
        },
        {
          key: "sickVacation",
          label: "病假(天)",
          num: 0,
        },
        {
          key: "marryVacation",
          label: "婚假(天)",
          num: 0,
        },
        {
          key: "paternityVacation",
          label: "陪产假(天)",
          num: 0,
        },
        {
          key: "funeralLeave",
          label: "丧假(天)",
          num: 0,
        },
        {
          key: "antenatalCare",
          label: "产检假(天)",
          num: 0,
        },
      ],
      submitDisabled: false,

      applyDimissionTime: "",
    };
  }
  componentDidMount() {
    if (getQueryVariable("dimissionProcId")) {
      this.queryAutoDimissionById();
      this.queryProcessLogList();
    } else if (getQueryVariable("staffId")) {
      this.queryStaffDetail();
      this.setState({
        showType: 1,
        logList: [],
      });
    } else {
      this.setState({
        showType: 1,
        logList: [],
      });
    }
  }

  queryStaffDetail = () => {
    this.$http["getStaffPostInfoForStaffRoster"]({
      staffId: getQueryVariable("staffId"),
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          staffDetail: res.data || {},
        });
      }
    });
  };

  queryAutoDimissionById = () => {
    this.$http["queryAutoDimissionById"]({
      dimissionProcId: getQueryVariable("dimissionProcId"),
    }).then((res) => {
      if (res.code === 200) {
        let showType = 2;
        let showWithdraw = false;
        let showOpinion = false;
        let showPass = false;
        let showComplete = false;

        let step2 = false;
        let step3 = false;

        let taskDefKey = res.data.processData
          ? res.data.processData.taskDefKey
          : "";

        if (res.data.procStatus === "STORAGE") {
          showType = 1;
        }
        if (
          res.data.procStatus == "REFUSE" ||
          res.data.procStatus == "RECALL"
        ) {
          if (res.data.creatorId == this.state.staffId) {
            showType = 1;
          }
        }
        if (res.data.procStatus == "AUDIT") {
          // 审核状态
          if (res.data.creatorId == this.state.staffId) {
            // 撤回操作
            showWithdraw = true;
          }
          if (res.data.auditId == this.state.staffId) {
            if (taskDefKey === "SPON_DIM0002") {
              // 第二步
              step2 = true;
            }
            if (taskDefKey === "SPON_DIM0003") {
              // 第三步
              step3 = true;
              showComplete = true;
            }
          }
        }
        if (res.data.procStatus == "COMPLETE") {
          // 完成状态
          showComplete = false;
        }

        // 抄送的详情没有操作权限
        if (getQueryVariable("copyToFlag") == 1) {
          step2 = false;
          step3 = false;
        }

        const { quitList } = this.state;

        quitList.map((item) => {
          item.num = Number(res.data[item.key]) || 0;
        });

        let date = moment(res.data.applyDimissionTime);

        // 本月的第一天，本月的最后一天
        let currentStart =
          date.add("month", 0).format("YYYY-MM") + "-01";
        let currentEnd = moment(currentStart)
          .add("month", 1)
          .add("days", -1)
          .format("YYYY-MM-DD");
        // 上个月的第一天，上个月的最后一天
        let prevStart =
          date.subtract("month", 1).format("YYYY-MM") +
          "-01";
        let prevEnd = moment(prevStart)
          .subtract("month", -1)
          .add("days", -1)
          .format("YYYY-MM-DD");

        console.log(currentStart, currentEnd);
        console.log(prevStart, prevEnd);
        const days = moment(
          res.data.applyDimissionTime
        ).diff(moment(currentStart), "days");
        console.log(days);

        let cutOffTime;
        if (days >= 14) {
          // 设置为本月底的日期
          cutOffTime = currentEnd;
        } else {
          // 设置为上月底的日期
          cutOffTime = prevEnd;
        }

        this.setState({
          dimissionDetail: res.data || {},
          staffDetail: res.data || {},
          staffRow: res.data
            ? {
                staffId: res.data.heirId || "",
                staffName: res.data.heirName || "",
              }
            : {},
          calcTotal: res.data.leaveDays || "0",
          quitList,
          showType,
          showWithdraw,
          showComplete,
          step2,
          step3,

          applyDimissionTime: cutOffTime,
        });
      }
    });
  };

  //流程日志
  queryProcessLogList = () => {
    this.$http["queryProcessLogList"]({
      businessKey: getQueryVariable("dimissionProcId"),
      procTemplate: getQueryVariable("procTemplate"),
    })
      .then((res) => {
        if (res.code == 200) {
          this.setState({
            logList: res.data || [],
          });
        } else {
        }
      })
      .catch((err) => {});
  };

  handleDelete = () => {
    const self = this;
    confirm({
      title: "删除",
      content: "您确定要删除该工作流吗？",
      onOk() {
        self.$http["deleteByAutoDimissionId"]({
          dimissionProcId: getQueryVariable(
            "dimissionProcId"
          ),
        })
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              self.props.history.go(-1);
            } else {
            }
          })
          .catch((err) => {});
      },
      onCancel() {},
    });
  };

  handleFail = () => {
    const self = this;
    const { form } = this.props;
    if (!form.getFieldValue("auditOpinion").trim()) {
      message.destroy();
      message.warning("请在审核意见中填写退回原因");
      document.getElementById("auditOpinion").focus();
      return;
    }
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        confirm({
          icon: (
            <Icon
              type="question-circle"
              style={{ color: "#4D91FA" }}
            />
          ),
          title: "您确定退回该工作流吗？",
          content: "",
          onOk() {
            const { dimissionDetail } = self.state;
            const { form } = self.props;
            self.$http["autoDimissionRefuse"]({
              dimissionProcId: getQueryVariable(
                "dimissionProcId"
              ),
              taskId: dimissionDetail.processData.taskId,
              ...values,
            })
              .then((res) => {
                if (res.code === 200) {
                  // window.close();
                  self.props.history.go(-1);
                } else {
                }
              })
              .catch((err) => {});
          },
          onCancel() {},
        });
      }
    });
  };

  handleSubmit = (type) => {
    const { staffRow, staffDetail } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (!staffDetail.staffCode) {
          message.destroy();
          message.warning("请选择员工");
          return;
        }
        if (
          values["accumulationFundCutOffTime"].format(
            "YYYY-MM-DD"
          ) != this.state.applyDimissionTime &&
          !values["welfareCause"].trim()
        ) {
          message.destroy();
          message.warning(
            "截止日修改，请填写截止日修改原因"
          );
          return;
        }

        if (
          values["socialInsuranceCutOffTime"].format(
            "YYYY-MM-DD"
          ) != this.state.applyDimissionTime &&
          !values["welfareCause"].trim()
        ) {
          message.destroy();
          message.warning(
            "截止日修改，请填写截止日修改原因"
          );
          return;
        }
        this.setState({
          submitDisabled: true,
        });
        const params = {
          ...values,
          applyDimissionTime: values[
            "applyDimissionTime"
          ].format("YYYY/MM/DD"),
          accumulationFundCutOffTime: values[
            "accumulationFundCutOffTime"
          ].format("YYYY-MM-DD"),
          socialInsuranceCutOffTime: values[
            "socialInsuranceCutOffTime"
          ].format("YYYY-MM-DD"),
          heirName: staffRow.staffName,
          heirId: staffRow.staffId,
          staffName: staffDetail.staffName,
          staffId: staffDetail.staffId,
          staffCode: staffDetail.staffCode,
          departmentId: staffDetail.departmentId,
          departmentName: staffDetail.departmentName,
          postId: staffDetail.postId,
          postName: staffDetail.postName,
          onBoardingTime:
            staffDetail.onboardingTime ||
            staffDetail.onBoardingTime,
          belongDeliverManager:
            staffDetail.belongDeliverManager,
          belongDeliverManagerId:
            staffDetail.belongDeliverManagerId,
          procStatus: type,
        };
        if (getQueryVariable("dimissionProcId")) {
          params.dimissionProcId = getQueryVariable(
            "dimissionProcId"
          );
        }
        console.log(params);
        this.$http[
          getQueryVariable("dimissionProcId")
            ? "updateByAutoDimissionId"
            : "addAutoDimissionProcess"
        ](params)
          .then((res) => {
            if (res.code === 200) {
              this.props.history.push("/workbench/launch");
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };

  handleAddSure = (values) => {
    console.log(values);
  };

  handleStaffDetail = (row) => {
    console.log("row", row);
    const { staffRow } = this.state;
    staffRow.staffId = "";
    staffRow.staffName = "";
    this.setState({
      staffDetail: row[0] || {},
      staffRow,
    });
  };

  handleStaffRow = (row) => {
    this.setState({
      staffRow: row[0] || {},
    });
  };

  handlePass = () => {
    const { dimissionDetail } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          submitDisabled: true,
        });
        const params = {
          dimissionProcId: getQueryVariable(
            "dimissionProcId"
          ),
          taskId: dimissionDetail.processData.taskId,
          ...values,
        };
        this.$http["autoDimissionPass"](params)
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              this.props.history.go(-1);
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };

  handleFile = () => {
    const { dimissionDetail } = this.state;
    const { form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          submitDisabled: true,
        });
        const params = {
          dimissionProcId: getQueryVariable(
            "dimissionProcId"
          ),
          taskId: dimissionDetail.processData.taskId,
          ...values,
          applyDimissionTime: values[
            "applyDimissionTime"
          ].format("YYYY-MM-DD"),
          propertyReturn: values["propertyReturn"].join(""),
          accountingLiquidation: values[
            "accountingLiquidation"
          ].join(""),
          resourcesConfirm: values["resourcesConfirm"].join(
            ""
          ),
        };
        this.$http["autoDimissionPass"](params)
          .then((res) => {
            if (res.code === 200) {
              // window.close();
              this.props.history.go(-1);
              this.setState({
                submitDisabled: false,
              });
            } else {
              this.setState({
                submitDisabled: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              submitDisabled: false,
            });
          });
      }
    });
  };

  validateLength128 = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };
  validatorMoney = (rule, value, callback) => {
    const reg = /^[0-9]+(.[0-9]{1,2})?$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback("大于等于0，小于99999999 ，两位小数");
    } else {
      if (Number(value.trim()) > 99999999) {
        callback("大于等于0，小于99999999 ，两位小数");
      } else {
        callback();
      }
    }
  };
  inputBlur = (e, key, index) => {
    const { form } = this.props;
    const value = e.target.value;
    if (value) {
      let formatNum = parseFloat(value);
      if (isNaN(formatNum)) {
        form.setFieldsValue({
          [key]: "0",
        });
        const { quitList } = this.state;
        quitList[index].num = 0;

        let total = 0;
        quitList.map((item) => {
          total += item.num;
        });
        this.setState({
          quitList,
          calcTotal: total,
        });
      } else {
        //小数则统一处理为0.5
        if (String(formatNum).indexOf(".") > 0) {
          form.setFieldsValue({
            [key]: parseInt(formatNum) + 0.5,
          });
          const { quitList } = this.state;
          quitList[index].num = parseInt(formatNum) + 0.5;

          let total = 0;
          quitList.map((item) => {
            total += item.num;
          });
          this.setState({
            quitList,
            calcTotal: total,
          });
        } else {
          if (
            (key == "leaveVacation" ||
              key == "produceVacation" ||
              key == "sickVacation") &&
            formatNum > 9999
          ) {
            formatNum = 9999;
          }
          if (
            (key == "yearVacation" ||
              key == "marryVacation" ||
              key == "paternityVacation" ||
              key == "funeralLeave") &&
            formatNum > 99
          ) {
            formatNum = 99;
          }
          form.setFieldsValue({
            [key]: formatNum,
          });

          const { quitList } = this.state;
          quitList[index].num = formatNum;

          let total = 0;
          quitList.map((item) => {
            total += item.num;
          });
          this.setState({
            quitList,
            calcTotal: total,
          });
        }
      }
    }
  };

  handleWithdraw = () => {
    this.props.history.go(-1);
  };

  applyDimissionTimeChange = (date1, dateString) => {
    const { form } = this.props;
    // console.log(date, dateString);
    if (!date1) {
      return;
    }

    let date = moment(dateString);

    // 本月的第一天，本月的最后一天
    let currentStart =
      date.add("month", 0).format("YYYY-MM") + "-01";
    let currentEnd = moment(currentStart)
      .add("month", 1)
      .add("days", -1)
      .format("YYYY-MM-DD");
    // 上个月的第一天，上个月的最后一天
    let prevStart =
      date.subtract("month", 1).format("YYYY-MM") + "-01";
    let prevEnd = moment(prevStart)
      .subtract("month", -1)
      .add("days", -1)
      .format("YYYY-MM-DD");

    console.log(currentStart, currentEnd);
    console.log(prevStart, prevEnd);
    const days = moment(dateString).diff(
      moment(currentStart),
      "days"
    );
    console.log(days);

    let cutOffTime;
    if (days >= 14) {
      // 设置为本月底的日期
      cutOffTime = currentEnd;
    } else {
      // 设置为上月底的日期
      cutOffTime = prevEnd;
    }
    form.setFieldsValue({
      accumulationFundCutOffTime: moment(
        cutOffTime,
        "YYYY-MM-DD"
      ),
      socialInsuranceCutOffTime: moment(
        cutOffTime,
        "YYYY-MM-DD"
      ),
    });
    this.setState({
      applyDimissionTime: cutOffTime,
      // accumulationFundCutOffTime,
      // socialInsuranceCutOffTime,
    });
  };

  checkValid = () => {
    const { staffDetail } = this.state;
    if (!staffDetail.staffId) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      showType,
      staffDetail,
      dimissionDetail,
      logList,
    } = this.state;

    const { form } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };

    // 显示审核结果，判断右侧记录第一个的状态operStatus，有的话就显示第一个，没有的话显示第二个
    let log = {};
    if (logList.length > 1) {
      if (logList[0].operStatus) {
        log = logList[0];
      } else {
        log = logList[1];
      }
    }

    return (
      <div className="autoquit-main">
        <div className="autoquit-title">
          <span>
            自动离职申请流程
            {showType == 1 && <span>-新建</span>}
            {dimissionDetail.procStatus == "AUDIT" ? (
              dimissionDetail.processData ? (
                <span>{`-${dimissionDetail.processData.taskName}`}</span>
              ) : null
            ) : null}
          </span>
          <ProcessGuide
            title="离职申请流程"
            temp="SPON_DIMI_PROC"
          />
        </div>
        <div className="autoquit-wrap">
          <div className="wrap-left" style={{ flex: 1 }}>
            {log && log.operDescription ? (
              <div>
                {log.operStatus == "PASS" && (
                  <Alert
                    message={`${log.taskName}通过`}
                    description={log.operDescription}
                    type="success"
                    showIcon
                    style={{ marginBottom: 12 }}
                  />
                )}
                {log.operStatus == "REFUSE" && (
                  <Alert
                    message={
                      <span className="alert-process-refuse">{`${log.taskName}不通过`}</span>
                    }
                    description={
                      <span className="alert-process-desc">
                        {log.operDescription}
                      </span>
                    }
                    type="error"
                    showIcon
                    icon={
                      <Icon
                        type="close-circle"
                        style={{
                          fontSize: 18,
                          color: "#E5537B",
                          top: "18px",
                        }}
                      />
                    }
                    style={{ marginBottom: 12 }}
                  />
                )}
              </div>
            ) : null}

            <Form
              {...formItemLayout}
              className="process-form-readonly"
            >
              <Row className="wrap-block">
                <Row style={{ marginLeft: 21 }}>
                  <Row>
                    <Col span={8}>
                      <FormItem label="员工">
                        {showType == 1 ? (
                          getQueryVariable("staffId") ? (
                            <span>
                              {staffDetail.staffName ||
                                "--"}
                            </span>
                          ) : (
                            <SelectStaff
                              form={this.props.form}
                              handleOk={
                                this.handleStaffDetail
                              }
                              organizationalItem={{
                                departmentPrincipalId:
                                  staffDetail.staffId,
                                departmentPrincipalName:
                                  staffDetail.staffName,
                              }}
                            />
                          )
                        ) : null}
                        {showType == 2 && (
                          <span>
                            {staffDetail.staffName || "--"}
                          </span>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="工号">
                        <span>
                          {staffDetail.staffCode || "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="直属上级">
                        <span>
                          {staffDetail.belongDeliverManager ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="部门名称">
                        <span>
                          {staffDetail.departmentName ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="岗位">
                        <span>
                          {staffDetail.postName || "--"}
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="入职时间">
                        <span>
                          {staffDetail.onBoardingTime ||
                            staffDetail.onboardingTime ||
                            "--"}
                        </span>
                      </FormItem>
                    </Col>
                  </Row>
                  {showType == 1 && (
                    <Row>
                      <Col span={8}>
                        <FormItem label="离职类型">
                          <span>自动</span>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="离职时间"
                          className="quit-form-item"
                        >
                          {getFieldDecorator(
                            "applyDimissionTime",
                            {
                              initialValue: dimissionDetail.applyDimissionTime
                                ? moment(
                                    dimissionDetail.applyDimissionTime,
                                    "YYYY-MM-DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: "离职时间必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              onChange={
                                this
                                  .applyDimissionTimeChange
                              }
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="交接人">
                          {/* <SelectStaff
                            form={this.props.form}
                            handleOk={this.handleStaffRow}
                            departmentId={
                              staffDetail.departmentId || ""
                            }
                            staffId={
                              staffDetail.staffId || ""
                            }
                            organizationalItem={{
                              departmentPrincipalId: this
                                .state.staffRow.staffId,
                              departmentPrincipalName: this
                                .state.staffRow.staffName,
                            }}
                            checkValid={this.checkValid}
                          /> */}
                          {console.log(
                            "staffDetail ->",
                            staffDetail.departmentId
                          )}
                          <SelectStaffNo
                            form={this.props.form}
                            departmentId={
                              staffDetail.departmentId
                            }
                            staffId={staffDetail.staffId}
                            handleOk={this.handleStaffRow}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                  {showType == 2 && (
                    <Row>
                      <Col span={8}>
                        {/* <FormItem label="申请离职时间">
                          <span>
                            {dimissionDetail.applyDimissionTime ||
                              "--"}
                          </span>
                        </FormItem> */}
                        <FormItem
                          label="申请离职时间"
                          className="quit-form-item"
                        >
                          {getFieldDecorator(
                            "applyDimissionTime",
                            {
                              initialValue: dimissionDetail.applyDimissionTime
                                ? moment(
                                    dimissionDetail.applyDimissionTime,
                                    "YYYY-MM-DD"
                                  )
                                : "",
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "申请离职时间必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              onChange={this.quitOnChange}
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="离职类型">
                          <span>自动</span>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="交接人">
                          <span>
                            {dimissionDetail.heirName ||
                              "--"}
                          </span>
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={16}>
                      {showType == 1 && (
                        <FormItem
                          label="离职原因"
                          labelCol={{ span: 6 }}
                          wrapperCol={{ span: 18 }}
                          className="quit-form-item"
                        >
                          {getFieldDecorator(
                            "dimissionCause",
                            {
                              initialValue:
                                dimissionDetail.dimissionCause ||
                                "",
                              rules: [
                                {
                                  required: true,
                                  whitespace: true,
                                  message: "离职原因必填",
                                },
                                {
                                  validator: this
                                    .validateLength128,
                                },
                              ],
                            }
                          )(
                            <TextArea
                              rows={4}
                              maxLength={128}
                              placeholder="请输入"
                              allowClear
                            />
                          )}
                        </FormItem>
                      )}
                      {showType == 2 && (
                        <FormItem
                          label="离职原因"
                          labelCol={{ span: 6 }}
                        >
                          <span>
                            {dimissionDetail.dimissionCause ||
                              "--"}
                          </span>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {showType == 1 && (
                        <FormItem
                          label="员工考勤"
                          labelCol={{ span: 4 }}
                          wrapperCol={{ span: 12 }}
                          className="quit-form-item"
                        >
                          <Row className="quit-total-wrap">
                            <div className="total-wrap-title">
                              累计请假
                              {this.state.calcTotal || 0}天
                            </div>
                            <Row>
                              {this.state.quitList.map(
                                (item, index) => {
                                  return (
                                    <div
                                      key={item.key}
                                      className={item.key}
                                    >
                                      <Col
                                        span={4}
                                        className="row-td-right"
                                      >
                                        {item.label}
                                      </Col>
                                      <Col
                                        span={4}
                                        className="row-td-right row-td-input"
                                      >
                                        <FormItem
                                          label=""
                                          style={{
                                            marginBottom: 0,
                                          }}
                                        >
                                          {getFieldDecorator(
                                            item.key,
                                            {
                                              initialValue:
                                                dimissionDetail[
                                                  item.key
                                                ] || "0",
                                            }
                                          )(
                                            <Input
                                              placeholder="请输入"
                                              onBlur={(e) =>
                                                this.inputBlur(
                                                  e,
                                                  item.key,
                                                  index
                                                )
                                              }
                                            />
                                          )}
                                        </FormItem>
                                      </Col>
                                    </div>
                                  );
                                }
                              )}
                            </Row>
                          </Row>
                        </FormItem>
                      )}
                      {showType == 2 && (
                        <FormItem
                          label="员工考勤"
                          labelCol={{ span: 4 }}
                          wrapperCol={{ span: 12 }}
                          className="quit-form-item"
                        >
                          <Row className="quit-total-wrap">
                            <div className="total-wrap-title">
                              累计请假
                              {this.state.calcTotal || "0"}
                              天
                            </div>
                            <Row>
                              {this.state.quitList.map(
                                (item, index) => {
                                  return (
                                    <div
                                      key={item.key}
                                      className={item.key}
                                    >
                                      <Col
                                        span={4}
                                        className="row-td-right"
                                      >
                                        {item.label}
                                      </Col>
                                      <Col
                                        span={4}
                                        className="row-td-right row-td-input"
                                      >
                                        <FormItem
                                          label=""
                                          style={{
                                            marginBottom: 0,
                                          }}
                                        >
                                          <span>
                                            {dimissionDetail[
                                              item.key
                                            ] || "0"}
                                          </span>
                                        </FormItem>
                                      </Col>
                                    </div>
                                  );
                                }
                              )}
                            </Row>
                          </Row>
                        </FormItem>
                      )}
                    </Col>
                  </Row>
                </Row>
                {showType == 1 ? (
                  <Row>
                    <Row style={{ marginLeft: 21 }}>
                      <Col span={8}>
                        <FormItem
                          label="公积金截止日"
                          className="quit-form-item"
                        >
                          {getFieldDecorator(
                            "accumulationFundCutOffTime",
                            {
                              initialValue: dimissionDetail.accumulationFundCutOffTime
                                ? moment(
                                    dimissionDetail.accumulationFundCutOffTime,
                                    "YYYY-MM-DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "公积金截止日必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="社保截止日"
                          className="quit-form-item"
                        >
                          {getFieldDecorator(
                            "socialInsuranceCutOffTime",
                            {
                              initialValue: dimissionDetail.socialInsuranceCutOffTime
                                ? moment(
                                    dimissionDetail.socialInsuranceCutOffTime,
                                    "YYYY-MM-DD"
                                  )
                                : undefined,
                              rules: [
                                {
                                  required: true,
                                  message: "社保截止日必填",
                                },
                              ],
                            }
                          )(
                            <DatePicker
                              getCalendarContainer={(
                                triggerNode
                              ) => triggerNode.parentNode}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem
                          label="离职补偿金"
                          className="quit-form-item"
                        >
                          {getFieldDecorator(
                            "severancePackage",
                            {
                              initialValue:
                                dimissionDetail.severancePackage ||
                                "",
                              rules: [
                                {
                                  validator: this
                                    .validatorMoney,
                                },
                              ],
                            }
                          )(
                            <Input
                              placeholder="请输入"
                              allowClear
                              style={{ width: "70%" }}
                            />
                          )}
                          <span>元</span>
                        </FormItem>
                      </Col>
                    </Row>
                    <Row style={{ marginLeft: "-27px" }}>
                      <Col span={24}>
                        <FormItem
                          label="社保/公积金截止日修改原因"
                          labelCol={{ span: 5 }}
                        >
                          {getFieldDecorator(
                            "welfareCause",
                            {
                              initialValue:
                                dimissionDetail.welfareCause ||
                                "",
                              rules: [
                                {
                                  validator: this
                                    .validateLength128,
                                },
                              ],
                            }
                          )(
                            <TextArea
                              rows={4}
                              maxLength={128}
                              placeholder="请输入"
                              allowClear
                              style={{ width: 460 }}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Row>
                ) : showType == 2 ? (
                  dimissionDetail.showWelfare == 1 && (
                    <Row>
                      <Row style={{ marginLeft: 26 }}>
                        <Col span={8}>
                          <FormItem label="公积金截止日">
                            <span>
                              {dimissionDetail.accumulationFundCutOffTime ||
                                "--"}
                            </span>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem label="社保截止日">
                            <span>
                              {dimissionDetail.socialInsuranceCutOffTime ||
                                "--"}
                            </span>
                          </FormItem>
                        </Col>
                        <Col span={8}>
                          <FormItem label="离职补偿金">
                            <span>
                              {dimissionDetail.severancePackage ||
                                "--"}
                            </span>
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <FormItem
                            label="社保/公积金截止日修改原因"
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 13 }}
                            style={{ marginLeft: "-21px" }}
                          >
                            <span
                              style={{
                                wordBreak: "break-all",
                                wordWrap: "break-word",
                              }}
                            >
                              {dimissionDetail.welfareCause ||
                                "--"}
                            </span>
                          </FormItem>
                        </Col>
                      </Row>
                    </Row>
                  )
                ) : null}
                {// 显示
                dimissionDetail.showReturn == 1 ? (
                  this.state.step3 ? (
                    <Row style={{ marginLeft: 27 }}>
                      <Col span={8}>
                        <FormItem label="固定资产归还">
                          {getFieldDecorator(
                            "propertyReturn",
                            {
                              initialValue: "",
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "请选择固定资产归还",
                                },
                              ],
                            }
                          )(
                            <Checkbox.Group>
                              <Checkbox value="1">
                                完成
                              </Checkbox>
                            </Checkbox.Group>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="账务清算">
                          {getFieldDecorator(
                            "accountingLiquidation",
                            {
                              initialValue: "",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择账务清算",
                                },
                              ],
                            }
                          )(
                            <Checkbox.Group>
                              <Checkbox value="1">
                                完成
                              </Checkbox>
                            </Checkbox.Group>
                          )}
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="人力资源部确认">
                          {getFieldDecorator(
                            "resourcesConfirm",
                            {
                              initialValue: "",
                              rules: [
                                {
                                  required: true,
                                  message:
                                    "请选择人力资源部确认",
                                },
                              ],
                            }
                          )(
                            <Checkbox.Group>
                              <Checkbox value="1">
                                完成
                              </Checkbox>
                            </Checkbox.Group>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  ) : (
                    <Row style={{ marginLeft: 27 }}>
                      <Col span={8}>
                        <FormItem label="固定资产归还">
                          <span>
                            {dimissionDetail.propertyReturn ==
                            1
                              ? "完成"
                              : "--"}
                          </span>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="账务清算">
                          <span>
                            {dimissionDetail.accountingLiquidation ==
                            1
                              ? "完成"
                              : "--"}
                          </span>
                        </FormItem>
                      </Col>
                      <Col span={8}>
                        <FormItem label="人力资源部确认">
                          <span>
                            {dimissionDetail.resourcesConfirm ==
                            1
                              ? "完成"
                              : "--"}
                          </span>
                        </FormItem>
                      </Col>
                    </Row>
                  )
                ) : null}
                {/* {this.state.step3 ? (
                  <Row>
                    <Col span={8}>
                      <FormItem label="固定资产归还">
                        {getFieldDecorator("propertyReturn", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: "请选择固定资产归还"
                            }
                          ]
                        })(
                          <Checkbox.Group>
                            <Checkbox value="1">完成</Checkbox>
                          </Checkbox.Group>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="账务清算">
                        {getFieldDecorator("accountingLiquidation", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: "请选择账务清算"
                            }
                          ]
                        })(
                          <Checkbox.Group>
                            <Checkbox value="1">完成</Checkbox>
                          </Checkbox.Group>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="人力资源部确认">
                        {getFieldDecorator("resourcesConfirm", {
                          initialValue: "",
                          rules: [
                            {
                              required: true,
                              message: "请选择人力资源部确认"
                            }
                          ]
                        })(
                          <Checkbox.Group>
                            <Checkbox value="1">完成</Checkbox>
                          </Checkbox.Group>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                ) : null} */}
              </Row>
              {this.state.step2 && (
                <Row
                  className="wrap-block"
                  style={{ marginBottom: 0 }}
                >
                  <div className="block-title">
                    流程审核
                  </div>
                  <Row>
                    <FormItem
                      label="审核意见"
                      labelCol={{ span: 2 }}
                      style={{ marginLeft: 16 }}
                    >
                      {getFieldDecorator("auditOpinion", {
                        initialValue: "",
                        rules: [
                          {
                            validator: this
                              .validateLength128,
                          },
                        ],
                      })(
                        <TextArea
                          rows={4}
                          maxLength={128}
                          placeholder="请输入"
                          allowClear
                        />
                      )}
                    </FormItem>
                  </Row>
                </Row>
              )}
            </Form>
          </div>
          <div className="wrap-right">
            <AuditRecord data={logList || []} />
          </div>
        </div>
        <div className="footer-fixed-wrap">
          <div className="footer-fixed-btn">
            {showType == 1 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // if (getQueryVariable("dimissionProcId")) {
                    //   window.close();
                    // }
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() =>
                    this.handleSubmit("INITIATE")
                  }
                  disabled={this.state.submitDisabled}
                >
                  提交
                </Button>
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() =>
                    this.handleSubmit("STORAGE")
                  }
                  disabled={this.state.submitDisabled}
                >
                  保存
                </Button>
                {(dimissionDetail.procStatus ===
                  "STORAGE" ||
                  dimissionDetail.procStatus ===
                    "REFUSE") && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={this.handleDelete}
                  >
                    删除
                  </Button>
                )}
              </div>
            )}
            {showType == 2 && (
              <div>
                <Button
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    // window.close();
                    this.props.history.go(-1);
                  }}
                >
                  返回
                </Button>
                {/* {this.state.showWithdraw && (
                  <Button
                    type="danger"
                    style={{ marginRight: 16 }}
                    onClick={() => {
                      this.setState(
                        {
                          visible: true
                        },
                        () => {
                          this.refs.withdrawRef.handleShow();
                        }
                      );
                    }}
                  >
                    撤回
                  </Button>
                )} */}
                {this.state.step2 && (
                  <span>
                    <Button
                      type="primary"
                      style={{ marginRight: 16 }}
                      onClick={this.handlePass}
                      disabled={this.state.submitDisabled}
                    >
                      通过
                    </Button>
                    <Button
                      type="danger"
                      style={{ marginRight: 16 }}
                      onClick={this.handleFail}
                    >
                      不通过
                    </Button>
                  </span>
                )}
                {this.state.step3 && (
                  <Button
                    type="primary"
                    style={{ marginRight: 16 }}
                    onClick={this.handleFile}
                    disabled={this.state.submitDisabled}
                  >
                    归档
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.visible && (
          <Withdraw
            ref="withdrawRef"
            form={this.props.form}
            data={{
              dimissionProcId:
                dimissionDetail.dimissionProcId,
              taskId: dimissionDetail.processData.taskId,
            }}
            url="autoDimissionWithdraw"
            handleOk={this.handleWithdraw}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Form.create()(MainManagement));
