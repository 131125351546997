/**
 * @fileoverview 系统管理---账号设置 -- 新增弹框
 * @author  蒋西园
 */
import React, { Component } from "react";
import "./index.less";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Upload,
  message,
  Button,
  Icon,
  DatePicker,
  Radio,
  Cascader,
  TreeSelect,
} from "antd";
import http from "../../../../../api/fetch";
import moment from "moment";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { SHOW_PARENT, TreeNode } = TreeSelect;
const SALARYTYPE = [
  {
    key: 1,
    label: "日薪",
  },
  {
    key: 2,
    label: "月薪",
  },
];
class AddOfferApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      attachmentKey: "",
      interview: [],
      workProvinceName: props.offerDetail.workProvinceName,
      workCityName: props.offerDetail.workCityName,
      socialProvinceName:
        props.offerDetail.socialProvinceName,
      socialCityName: props.offerDetail.socialCityName,
      contractType: "",
      departmentList: [],
    };
  }

  handleValidate = () => {
    const { form } = this.props;
    const { interview, fileList } = this.state;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (!fileList[0]) {
          message.destroy();
          message.warning("请上传简历");
          return;
        }
        const arr = interview
          .filter((item) => item.checked)
          .map((v) => v.dicKey);
        if (!arr.length) {
          message.destroy();
          message.warning("请选择面试标签");
          return;
        }
        this.props.handlePush({
          ...values,
          interviewEstimateDics: arr,
          papersType: values["papersType"].key,
          sex: values["sex"].key,
          sourceOfDitch: values["sourceOfDitch"].key,
          departmentId: values["departmentId"].value,
          departmentName: values["departmentId"].label,
          positionId: values["positionId"].key,
          positionName: values["positionId"].label,
          rankId: values["rankId"].key,
          rankName: values["rankId"].label,
          workProvinceId:
            values["workProvinceId"] &&
            values["workProvinceId"][0],
          workProvinceName: this.state.workProvinceName,
          workCityId:
            values["workProvinceId"] &&
            values["workProvinceId"][1],
          salaryType: values["salaryType"].key,
          workCityName: this.state.workCityName,
          socialProvinceId:
            values["socialProvinceId"] &&
            values["socialProvinceId"][0],
          socialProvinceName: this.state.socialProvinceName,
          socialCityId:
            values["socialProvinceId"] &&
            values["socialProvinceId"][1],
          socialCityName: this.state.socialCityName,
          hireDate:
            values["hireDate"] &&
            values["hireDate"].format("YYYY-MM-DD"),
          positiveTime:
            values["positiveTime"] &&
            values["positiveTime"].format("YYYY-MM-DD"),
          expireOfContract:
            values["expireOfContract"] &&
            values["expireOfContract"].format("YYYY-MM-DD"),
          contractType: values["contractType"].key,
          attachmentKey: fileList[0] ? fileList[0].uid : "",
        });
      }
    });
  };
  // 点击取消关闭弹框
  handleCancel = () => {
    this.props.handleCancel();
  };
  handleChange = ({ fileList }) => {
    console.log("上传类型");
    if (fileList.length == 0) {
      this.setState({ fileList });
      this.triggerChange(fileList);
    }
  };
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.destroy();
      message.warning(
        "只能上传pdf/word/jpg/jpeg/png文件，且不超过10M"
      );
      return false;
    }
    const name = file.name;
    const index = name.lastIndexOf(".");
    const suffix = name.substr(index + 1).toLowerCase();
    if (
      ["doc", "docx", "pdf", "jpg", "jpeg", "png"].indexOf(
        suffix
      ) == -1
    ) {
      message.destroy();
      message.warning(
        "只能上传pdf/word/jpg/jpeg/png文件，且不超过10M"
      );
      return false;
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      // 使用ossupload覆盖默认的上传方法
      http.uploadFile(file, file.name).then((data) => {
        let fileList = [
          {
            url: data.url,
            uid: data.key,
            name: data.name,
          },
        ];
        this.setState({ fileList });
        this.triggerChange(fileList); //只返回id
      });
    };
    return false; // 不调用默认的上传方法
  };

  validatorName = (rule, value, callback) => {
    const reg = /^[A-Za-z\u4e00-\u9fa5\s]+$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback("仅支持输入中英文和空格");
    } else if (value.trim().length > 32) {
      callback("长度限制32");
    } else {
      callback();
    }
  };
  validatorPhone = (rule, value, callback) => {
    const reg = /^1[3456789]\d{9}$/;
    if (value.trim().length > 11) {
      callback("手机号不能超过11位");
    } else if (
      value &&
      value.trim() &&
      !reg.test(value.trim())
    ) {
      callback("请输入正确手机号码");
    } else {
      callback();
    }
  };
  validatorNumber = (rule, value, callback) => {
    const reg = /^[0-9A-Z]*$/g;
    if (value.trim().length > 18) {
      callback("证件号码不能超过18位");
    } else if (
      value &&
      value.trim() &&
      !reg.test(value.trim())
    ) {
      callback("请输入正确证件号码");
    } else {
      callback();
    }
    // 需要知道证件类型的种类，身份证，请输入证件号码，护照
    // const { form } = this.props;
    // if (form.getFieldValue("papersType")) {
    //   if (form.getFieldValue("papersType").key == "ID_CARD") {
    //     const reg = /^[0-9A-Z]*$/g;
    //     if (value.trim().length > 18) {
    //       callback("证件号码不能超过18位");
    //     } else if (value && value.trim() && !reg.test(value.trim())) {
    //       callback("请输入正确证件号码");
    //     } else {
    //       callback();
    //     }
    //   } else if (
    //     form.getFieldValue("papersType").key == "PASSPORT" ||
    //     form.getFieldValue("papersType").key == "HK_MACAO_PASS"
    //   ) {
    //     const reg = /^[0-9a-zA-Z]*$/;
    //     if (value.trim().length > 18) {
    //       callback("证件号码不能超过18位");
    //     } else if (value && value.trim() && !reg.test(value.trim())) {
    //       callback("仅支持输入数字和字母");
    //     } else {
    //       callback();
    //     }
    //   } else {
    //     callback();
    //   }
    // } else {
    //   callback();
    // }
  };

  validatorInterview = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };
  validatorRemark = (rule, value, callback) => {
    if (value.trim().length > 128) {
      callback("长度限制128");
    } else {
      callback();
    }
  };

  validatorMoney = (rule, value, callback) => {
    const reg = /^[0-9]+(.[0-9]{1,2})?$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback("大于等于0，小于999999 ，两位小数");
    } else {
      if (Number(value.trim()) > 999999) {
        callback("大于等于0，小于999999 ，两位小数");
      } else {
        callback();
      }
    }
  };
  validatorMonth = (rule, value, callback) => {
    const reg = /^(0|[1-9][0-9]{0,2})$/;
    if (value && value.trim() && !reg.test(value.trim())) {
      callback("0或小于等于3位的正整数");
    } else {
      callback();
    }
  };
  // 试用期时长改变
  durationProbationChange = (e) => {
    console.log("试用期时长改变->", e.target.value);
    const value = parseInt(e.target.value.trim());
    const { form } = this.props;
    if (value != null && typeof value == "number") {
      if (value < 1000) {
        let date = form.getFieldValue("hireDate")
          ? form
              .getFieldValue("hireDate")
              .format("YYYY-MM-DD")
          : "";
        form.setFieldsValue({
          positiveTime: date
            ? moment(date).add(value, "M")
            : undefined,
        });
      }
    }
  };

  componentDidMount() {
    this.queryInterview();
    this.selectDepartment();
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    const { interview } = this.state;
    const { offerDetail } = nextProps;
    if (
      offerDetail.offerProcId !==
      this.props.offerDetail.offerProcId
    ) {
      interview.map((item) => {
        if (
          offerDetail.interviewEstimateDics.indexOf(
            item.dicKey
          ) > -1
        ) {
          item.checked = true;
        }
      });
      this.setState(
        {
          interview,
          fileList: offerDetail.attachmentKey
            ? [
                {
                  url: offerDetail.attachmentKeyUrl,
                  uid: offerDetail.attachmentKey,
                  name: offerDetail.attachmentKey.substr(
                    offerDetail.attachmentKey.indexOf("/") +
                      1
                  ),
                },
              ]
            : [],
        },
        () => {
          this.$http["queryProvinceList"]({}).then(
            (res) => {
              if (res.code === 200) {
                this.setState({
                  workProvince: res.data.provinceList || [],
                  socialProvince:
                    res.data.provinceList || [],
                });
              }
            }
          );
        }
      );
    }
  }

  // 面试评价
  queryInterview = () => {
    this.$http["selectListByGroup"]({
      dicGroup: "INTERVIEW_EVALUATION",
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          interview: res.data || [],
        });
      }
    });
  };
  // 面试评价选择
  interviewCheck = (item) => {
    const { interview } = this.state;
    interview.map((v) => {
      if (v.dicKey === item.dicKey) {
        v.checked = !v.checked;
      }
    });
    this.setState({ interview });
  };

  // 查询省市列表
  queryProvinceList = (open, key) => {
    if (open) {
      this.$http["queryProvinceList"]({}).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data.provinceList || [],
          });
        }
      });
    }
  };
  // 省市选择
  provinceChange = (value, selectedOptions, type) => {
    console.log(value, selectedOptions);
    if (type === "workProvince") {
      // 工作城市
      this.setState({
        workProvinceName: selectedOptions[0]
          ? selectedOptions[0].addressName
          : "",
        workCityName: selectedOptions[1]
          ? selectedOptions[1].addressName
          : "",
      });
    } else if (type === "socialProvince") {
      // 社保缴纳地
      this.setState({
        socialProvinceName: selectedOptions[0]
          ? selectedOptions[0].addressName
          : "",
        socialCityName: selectedOptions[1]
          ? selectedOptions[1].addressName
          : "",
      });
    }
  };

  // 下拉框数据查询
  selectListByGroup = (open, key) => {
    if (open) {
      this.$http["selectListByGroup"]({
        dicGroup: key,
      }).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data || [],
          });
        }
      });
    }
  };

  // 部门名称下拉框数据查询
  selectDepartment = (open, key) => {
    this.$http["departmentPermissions"]({}).then((res) => {
      if (res.code === 200) {
        this.setState({
          departmentList: res.data || [],
        });
      }
    });
  };

  // 岗位下拉框
  queryPost = (open, key) => {
    if (open) {
      this.$http["selectPost"]({}).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data || [],
          });
        }
      });
    }
  };

  // 职级下拉框
  queryLevel = (open, key) => {
    if (open) {
      this.$http["selectLevel"]({}).then((res) => {
        if (res.code === 200) {
          this.setState({
            [key]: res.data || [],
          });
        }
      });
    }
  };

  disabledPositiveTime = (current) => {
    console.log(current);
    const { form } = this.props;
    const date = form.getFieldValue("hireDate");
    const time = moment(date).format("YYYY-MM-DD");
    if (date) {
      return current && current < moment(time);
    }
  };

  disabledExpireTime = (current) => {
    console.log("current ===>", current);
    const { form } = this.props;
    const date = form.getFieldValue("hireDate");
    console.log("getFieldsValue", form.getFieldsValue());
    console.log("date -->", date);
    if (date) {
      return current && current < date.endOf("day");
    }
  };

  hireDatechange = (date, dateString) => {
    const { form } = this.props;
    form.setFieldsValue({
      // positiveTime: undefined,
      expireOfContract: undefined,
    });

    let durationProbation = form.getFieldValue(
      "durationProbation"
    );
    if (durationProbation) {
      durationProbation = parseInt(
        durationProbation.trim()
      );
      if (typeof durationProbation == "number") {
        if (durationProbation < 1000) {
          if (dateString) {
            form.setFieldsValue({
              positiveTime: moment(dateString).add(
                durationProbation,
                "M"
              ),
            });
          }
        }
      }
    }
  };

  positiveTimeChange = (date, dateString) => {
    const { form } = this.props;
    form.setFieldsValue({
      expireOfContract: undefined,
    });
  };

  // 部门查询树状结构
  getTreeNode = (item) => {
    return (
      <TreeNode
        key={item.departmentSonId}
        value={item.departmentSonId}
        title={item.departmentSonName}
      >
        {item.dsTree
          ? item.dsTree.map((c_item) => {
              return this.getTreeNode(c_item);
            })
          : ""}
      </TreeNode>
    );
  };

  render() {
    const { fileList, interview } = this.state;
    const { form, offerDetail } = this.props;
    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: {
        span: 12,
      },
      wrapperCol: {
        span: 12,
      },
    };
    console.log("offerDetail---->", offerDetail);
    return (
      <Form
        {...formItemLayout}
        className="process-form"
        style={{ height: "100%" }}
      >
        <Row className="wrap-block">
          <div className="block-title">员工信息</div>
          <Row>
            <Col span={8}>
              <FormItem label="姓名">
                {getFieldDecorator("staffName", {
                  initialValue: offerDetail.staffName || "",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "姓名必填",
                    },
                    { validator: this.validatorName },
                  ],
                })(
                  <Input
                    placeholder="请输入姓名"
                    allowClear
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="证件类型">
                {getFieldDecorator("papersType", {
                  initialValue: offerDetail.papersType
                    ? {
                        key: offerDetail.papersType,
                        label: offerDetail.papersTypeName,
                      }
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "证件类型必填",
                    },
                  ],
                })(
                  <Select
                    placeholder="请选择证件类型"
                    allowClear
                    labelInValue
                    onDropdownVisibleChange={(value) =>
                      this.selectListByGroup(
                        value,
                        "ID_TYPE"
                      )
                    }
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                  >
                    {this.state["ID_TYPE"] &&
                      this.state["ID_TYPE"].map((item) => {
                        return (
                          <Option
                            key={item.dicKey}
                            value={item.dicKey}
                          >
                            {item.dicValue}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="证件号码">
                {getFieldDecorator("papersNumber", {
                  initialValue:
                    offerDetail.papersNumber || "",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "证件号码必填",
                    },
                    // {
                    //   pattern: new RegExp(/^[0-9A-Z]*$/, "g"),
                    //   message: "请输入正确证件号码"
                    // },
                    // {
                    //   max: 18,
                    //   message: "证件号码不能超过18位"
                    // }
                    {
                      validator: this.validatorNumber,
                    },
                  ],
                })(
                  <Input
                    placeholder="请输入证件号码"
                    allowClear
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormItem label="手机号">
                {getFieldDecorator("telephone", {
                  initialValue: offerDetail.telephone || "",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "手机号必填",
                    },
                    { validator: this.validatorPhone },
                  ],
                })(
                  <Input placeholder="请输入" allowClear />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="性别">
                {getFieldDecorator("sex", {
                  initialValue: offerDetail.sex
                    ? {
                        key: offerDetail.sex,
                        label: offerDetail.sexName,
                      }
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "性别必填",
                    },
                  ],
                })(
                  <Select
                    placeholder="请选择性别"
                    allowClear
                    labelInValue
                    onDropdownVisibleChange={(value) =>
                      this.selectListByGroup(
                        value,
                        "GENDER"
                      )
                    }
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                  >
                    {this.state["GENDER"] &&
                      this.state["GENDER"].map((item) => {
                        return (
                          <Option
                            key={item.dicKey}
                            value={item.dicKey}
                          >
                            {item.dicValue}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="渠道来源">
                {getFieldDecorator("sourceOfDitch", {
                  initialValue: offerDetail.sourceOfDitch
                    ? {
                        key: offerDetail.sourceOfDitch,
                        label:
                          offerDetail.sourceOfDitchName,
                      }
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "渠道来源必填",
                    },
                  ],
                })(
                  <Select
                    placeholder="请选择渠道来源"
                    allowClear
                    labelInValue
                    onDropdownVisibleChange={(value) =>
                      this.selectListByGroup(
                        value,
                        "SOURCE_FROM"
                      )
                    }
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                  >
                    {this.state["SOURCE_FROM"] &&
                      this.state["SOURCE_FROM"].map(
                        (item) => {
                          return (
                            <Option
                              key={item.dicKey}
                              value={item.dicKey}
                            >
                              {item.dicValue}
                            </Option>
                          );
                        }
                      )}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem
                label="简历"
                required
                labelCol={{ span: 4 }}
              >
                <Upload
                  name="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png,image/jpg,image/jpeg,.doc,.docx"
                  fileList={fileList}
                  onChange={this.handleChange}
                  beforeUpload={this.beforeUpload}
                >
                  <Button>
                    <Icon type="upload" /> 上传文件
                  </Button>
                </Upload>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <FormItem
              label="面试评价"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 20 }}
              className="interview-form-item"
            >
              <div className="interview-wrap">
                {interview.map((item) => {
                  return (
                    <span
                      key={item.dicKey}
                      className={
                        item.checked
                          ? "interview-block interview-active"
                          : "interview-block"
                      }
                      onClick={() =>
                        this.interviewCheck(item)
                      }
                    >
                      {item.dicValue}
                    </span>
                  );
                })}
              </div>
              {getFieldDecorator(
                "interviewEstimateCustom",
                {
                  initialValue:
                    offerDetail.interviewEstimateCustom ||
                    "",
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "面试评价必填",
                    },
                    { validator: this.validatorInterview },
                  ],
                }
              )(
                <TextArea
                  rows={4}
                  maxLength={128}
                  placeholder="请输入面试评价"
                  allowClear
                  style={{ width: 575 }}
                />
              )}
            </FormItem>
          </Row>
          <Row>
            <FormItem
              label="备注"
              labelCol={{ span: 2 }}
              className="remark-form-item"
            >
              {getFieldDecorator("offerRemark", {
                initialValue: offerDetail.offerRemark || "",
                rules: [
                  { validator: this.validatorRemark },
                ],
              })(
                <TextArea
                  rows={4}
                  maxLength={128}
                  placeholder="请输入备注"
                  allowClear
                  style={{ width: 575 }}
                />
              )}
            </FormItem>
          </Row>
        </Row>

        <Row className="wrap-block">
          <div className="block-title">岗位信息</div>
          <Row>
            <Col span={8}>
              <FormItem label="部门名称">
                {getFieldDecorator("departmentId", {
                  initialValue: offerDetail.departmentId
                    ? {
                        value: offerDetail.departmentId,
                        label: offerDetail.departmentName,
                      }
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "部门名称必填",
                    },
                  ],
                })(
                  <TreeSelect
                    showSearch
                    placeholder="请选择部门名称"
                    style={{ height: 32 }}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="请选择"
                    allowClear
                    labelInValue
                    treeNodeFilterProp="title"
                    showCheckedStrategy={SHOW_PARENT}
                    getTooltipPopupContainer={(
                      triggerNode
                    ) => triggerNode.parentElement}
                  >
                    {(this.state.departmentList || []).map(
                      (item) => {
                        return this.getTreeNode(item);
                      }
                    )}
                  </TreeSelect>
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="岗位">
                {getFieldDecorator("positionId", {
                  initialValue: offerDetail.positionId
                    ? {
                        key: offerDetail.positionId,
                        label: offerDetail.positionName,
                      }
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "岗位必填",
                    },
                  ],
                })(
                  <Select
                    placeholder="请选择岗位"
                    allowClear
                    labelInValue
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onDropdownVisibleChange={(value) =>
                      this.queryPost(value, "position")
                    }
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                  >
                    {this.state["position"] &&
                      this.state["position"].map((item) => {
                        return (
                          <Option
                            key={item.postId}
                            value={item.postId}
                          >
                            {item.postName}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="职级">
                {getFieldDecorator("rankId", {
                  initialValue: offerDetail.rankId
                    ? {
                        key: offerDetail.rankId,
                        label: offerDetail.rankName,
                      }
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "职级必填",
                    },
                  ],
                })(
                  <Select
                    placeholder="请选择职级"
                    allowClear
                    labelInValue
                    onDropdownVisibleChange={(value) =>
                      this.queryLevel(value, "RANK")
                    }
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                  >
                    {this.state["RANK"] &&
                      this.state["RANK"].map((item) => {
                        return (
                          <Option
                            key={item.occupationId}
                            value={item.occupationId}
                          >
                            {item.occupationName}
                          </Option>
                        );
                      })}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormItem label="入职时间">
                {getFieldDecorator("hireDate", {
                  initialValue: offerDetail.hireDateStr
                    ? moment(
                        offerDetail.hireDateStr,
                        "YYYY-MM-DD"
                      )
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "入职时间必填",
                    },
                  ],
                })(
                  <DatePicker
                    placeholder="请选择入职日期"
                    onChange={this.hireDatechange}
                    getCalendarContainer={(triggerNode) =>
                      triggerNode.parentNode
                    }
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              {offerDetail.contractType === "LABOR" ? (
                <FormItem label="转正时间">
                  {getFieldDecorator("positiveTime", {
                    initialValue: offerDetail.positiveTimeStr
                      ? moment(
                          offerDetail.positiveTimeStr,
                          "YYYY-MM-DD"
                        )
                      : undefined,
                    rules: [
                      {
                        required: true,
                        message: "转正时间必填",
                      },
                    ],
                  })(
                    <DatePicker
                      disabled={true}
                      placeholder="请选择转正时间"
                      disabledDate={
                        this.disabledPositiveTime
                      }
                      onChange={this.positiveTimechange}
                      getCalendarContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    />
                  )}
                </FormItem>
              ) : (
                <FormItem label="转正时间">
                  <span>--</span>
                </FormItem>
              )}
            </Col>
            <Col span={8}>
              <FormItem label="合同到期日">
                {getFieldDecorator("expireOfContract", {
                  initialValue: offerDetail.expireOfContractStr
                    ? moment(
                        offerDetail.expireOfContractStr,
                        "YYYY-MM-DD"
                      )
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "合同到期时间必填",
                    },
                  ],
                })(
                  <DatePicker
                    placeholder="请选择合同到期时间"
                    disabledDate={this.disabledExpireTime}
                    onChange={this.expireTimechange}
                    getCalendarContainer={(triggerNode) =>
                      triggerNode.parentNode
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormItem label="工作城市">
                {getFieldDecorator("workProvinceId", {
                  initialValue: offerDetail.workProvinceId
                    ? [
                        offerDetail.workProvinceId,
                        offerDetail.workCityId,
                      ]
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "工作城市必填",
                    },
                  ],
                })(
                  <Cascader
                    fieldNames={{
                      label: "addressName",
                      value: "addressCode",
                      children: "cityList",
                    }}
                    options={this.state.workProvince || []}
                    onChange={(value, selectedOptions) =>
                      this.provinceChange(
                        value,
                        selectedOptions,
                        "workProvince"
                      )
                    }
                    placeholder="请选择工作城市"
                    onPopupVisibleChange={(value) =>
                      this.queryProvinceList(
                        value,
                        "workProvince"
                      )
                    }
                  />
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="合同性质">
                {getFieldDecorator("contractType", {
                  initialValue: offerDetail.contractType
                    ? {
                        key: offerDetail.contractType,
                        label: offerDetail.contractName,
                      }
                    : undefined,
                  rules: [
                    {
                      required: true,
                      message: "合同性质必填",
                    },
                  ],
                })(
                  <Select
                    placeholder="请选择合同性质"
                    allowClear
                    labelInValue
                    onDropdownVisibleChange={(value) =>
                      this.selectListByGroup(
                        value,
                        "CONTRACT_NATURE"
                      )
                    }
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    onSelect={(value) => {
                      form.setFieldsValue({
                        salaryType:
                          value.key === "LABOR"
                            ? SALARYTYPE[1]
                            : undefined,
                      });
                      console.log(
                        "offerDetail--->",
                        offerDetail
                      );
                      offerDetail.contractType = value.key;
                      this.setState({
                        offerDetail,
                      });
                    }}
                  >
                    {this.state["CONTRACT_NATURE"] &&
                      this.state["CONTRACT_NATURE"].map(
                        (item) => {
                          return (
                            <Option
                              key={item.dicKey}
                              value={item.dicKey}
                            >
                              {item.dicValue}
                            </Option>
                          );
                        }
                      )}
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="是否校招">
                {getFieldDecorator("onCampus", {
                  initialValue:
                    offerDetail.onCampus != null
                      ? offerDetail.onCampus
                      : "",
                  rules: [
                    {
                      required: true,
                      message: "是否校招必填",
                    },
                  ],
                })(
                  <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <FormItem
                label="是否其他公司转签"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                {getFieldDecorator("companySigned", {
                  initialValue:
                    offerDetail.companySigned != null
                      ? offerDetail.companySigned
                      : "",
                  rules: [
                    {
                      required: true,
                      message: "是否其他公司转签必填",
                    },
                  ],
                })(
                  <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="是否部门负责人"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                {getFieldDecorator("deptHead", {
                  initialValue:
                    offerDetail.deptHead != null
                      ? offerDetail.deptHead
                      : "",
                  rules: [
                    {
                      required: true,
                      message: "是否部门负责人必填",
                    },
                  ],
                })(
                  <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={0}>否</Radio>
                  </Radio.Group>
                )}
              </FormItem>
            </Col>
          </Row>
        </Row>
        <Row
          className="wrap-block"
          style={{ marginBottom: 0 }}
        >
          {offerDetail.contractType && (
            <>
              <div className="block-title">薪资福利</div>
              <Row>
                <Col span={8}>
                  <FormItem label="薪资类型">
                    {getFieldDecorator("salaryType", {
                      initialValue: offerDetail.salaryType
                        ? {
                            key: offerDetail.salaryType,
                            label:
                              offerDetail.salaryTypeName,
                          }
                        : SALARYTYPE[1],
                      rules: [
                        {
                          required: true,
                          message: "薪资类型必填",
                        },
                      ],
                    })(
                      <Select
                        placeholder="请选择薪资类型"
                        allowClear
                        labelInValue
                        disabled={
                          offerDetail.contractType ===
                          "LABOR"
                            ? true
                            : false
                        }
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                      >
                        {SALARYTYPE.map((item) => {
                          return (
                            <Option
                              key={item.key}
                              value={item.key}
                            >
                              {item.label}
                            </Option>
                          );
                        })}
                      </Select>
                    )}
                  </FormItem>
                </Col>
                {offerDetail.contractType === "LABOR" ? (
                  <>
                    <Col span={8}>
                      <FormItem label="试用期薪资">
                        {getFieldDecorator("trySalary", {
                          initialValue:
                            offerDetail.trySalary || "",
                          rules: [
                            {
                              required: true,
                              whitespace: true,
                              message: "试用期薪资必填",
                            },
                            {
                              validator: this
                                .validatorMoney,
                            },
                          ],
                        })(
                          <Input
                            placeholder="请输入试用期薪资"
                            allowClear
                            style={{ width: "70%" }}
                          />
                        )}
                        <span style={{ marginLeft: 11 }}>
                          元
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="转正薪资">
                        {getFieldDecorator(
                          "regularSalary",
                          {
                            initialValue:
                              offerDetail.regularSalary ||
                              "",
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "转正薪资必填",
                              },
                              {
                                validator: this
                                  .validatorMoney,
                              },
                            ],
                          }
                        )(
                          <Input
                            placeholder="请输入转正薪资"
                            allowClear
                            style={{ width: "70%" }}
                          />
                        )}
                        <span style={{ marginLeft: 11 }}>
                          元
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="试用期时长">
                        {getFieldDecorator(
                          "durationProbation",
                          {
                            initialValue:
                              offerDetail.durationProbation !=
                              null
                                ? offerDetail.durationProbation +
                                  ""
                                : "",
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "试用期时长必填",
                              },
                              {
                                validator: this
                                  .validatorMonth,
                              },
                            ],
                          }
                        )(
                          <Input
                            placeholder="请输入试用期时长"
                            allowClear
                            style={{ width: 110 }}
                            onChange={
                              this.durationProbationChange
                            }
                          />
                        )}
                        <span style={{ marginLeft: 11 }}>
                          个月
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="社保缴纳地">
                        {getFieldDecorator(
                          "socialProvinceId",
                          {
                            initialValue: offerDetail.socialProvinceId
                              ? [
                                  offerDetail.socialProvinceId,
                                  offerDetail.socialCityId,
                                ]
                              : undefined,
                            rules: [
                              {
                                required: true,
                                message: "社保缴纳地必填",
                              },
                            ],
                          }
                        )(
                          <Cascader
                            fieldNames={{
                              label: "addressName",
                              value: "addressCode",
                              children: "cityList",
                            }}
                            options={
                              this.state.socialProvince ||
                              []
                            }
                            onChange={(
                              value,
                              selectedOptions
                            ) =>
                              this.provinceChange(
                                value,
                                selectedOptions,
                                "socialProvince"
                              )
                            }
                            placeholder="请选择社保缴纳地"
                            onPopupVisibleChange={(value) =>
                              this.queryProvinceList(
                                value,
                                "socialProvince"
                              )
                            }
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="社保基数">
                        {getFieldDecorator(
                          "socialInsuranceBase",
                          {
                            initialValue:
                              offerDetail.socialInsuranceBase ||
                              "",
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "社保基数必填",
                              },
                              {
                                validator: this
                                  .validatorMoney,
                              },
                            ],
                          }
                        )(
                          <Input
                            placeholder="请输入社保基数"
                            allowClear
                            style={{ width: "70%" }}
                          />
                        )}
                        <span style={{ marginLeft: 11 }}>
                          元
                        </span>
                      </FormItem>
                    </Col>
                    <Col span={8}>
                      <FormItem label="公积金基数">
                        {getFieldDecorator(
                          "accumulationFundBase",
                          {
                            initialValue:
                              offerDetail.accumulationFundBase ||
                              "",
                            rules: [
                              {
                                required: true,
                                whitespace: true,
                                message: "公积金基数必填",
                              },
                              {
                                validator: this
                                  .validatorMoney,
                              },
                            ],
                          }
                        )(
                          <Input
                            placeholder="请输入公积金基数"
                            allowClear
                            style={{ width: "70%" }}
                          />
                        )}
                        <span style={{ marginLeft: 11 }}>
                          元
                        </span>
                      </FormItem>
                    </Col>
                  </>
                ) : (
                  <Col span={8}>
                    <FormItem label="薪资">
                      {getFieldDecorator("salaryNumber", {
                        initialValue:
                          offerDetail.salaryNumber || "",
                        rules: [
                          {
                            required: true,
                            whitespace: true,
                            message: "薪资必填",
                          },
                          {
                            validator: this.validatorMoney,
                          },
                        ],
                      })(
                        <Input
                          placeholder="请输入薪资"
                          allowClear
                          style={{ width: "70%" }}
                        />
                      )}
                      <span style={{ marginLeft: 11 }}>
                        元
                      </span>
                    </FormItem>
                  </Col>
                )}
              </Row>
            </>
          )}
        </Row>
      </Form>
    );
  }
}
export default AddOfferApproval;
