/**
* @fileoverview 系统管理---岗位管理 -- 搜索条件
* @author  蒋西园
*/
import React,{ Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Select, Row, Col } from 'antd';
import './index.less'

const FormItem = Form.Item;
const Option = Select.Option;


class SearchPostlist extends Component{
    // 提交表单
    handleSubmit = () => {
        this.props.form.validateFieldsAndScroll((err,values)=>{
            if(err){
                return false
            }
            const data = {};
            // 去除含有undefined的item
            Object.keys(values).forEach(res=>{
                if(values[res]){
                    data[res] = values[res]
                }
            })
            this.props.handleSearchBtn(data);
        })
    }
    handleReset = () => {
        this.props.form.resetFields();
        this.props.handleSearchBtn({});
    }
	render(){
		const { getFieldDecorator } = this.props.form;
		return (
            <div className="search-form">
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
                    <Row gutter={24}>
                        <Col span={5}>
                            <FormItem label="岗位" style={{marginLeft:24}}>
                                {
                                    getFieldDecorator('postName', {
                                        rules: [
                                            { required: false,whitespace:true,  message: '请输入岗位' },
                                        ],
                                    })(
                                        <Input placeholder="请输入" allowClear />
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col span={19} style={{marginLeft:'-34px'}}>
                            <Button onClick={this.handleReset}>重置</Button>
                            <Button type="primary"  onClick={this.handleSubmit} style={{ marginLeft: 14 }}>查询</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
		)
	}
}

export default  withRouter( Form.create()(SearchPostlist))
