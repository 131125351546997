/**
* @fileoverview 系统管理---账号设置 -- 搜索条件
* @author  蒋西园
*/
import React,{ Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Row, Col, DatePicker,TreeSelect } from 'antd';
import moment from 'moment';
import './index.less'

const FormItem = Form.Item; // Form.Item
const MonthPicker = DatePicker.MonthPicker; // 日期框
const { SHOW_PARENT,TreeNode  } = TreeSelect;  // 下拉选择--树状


class SearchAttendancelist extends Component{
     constructor(props) {
        super(props)
        this.state = {
            departmentIds: [],  // 部门id
        }
    }
    // 提交表单
    handleSubmit = () => {
        this.props.form.validateFieldsAndScroll((err,values)=>{
            if(err){
                return false
            }
            const data = {};
            // 去除含有undefined的item
            Object.keys(values).forEach(res=>{
                if (values[res] && res === 'cycleMonth') {
                    console.log(values.time)
                    data.cycleMonth = moment(values[res]).format('YYYY/MM');
                }else if(values[res]!==undefined){
                    data[res] = values[res]
                }
            })
            data.departmentIds= this.state.departmentIds[0]?this.state.departmentIds:[]
            this.props.handleSearchBtn(data);
        })
    }

    // 重置
    handleReset = () => {
        this.props.form.resetFields();
        this.props.handleSearchBtn({ departmentIds: [] });
        this.setState({ departmentIds: [] });
    }

    // 选择部门
    onChange = value => {
        const departmentIds = []
        departmentIds.push(value)
        this.props.departmentList.map(item => {
            if (item.departmentSonId===value&&item.dsTree ) {
                getChildDepartmentId(item.dsTree )
            } else if(item.dsTree ){
                mapItem(item.dsTree )
            }
        })

        function mapItem(item) {
            item.map(c_item => {
                if (c_item.departmentSonId === value) {
                    if (c_item.dsTree ) {
                        getChildDepartmentId(c_item.dsTree )
                    }   
                } else if(item.dsTree ){
                    mapItem(item.dsTree )
                }
            })
            
        }

        function getChildDepartmentId(item) {
            if (item.departmentSonId) {
                departmentIds.push(item.departmentSonId)
            }
            item.map(c_item => {
                departmentIds.push(c_item.departmentSonId)
                if (c_item.dsTree ) {
                    getChildDepartmentId(c_item.dsTree )
                }
            })
        }
        this.setState({ departmentIds: departmentIds });
    };

    // 部门查询树状结构
    getTreeNode = (item) => {
        return <TreeNode key={item.departmentSonId} value={item.departmentSonId} title={item.departmentSonName} >
               { item.dsTree ?item.dsTree .map(c_item => {
                   return this.getTreeNode(c_item)
               }):""
            }
        </TreeNode>
    }

	render(){
		const { getFieldDecorator } = this.props.form;
		return (
            <div className="search-form">
                <Form>
                    <Row>
                        <Col>
                            <FormItem label="姓名" style={{marginLeft:16}}>
                                {
                                    getFieldDecorator('staffName',{
                                        rules: [
                                            { max: 32, message: '请输入32字以内的名字'}
                                        ],
                                        
                                    })(
                                        <Input placeholder="请输入" allowClear style={{ width: 180 }}/>
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem label="部门" style={{marginLeft:16}}>
                                {
                                    getFieldDecorator('departmentIds')(
                                         <TreeSelect
                                            showSearch
                                            style={{ height:32 }}
                                            value={this.state.departmentIds}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder="请选择"
                                            allowClear
                                            onChange={this.onChange}
                                            showCheckedStrategy={SHOW_PARENT}
                                            treeNodeFilterProp='title'
                                            style={{ width: 180 }}
                                        >
                                            {(this.props.departmentList || []).map(item => {return this.getTreeNode(item) })}
                                        </TreeSelect>
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col>
                            <FormItem label="年月" style={{marginLeft:16}}>
                                {
                                    getFieldDecorator('cycleMonth')(
                                        <MonthPicker format='YYYY-MM' style={{ width: 180 }}/>
                                    )
                                }
                            </FormItem>
                        </Col>
                        <Col>
                            <Button onClick={this.handleReset} style={{ marginLeft: 16 }}>重置</Button>
                            <Button type="primary"  onClick={this.handleSubmit} style={{ marginLeft: 16 }}>查询</Button>
                        </Col>
                    </Row>
                </Form>
            </div>
		)
	}
}

export default  withRouter( Form.create()(SearchAttendancelist))
