/**
 * @description 二级维度
 */

import React from "react";
import ArrivalMonth from "../arrivalMonth"; // 到岗月份
import RecruitmentSpecialist from "../recruitmentSpecialist"; // 招聘专员
import MonthOfDeparture from "../monthOfDeparture"; // 离职月份
import Level from "../level"; // 级别
class TwoStageSelect extends React.Component {
  render() {
    const { onChange, type } = this.props;
    const TYPE = {
      monthOfArrival: <ArrivalMonth onChange={onChange} />,
      recruitmentSpecialist: (
        <RecruitmentSpecialist onChange={onChange} />
      ),
      monthOfDeparture: (
        <MonthOfDeparture onChange={onChange} />
      ),
      level: <Level onChange={onChange} />,
    };
    return type && TYPE[type];
  }
}

export default TwoStageSelect;
