import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Select,
  Row,
  Col,
} from "antd";
import "./index.less";

const FormItem = Form.Item;
const Option = Select.Option;

class SearchUserlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "Hello, World",
    };
  }
  // 提交表单
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll(
      (err, values) => {
        if (err) {
          return false;
        }
        const data = {};
        // 去除含有undefined的item
        Object.keys(values).forEach((res) => {
          if (values[res]) {
            if (res === "probation" || res === "leaved") {
              data[res] = {
                "1": "",
                "2": true,
                "3": false,
              }[values[res]];
            } else {
              data[res] = values[res];
            }
          }
        });
        this.props.handleSearchBtn(data);
      }
    );
  };
  handleReset = () => {
    this.props.form.resetFields();
    this.props.handleSearchBtn({});
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="">
        <Form layout="inline">
          {/* <FormItem label="状态">
						{getFieldDecorator("leaved")(
							<Select placeholder="请选择" allowClear style={{ width: 150 }}>
								<Option value="1">全部</Option>
								<Option value="2">离职</Option>
								<Option value="3">未离职</Option>
							</Select>
						)}
					</FormItem> */}
          <FormItem label="试用期">
            {getFieldDecorator("probation")(
              <Select
                placeholder="请选择"
                allowClear
                style={{ width: 150 }}
              >
                <Option value="1">全部</Option>
                <Option value="2">试用期</Option>
                <Option value="3">正式员工</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="姓名">
            {getFieldDecorator("staffName")(
              <Input
                placeholder="请输入"
                allowClear
                style={{ width: 230 }}
              />
            )}
          </FormItem>
          <FormItem>
            <Button onClick={this.handleReset}>重置</Button>
            <Button
              type="primary"
              onClick={this.handleSubmit}
              style={{ marginLeft: 14 }}
            >
              查询
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

export default withRouter(Form.create()(SearchUserlist));
