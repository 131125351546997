import React, { Component } from "react";
import "./App.less";
import { NavLink, withRouter } from "react-router-dom";
import { Layout, Menu, Dropdown, Avatar, Icon, message } from "antd";

import Api from "./api/apis";
import { DepartmentContext } from "./utils/context";
import utils from "./utils/utils";
// import userDefault from './assets/images/userDefault.png'
const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

// 用户信息
// const DepartmentContext = React.createContext('')

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // 菜单数据
      menuData: [
        // {
        //   key: "workbench",
        //   menuUrl: "/workbench/index",
        //   menuName: "工作台",
        //   menuIcon: "workbench",
        //   chirdren: [
        //     {
        //       key: "schedule",
        //       menuUrl: "/workbench/schedule",
        //       menuName: "待办事项",
        //       menuIcon: "schedule"
        //     },
        //     {
        //       key: "newProcess",
        //       menuUrl: "/workbench/newProcess",
        //       menuName: "新建流程",
        //       menuIcon: "newProcess"
        //     },
        //     {
        //       key: "completedItems",
        //       menuUrl: "/workbench/completedItems",
        //       menuName: "已办事项",
        //       menuIcon: "completedItems"
        //     },
        //     {
        //       key: "launch",
        //       menuUrl: "/workbench/launch",
        //       menuName: "我发起的",
        //       menuIcon: "launch"
        //     },
        //     {
        //       key: "jurisdiction",
        //       menuUrl: "/workbench/jurisdiction",
        //       menuName: "管辖部门",
        //       menuIcon: "jurisdiction"
        //     }
        //   ]
        // },
        // {
        //   key: "personnelMatters",
        //   menuUrl: "/personnelMatters/employeeRoster",
        //   menuName: "人事",
        //   menuIcon: "personnelMatters",
        //   chirdren: [
        //     {
        //       key: "employeeRoster",
        //       menuUrl: "/personnelMatters/employeeRoster",
        //       menuName: "员工花名册",
        //       menuIcon: "employeeRoster"
        //     },
        //     {
        //       key: "contractManagement",
        //       menuUrl: "/personnelMatters/contractManagement",
        //       menuName: "合同管理",
        //       menuIcon: "contractManagement"
        //     },
        //     {
        //       key: "attendanceManagement",
        //       menuUrl: "/personnelMatters/attendanceManagement",
        //       menuName: "考勤管理",
        //       menuIcon: "attendanceManagement"
        //     },
        //     {
        //       key: "salaryManagement",
        //       menuUrl: "/personnelMatters/salaryManagement",
        //       menuName: "薪资管理",
        //       menuIcon: "salaryManagement"
        //     },
        //     {
        //       key: "queryProcess",
        //       menuUrl: "/personnelMatters/queryProcess",
        //       menuName: "流程查询",
        //       menuIcon: "queryProcess"
        //     }
        //   ]
        // },
        // {
        //   key: "systemSetup",
        //   menuUrl: "/systemSetup/organizationalStructure",
        //   menuName: "系统设置",
        //   menuIcon: "systemSetup",
        //   chirdren: [
        //     {
        //       key: "organizationalStructure",
        //       menuUrl: "/systemSetup/organizationalStructure",
        //       menuName: "组织架构",
        //       menuIcon: "organizationalStructure"
        //     },
        //     {
        //       key: "processConfig",
        //       menuUrl: "/systemSetup/processConfig",
        //       menuName: "流程配置",
        //       menuIcon: "processConfig"
        //     },
        //     {
        //       key: "wagePayment",
        //       menuUrl: "/systemSetup/wagePayment",
        //       menuName: "工资发放",
        //       menuIcon: "wagePayment"
        //     },
        //     {
        //       key: "accountManagement",
        //       menuUrl: "/systemSetup/accountManagement",
        //       menuName: "账号管理",
        //       menuIcon: "accountManagement"
        //     },
        //     {
        //       key: "roleManagement",
        //       menuUrl: "/systemSetup/roleManagement",
        //       menuName: "角色管理",
        //       menuIcon: "roleManagement"
        //     },
        //     {
        //       key: "postManagement",
        //       menuUrl: "/systemSetup/postManagement",
        //       menuName: "岗位管理",
        //       menuIcon: "postManagement"
        //     },
        //     {
        //       key: "rankManagement",
        //       menuUrl: "/systemSetup/rankManagement",
        //       menuName: "职级管理",
        //       menuIcon: "rankManagement"
        //     },
        //     {
        //       key: "dictionary",
        //       menuUrl: "/systemSetup/dictionary",
        //       menuName: "数据字典",
        //       menuIcon: "dictionary"
        //     },
        //     {
        //       key: "systemBulletin",
        //       menuUrl: "/systemSetup/systemBulletin",
        //       menuName: "系统公告",
        //       menuIcon: "systemBulletin"
        //     }
        //   ]
        // }
      ],
      current: "",
      currentNav: "",
      // 当前一级菜单
      activeMenu: {
        opMenuDTOS: [],
      },
      selectKey: [],
      openKeys: [],
      // 是否展示侧边栏
      isShowSide: true,
      // 用户信息
      userData: {},
      // userData: utils.localDb.get("userData") || {}
    };
  }
  // 切换一级菜单
  handleMenu = (val) => {
    console.log(val);
    let _arr = [];
    _arr.push(val.menuUrl);
    this.setState(
      {
        activeMenu: val,
        selectKey: _arr,
      },
      () => {
        // this.props.history.push(val.opMenuDTOS[0].menuUrl);
      }
    );
  };
  // 设置初始选中菜单
  setInitMenu() {
    const { menuData } = this.state;
    let { selectKey, openKeys } = this.setActiveMenu(menuData);
    this.setState({
      selectKey: selectKey,
      openKeys: openKeys,
    });
  }
  setActiveMenu(menuData, selectKey = [], openKeys = [], parent = "") {
    // let _arr = [];
    menuData.forEach((val, ind, arr) => {
      // 有子元素
      if (!val.menuUrl && val.children && val.children.length > 0) {
        this.setActiveMenu(val.children, selectKey, openKeys, val.menuName);
      } else {
        if (this.props.history.location.pathname.indexOf(val.menuUrl) > -1) {
          selectKey.push(val.menuUrl);
          if (parent) {
            openKeys.push(parent ? parent : val.menuUrl);
          }

          return selectKey;
        }
      }
    });
    return { selectKey, openKeys };
  }
  componentWillMount() {
    let arr = this.props.location.pathname.split("/");
    console.log(arr);
    if (arr) {
      this.setState({
        current: arr[1],
        currentNav: arr[2],
      });
    }
    if (!arr[1] || !arr[2]) {
      this.props.history.replace("/workbench/index");
    }
    const self = this;
    // 登录轮询
    this.$http["menulistForUser"]({
      menuType: 0,
    }).then((res) => {
      if (res.code === 200) {
        this.setState({
          menuData: res.data || [],
        });
      }
    });

    this.queryForPersonal();
  }

  queryForPersonal = () => {
    this.$http["queryForPersonal"]({}).then((res) => {
      if (res.code === 200) {
        if (res.data) {
          this.setState({
            userData: res.data,
          });
        }
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    let arr = nextProps.location.pathname.split("/");
    console.log(arr);
    if (arr) {
      this.setState({
        current: arr[1],
        currentNav: arr[2],
      });
    }

    if (nextProps !== this.props) {
    }
  }

  toggleTheme() {}
  exitLogin() {
    Api.logout({}).then((res) => {
      if (res.code == "200") {
        utils.localDb.clear();
        message.success("退出成功");
        // window.sessionStorage.removeItem("isLogin");
        this.props.history.push("/login");
      }
    });
  }
  toPersonalCenter(type) {
    this.props.history.push({
      pathname: `/${type}`,
      state: { from: "modify" },
    });
  }
  onOpenChange(openKeys) {
    this.setState({
      openKeys,
    });
  }

  handleMenuClick = (e) => {
    console.log(e.key);
    const { menuData } = this.state;
    const checkedItem = menuData.find((item) => {
      return item.menuCode === e.key;
    });
    this.setState(
      {
        current: e.key,
        // currentNav: checkedItem.chirdren[0].key,
      },
      () => {
        if (e.key != "workbench") {
          this.props.history.push(checkedItem.chirdren[0].menuUrl);
        }
      }
    );
  };
  handleNavClick = (item) => {
    this.setState({
      currentNav: item.menuCode,
    });
  };

  render() {
    const { children } = this.props;
    const {
      menuData,
      current,
      currentNav,
      activeMenu,
      selectKey,
      openKeys,
    } = this.state;
    let userData = utils.localDb.get("userData");
    const menu = (
      <Menu>
        <Menu.Item
          onClick={this.toPersonalCenter.bind(
            this,
            "personalInformation",
            "个人信息"
          )}
          key="personalInformation"
          style={{
            display: userData.staffCode == "00000" ? "none" : "",
          }}
        >
          <span
            style={{
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            个人信息
          </span>
        </Menu.Item>
        <Menu.Item
          onClick={this.toPersonalCenter.bind(
            this,
            "personalFiles",
            "个人档案"
          )}
          key="personalFiles"
          style={{
            display: userData.staffCode == "00000" ? "none" : "",
          }}
        >
          <span
            style={{
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            个人档案
          </span>
        </Menu.Item>
        <Menu.Item
          onClick={this.toPersonalCenter.bind(this, "editPassword", "修改密码")}
          key="editPass"
        >
          <span
            style={{
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            修改密码
          </span>
        </Menu.Item>
        <Menu.Item onClick={this.exitLogin.bind(this)} key="loginOut">
          <span
            style={{
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            退出
          </span>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="layout-box">
        <Layout>
          <Header className="header" style={{ background: "#fff", padding: 0 }}>
            <div className="logo">
              {/* <img
                src={require("./assets/image/logo.png")}
                className="logo-img"
                alt="华领管理平台"
              /> */}
              <span className="logo-text">后台管理系统</span>
              <div style={{ display: "inline-block" }}>
                <Menu
                  onClick={this.handleMenuClick}
                  selectedKeys={[current]}
                  mode="horizontal"
                  style={{ borderBottom: "1px solid #fff" }}
                >
                  {menuData.map((item) => {
                    return (
                      <Menu.Item key={item.menuCode}>
                        <NavLink to={item.menuUrl} exact={false}>
                          <img
                            src={
                              item.menuCode == current
                                ? require("./assets/image/icon/" +
                                    item.menuIcon +
                                    "-on.png")
                                : require("./assets/image/icon/" +
                                    item.menuIcon +
                                    ".png")
                            }
                            className="menu-img"
                          />
                          <span className="menu-text">{item.menuName}</span>
                        </NavLink>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </div>
            </div>

            {/* <img src={ userData&&userData.personalPhotoUrl ? userData.personalPhotoUrl : require("../../assets/image/person.png")} alt=""/> */}
            <Dropdown overlay={menu} trigger={["click"]}>
              <div className="layout-user">
                {/* <img src= {userData.avatar} alt=""/> */}
                {userData.avatarUrl ? (
                  <Avatar src={userData.avatarUrl}></Avatar>
                ) : (
                  <Avatar icon="user"></Avatar>
                )}

                <span
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                  }}
                >
                  {userData.staffName}
                </span>

                <Icon type="down" />
              </div>
            </Dropdown>
          </Header>
        </Layout>
        <div className="content-box">
          <div
            style={{
              display: "flex",
              padding: "40px 0 0 40px",
              height: "100%",
            }}
          >
            <div>
              {menuData.length > 0 &&
                menuData[
                  menuData.findIndex((menu) => menu.menuCode == current)
                ] && (
                  <div className="left-nav-block">
                    {menuData[
                      menuData.findIndex((menu) => menu.menuCode == current)
                    ].chirdren.length &&
                      menuData[
                        menuData.findIndex((menu) => menu.menuCode == current)
                      ].chirdren.map((item) => {
                        return item.menuId === 11 ? ( // 隐藏考勤管理页面
                          ""
                        ) : (
                          <div
                            className="nav-left"
                            key={item.menuUrl}
                            onClick={() => this.handleNavClick(item)}
                            onMouseOver={(e) => {
                              e.stopPropagation();
                              this.setState({
                                currentNavOver: item.menuCode,
                              });
                            }}
                          >
                            <NavLink to={item.menuUrl} exact={false}>
                              <div className="layout-menu">
                                {(item.menuCode == currentNav ||
                                  item.menuCode ==
                                    this.state.currentNavOver) && (
                                  <div
                                    className="layout-menu-active"
                                    onMouseOut={(e) => {
                                      e.stopPropagation();
                                      this.setState({
                                        currentNavOver: "",
                                      });
                                    }}
                                  >
                                    <div>
                                      <span className="icon-wrapper">
                                        {item.menuIcon && (
                                          <img
                                            alt=""
                                            src={require("./assets/image/icon/" +
                                              item.menuIcon +
                                              "-on.png")}
                                            className={`layout-menu-icon ${item.menuIcon}-icon`}
                                          />
                                        )}
                                      </span>
                                      <span className="text-menu-active">
                                        {item.menuName}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                <span className="icon-wrapper">
                                  {item.menuIcon && (
                                    <img
                                      alt=""
                                      src={require("./assets/image/icon/" +
                                        item.menuIcon +
                                        ".png")}
                                      className={`layout-menu-icon ${item.menuIcon}-icon`}
                                    />
                                  )}
                                </span>
                              </div>
                            </NavLink>
                          </div>
                        );
                      })}
                  </div>
                )}
            </div>

            <DepartmentContext.Provider
              value={{
                data: userData.department,
                toggleTheme: this.toggleTheme,
                userId: userData.id,
              }}
            >
              <Content className="content-box-main">{children}</Content>
            </DepartmentContext.Provider>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
