import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import "./index.less";

import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表
import ModifyModalShow from "./component/modifyModal"; // 修改应出勤天数弹框
// import LeftTree from "../../../components/leftTreeTwo"; // 左侧树
import LeftTree from "./component/leftTree";
import Pie from "./component/pie"; // 饼图

class MainManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataList: [], // 人员列表数据
      total: 0, // 人员列表总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {}, // 搜索条件
      modifyModalShow: false, // 修改应出勤天数弹框显隐
      disabled: false, // 防重复点击
      sorter: "", // 入职时间排序（asc：升序；desc：降序），默认为desc
      attendanceConfirmStatisticsDTO: {},
      depList: [], // 部门列表
      depNum: 0, // 部门个数
      departmentIds: [], // 当前部门--id
      departmentName: "", // 当前部门 -- name
      personnelData: {}, // 人员统计
      departmentIdList: [],
    };
  }
  componentDidMount() {}
  /**
   * 函数描述: 管辖部门下的人员统计接口
   * @param {string} departmentId   部门id
   * @return {Object}
   */
  queryStatisticsEmpData = (departmentIdsValue) => {
    const { departmentIds } = this.state;
    const params = {
      departmentIds: departmentIdsValue[0]
        ? departmentIdsValue
        : departmentIds,
    };
    this.$http.queryStatisticsEmp(params).then((res) => {
      if (res.code === 200) {
        this.setState({
          personnelData: res.data,
        });
      }
    });
  };

  /**
   * 函数描述: 分页查询管辖部门下的员工信息
   * @param {string} pageNo   	页码
   * @param {string} pageSize 	条数
   * @param {string} leaved  	 	是否离职字段 离职（true）未离职（false）
   * @param {string} departmentId 是否是试用期 试用期（true）正式员工（false）
   * @param {string} staffName   	员工姓名
   * @return {Object}
   */
  queryTableList = (
    departmentIdsValue,
    pageNo = 1,
    pageSize = 10
  ) => {
    const { searchVale, departmentIds } = this.state;
    this.setState(
      {
        loading: true,
        dataList: [],
      },
      () => {
        console.log(
          "sessionStorage.jurisdictionSelectedKeys ->",
          sessionStorage.jurisdictionSelectedKeys
        );
        console.log(
          "departmentIdsValue ->",
          departmentIdsValue
        );
        console.log("departmentIds ->", departmentIds);
        const params = {
          departmentIds:
            (sessionStorage.jurisdictionDepartmentIds &&
              sessionStorage.jurisdictionDepartmentIds.split(
                ","
              )) ||
            ((departmentIdsValue &&
              departmentIdsValue[0]) ||
            departmentIdsValue[0] == "all"
              ? departmentIdsValue
              : departmentIds),
          pageNo,
          pageSize,
          inductionTimeSort: this.state.sorter,
          ...searchVale,
        };
        this.setState({
          departmentIdList: params.departmentIds,
        });
        console.log("wwwwwwwwwwwwwwwwwwwww");
        this.$http
          .queryPageGovernmentDepEmp(params)
          .then((res) => {
            if (res.code == 200) {
              this.setState({
                loading: false,
                pageNo,
                pageSize,
                dataList: res.data.list,
                total: res.data.total,
              });
            } else {
              this.setState({
                loading: false,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList(this.state.departmentIds);
        this.queryStatisticsEmpData(
          this.state.departmentIds
        );
      }
    );
  };

  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (page, sorter) => {
    const { departmentIds } = this.state;
    let sorterOrder = "";
    if (sorter.order) {
      sorterOrder =
        sorter.order === "ascend" ? "asc" : "desc";
    }
    this.setState(
      {
        sorter: sorterOrder,
      },
      () => {
        this.queryTableList(
          departmentIds,
          page.current,
          page.pageSize
        );
      }
    );
  };

  /**
   * 修改应出勤天数 -- 显示弹框
   */
  handleModify = () => {
    this.setState({
      modifyModalShow: true,
    });
  };
  /**
   * 修改应出勤天数 -- 关闭弹框
   */
  handleCancel = () => {
    this.setState({
      modifyModalShow: false,
    });
  };
  /**
   * 函数描述: 修改应出勤天数
   * @param {string} attendanceMonth  考勤月（2020-02）
   * @param {string} attendanceDays 	应出勤天数
   * @param {string} departmentId 	部门id
   * @return {Object}
   */
  handleModifySure = (values) => {
    const {
      attendanceConfirmStatisticsDTO,
      departmentId,
    } = this.state;
    if (values.attendanceDays) {
      const params = {
        attendanceMonth:
          attendanceConfirmStatisticsDTO.confirmMonth,
        attendanceDays: values.attendanceDays * 1,
        departmentId,
      };
      this.$http.setDepAttendance(params).then((res) => {
        if (res.code == 200) {
          message.success("修改成功！");
          this.queryStatisticsEmpData();
        }
      });
    }
    this.handleCancel();
  };

  // 左侧部门树
  changeDepartmentId = (val) => {
    this.setState({
      checkedTreeId: val[0] == "all" ? "" : val[0],
      searchVale: this.state.searchVale,
      departmentIds: val,
    });
  };
  getDepartmentId = (value) => {
    console.log("value ->", value);
    this.setState({
      departmentIds: value,
    });
    this.queryTableList(value);
    this.queryStatisticsEmpData(value);
  };

  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
      modifyModalShow,
      attendanceConfirmStatisticsDTO,
      personnelData,
      departmentIdList,
    } = this.state;
    return (
      <div className="workbench-jurisdiction">
        <div className="jurisdiction-title">管辖部门</div>
        <div className="jurisdiction-top">
          <div className="employee-wrap">
            {/* <LeftTree
              onSelect={this.changeDepartmentId}
              type="管辖部门"
              selectedKeys="jurisdictionSelectedKeys"
              selectedDepartmentIds="jurisdictionDepartmentIds"
              expandedKeys="jurisdictionExpandedKeys"
              getDepartmentId={this.getDepartmentId}
            /> */}
            <LeftTree onSelect={this.getDepartmentId} />
          </div>
          <div className="jurisdiction-blocks">
            <div className="statisticsEmp">
              <div className="block-item">
                <div className="item-title">在职人员</div>
                <Pie personnelData={personnelData} />
              </div>
              <div className="block-item">
                <div className="item-title">
                  本月人员流动
                </div>
                <div className="item-main-wrap">
                  <div className="main-item">
                    <div className="number-value">
                      <span>
                        {personnelData.joinEmployeesNum ||
                          0}
                      </span>
                      <span className="value-text">人</span>
                    </div>
                    <div className="number-label">入职</div>
                  </div>
                  <div className="main-item">
                    <div className="number-value origin-text">
                      <span>
                        {personnelData.leavelEmployeesNum ||
                          0}
                      </span>
                      <span className="value-text">人</span>
                    </div>
                    <div className="number-label">离职</div>
                  </div>
                  <div className="main-item">
                    <div className="number-value green-text">
                      <span>
                        {personnelData.netIncrease || 0}
                      </span>
                    </div>
                    <div className="number-label">
                      净增长
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="jurisdiction-content">
              <div className="content-top">
                <SearchForm
                  handleSearchBtn={this.handleSearchBtn}
                />
              </div>
              <div className="content-main">
                <Table
                  loading={loading}
                  history={this.props.history}
                  data={dataList}
                  total={total}
                  pageNo={pageNo}
                  pageSize={pageSize}
                  departmentIdList={departmentIdList}
                  handleChangePage={this.handleChangePage} // 切换页码
                />
              </div>
            </div>
            {modifyModalShow && (
              <ModifyModalShow
                Item={attendanceConfirmStatisticsDTO}
                modifyModalShow={modifyModalShow}
                handleCancel={this.handleCancel}
                handleModifySure={this.handleModifySure}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MainManagement);
