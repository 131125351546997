import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Timeline,
  Icon,
  Form,
  Select,
  Row,
  Col,
  Button,
  Input,
  Upload,
  message,
  Radio,
  DatePicker,
  Modal,
  InputNumber,
  TreeSelect,
  Cascader,
  Table,
} from "antd";

import uploadIcon from "../../../../../assets/image/upload-icon.png";
import Http from "../../../../../api/fetch";
import enlargeIcon from "../../../../../assets/image/enlarge.png";
import deleteIcon from "../../../../../assets/image/delete.png";
import moment from "moment";
import util from "../../../../../utils/utils";
import SelectStaff from "../../../../../components/selectStaff";

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;
const { TreeNode } = TreeSelect;
const { MonthPicker, RangePicker } = DatePicker;
const dateFormat = "YYYY/MM/DD";

class AddForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      imageUrl: {
        byz: "",
        xwz: "",
      },
      fileList: [],
      previewUrl: "",
      isLeave: "0",
      previewVisible: false,
      isOtherCompanyTransfer: "0",
      staffAnnexs: [
        {
          annexType: "1",
        },
        {
          annexType: "2",
        },
        {
          annexType: "3",
        },
        {
          annexType: "4",
        },
      ],
      trunSalary: 0,
      clientRaise: 0,
      companyRaise: 0,
      // dataStart: {}, //星座年纪性别生日数组
    };
    this.columns = [
      {
        title: "时间",
        dataIndex: "time",
        render: (text, record, index) => {
          return (
            <FormItem>
              {this.props.form.getFieldDecorator(
                `workExperience[${index}].time`,
                {
                  initialValue: text,
                  rules: [
                    {
                      required: true,
                      message: "请选择时间",
                    },
                  ],
                }
              )(<RangePicker onBlur={this.onChangeAdd} format={dateFormat} />)}
            </FormItem>
          );
        },
      },
      {
        title: "所在公司",
        dataIndex: "company",
        render: (text, record, index) => {
          return (
            <FormItem>
              {this.props.form.getFieldDecorator(
                `workExperience[${index}].company`,
                {
                  initialValue: text,
                  rules: [
                    {
                      required: true,
                      message: "请输入所在公司",
                    },
                    {
                      max: 64,
                      message: "毕业院校最大长度为64个字符",
                    },
                    {
                      pattern: /^(?!\s).*/,
                      message: "首不可为空格",
                    },
                  ],
                }
              )(
                <Input
                  onChange={this.onChangeAdd}
                  placeholder="请输入"
                  allowClear
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: "工作岗位",
        dataIndex: "job",
        render: (text, record, index) => {
          return (
            <FormItem>
              {this.props.form.getFieldDecorator(
                `workExperience[${index}].job`,
                {
                  initialValue: text,
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "请输入工作岗位",
                    },
                    {
                      max: 64,
                      message: "毕业院校最大长度为64个字符",
                    },
                    {
                      pattern: /^(?!\s).*/,
                      message: "首不可为空格",
                    },
                  ],
                }
              )(
                <Input
                  onChange={this.onChangeAdd}
                  placeholder="请输入"
                  allowClear
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: "薪资",
        dataIndex: "salary",
        render: (text, record, index) => {
          return (
            <FormItem>
              {this.props.form.getFieldDecorator(
                `workExperience[${index}].salary`,
                {
                  initialValue: text,
                  rules: [
                    {
                      max: 8,
                      message: "薪资最大长度为8个字符",
                    },
                    {
                      pattern: /^\d+$|^\d+[.]?\d+$/,
                      message: "请输入数字",
                    },
                  ],
                }
              )(
                <Input
                  onChange={this.onChangeAdd}
                  className="suffix-input"
                  placeholder="请输入"
                  allowClear
                />
              )}
              <span>元</span>
            </FormItem>
          );
        },
      },
      {
        title: "证明人",
        dataIndex: "witness",
        render: (text, record, index) => {
          return (
            <FormItem>
              {this.props.form.getFieldDecorator(
                `workExperience[${index}].witness`,
                {
                  initialValue: text,
                  rules: [
                    {
                      max: 32,
                      message: "证明人最大长度为32个字符",
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z\u4e00-\u9fa5]+$/, "g"),
                      message: "仅支持输入中英文",
                    },
                  ],
                }
              )(
                <Input
                  onChange={this.onChangeAdd}
                  placeholder="请输入"
                  allowClear
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: "联系方式",
        dataIndex: "contactWay",
        render: (text, record, index) => {
          return (
            <FormItem>
              {this.props.form.getFieldDecorator(
                `workExperience[${index}].contactWay`,
                {
                  initialValue: text,
                  rules: [
                    {
                      pattern: /^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/,
                      message: "请输入有效联系方式",
                    },
                  ],
                }
              )(
                <Input
                  onChange={this.onChangeAdd}
                  placeholder="请输入"
                  allowClear
                />
              )}
            </FormItem>
          );
        },
      },
      {
        title: "操作",
        width: "8%",
        render: (index) => {
          return (
            <span
              style={{
                color: "#4D91FA",
                cursor: "pointer",
              }}
              onClick={this.handleClickDelete.bind(this, index)}
            >
              删除
            </span>
          );
        },
      },
    ];
  }
  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.data !== nextProps.staffDetail) {
      console.log(nextProps.staffDetail);
      this.setState({
        fileList: nextProps.staffDetail.fileList || this.state.fileList,
        dataSource: nextProps.staffDetail.workExperience || [],
      });
    }
  }
  renderSupplement() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <p className="warning-info">有否传染病或慢性病史，请具体说明:</p>
        <FormItem required>
          {getFieldDecorator("isDisease", {
            initialValue: 1,
          })(
            <Radio.Group>
              <Radio value={0}>是</Radio>
              <Radio value={1}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        {this.props.form.getFieldValue("isDisease") === 0 && (
          <FormItem>
            {getFieldDecorator("diseaseDesc", {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: "具体说明不能为空",
                },
              ],
            })(<TextArea rows={4} style={{ width: "100%" }} />)}
          </FormItem>
        )}
        <p className="warning-info">
          有否接受过行政或者刑事处分，或与原公司存在劳动/财务纠纷，或因违章违纪遭辞退/开除等情况请具体说明:
        </p>
        <FormItem required>
          {getFieldDecorator("isWarning", {
            initialValue: 1,
          })(
            <Radio.Group>
              <Radio value={0}>是</Radio>
              <Radio value={1}>否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        {this.props.form.getFieldValue("isWarning") === 0 && (
          <FormItem>
            {getFieldDecorator("warningDesc", {
              initialValue: "",
              rules: [
                {
                  required: true,
                  message: "具体说明不能为空",
                },
              ],
            })(<TextArea rows={4} style={{ width: "100%" }} />)}
          </FormItem>
        )}
      </div>
    );
  }
  // 循环卡片
  renderCard = (data) =>
    data.map((item, index) => {
      if (item.value != "在职情况") {
        return (
          <div
            className={
              ["supplement", "contract"].includes(item.key)
                ? "if-card p-b"
                : "if-card"
            }
            key={`${item.key}${index}`}
          >
            <div className="if-card-tip">
              {item.value}
              <span
                style={{
                  display: item.info == undefined ? "none" : "",
                }}
                className="if-card-tip-span"
              >
                ({item.info})
              </span>
            </div>
            <div className="if-card-wrap">
              {item.children
                ? this.renderItem(item.children)
                : item.key === "workExperience"
                ? this.renderWorkExperience()
                : item.key === "uploadDocuments"
                ? this.renderUploadDocuments(item.uploadChildren)
                : item.key === "supplement"
                ? this.renderSupplement()
                : this.renderUploadOther()}
            </div>
          </div>
        );
      }
    });
  // 循环表单项
  renderItem = (data) => {
    // console.log(data,'9999999999')
    const { getFieldDecorator } = this.props.form;
    const { dataStart } = this.state;
    const { staffDetail } = this.props;
    return data.map((list, idx) => {
      if (list.key == "contractStartday") {
        console.log(staffDetail[list.key]);
      }
      return (
        <FormItem
          style={{
            display:
              list.key == "leaveTime"
                ? this.state.isLeave == "0"
                  ? "none"
                  : ""
                : list.key == "leaveReason"
                ? this.state.isLeave == "0"
                  ? "none"
                  : ""
                : "",
          }}
          label={list.value}
          className={list.onlyLine ? "item only-line" : "item"}
          key={`${list.key}${idx}`}
        >
          {getFieldDecorator(`${list.key}`, {
            initialValue: list.key==='probationTime'?0:staffDetail[list.key]?staffDetail[list.key]:undefined,
            rules:
              list.key == "lastCompanyTime"
                ? this.state.isOtherCompanyTransfer == "1"
                  ? list.rules
                  : []
                : list.rules || [],
          })(this.renderInputType(list))}
          {list.suffix && <span>{list.suffix}</span>}
        </FormItem>
      );
    });
  };
  getInfo = (e) => {
    const regIdCard = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (e.target.value.length == 18 && regIdCard.test(e.target.value)) {
      let info = util.getInfoFromIdCard(e.target.value);
      this.props.staffDetail.age = info.age;
      this.props.staffDetail.birthday = moment(info.birthday);
      this.props.form.setFieldsValue({
        age: info.age,
      });
    }
  };
  handleOk = (val) => {
    this.props.staffDetail.belongDeliverManagerId = val[0].staffId;
    this.props.staffDetail.belongDeliverManager = val[0].staffName;
    val[0].departmentPrincipalId = val[0].staffId;
    val[0].departmentPrincipalName = val[0].staffName;
    this.setState({
      organizationalItem: val[0],
    });
  };
  getTreeNode = (item) => {
    return (
      <TreeNode
        key={item.departmentSonId}
        value={item.departmentSonId}
        title={item.departmentSonName}
      >
        {item.dsTree
          ? item.dsTree.map((c_item) => {
              return this.getTreeNode(c_item);
            })
          : ""}
      </TreeNode>
    );
  };
  // 匹配inputType
  renderInputType = (list) => {
    const { staffDetail } = this.props;
    switch (list.inputTyle) {
      case "SelectStaff":
        return (
          this.props.form && (
            <SelectStaff
              organizationalItem={this.state.organizationalItem}
              disabled={false}
              form={this.props.form}
              handleOk={this.handleOk}
            />
          )
        );
      case "treeSelect":
        return (
          <TreeSelect
            showSearch
            style={{ height: 32 }}
            value={this.props.selectList.departmentIdList}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="请选择"
            allowClear
            treeDefaultExpandAll
            onDropdownVisibleChange={(value) => {
              console.log(this.props.selectList.departmentIdList);
              this.props.querydepartmentId(true);
            }}
            onChange={this.onChange}
            showCheckedStrategy={"SHOW_PARENT"}
            treeNodeFilterProp="title"
          >
            {(this.props.selectList.departmentIdList || []).map((item) => {
              return this.getTreeNode(item);
            })}
          </TreeSelect>
        );
      case "select":
        return (
          <Select
            placeholder={list.placeholder}
            allowClear
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onDropdownVisibleChange={(value) => {
              if (list.dicGroup) {
                this.props.selectListByGroup(value, list.key, list.dicGroup);
              } else {
                this.props.querySelectList(
                  `query${list.key == "postIdOK" ? "postId" : list.key}`,
                  value
                );
              }
            }}
            showSearch={list.key == "postIdOK" ? true : false}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onChange={(value) => this.onInputKeyDown(value, list.key)}
          >
            {(
              this.props.selectList[
                `${list.key == "postIdOK" ? "postId" : list.key}List`
              ] || []
            ).map((it, num) => {
              return (
                <Option value={it.dicKey} key={num}>
                  {it.dicValue}
                </Option>
              );
            })}
          </Select>
        );
      case "datePicker":
        return (
          <DatePicker
            disabledDate={
              list.key == "onboardingTime"
                ? this.disabledStartDate
                : list.key == "turnTime"
                ? this.disabledEndDate
                : list.key == "contractStartday"
                ? this.disabledContractStartday
                : list.key == "contractMaturityday"
                ? this.disabledContractMaturityday
                : list.key == "firstContractStartday"
                ? this.disabledFirstContractStartday
                : list.key == "firstContractMaturityday"
                ? this.disabledFirstContractMaturityday
                : ""
            }
            disabled={
              list.key == "contractStartday"
                ? true
                : list.key == "firstContractStartday"
                ? staffDetail.signTotalFrequency === 1
                  ? true
                  : ""
                : list.key == "firstContractMaturityday"
                ? staffDetail.signTotalFrequency === 1
                  ? true
                  : ""
                : list.key == "turnTime"
                ? true
                : ""
            }
            // format={list.key==='graduationTime'?'YYYY/MM':'YYYY/MM/DD'}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            onChange={(date, dateString) =>
              this.changeTime(date, dateString, list.key)
            }
          />
        );
      case "monthPicker":
        return <MonthPicker format={"YYYY/MM"} />;
      case "InputNumber":
        return (
          <InputNumber
            max={list.max}
            min={list.min}
            precision={list.precision}
            defaultValue={0}
            disabled={list.key==='probationTime'&&staffDetail.hireNature!=='全职'?true:''}
            onChange={
              list.detailKey == "certificatesNumber"
                ? this.getInfo
                : (e) => this.changeInput(e, list.key)
            }
            className={`${list.suffix ? "suffix-input" : ""}`}
            placeholder={list.placeholder}
          ></InputNumber>
        );
      case "input":
        return (
          <Input
            onChange={
              list.detailKey == "certificatesNumber"
                ? this.getInfo
                : (e) => this.changeInput(e, list.key)
            }
            className={`${list.suffix ? "suffix-input" : ""}`}
            placeholder={list.placeholder}
            allowClear
          />
        );
      case "cascader":
        return (
          <Cascader
            fieldNames={{
              label: "addressName",
              value: "key",
              children: "cityList",
            }}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            options={this.props.cityData}
            placeholder="省市二级"
          />
        );
      case "radio":
        return (
          <Radio.Group
            disabled={list.key == "isLeave" ? true : false}
            onChange={(e) => this.changeRadio(e, list.key)}
          >
            {list.radioData.map((item, index) => {
              // console.log(item,index,'333333')
              return (
                <Radio value={item.value} key={index}>
                  {item.label}
                </Radio>
              );
            })}
          </Radio.Group>
        );
      case "textArea":
        return <TextArea placeholder={list.placeholder} rows={4} />;
      default:
        return (
          <span>
            {this.props.staffDetail[list.key]
              ? this.props.staffDetail[list.key]
              : "--"}
          </span>
        );
    }
  };
  changeRadio = (e, key) => {
    if (key == "isLeave") {
      this.setState({
        isLeave: e.target.value,
      });
    } else if (key == "isOtherCompanyTransfer") {
      this.setState({
        isOtherCompanyTransfer: e.target.value,
      });
    }
  };
  // 工作经历 -- table
  renderWorkExperience = () => {
    return (
      <div style={{ width: "100%" }}>
        <Button
          style={{ marginBottom: 10 }}
          type="primary"
          onClick={this.addTable}
        >
          新增
        </Button>
        <Table
          rowKey={(record) => {
            return record.key;
          }}
          dataSource={this.state.dataSource}
          columns={this.columns}
          pagination={false}
        />
      </div>
    );
  };
  addTable = () => {
    this.state.dataSource.push({
      key: this.state.dataSource.length,
    });
    this.setState({
      dataSource: this.state.dataSource,
    });
  };
  // select选中后huidiao
  onInputKeyDown = (value, key) => {
    if (key == "contractType") {
      let arr = value.split("&");
      if (arr[1] == "FULL_TIME") {
        this.props.staffDetail.hireNature = "全职";
        this.props.staffDetail.turnTime1 =
          moment(this.props.staffDetail.turnTime).format("YYYY/MM/DD") || "";
      } else if (arr[1] == "PART_TIME") {
        this.props.staffDetail.hireNature = "兼职";
        this.props.staffDetail.turnTime1 = "";
      } else if (arr[1] == "INTERNSHIP") {
        this.props.staffDetail.hireNature = "实习";
        this.props.staffDetail.turnTime1 = "";
      }
    }
    if (key == "highestEducation") {
      if (value == "SENIOR") {
        this.props.staffDetail.profession = "义务教育";
      } else {
        this.props.staffDetail.profession = "";
      }
    }
  };
  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  disabledContractStartday = (startValue) => {
    const { contractMaturityday } = this.state;
    if (!startValue || !contractMaturityday) {
      return false;
    }
    return startValue.valueOf() > contractMaturityday.valueOf();
  };

  disabledContractMaturityday = (endValue) => {
    const { contractStartday } = this.state;
    if (!endValue || !contractStartday) {
      return false;
    }
    return endValue.valueOf() <= contractStartday.valueOf();
  };

  disabledFirstContractStartday = (startValue) => {
    const { firstContractMaturityday, onboardingTime } = this.state;
    const { signTotalFrequency } = this.props.staffDetail;
    if (signTotalFrequency > 1) {
      if (!startValue || !onboardingTime) {
        return false;
      }
      return startValue.valueOf() >= onboardingTime.valueOf();
    } else {
      if (!startValue || !firstContractMaturityday) {
        return false;
      }
      return startValue.valueOf() > firstContractMaturityday.valueOf();
    }
  };

  disabledFirstContractMaturityday = (endValue) => {
    const { firstContractStartday } = this.state;
    if (!endValue || !firstContractStartday) {
      return false;
    }
    return endValue.valueOf() <= firstContractStartday.valueOf();
  };
  //时间组件回调
  changeTime = (date, dateString, key) => {
    if (key == "turnTime") {
      this.props.staffDetail.turnTime1 = dateString;
      this.setState({
        endValue: date,
      });
    }
    if (key == "onboardingTime") {
      const {hireNature} = this.props.staffDetail
      console.log(this.props.staffDetail,'2w7343985798579')
      if (dateString != "") {
        this.props.staffDetail.turnTime = moment(dateString).add(
          this.props.form.getFieldValue("probationTime"),
          "M"
        );
        this.props.staffDetail.turnTime1 = hireNature!=='全职'?'':moment(dateString)
          .add(this.props.form.getFieldValue("probationTime"), "M")
          .format("YYYY/MM/DD");
        this.props.form.setFieldsValue({
          turnTime: moment(dateString).add(
            this.props.form.getFieldValue("probationTime"),
            "M"
          ),
          // turnTime1:moment(dateString).add(this.props.form.getFieldValue('probationTime'), 'M')
          firstContractStartday: moment(dateString),
          contractStartday: moment(dateString),
        });
      }
      this.setState({
        onboardingTime: date,
      });
    }
    if (key == "contractStartday") {
      if (this.props.staffDetail.signTotalFrequency > 1) {
        this.props.staffDetail.firstContractStartday = moment(dateString);
        console.log(dateString);
        this.props.form.setFieldsValue({
          firstContractStartday: moment(dateString),
        });
      }
      this.setState({
        contractStartday: date,
      });
    }
    if (key == "contractMaturityday") {
      if (this.props.staffDetail.signTotalFrequency === 1) {
        this.props.form.setFieldsValue({
          firstContractMaturityday: moment(dateString),
        });
      }
      this.setState({
        contractMaturityday: date,
      });
    }
    if (key == "firstContractStartday") {
      if (this.props.staffDetail.signTotalFrequency == 1) {
        this.props.staffDetail.contractStartday = moment(dateString);
        this.props.form.setFieldsValue({
          contractStartday: moment(dateString),
        });
      }
      this.setState({
        firstContractStartday: date,
      });
    }
    if (key == "firstContractMaturityday") {
      if (this.props.staffDetail.signTotalFrequency == 1) {
        this.props.staffDetail.contractMaturityday = moment(dateString);
        this.props.form.setFieldsValue({
          contractMaturityday: moment(dateString),
        });
      }
      this.setState({
        firstContractMaturityday: date,
      });
    }
    if (key == "birthday") {
      console.log(util.Age(moment(dateString)));
      this.props.staffDetail.age = util.Age(moment(dateString));
      this.props.form.setFieldsValue({
        age: util.Age(moment(dateString)),
      });
    }
  };
  changeInput = (e, key) => {
    if (key == "trunSalary") {
      this.props.staffDetail.totalTurn =
        parseInt(e) + this.state.clientRaise + this.state.companyRaise;
      this.setState({
        trunSalary: parseInt(e),
      });
    } else if (key == "clientRaise") {
      this.props.staffDetail.totalTurn =
        this.state.trunSalary + parseInt(e) + this.state.companyRaise;
      this.setState({
        clientRaise: parseInt(e),
      });
    } else if (key == "companyRaise") {
      this.props.staffDetail.totalTurn =
        this.state.trunSalary + this.state.clientRaise + parseInt(e);
      this.setState({
        companyRaise: parseInt(e),
      });
    } else if (key == "signTotalFrequency") {
      this.props.staffDetail.signTotalFrequency = parseInt(e);
      if (parseInt(e) == 1) {
        this.props.staffDetail.contractStartday =
          this.state.firstContractStartday ||
          this.props.staffDetail.firstContractStartday;
        this.props.staffDetail.contractMaturityday =
          this.state.firstContractMaturityday ||
          this.props.staffDetail.firstContractMaturityday;
        this.props.form.setFieldsValue({
          contractStartday: moment(
            this.state.firstContractStartday ||
              this.props.staffDetail.firstContractStartday
          ),
          contractMaturityday: moment(
            this.state.firstContractMaturityday ||
              this.props.staffDetail.firstContractMaturityday
          ),
        });
      }
    } else if (key == "probationTime") {
      if (this.props.form.getFieldValue("onboardingTime") != null) {
        this.props.staffDetail.turnTime = moment(
          this.props.form.getFieldValue("onboardingTime")
        ).add(parseInt(e), "M");
        this.props.staffDetail.turnTime1 = moment(
          this.props.form.getFieldValue("onboardingTime")
        )
          .add(parseInt(e), "M")
          .format("YYYY/MM/DD");
        this.props.form.setFieldsValue({
          turnTime: moment(this.props.form.getFieldValue("onboardingTime")).add(
            parseInt(e),
            "M"
          ),
          // turnTime1:moment(dateString).add(this.props.form.getFieldValue('probationTime'), 'M')
        });
      }
    }
  };
  beforeUpload = (file, key) => {
    const self = this;
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    let _bool = true;
    if (!isJpgOrPng) {
      message.error("图片格式只支持jpg、jpeg、png!");
      _bool = false;
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("图片大小不超过 2MB!");
      _bool = false;
      return false;
    }

    if (!_bool) {
      return false;
    }
  };
  // 证件上传
  renderUploadDocuments = (uploadChildren) => {
    const { getFieldDecorator } = this.props.form;
    const { imageUrl } = this.state;
    return uploadChildren.map((item) => {
      return imageUrl[item.key] ? (
        <div className="image-item">
          <div className="image flex flex-align-center flex-justify-center">
            <img className="upload" src={imageUrl[item.key]} alt="upload" />
            <div className="black-icons flex flex-align-center flex-justify-center">
              <img
                className="icon"
                onClick={this.handlePreview.bind(this, imageUrl[item.key])}
                src={enlargeIcon}
                alt="icon"
              />
              <img
                className="icon"
                onClick={this.handleClickDeleteUrl.bind(this, item.key, "")}
                src={deleteIcon}
                alt="icon"
              />
            </div>
          </div>
          <p
            className={"image-name marginTop " + `image-name-${item.key}`}
          >{`上传${item.value}`}</p>
        </div>
      ) : (
        <div className="image-item">
          <FormItem>
            {getFieldDecorator(`${item.key}`, {
              initialValue: undefined,
              valuePropName: "fileList",
              getValueFromEvent: this.normFile,
              rules: [
                {
                  required: item.required,
                  message: `请上传${item.value}`,
                },
              ],
            })(
              <Upload
                name="avatar"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={(file) => this.beforeUpload(file, item.key)}
                accept=".png,.jpg,.jpeg"
                customRequest={(file) => this.handleUploadFile(file, item.key)}
              >
                {imageUrl[item.key] ? null : (
                  <div className="image flex flex-align-center flex-justify-center">
                    <img
                      className="upload-image"
                      src={uploadIcon}
                      alt="upload"
                    />
                  </div>
                )}
              </Upload>
            )}
          </FormItem>
          <p
            className={"image-name " + `image-name-${item.key}`}
          >{`上传${item.value}`}</p>
        </div>
      );
    });
  };
  // 其他证件上传
  renderUploadOther = () => {
    const { fileList } = this.state;
    return (
      <div className="other-image">
        {fileList.map((item, index) => {
          return (
            <div className="image-item">
              <div className="image flex flex-align-center flex-justify-center">
                <img className="upload" src={item.annexUrl} alt="upload" />
                <div className="black-icons flex flex-align-center flex-justify-center">
                  <img
                    className="icon"
                    onClick={this.handlePreview.bind(this, item.annexUrl)}
                    src={enlargeIcon}
                    alt="icon"
                  />
                  <img
                    className="icon"
                    onClick={this.handleClickDeleteUrl.bind(
                      this,
                      index,
                      "other"
                    )}
                    src={deleteIcon}
                    alt="icon"
                  />
                </div>
              </div>
              <p className="image-name marginTop">{`附件${index + 1}`}</p>
            </div>
          );
        })}
        <div className="image-item">
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            accept=".png,.jpg,.jpeg"
            beforeUpload={this.beforeUpload}
            customRequest={(file) => this.handleUploadFile(file, null)}
          >
            <div className="image flex flex-align-center flex-justify-center">
              <img className="upload-image" src={uploadIcon} alt="upload" />
            </div>
          </Upload>
          <p className="image-name">上传</p>
        </div>
      </div>
    );
  };
  handleUploadFile = (uploadFile, key) => {
    console.log(key);
    Http.uploadFile(uploadFile.file, uploadFile.file.name).then((data) => {
      if (key) {
        const { imageUrl, staffAnnexs } = this.state;
        imageUrl[key] = data.url;
        if (key == "byz") {
          staffAnnexs[0].annexKey = data.key;
        } else if (key == "xwz") {
          staffAnnexs[1].annexKey = data.key;
        } else if (key == "sfzz") {
          staffAnnexs[2].annexKey = data.key;
        } else if (key == "sfzb") {
          staffAnnexs[3].annexKey = data.key;
        }
        this.setState(
          {
            imageUrl,
            staffAnnexs,
          },
          () => {
            // this.props.form.setFieldsValue({
            //     [key]: {key: data.key,url: data.url}
            // });
          }
        );
      } else {
        const { fileList } = this.state;
        fileList.push({
          annexType: "5",
          annexKey: data.key,
          annexUrl: data.url,
        });
        this.setState(
          {
            fileList,
          },
          () => {
            // this.props.form.setFieldsValue({
            //     'other': fileList
            // });
          }
        );
      }
    });
  };
  normFile = (e) => {
    console.log(e);
    let file = e.file;
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    let _bool = true;
    if (!isJpgOrPng) {
      // message.error('图片格式只支持jpg、jpeg、png!');
      _bool = false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      //   message.error('图片大小不超过 2MB!');
      _bool = false;
    }

    if (!_bool) {
      return undefined;
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // 打开预览弹框
  handlePreview = (url) => {
    this.setState({
      previewVisible: true,
      previewUrl: url,
    });
  };
  // 取消预览弹框
  handleCancel = () => {
    this.setState({
      previewVisible: false,
    });
  };
  // 删除上传的附件
  handleClickDeleteUrl = (index, type) => {
    if (type === "other") {
      const { fileList } = this.state;
      fileList.splice(index, 1);
      this.setState({
        fileList,
      });
    } else {
      const { imageUrl } = this.state;
      imageUrl[index] = "";
      this.setState(
        {
          imageUrl,
        },
        () => {
          // this.props.form.setFieldsValue({
          //     [index]: {}
          // });
        }
      );
    }
  };
  // 点击确定
  handleAddSure = () => {
    const { form } = this.props;
    let formValues = false;
    form.validateFieldsAndScroll((err, values) => {
      console.log(err);
      console.log(values);
      if (!err) {
        values.fileList = this.state.fileList;
        values.staffAnnexs = this.state.staffAnnexs;
        formValues = values;
      }
    });
    return formValues;
  };
  // 工作经历 表格删除行
  handleClickDelete = (index) => {
    let dataSource = [...this.state.dataSource];
    dataSource = dataSource.filter((val) => {
      return val.key !== index.key;
    });
    this.setState({ dataSource });
  };
  onChangeAdd = (value) => {
    console.log(value);
    let flag = true;
    const { workExperience } = this.props.form.getFieldsValue([
      "workExperience",
    ]);
    loop: for (let i = 0; i < workExperience.length; i++) {
      const list = Object.keys(workExperience[i]);
      for (let j = 0; j < list.length; j++) {
        if (workExperience[i][list[j]] === undefined) {
          flag = false;
          break loop;
        }
      }
    }
    console.log(workExperience);
    this.props.staffDetail.workExperience = workExperience;
    if (flag) {
      const json = { key: workExperience.length };
      const { dataSource } = this.state;
      this.setState({
        dataSource,
      });
    }
  };

  render() {
    const { formItemData } = this.props;
    const formItemLayout = {
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 15,
      },
    };
    return (
      <div className="content-job-wrap">
        <Form {...formItemLayout}>{this.renderCard(formItemData)}</Form>
        <Modal
          visible={this.state.previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img
            style={{ width: "100%" }}
            src={this.state.previewUrl}
            alt="example"
          />
        </Modal>
      </div>
    );
  }
}

export default withRouter(Form.create()(AddForm));
