/**
* @fileoverview 系统管理---假期设置
* @author  宣灵杰
*/
import React,{ Component } from 'react'
import './index.less'

import { message,Button } from 'antd';
import Table from "./component/tableList"  		// 数据列表
import AddModal from './component/addAccount'	// 新增或修改账号
import moment from 'moment';


class AccountManagement extends Component{
	constructor(props){
		super(props)
		this.state = {
			loading: false,
            dataList: [],     	// 账号列表数据
            total: 0,       	// 账号总数
            pageSize: 10,     	// 分页Size
            pageNo: 1,        	// 分页页码
			addModalShow: false,// 新增弹框显隐
			modalType: 0,		// 0：添加账号弹框 1：修改账号弹框
			accountItem: {},	// 编辑账号弹框回显数据
			disabled: false,	// 防重复点击
		}
	}
	componentDidMount(){
		this.queryTableList();
	}
   
    
	
    
    /**
     * 函数描述: 查询账号数据
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @return {Object} 
     */
    queryTableList = (pageNo=1, pageSize=10) =>{
		this.setState({
			loading: true
		},() => {
			const params = {
				pageNo,
				pageSize,
			}
			this.$http.holidayList(params).then((res) => {
				if(res.code == 200){
					this.setState({
						loading: false,
						pageNo,
						pageSize,
						dataList: res.data.list,
						total: res.data.total
					})
				}
			}).catch((err) => {
				this.setState({
					loading: false,
				})
			})
		})
	}	
   
    /**
     * 函数描述: 切换列表页码
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
     * @return {Object} 请求pageNo页列表数据
     */
    handleChangePage = (page) => {
		
        this.queryTableList(page.current,page.pageSize);
	}
    /**
     * 函数描述: 编辑账号
     * @return 打开新增弹框
     */
	handleEditSubmit = (item) => {
		this.refs.addModal.resetFields(); // 避免数据闪现
		this.setState({
			addModalShow: true,
			modalType: 1,
			accountItem: item
		})
    }
    handleAdd =()=>{
		this.refs.addModal.resetFields(); // 避免数据闪现
        this.setState({
			addModalShow: true,
            modalType: 0,
            accountItem:{}
		})
    }
	// 关闭弹框
	handleCancelModal = () => {
		this.setState({
			addModalShow: false
		})
    }
    //删除
    handleProhibit = ({holidayId}) => {
		this.$http.removeHoliday({
			holidayId,
		}).then((res) => {
			if(res.code === 200) {
				const { pageNo, pageSize } = this.state;
				this.queryTableList(pageNo,pageSize);
			}
		}).catch((err) => {

		})
	}
    /**
     * 函数描述: 修改或新增确认
     * @param {Object} value 弹框各项值
     * @return 
     */
	handleAddSure = (value) => {
		console.log(value);
		const { disabled, accountItem,modalType } = this.state;
		if(!disabled) {
            if(modalType==0){
                this.setState({
                	disabled: true
                },() => {
                	this.$http.addHoliday({
                        holidayYear:moment().format('YYYY'),
                        holidayDay:moment(value.holidayDay).format('YYYY-MM-DD'),
                        holidayType:value.holidayType,
                        remarks:value.remarks,
                	}).then((res) => {
                		if(res.code === 200) {
                			this.handleCancelModal();
                			this.setState({
                				disabled: false
                			},() => {
                				const { pageNo, pageSize } = this.state;
                				this.queryTableList(pageNo,pageSize);
                			})
                		}else{
                            this.setState({
                				disabled: false
                			})
                        }
                	})
                })
            }else if(modalType == 1){
                this.setState({
                	disabled: true
                },() => {
                	this.$http.updataHoliday({
                		holidayId: accountItem.holidayId,
                        holidayDay:moment(value.holidayDay).format('YYYY-MM-DD'),
                        holidayType:value.holidayType,
                        remarks:value.remarks,
                	}).then((res) => {
                		if(res.code === 200) {
                			this.handleCancelModal();
                			this.setState({
                				disabled: false
                			},() => {
                				const { pageNo, pageSize } = this.state;
                				this.queryTableList(pageNo,pageSize);
                			})
                		}else{
                            this.setState({
                				disabled: false
                			})
                        }
                	})
                })
            }
		
		}
	}
	render(){
		const { dataList, total, pageNo, pageSize, 
		loading, addModalShow, modalType, accountItem, 
		roleList } = this.state;
		return (
		<div className="account-management" style={{height:'calc(100% - 50px)'}}>
			<div className="crumbs">假期管理</div>
			{/* 账号数据 */}
			<div className="account-box wrap-card" style={{height:'100%'}}>
				<Button className="addButton" type="primary"  onClick={this.handleAdd}>添加</Button>
				<Table 
					loading={loading} 
					data={dataList} 
					total={total}
					pageNo={pageNo}
					pageSize={pageSize}
					handleChangePage={this.handleChangePage} // 切换页码
					handleProhibit={this.handleProhibit}	 // 删除假期
					handleEditSubmit={this.handleEditSubmit} // 编辑账号
				/>
			</div>
			{/* 新增或修改账号 */}
			<AddModal
				ref="addModal"
				addModalShow={addModalShow}
				type={modalType}
				roleList={roleList}
				accountItem={accountItem}
				handleCancel={this.handleCancelModal}
				handleAddSure={this.handleAddSure}
			/>
		</div>
		)
	}
}

export default AccountManagement

