/**
* @fileoverview 管辖部门---修改应出勤天数
* @author  蒋西园
*/
import React,{Component} from 'react';
import './index.less'
import { Modal, Form, Input, Select } from "antd";
const FormItem = Form.Item;
const Option = Select.Option;

class ModifyModal extends Component{
    // 点击确定
    handleModifySure = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleModifySure(values)
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    render(){
        const { modifyModalShow, form, Item } = this.props;
        const { getFieldDecorator } = form;
        return(
            <Modal
                className="modify-Modal"
                title='应出勤天数'
                visible={modifyModalShow}
                width={500}
                onCancel={this.handleCancel}
                onOk={this.handleModifySure}
                maskClosable={false}
            >
                <div className="modify-box">
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 17 }}>
                        <FormItem 
                            label="考勤月">
                            <span>{Item.confirmMonth}</span>
                        </FormItem>
                        <FormItem label="出勤天数">
                            {getFieldDecorator('attendanceDays',{
                                initialValue:Item.shouldAttendanceDay || null  
                            })(
                                <Input placeholder="请输入" allowClear />
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Form.create()(ModifyModal)