/**
 * @method 人员状态
 */
import React from "react";
import { Select, Radio, Form } from "antd";
import { map } from "lodash";
const { Option } = Select;
class PersonnelStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: [
        { lable: "在职", value: "0", key: "0" },
        { lable: "离职", value: "1", key: "1" },
      ],
    };
  }

  onChange = (value) => {
    const { onChange } = this.props;
    onChange({
      status: value,
    });
  };

  render() {
    const { status } = this.state;
    return (
      <Form.Item>
        <Select
          placeholder="选择状态"
          onChange={this.onChange}
          style={{ width: "160px" }}
          defaultValue={"0"}
        >
          {map(status, (i) => {
            const { key, lable, value } = i;
            return (
              <Option key={key} value={value}>
                {lable}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  }
}

export default PersonnelStatus;
