import http from "./fetch";
const baseUrl = process.env.API_URL
  ? process.env.API_URL
  : "";

export default {
  //用户权限分页列表查询
  rolePageRole(param) {
    return http.post(
      `${baseUrl}/platform/role/pageRole`,
      param
    );
  },
  //用户权限删除
  deleteRole(param) {
    return http.post(
      `${baseUrl}/platform/role/deleteRole`,
      param,
      true
    );
  },
  //用户权限新增
  addRole(param) {
    return http.post(
      `${baseUrl}/platform/role/addRole`,
      param,
      true
    );
  },
  //用户权限更新
  updateRole(param) {
    return http.post(
      `${baseUrl}/platform/role/updateRole`,
      param,
      true
    );
  },
  //用户权限树结构下拉
  listMenuForRole(param) {
    return http.post(
      `${baseUrl}/platform/menu/listMenuForRole`,
      param
    );
  },
  //用户权限状态更改
  updateRoleStatus(param) {
    return http.post(
      `${baseUrl}/platform/role/updateRoleStatus`,
      param,
      true
    );
  },

  // 用户管理
  // 分页查询用户
  listUserByPage(param) {
    return http.post(
      `${baseUrl}/platform/user/listUserByPage`,
      param
    );
  },
  // 新增用户
  addUser(param) {
    return http.post(
      `${baseUrl}/platform/user/addUser`,
      param,
      true
    );
  },
  // 修改用户
  updateUser(param) {
    return http.post(
      `${baseUrl}/platform/user/updateUser`,
      param,
      true
    );
  },
  // 修改用户状态
  updateUserStatus(param) {
    return http.post(
      `${baseUrl}/platform/user/updateUserStatus`,
      param,
      true
    );
  },
  // 删除用户
  deleteUser(param) {
    return http.post(
      `${baseUrl}/platform/user/deleteUser`,
      param,
      true
    );
  },
  // 角色下拉
  listRole(param) {
    return http.post(
      `${baseUrl}/platform/role/listRole`,
      param
    );
  },

  //小程序列表查询
  smallRolePageRole(param) {
    return http.post(
      `${baseUrl}/platform/role/pageMiniRole`,
      param
    );
  },

  //小程序管理员配置
  smallConfigList(param) {
    return http.post(
      `${baseUrl}/platform/miniAdmin/listMiniAdminByPage`,
      param
    );
  },

  //小程序管理角色下拉

  selectRolsList(param) {
    return http.post(
      `${baseUrl}/platform/role/listRoleForMini`,
      param
    );
  },
  //小程序管理员配置停用启用
  updateMiniAdminStatus(param) {
    return http.post(
      `${baseUrl}/platform/miniAdmin/updateMiniAdminStatus`,
      param
    );
  },
  //小程序管理员配置删除
  // /platform/miniAdmin/deleteMiniAdmin
  deleteMiniAdmin(param) {
    return http.post(
      `${baseUrl}/platform/miniAdmin/deleteMiniAdmin`,
      param
    );
  },
  //listUserByUserName
  //小程序管理员下拉用户模糊搜索
  listUserByUserName(param) {
    return http.post(
      `${baseUrl}/platform/user/listUserByUserName`,
      param
    );
  },
  //小程序管理员配置新增
  addMiniAdmin(param) {
    return http.post(
      `${baseUrl}/platform/miniAdmin/addMiniAdmin`,
      param
    );
  },
  //小程序管理员配置修改
  updateMiniAdmin(param) {
    return http.post(
      `${baseUrl}/platform/miniAdmin/updateMiniAdmin`,
      param
    );
  },
};
