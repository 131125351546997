/**
* @fileoverview 系统管理---职级设置
* @author  蒋西园
*/
import React,{ Component } from 'react'
import './index.less'

import { Button, message } from 'antd';
import SearchForm from './component/searchForm' // 搜索条件
import Table from "./component/tableList"  		// 数据列表
import AddModal from './component/addAccount'	// 新增或修改职级


class RankManagement extends Component{
	constructor(props){
		super(props)
		this.state = {
			loading: false,
            dataList: [],     	// 职级列表数据
            total: 0,       	// 职级列表总数
            pageSize: 10,     	// 分页Size
            pageNo: 1,        	// 分页页码
			searchVale: {},   	// 搜索条件
			addModalShow: false,// 新增弹框显隐
			modalType: 0,		// 0：添加职级弹框 1：修改职级弹框
			rankItem: {},		// 编辑职级弹框回显数据
			disabled: false,	// 防重复点击
			sorter: '',			// 排序方式 ASC： 正序 DESC: 倒叙
		}
	}
	componentDidMount(){
		this.queryTableList();
	}
	
    /**
     * 函数描述: 查询职级数据
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} occupationName   职级
     * @param {string} sortParam    排序方式 ASC： 正序 DESC: 倒叙
     * @return {Object} 
     */
    queryTableList = (pageNo=1, pageSize=10) =>{
       this.setState({
			loading: true
		},() => {
			const params = {
				...this.state.searchVale,
				pageNo,
				pageSize,
				// sortParam: this.state.sorter
			}
			this.$http.rankList(params).then((res) => {
				if(res.code == 200){
					const data = res.data;
					this.setState({
						loading: false,
						pageNo: data.pageNum,
						pageSize: data.pageSize,
						dataList: data.list,
						total: data.total
					})
				}
			}).catch((err) => {
				this.setState({
					loading: false,
				})
			})
		})
	}
    /**
     * 函数描述: 高级搜索
     * @param {Object} value   高级搜索条件
     * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
     */
    handleSearchBtn = (value) =>{
        this.setState({
            searchVale: {...value}
        },() => {
            this.queryTableList();
        })
	}
    /**
     * 函数描述: 切换列表页码
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
     * @return {Object} 请求pageNo页列表数据
     */
    handleChangePage = (page,sorter) => {
		console.log(page,sorter)
		let sorterOrder = ''
		if (sorter.order) {
			sorterOrder = sorter.order === 'ascend' ? 'ASC' : 'DESC'
		}
		this.setState({
			sorter: sorterOrder
		},() => {
			this.queryTableList(page.current,page.pageSize);
		})
	}
    /**
     * 函数描述: 新增职级
     * @return 打开新增弹框
     */
	handleAddSubmit = () => {
		this.refs.addModal.resetFields(); // 避免数据闪现
		this.setState({
			addModalShow: true,
			modalType: 0,
			rankItem: {}
		})
	}
	// 关闭弹框
	handleCancelModal = () => {
		this.setState({
			addModalShow: false
		})
	}
    /**
     * 函数描述: 删除职级
     * @param {Object} occupationId 职级id
     * @return {Object} 刷新当前页数据
     */
	handleDelete = (occupationId) => {
		this.$http.rankDeleteLevel({occupationId}).then(res => {
			if (res.code === 200) {
				const { pageNo, pageSize } = this.state;
				this.queryTableList(pageNo,pageSize);
			}
		})
	}
    /**
     * 函数描述: 编辑职级
     * @return 打开新增弹框
     */
	handleEditSubmit = (item) => {
		this.refs.addModal.resetFields(); // 避免数据闪现
		this.setState({
			addModalShow: true,
			modalType: 1,
			rankItem: item
		})
	}
    /**
     * 函数描述: 修改或新增确认
     * @param {string} type 弹框类型 0：新增 1：修改
     * @param {Object} value.occupationName 职级名称
     * @param {Object} value.description 说明
     * @return 
     */
	handleAddSure = (type,value) => {
		const { disabled } = this.state;
		if(!disabled) {
			this.setState({
				disabled: true
			},() => {
				if (type === 0) { // 新增弹框
					this.$http.rankAdd(value).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								this.queryTableList();
							}
						})
					})
				}else{
					this.$http.rankUpdateLevel({
						...value,
						occupationId: this.state.rankItem.occupationId
					}).then(res => {
						this.setState({
							disabled: false
						},() => {
							if (res.code === 200){
								this.handleCancelModal();
								const { pageNo, pageSize } = this.state;
								this.queryTableList(pageNo, pageSize);
							}
						})
					})
				}
			})
		}
	}
	render(){
		const { dataList, total, pageNo, pageSize, loading, addModalShow, modalType, rankItem } = this.state;
		return (
		<div className="rank-management">
			<div className="crumbs">职级管理</div>
			{/* 搜索框 */}
			<SearchForm handleSearchBtn={this.handleSearchBtn} />
			{/* 职级数据 */}
			<div className="rank-box wrap-card">
				<Button className="addButton" type="primary"  onClick={this.handleAddSubmit}>添加</Button>
				<Table 
					loading={loading} 
					data={dataList} 
					total={total}
					pageNo={pageNo}
					pageSize={pageSize}
					handleChangePage={this.handleChangePage} // 切换页码
					handleDelete={this.handleDelete}		 // 删除职级
					handleEditSubmit={this.handleEditSubmit} // 编辑职级
				/>
			</div>
			{/* 新增或修改职级 */}
			<AddModal
				ref="addModal"
				addModalShow={addModalShow}
				type={modalType}
				rankItem={rankItem}
				handleCancel={this.handleCancelModal}
				handleAddSure={this.handleAddSure}
			/>
		</div>
		)
	}
}

export default RankManagement
