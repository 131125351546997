import React from 'react';
import { BrowserRouter, Route, Switch, Router,Redirect } from 'react-router-dom';
import App from '../App.js'
import Login from '../pages/login'
import Workbench from '../pages/workbench/index'
import EditPsd from '../pages/editPsd'
import mainRoutes from './router'
import history from '../api/history';
let OSS = window.OSS.Wrapper
let client = new OSS({
	region: 'oss-cn-hangzhou',
	accessKeyId: 'LTAI4Fi2CjTdLjrAtCxkx1Kv',
	accessKeySecret: 'AXs3Ztduiitkn70LInaX0YfifA8wEZ',
	secure: true,
	bucket: '70keji' //自定义的上传后地址，加在oss前
});
window.client = client
const RouterP = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/editPsd" component={EditPsd} />
				
				<App>

					<Route path="/" exact component={Workbench} />
					{
						mainRoutes.map(res => {
							return (
								<Route
									key={res.link}
									exact
									path={res.link}
									component={res.component}
								>
								</Route>

							)
						})
					}

				</App>
			</Switch>
		</Router>
	)
}
export default RouterP
