/**
 * @fileoverview 系统管理---账号设置 -- 搜索条件
 * @author  蒋西园
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Form,
  Select,
  Input,
  Row,
  Col,
  TreeSelect,
} from "antd";

const FormItem = Form.Item;
const Option = Select.Option;
const { SHOW_PARENT, TreeNode } = TreeSelect;
class SearchUserlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: "Hello, World",
    };
  }
  // 提交表单
  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll(
      (err, values) => {
        if (err) {
          return false;
        }
        const data = {};
        // 去除含有undefined的item
        Object.keys(values).forEach((res) => {
          if (values[res]) {
            if (res == "status") {
              data[res] = values[res] === "1";
            } else {
              data[res] = values[res];
            }
          }
        });
        this.props.handleSearchBtn(data);
      }
    );
  };
  handleReset = () => {
    this.props.form.resetFields();
    this.props.handleSearchBtn({});
  };
  onDropdownVisiblePost = (open) => {
    if (open) {
      this.props.listForDropDownPost();
    }
  };
  querydepartmentId = (open) => {
    if (open) {
      this.props.querydepartmentId();
    }
  };
  // 部门查询树状结构
  getTreeNode = (item) => {
    return (
      <TreeNode
        key={item.departmentSonId}
        value={item.departmentSonId}
        title={item.departmentSonName}
      >
        {item.dsTree
          ? item.dsTree.map((c_item) => {
              return this.getTreeNode(c_item);
            })
          : ""}
      </TreeNode>
    );
  };

  render() {
    const { postList, departmentList } = this.props;
    console.log("departmentList ->", departmentList);
    const { getFieldDecorator } = this.props.form;
    return (
      <div
        className="search-form"
        style={{
          height: "auto",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <Form
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 16 }}
        >
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="账号">
                {getFieldDecorator("staffCode", {
                  rules: [
                    {
                      required: false,
                      message: "账号不可输入为空!",
                      whitespace: true,
                    },
                  ],
                })(
                  <Input placeholder="请输入" allowClear />
                )}
              </FormItem>
            </Col>
            {/* 员工姓名 */}
            <Col span={6}>
              <FormItem label="姓名">
                {getFieldDecorator("staffName", {
                  rules: [
                    {
                      required: false,
                      message: "姓名不可输入为空!",
                      whitespace: true,
                    },
                  ],
                })(
                  <Input placeholder="请输入" allowClear />
                )}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="部门">
                {getFieldDecorator("departmentId")(
                  <TreeSelect
                    // showSearch
                    style={{ height: 32 }}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    placeholder="请选择"
                    allowClear
                    showCheckedStrategy={SHOW_PARENT}
                    treeNodeFilterProp="title"
                  >
                    {(departmentList || []).map((item) => {
                      return this.getTreeNode(item);
                    })}
                  </TreeSelect>
                )}
                {/* {getFieldDecorator("departmentId")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    onDropdownVisibleChange={
                      this.querydepartmentId
                    }
                    showSearch
                    optionFilterProp="children"
                  >
                    {departmentList.map((item) => {
                      return (
                        <Option
                          key={item.departmentId}
                          value={item.departmentId}
                        >
                          {item.departmentName}
                        </Option>
                      );
                    })}
                  </Select>
                )} */}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="岗位">
                {getFieldDecorator("postId")(
                  <Select
                    placeholder="请选择"
                    allowClear
                    onDropdownVisibleChange={
                      this.onDropdownVisiblePost
                    }
                  >
                    {postList.map((item) => {
                      return (
                        <Option
                          key={item.postId}
                          value={item.postId}
                        >
                          {item.postName}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="状态">
                {getFieldDecorator("status")(
                  <Select placeholder="请选择" allowClear>
                    <Option value="1">使用中</Option>
                    <Option value="0">禁用</Option>
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col span={6}>
              <Button onClick={this.handleReset}>
                重置
              </Button>
              <Button
                type="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: 14 }}
              >
                查询
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default withRouter(Form.create()(SearchUserlist));
