/**
 * @fileoverview 工作台--未完善名单
 * @author  赵娟
 */
import React, { Component } from "react";
import "./index.less";

import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表

class Imperfect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataList: [], // 未完善名单列表数据
      total: 100, // 未完善名单总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {}, // 搜索条件
      disabled: false, // 防重复点击
      departmentList: [], // 部门下拉
    };
  }
  componentDidMount() {
    this.queryTableList();
    this.querydepartmentId();
  }
  /**
   * 函数描述: 查询部门名称
   */
  querydepartmentId = () => {
    this.$http.getJurisdictionTree({}).then((res) => {
      console.log("res ->", res);
      if (res.code === 200) {
        this.setState({
          departmentList: res.data || [],
        });
      }
    });
  };
  /**
   * 函数描述: 查询未完善名单
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} value   部门
   * @return {Object}
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
          pageNo,
          pageSize,
        };
        this.$http
          .queryImperfectList(params)
          .then((res) => {
            if (res.code == 200) {
              this.setState({
                pageNo,
                pageSize,
                dataList: res.data.list,
                total: res.data.total,
              });
            }
            this.setState({
              loading: false,
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };
  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (pageNo, pageSize) => {
    this.queryTableList(pageNo, pageSize);
  };
  build(url, params) {
    // URL构建方法
    const ps = [];
    if (params) {
      for (let p in params) {
        if (p) {
          ps.push(p + "=" + encodeURIComponent(params[p]));
        }
      }
    }
    return url + "?" + ps.join("&");
  }

  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
      departmentList,
    } = this.state;
    return (
      <div className="imperfect">
        <div className="crumbs">未完善名单</div>
        {/* 搜索框 */}
        <SearchForm
          handleSearchBtn={this.handleSearchBtn}
          departmentList={departmentList}
          querydepartmentId={this.querydepartmentId}
        />
        {/* 未完善名单 */}
        <div className="imperfect-box wrap-card">
          <Table
            loading={loading}
            data={dataList}
            total={total}
            pageNo={pageNo}
            pageSize={pageSize}
            handleChangePage={this.handleChangePage} // 切换页码
          />
        </div>
      </div>
    );
  }
}

export default Imperfect;
