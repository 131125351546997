
import React from 'react';
import ReactDOM from 'react-dom';
import Router from './routers';
import '../src/assets/css/reset.less'
import Es6Promise from 'es6-promise'
import './index.css'
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import md5 from 'js-md5'
import 'babel-polyfill'
import api from './api/apis'
moment.locale('zh-cn');
require('es6-promise').polyfill()
Es6Promise.polyfill()

// md5加密
React.Component.prototype.$md5 = md5;
// 请求
React.Component.prototype.$http = api;

ReactDOM.render(
  <LocaleProvider locale={zh_CN}><Router /></LocaleProvider>
  , document.getElementById('root')
);
