/**
 * @method 到岗月份
 */
import React from "react";
import { DatePicker, Form, message, Row, Col } from "antd";
import moment from "moment";
import "./index.less";
const { MonthPicker } = DatePicker;
class ArrivalMonth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startValue: moment(
        `${new Date().getFullYear()}/01`,
        "YYYY/MM"
      ),
      endValue: moment(
        `${new Date().getFullYear()}/12`,
        "YYYY/MM"
      ),
    };
  }

  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return (
      startValue.valueOf() > endValue.valueOf() ||
      startValue.valueOf() <=
        moment(moment(endValue).format("YYYY-MM")).subtract(
          12,
          "M"
        )
    );
  };
  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }

    return (
      endValue.valueOf() < startValue.valueOf() ||
      endValue.valueOf() >=
        moment(moment(startValue).format("YYYY-MM")).add(
          12,
          "M"
        )
    );
  };
  onStartChange = (value) => {
    console.log("value1 ->", value);
    this.setState({ startValue: value }, () => {
      this.returnParams();
    });
  };
  onEndChange = (value) => {
    console.log("value2 ->", value);
    this.setState({ endValue: value }, () => {
      this.returnParams();
    });
  };

  returnParams = () => {
    const { onChange } = this.props;
    const { startValue, endValue } = this.state;
    /* if (!(startValue || endValue)) {
      message.warning("请填写起始日期和结束日期");
    } else { */
    onChange({
      onboardingTimeStart: startValue
        ? moment(startValue).format("YYYY/MM")
        : null,
      onboardingTimeEnd: endValue
        ? moment(endValue).format("YYYY/MM")
        : null,
    });
    //}
  };

  render() {
    const { startValue, endValue } = this.state;
    return (
      <Form.Item className="picker">
        <div className="picker-item">
          <MonthPicker
            allowClear={true}
            disabledDate={this.disabledStartDate}
            format="YYYY/MM"
            value={startValue}
            placeholder="开始日期"
            onChange={this.onStartChange}
          />
          <MonthPicker
            allowClear={true}
            style={{ marginLeft: 10 }}
            disabledDate={this.disabledEndDate}
            format="YYYY/MM"
            value={endValue}
            placeholder="结束日期"
            onChange={this.onEndChange}
          />
        </div>
      </Form.Item>
    );
  }
}

export default ArrivalMonth;
