import React, { Component } from 'react';
import './index.less'
import { Modal, Form, Input, Select, message } from "antd";
import 'braft-editor/dist/index.css'
import BraftEditor from 'braft-editor'
import MaxLength from 'braft-extensions/dist/max-length'
const options = {
  defaultValue: 512, // 指定默认限制数，如不指定则为Infinity(无限)
};
BraftEditor.use(MaxLength(options));

const FormItem = Form.Item;
const Option = Select.Option;

class addAccountModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState:  BraftEditor.createEditorState('')
        }
    }
    // 点击确定
    handleAddSure = (e) => {
        e.preventDefault();
        const { form, type } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            // values.content.toRAW() // or values.content.toHTML()
            console.log(values.content.toHTML())
            if (!err) {
                this.props.handleAddSure(type, {title: values.title,content: values.content.toHTML()})
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    handleMaxLength = () => {
        message.destroy()
        message.info('内容最多只能输入512个字符')
    }

    render() {
        const { addModalShow, form, type, accountItem } = this.props;
        const { getFieldDecorator } = form;
        const controls = ['undo','redo','separator','font-size','bold', 'italic', 'underline', 'text-color', 'separator', 'link', 'separator','text-align', 'text-indent','headings', 'list-ul', 'list-ol']
        const editorState = type === 0 ? BraftEditor.createEditorState('') : BraftEditor.createEditorState(accountItem.content)
        return (
            <Modal
                className="setUserModal"
                title={type === 0 ? '添加公告' : '修改公告'}
                visible={addModalShow}
                onCancel={this.handleCancel}
                onOk={this.handleAddSure}
                width={700}
                maskClosable={false}
            >
                <div className="setgg-box">
                    <Form labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                        <FormItem
                            label="标题">
                            {getFieldDecorator('title', {
                                initialValue: accountItem.title,
                                rules: [
                                    { required: true,whitespace:true, message: '请输入标题' },
                                    { max: 16, message: '标题长度为16个字符' }
                                ],
                            })(
                                <Input placeholder="请输入" allowClear autoComplete="new-password" />
                            )}
                        </FormItem>
                        <FormItem
                            label="内容">
                            {getFieldDecorator('content', {
                                initialValue: editorState,
                                rules: [{
                                    validateTrigger: 'onBlur',
                                    required: true,
                                    validator: (_, value, callback) => {
                                        if (value.isEmpty()) {
                                            callback('请输入正文内容')
                                        } else {
                                            callback()
                                        }
                                    }
                                }],
                            })(
                                <BraftEditor
                                    style={{border:'1px solid #ccc'}}
                                    placeholder="请输入" 
                                    controls={controls} 
                                    maxLength={512}
                                    onReachMaxLength={this.handleMaxLength}
                                />
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Form.create()(addAccountModal)