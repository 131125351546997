import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form, Input, Button, message, Icon } from "antd";
import "./index.less";

// import logoUrl from '../../assets/images/logo.png';
import loginMain from "../../assets/image/login.png";
import Api from "../../api/apis";
import utils from "../../utils/utils";
import md5 from "js-md5";
// console.log(utils.localDb, "ss");

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 120,
      getCodeChange: true, //文案 获取验证码,
      menuData: [],
      firstMenu: "",
    };
  }
  componentDidMount() {}
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      (err, values) => {
        if (!err) {
          Api.login({
            staffCode: values.staffCode,
            password: this.$md5(values.password),
          })
            .then((res) => {
              if (res.code === 200) {
                this.props.history.push("/workbench/index");
                //   this.getMenu();
                utils.localDb.set("userData", res.data);
              } else {
                message.warning(res.message);
              }
              // this.setCookie('JSESSIONID', res.sessionKey, 7)
            })
            .catch((err) => {
              message.error("服务未响应");
            });
        }
      }
    );
  };
  // 获取第一个菜单
  getFirstMenu(menuData) {
    menuData.forEach((val, ind) => {
      if (ind === 0) {
        if (val.children) {
          this.getFirstMenu(val.children);
        } else {
          this.setState({
            firstMenu: val.menuUrl,
          });
        }
      }
    });
  }
  // 获取菜单
  getMenu() {
    Api.listMenuForUser({})
      .then((res) => {
        if (res.success) {
          utils.localDb.set("menuData", res.data);
          this.getFirstMenu(res.data);
          this.setState(
            {
              menuData: res.data,
            },
            () => {
              this.props.history.push(this.state.firstMenu);
            }
          );
        } else {
          message.warning(res.message);
        }
      })
      .catch((e) => console.log(e, "e"));
  }
  setCookie = function (name, value, day) {
    if (day !== 0) {
      //当设置的时间等于0时，不设置expires属性，cookie在浏览器关闭后删除
      var expires = day * 24 * 60 * 60 * 1000;
      var date = new Date(+new Date() + expires);
      document.cookie =
        name +
        "=" +
        escape(value) +
        ";expires=" +
        date.toUTCString();
    } else {
      document.cookie = name + "=" + escape(value);
    }
  };
  // 发送验证码
  sendCaptcha = () => {
    let { getCodeChange, count } = this.state;

    this.props.form.validateFieldsAndScroll(
      ["phone"],
      (err, values) => {
        if (!err) {
          // 验证码有效期内
          if (!getCodeChange) {
            return;
          }
          // 倒计时
          const timer = setInterval(() => {
            this.setState(
              {
                getCodeChange: false,
                count: count--,
              },
              () => {
                if (count === 0) {
                  clearInterval(timer);
                  this.setState({
                    getCodeChange: true,
                    count: 60,
                  });
                }
              }
            );
          }, 1000);
          // 发送
          Api.sendCaptcha({
            phone: values.phone,
            type: "3",
          })
            .then((res) => {})
            .catch((e) => console.log(e, "e"));
        }
      }
    );
  };
  forgetPsd = () => {
    this.props.history.push({
      pathname: "editPsd",
      state: { from: "login" },
    });
  };

  validatePassword = (rule, value, callback) => {
    if (value && value.trim() == "") {
      callback("密码必填");
    } else {
      callback();
    }
  };
  validateStaffCode = (rule, value, callback) => {
    const reg = /^[0-9]+$/;
    if (value && value.trim() == "") {
      callback("账号必填");
    } else if (
      value &&
      value.trim() &&
      !reg.test(value.trim())
    ) {
      callback("只能输入数字");
    } else {
      callback();
    }
  };

  render() {
    // const { getCodeChange, count } = this.state;
    const { getFieldDecorator } = this.props.form;

    const isPhone = function (rule, value, callback) {
      callback();
    };
    return (
      <div className="p-main">
        {/* <img
          alt=""
          src={require("../../assets/image/triangle-right.png")}
          className="conent-bg-r"
        /> */}
        {/* <img
          alt=""
          src={require("../../assets/image/triangle-left.png")}
          className="conent-bg-l"
        /> */}
        <div className="p-content">
          <div
            className="content-img"
            style={{
              backgroundImage: "url(" + loginMain + ")",
            }}
          ></div>
          <div className="content-form">
            <div className="content-form-main">
              <img className="logo-image" src={require("../../assets/image/favicon.jpg")}></img>
              <h3 className="title">后台管理系统</h3>
              <div id="login_container"></div>
              <Form
                onSubmit={this.handleSubmit}
                className="login-form "
              >
                <Form.Item style={{ marginBottom: 16 }}>
                  {getFieldDecorator("staffCode", {
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "未输入账号!",
                      },
                      {
                        validator: this.validateStaffCode,
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{
                            color: "rgba(0,0,0,.25)",
                          }}
                        />
                      }
                      placeholder="请输入账号"
                    />
                    // <Input
                    //   size="large"
                    //   className="round-large login-input"
                    //   prefix={
                    //     <img
                    //       alt=""
                    //       src={require("../../assets/image/login-phone.png")}
                    //       className="logo-phone-icon"
                    //     />
                    //   }
                    //   placeholder="请输入手机号码"
                    // />
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 20 }}>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "未输入密码",
                      },
                      {
                        validator: this.validatePassword,
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{
                            color: "rgba(0,0,0,.25)",
                          }}
                        />
                      }
                      type="password"
                      placeholder="请输入登录密码"
                    />
                    // <Input.Password
                    //   size="large"
                    //   className="round-large certificate-input login-input"
                    //   prefix={
                    //     <img
                    //       alt=""
                    //       src={require("../../assets/image/login-psd.png")}
                    //       className="logo-psd-icon"
                    //     />
                    //   }
                    //   placeholder="请输入密码"
                    // />
                  )}
                </Form.Item>
                <div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%", height: 40 }}
                    // shape="round"
                    // size="large"
                  >
                    登录
                  </Button>
                </div>
                <div style={{ marginTop: 16 }}>
                  <span
                    className="pointer"
                    style={{
                      fontSize: 12,
                      color: "#A8B8CC",
                      lineBreak: "17px",
                    }}
                    onClick={this.forgetPsd}
                  >
                    忘记密码？
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <div className="footer-container">
          <a className="beian-link" href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备20011772号-1</a>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create({
  name: "normal_login",
})(LoginForm);

export default withRouter(WrappedNormalLoginForm);
