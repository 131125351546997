/**
 * @description 卡片
 */
import React from "react";
import { map } from "lodash";
import "./index.less";
class Card extends React.Component {
  constructor(props) {
    super(props);
  }

  cardRender = (url, num, lable, width) => {
    return (
      <div className="card-box" style={{ width }}>
        <div className="content">
          <img src={url} />
        </div>
        <div className="num">
          <p className="num-box">{num}</p>
          <p className="describe">{lable}</p>
        </div>
      </div>
    );
  };

  render() {
    const { dataSource } = this.props;
    let width =
      dataSource.length == 2 ? "49.5%" : "32.333%";
    return (
      <div className="box">
        {map(dataSource, (item) => {
          const { url, num, lable } = item;
          return this.cardRender(url, num, lable, width);
        })}
      </div>
    );
  }
}

export default Card;
