import React, { Component } from "react";
import "./index.less";

import { Button, message } from "antd";
import LeftTree from "../../../components/leftTreeTwo"; // 左侧树
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表

class EmployeeRoster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataList: [], // 合同列表数据
      total: 0, // 合同总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {}, // 搜索条件
      disabled: false, // 防重复点击
      renewModalShow: false, // 续签弹框
      contractItem: {},
      sorter: "desc", // 排序 desc asc
    };
  }
  componentDidMount() {
    this.queryTableList();
  }

  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
          departmentId:
            (sessionStorage.processConfigSelectedKeys &&
              sessionStorage.processConfigSelectedKeys.split(
                "&"
              )[0]) ||
            this.state.checkedTreeId,
          pageNo,
          pageSize,
        };
        this.$http
          .listProcessDoneTask(params)
          .then((res) => {
            if (res.code == 200) {
              this.setState({
                loading: false,
                pageNo,
                pageSize,
                dataList: res.data.list,
                total: res.data.total,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };

  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };

  handleChangePage = (page, sorter) => {
    this.queryTableList(page.current, page.pageSize);
  };

  changeDepartmentId = (val) => {
    this.setState(
      {
        checkedTreeId: val[0],
      },
      () => {
        this.queryTableList();
      }
    );
  };
  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
    } = this.state;
    return (
      <div className="contract-roster">
        <div className="crumbs">流程查询</div>
        <div className="contract-wrap">
          {/* 左侧树 */}
          <LeftTree
            typeA="流程查询"
            selectedKeys="processConfigSelectedKeys"
            selectedDepartmentIds="processConfigDepartmentIds"
            expandedKeys="processConfigExpandedKeys"
            onSelect={this.changeDepartmentId}
          />
          <div className="right-wrap">
            <SearchForm
              handleSearchBtn={this.handleSearchBtn}
            />
            <div className="contract-box wrap-card contract-box_LC">
              <Table
                loading={loading}
                history={this.props.history}
                data={dataList}
                total={total}
                pageNo={pageNo}
                pageSize={pageSize}
                handleChangePage={this.handleChangePage} // 切换页码
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EmployeeRoster;
