import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Modal } from "antd";
import "./index.less";

class SearchUserlist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false
    };
  }
  //
  handleOk = () => {};

  handleCancel = () => {
    this.setState({
      modalVisible: false
    });
  };

  handleShow = () => {
    this.setState({
      modalVisible: true
    });
  };

  render() {
    const { title, temp } = this.props;
    return (
      <span className="">
        <a className="guide-text" onClick={this.handleShow}>
          流程指南
        </a>
        <Modal
          visible={this.state.modalVisible}
          title={title || "流程指南"}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              关闭
            </Button>
          ]}
        >
          <div>
            <img
              src={require(`../../assets/image/pic/${temp}.jpg`)}
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </Modal>
      </span>
    );
  }
}

export default withRouter(SearchUserlist);
