/**
* @fileoverview 系统管理---账号设置 -- 新增弹框
* @author  蒋西园
*/
import React,{Component} from 'react';
import './index.less'
import { Modal, Form, Input, Select,DatePicker } from "antd";
import moment from 'moment'
const FormItem = Form.Item;
const Option = Select.Option;

class addAccountModal extends Component{
    // 点击确定
    handleAddSure = (e) => {
        e.preventDefault();
        const { form } = this.props;
        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.props.handleAddSure(values)
            }
        })
    }
    // 点击取消关闭弹框
    handleCancel = () => {
        this.props.handleCancel();
    }
    disabledDate=(current)=> {
        // Can not select days before today and today
        return current && current < moment().endOf('month') || current > moment().endOf('year');
    }
    render(){
        const { addModalShow, form, accountItem,type } = this.props;
        const { getFieldDecorator } = form;
        return(
            <Modal
                className="setUserModal"
                title={type==0?'新增':'编辑'}
                visible={addModalShow}
                width={500}
                onCancel={this.handleCancel}
                onOk={this.handleAddSure}
                maskClosable={false}
            >
                <div className="setUser-box">
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 17 }}>
                        <FormItem 
                            label="年份">
                            <span>{moment().format('YYYY')}</span>
                        </FormItem>
                        <FormItem label="日期">
                            {getFieldDecorator('holidayDay', {
                                initialValue: !accountItem.holidayDay?undefined:moment(accountItem.holidayDay),
                                rules: [
                                    { required: true, message: '请选择时间' },
                                ]
                            })(
                                <DatePicker
                                style={{width:260}}
                                format="YYYY-MM-DD"
                                disabledDate={this.disabledDate}
                              />)}
                        </FormItem>
                        <FormItem label="类型">
                            {getFieldDecorator('holidayType', {
                                initialValue: accountItem.holidayType,
                                rules: [
                                    { required: true, message: '请选择类型' },
                                ]
                            })(
                                <Select style={{ width: 260 }} placeholder='请选择类型'>
                                    <Option value={0}>节假日</Option>
                                    <Option value={1}>补班</Option>
                                </Select>
                              )}
                        </FormItem>
                        <FormItem label="备注">
                            {getFieldDecorator('remarks', {
                                initialValue: accountItem.remarks,
                                rules: [
                                    { max: 20, message: '请输入20字符以内'}
                                ]
                            })(
                                <Input  placeholder="请输入备注"  allowClear />
                            )}
                        </FormItem>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Form.create()(addAccountModal)