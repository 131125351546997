/**
* @fileoverview 系统管理---岗位管理 -- 数据列表
* @author  蒋西园
*/
import React, { Component } from 'react';
import { Table, Modal,Icon } from 'antd';
import './index.less'
const { confirm } = Modal;

class tableList extends Component {
    constructor(props) {
        super(props)
        this.columns = [{
                title: '角色',
                width:'10%',
                dataIndex: 'rolesName'
            },{
                title: '创建时间',
                width:'20%',
                dataIndex: 'gmtCreated',
                sorter: true
            },{
                title: '说明',
                width:'60%',
                dataIndex: 'description',
                render: (text) => text ? text : '--'
            },{
                title: '操作',
                width:'10%',
                render: (record) => {
                    return (
                        <span>
                           <span onClick={() => this.props.handleEditSubmit(record)} style={{ color: "#4D91FA", cursor: 'pointer' }}>编辑</span>
                           <span onClick={this.handleDelete.bind(this,record.rolesId)} style={{ marginLeft: "16px", color: "#4D91FA", cursor: 'pointer',display:record.isOperable==false?'none':'' }}>删除</span>
                        </span>
                    )
                }
            },
        ]
    }
    /**
     * 函数描述: 删除按钮事件
     * @param {object} rolesId 角色id
     * @return {Object} 二次确认后统一返回父组件处理请求
     */
    handleDelete = (rolesId) => {
         confirm({
             title: '删除',
             content: '您确定要删除该数据吗？',
             icon:<Icon type="delete" style={{color:'#E5537B'}} />,
             onOk: () => {
               this.props.handleDelete(rolesId);
             }
         });
     }
    render() {
        console.log(this.props.pageNo)
        return (
            <Table
                rowKey={(record) => { return `${Math.random(10)}` }}
                loading={this.props.loading}
                columns={this.columns} 
                dataSource={this.props.data}
                locale={{
                    emptyText: (
                      <div>
                        <img
                          style={{ width: 182, height: 94 }}
                          src={require("../../../../../assets/image/no-data.png")}
                          alt=""
                        />
                        <p
                          style={{
                            fontSize: 14,
                            color: "#717E99",
                            lineHeight: "20px"
                          }}
                        >
                          暂无数据
                        </p>
                      </div>
                    )
                  }}
                pagination={{
                    hideOnSinglePage: true,
                    total: this.props.total,
                    current: this.props.pageNo,
                    pageSize: this.props.pageSize || 10
                }}
                onChange={(pageNo, {}, sorter) => this.props.handleChangePage(pageNo, sorter)}
            />
        )
    }
}
export default tableList