import React, { Component } from "react";

import {
  Input,
  Select,
  Radio,
  Form,
  Tooltip,
  Switch,
  Icon,
} from "antd";
import { compact } from "lodash";
import "./index.less";
import SelectStaff from "../../../../../components/selectStaff";
const { Option } = Select;

class ProcessForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchChecked: false,
      topDepartmentAgent: {}, // 顶级部门代理人
      topDepartmentAgentTwo: {}, //抄送人自定义
      departmentHead: undefined, // 指定部门负责人
      groupCustom: {}, // 自定义流程处理人
      copyGive: {}, // 自定义抄送人
      selectedItems: undefined,
      filteredOptions: [],
    };
  }
  componentDidMount() {
    console.log("wwwwwwwwwwwww");
    this.$http["listStaffForChoose"]({ departmentId: "" })
      .then((res) => {
        if (res.code === 200) {
          this.setState({
            filteredOptions: res.data.list || [],
          });
        }
      })
      .catch((err) => {});
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { configItem } = nextProps;
    if (configItem != prevState.configItem) {
      return {
        configItem: nextProps.configItem,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    console.log("rrrrrrrrrrrrrrrrrrrr");
    if (
      prevProps.configItem.taskDefKey !==
      this.props.configItem.taskDefKey
    ) {
      let switchChecked = false,
        groupCustom = {},
        departmentHead = undefined,
        selectedItems = undefined,
        topDepartmentAgent = {},
        topDepartmentAgentTwo = {};
      if (this.props.configItem.assigneeEcho === "AGENT") {
        topDepartmentAgent[
          "staffId"
        ] = this.props.configItem.assigneeId;
        topDepartmentAgent[
          "staffName"
        ] = this.props.configItem.assigneeName;
        this.setState({
          organizationalItem: {
            departmentPrincipalId: this.props.configItem
              .assigneeId,
            departmentPrincipalName: this.props.configItem
              .assigneeName,
          },
        });
        switchChecked = true;
        this.props.form.setFields({
          assigneeEcho: {
            value: "SUPERIOR",
            errors: [new Error()],
          },
        });
      } else if (
        this.props.configItem.assigneeEcho === "DEPT_HEAD"
      ) {
        if (
          this.props.configItem.showId &&
          this.props.configItem.showName
        ) {
          departmentHead = {
            key: this.props.configItem.showId,
            label: this.props.configItem.showName,
          };
        } else {
          departmentHead = this.props.configItem.assigneeId;
        }
        this.props.form.setFields({
          assigneeEcho: {
            value: "DEPT_HEAD",
            errors: [new Error()],
          },
        });
      } else if (
        this.props.configItem.assigneeEcho === "USER_DEF"
      ) {
        groupCustom[
          "staffId"
        ] = this.props.configItem.assigneeId;
        groupCustom[
          "staffName"
        ] = this.props.configItem.assigneeName;
        this.setState({
          organizationalItem1: {
            departmentPrincipalId: this.props.configItem
              .assigneeId,
            departmentPrincipalName: this.props.configItem
              .assigneeName,
          },
        });
        this.props.form.setFields({
          assigneeEcho: {
            value: "USER_DEF",
            errors: [new Error()],
          },
        });
      } else if (
        this.props.configItem.assigneeEcho === "STAFF"
      ) {
        this.props.form.setFields({
          assigneeEcho: {
            value: "STAFF",
            errors: [new Error()],
          },
        });
      } else if (
        this.props.configItem.assigneeEcho === "SUPERIOR"
      ) {
        this.props.form.setFields({
          assigneeEcho: {
            value: "SUPERIOR",
            errors: [new Error()],
          },
        });
      }
      if (this.props.configItem.copyEcho === "USER_DEF2") {
        topDepartmentAgentTwo[
          "staffId"
        ] = this.props.configItem.copyToId;
        topDepartmentAgentTwo[
          "staffName"
        ] = this.props.configItem.copyToName;
        this.setState({
          organizationalItem2: {
            departmentPrincipalId:
              this.props.configItem.copyToList != undefined
                ? this.props.configItem.copyToList.length >
                  0
                  ? this.props.configItem.copyToList[0]
                      .copyToId
                  : ""
                : "",
            departmentPrincipalName:
              this.props.configItem.copyToList != undefined
                ? this.props.configItem.copyToList.length >
                  0
                  ? this.props.configItem.copyToList[0]
                      .copyToName
                  : ""
                : "",
          },
        });

        selectedItems = this.props.configItem.copyToIdList;
        this.props.form.setFields({
          copyEcho: {
            value: "USER_DEF2",
            errors: [new Error()],
          },
        });
      } else if (
        this.props.configItem.copyEcho === "DEPT_HEAD"
      ) {
        this.props.form.setFields({
          copyEcho: {
            value: "DEPT_HEAD",
            errors: [new Error()],
          },
        });
      } else {
        this.props.form.setFields({
          copyEcho: {
            value: undefined,
            errors: [new Error()],
          },
        });
      }
      this.setState(
        {
          switchChecked,
          groupCustom,
          departmentHead,
          selectedItems,
          topDepartmentAgent,
          topDepartmentAgentTwo,
        },
        () => {}
      );
    }
  }
  // 点击确定
  handleAddSure = (callBack) => {
    const { form } = this.props;
    const {
      switchChecked,
      topDepartmentAgent,
      departmentHead,
      groupCustom,
      copyGive,
      topDepartmentAgentTwo,
      selectedItems,
      filteredOptions,
    } = this.state;

    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const data = {};
        data["assigneeEcho"] = values["assigneeEcho"];
        switch (values.assigneeEcho) {
          case "SUPERIOR":
            if (switchChecked) {
              // 选择直属上级
              data["assigneeId"] =
                topDepartmentAgent.staffId;
              data["assigneeName"] =
                topDepartmentAgent.staffName;
              data["switchChecked"] = switchChecked;
              data["groupId"] = "";
              data["assigneeEcho"] = "AGENT";
            } else {
              data["assigneeId"] = "";
              data["groupId"] = "SUPERIOR";
              data["assigneeEcho"] = "SUPERIOR";
            }
            break;
          case "DEPT_HEAD": // 指定部门负责人
            data["groupId"] = "";
            data["assigneeId"] = departmentHead;
            break;
          case "STAFF": // 员工自己
            data["groupId"] = "STAFF";
            data["assigneeId"] = "";
            break;
          case "USER_DEF": // 自定义
            data["groupId"] = "";
            data["assigneeId"] = groupCustom.staffId;
            data["assigneeName"] = groupCustom.staffName;
            break;
        }
        if (values.copyEcho) {
          data["copyEcho"] = values["copyEcho"];
          if (values.copyEcho === "DEPT_HEAD") {
            data["copyToGroup"] = values.copyEcho;
          } else if (values.copyEcho === "USER_DEF2") {
            console.log("wwwwwwwwwwwwwwwwwww");
            topDepartmentAgentTwo.copyToId =
              topDepartmentAgentTwo.staffId;
            topDepartmentAgentTwo.copyToName =
              topDepartmentAgentTwo.staffName;
            const copyNameIdList = [];
            for (let item of filteredOptions) {
              if (selectedItems.includes(item.staffId)) {
                copyNameIdList.push({
                  copyToId: item.staffId,
                  copyToName: item.staffName,
                  staffId: item.staffId,
                  staffName: item.staffName,
                });
              }
            }
            // data['copyToGroup'] =  values['copyEcho'];
            data["copyToList"] = [topDepartmentAgentTwo];
            data["copyNameIdList"] = copyNameIdList;
            data["copyToId"] =
              topDepartmentAgentTwo.staffId;
            data["copyToIdList"] = selectedItems;
            data["copyToName"] =
              topDepartmentAgentTwo.staffName;
          } else {
            data["copyToGroup"] = "";
            data["copyToId"] = "";
          }
        }
        // else{
        //     data['copyToGroup'] = '';
        //     data['copyToId'] = '';
        //     data['copyEcho'] ='';
        // }
        this.setState({
          organizationalItem: null,
          organizationalItem1: null,
          organizationalItem2: null,
        });
        callBack(data);
      } else {
      }
    });
  };
  handleSwitch = (checked) => {
    this.setState(
      {
        switchChecked: checked,
      },
      () => {
        if (checked) {
          this.props.form.setFields({
            assigneeEcho: {
              value: "SUPERIOR",
              errors: [new Error("请设置顶级代理人")],
            },
          });
        }
      }
    );
  };
  // 指定部门负责人--select下拉重新请求数据
  querydepartmentId = (open) => {
    if (open) {
      this.props.querydepartmentId();
    }
  };
  handleReset = (copyId) => {
    console.log("ffffffffffffffffffffff");
    this.props.form.resetFields();
    this.setState({
      switchChecked: false,
      topDepartmentAgent: {}, // 顶级部门代理人
      departmentHead: undefined, // 指定部门负责人
      groupCustom: undefined, // 自定义流程处理人
      topDepartmentAgentTwo: {},
      selectedItems: copyId || [],
    });
  };
  groupValidator = (rule, value, callback) => {
    const { departmentHead, groupCustom } = this.state;
    if (value && value === "DEPT_HEAD" && !departmentHead) {
      callback("请选择指定部门负责人");
    } else if (
      value &&
      value === "USER_DEF" &&
      !groupCustom.staffId
    ) {
      callback("请选择自定义处理人");
    }
    callback();
  };
  groupCustomValidator = (rule, value, callback) => {
    const { copyGive, topDepartmentAgentTwo } = this.state;
    if (
      value &&
      value === "USER_DEF" &&
      !topDepartmentAgentTwo.staffId
    ) {
      callback("请选择抄送人");
    }
    callback();
  };
  // 切换指定部门负责人
  handleDepartmentHead = (value) => {
    if (value) {
      this.props.form.setFields({
        assigneeEcho: {
          value: "DEPT_HEAD",
          errors: [new Error()],
        },
      });
    }
    this.setState({
      departmentHead: value,
    });
  };
  // 直属上级
  handleOk = (value) => {
    value[0].departmentPrincipalId = value[0].staffId;
    value[0].departmentPrincipalName = value[0].staffName;
    this.setState(
      {
        topDepartmentAgent: value[0] || {},
        organizationalItem: value[0],
      },
      () => {
        if (value[0]) {
          this.props.form.setFields({
            assigneeEcho: {
              value: "SUPERIOR",
              errors: [new Error()],
            },
          });
        }
      }
    );
  };
  //抄送人 -- 自定义
  handleOkTwo = (value) => {
    value[0].departmentPrincipalId = value[0].staffId;
    value[0].departmentPrincipalName = value[0].staffName;
    this.setState(
      {
        topDepartmentAgentTwo: value[0] || {},
        organizationalItem2: value[0],
      },
      () => {
        if (value[0]) {
          this.props.form.setFields({
            copyEcho: {
              value: "USER_DEF2",
              errors: [new Error()],
            },
          });
        }
      }
    );
  };
  // 处理人 -- 自定义
  groupCustomOk = (value) => {
    value[0].departmentPrincipalId = value[0].staffId;
    value[0].departmentPrincipalName = value[0].staffName;
    this.setState(
      {
        groupCustom: value[0] || {},
        organizationalItem1: value[0],
      },
      () => {
        if (value[0]) {
          this.props.form.setFields({
            assigneeEcho: {
              value: "USER_DEF", // 自定义
              errors: [new Error()],
            },
          });
        }
      }
    );
  };

  onChangeRadio = (e) => {
    this.setState({
      organizationalItem: null,
      organizationalItem1: null,
      departmentHead: undefined,
    });
    if (e.target.value == "SUPERIOR") {
      this.setState({
        switchChecked: false,
      });
    }
  };
  onChangeRadioCopy = (e) => {
    this.setState({
      organizationalItem2: null,
    });
  };

  handleChange = (selectedItems) => {
    if (Array.isArray(selectedItems)) {
      this.props.form.setFields({
        copyEcho: {
          value: "USER_DEF2",
          errors: [new Error()],
        },
      });
    }
    this.setState({ selectedItems });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("nextProps---->", nextProps);
    console.log("prevState---->", prevState);
  }
  render() {
    const {
      switchChecked,
      departmentHead,
      selectedItems,
      filteredOptions,
    } = this.state;
    const {
      checkedProcessItem,
      departmentList,
      configItem,
    } = this.props;

    const formItemLayout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 12 },
    };
    const {
      getFieldDecorator,
      getFieldValue,
    } = this.props.form;

    const radioStyle = {
      display: "block",
      lineHeight: "40px",
    };
    return (
      <div className="node-bottom-form">
        <Form>
          <Form.Item label="节点名称" {...formItemLayout}>
            <span>{checkedProcessItem.des}</span>
          </Form.Item>
          <Form.Item label="处理人规则" {...formItemLayout}>
            {getFieldDecorator("assigneeEcho", {
              initialValue: configItem.assigneeEcho
                ? configItem.assigneeEcho
                : undefined,
              rules: [
                {
                  required: true,
                  message: "请选择处理人规则",
                },
                {
                  validator: this.groupValidator,
                },
              ],
            })(
              <Radio.Group onChange={this.onChangeRadio}>
                <Radio
                  style={radioStyle}
                  value={"DEPT_HEAD"}
                >
                  <div className="radio-inline">
                    <div>
                      指定部门负责人
                      <Tooltip title="所选部门的负责人">
                        <Icon type="question-circle" />
                      </Tooltip>
                    </div>
                    <Select
                      className="groupId-select"
                      disabled={
                        getFieldValue("assigneeEcho") !==
                        "DEPT_HEAD"
                      }
                      placeholder="请选择"
                      allowClear
                      labelInValue
                      value={departmentHead}
                      onChange={this.handleDepartmentHead}
                      onDropdownVisibleChange={
                        this.querydepartmentId
                      }
                    >
                      {departmentList.map((item) => {
                        return (
                          <Option
                            key={
                              item.departmentPrincipalId +
                              "&" +
                              item.departmentId
                            }
                            value={
                              item.departmentPrincipalId +
                              "&" +
                              item.departmentId
                            }
                          >{`${
                            item.departmentName
                          }(负责人：${
                            item.departmentPrincipalName ==
                            undefined
                              ? "未配置"
                              : item.departmentPrincipalName
                          })`}</Option>
                        );
                      })}
                    </Select>
                  </div>
                </Radio>
                <Radio
                  style={radioStyle}
                  value={"SUPERIOR"}
                >
                  <div className="radio-inline">
                    <div>
                      表单中员工的直属上级
                      <Tooltip title="OFFER审批流程、入职流程：对应表单中入职者的直属上级（部门负责人）。其他流程:对应表单中员工的直属上级（花名册中设置）">
                        <Icon type="question-circle" />
                      </Tooltip>
                    </div>
                    <div className="flex flex-align-center">
                      <Switch
                        disabled={
                          getFieldValue("assigneeEcho") !==
                          "SUPERIOR"
                        }
                        checked={
                          switchChecked &&
                          getFieldValue("assigneeEcho") ===
                            "SUPERIOR"
                        }
                        onChange={this.handleSwitch}
                      />
                      <span className="tip">
                        设置顶级部门代理人
                        <Tooltip title="若指定代理人，当直属上级为GM时，由代理人进行审核">
                          <Icon type="question-circle" />
                        </Tooltip>
                      </span>
                      <SelectStaff
                        organizationalItem={
                          this.state.organizationalItem
                        }
                        disabled={
                          !(
                            switchChecked &&
                            getFieldValue(
                              "assigneeEcho"
                            ) === "SUPERIOR"
                          )
                        }
                        form={this.props.form}
                        handleOk={this.handleOk}
                      />
                    </div>
                  </div>
                </Radio>

                <Radio style={radioStyle} value={"STAFF"}>
                  表单中的员工
                  <Tooltip title="为本人发起流程时员工指本人；为他人发起流程时员工指被发起人">
                    <Icon type="question-circle" />
                  </Tooltip>
                </Radio>
                <Radio
                  style={radioStyle}
                  value={"USER_DEF"}
                >
                  <div className="radio-inline">
                    <div>自定义</div>
                    <SelectStaff
                      organizationalItem={
                        this.state.organizationalItem1
                      }
                      disabled={
                        getFieldValue("assigneeEcho") !==
                        "USER_DEF"
                      }
                      form={this.props.form}
                      handleOk={this.groupCustomOk}
                      isAllShowTree={true}
                    />
                  </div>
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label="抄送人" {...formItemLayout}>
            {getFieldDecorator("copyEcho", {
              initialValue: configItem.copyEcho
                ? configItem.copyEcho
                : undefined,
            })(
              <Radio.Group
                onChange={this.onChangeRadioCopy}
              >
                <Radio
                  style={radioStyle}
                  value={"DEPT_HEAD"}
                >
                  表单中员工的直属上级
                  <Tooltip title="OFFER审批流程、入职流程：对应表单中入职者的直属上级（部门负责人）。其他流程:对应表单中员工的直属上级（花名册中设置）">
                    <Icon type="question-circle" />
                  </Tooltip>
                </Radio>
                <Radio
                  style={radioStyle}
                  value={"USER_DEF2"}
                >
                  <div className="radio-inline">
                    <div>自定义</div>
                    {/* <SelectStaff
                                        organizationalItem={this.state.organizationalItem2}
                                        disabled={getFieldValue('copyEcho') !== 'USER_DEF2'}
                                        form={this.props.form}
                                        handleOk={this.handleOkTwo}
                                    /> */}
                    <Select
                      mode="multiple"
                      className="groupId-select"
                      placeholder="请选择抄送人员"
                      value={compact(selectedItems)}
                      allowClear
                      disabled={
                        getFieldValue("copyEcho") !==
                        "USER_DEF2"
                      }
                      onChange={this.handleChange}
                      style={{ width: "600px" }}
                      showSearch
                      optionFilterProp="children"
                    >
                      {filteredOptions.map((item) => (
                        <Select.Option
                          key={item.staffId}
                          value={item.staffId}
                        >
                          {item.staffName}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export default Form.create()(ProcessForm);
