/**
 * @fileoverview 人事管理---考勤管理
 * @author  蒋西园
 */
import React, { Component } from "react";
import "./index.less";
import axios from "axios";

import { Button, message } from "antd";
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表

class AttendanceManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      dataList: [], // 考勤列表数据
      total: 100, // 考勤总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      searchVale: {}, // 搜索条件
      disabled: false, // 防重复点击
      departmentList: [], // 部门下拉
    };
  }
  componentDidMount() {
    this.queryTableList();
    this.querydepartmentId();
  }
  /**
   * 函数描述: 查询部门名称
   * @param {string} type   类型（1：项目 2：部门）
   */
  querydepartmentId = () => {
    this.$http["departmentListByParent"]({}).then((res) => {
      if (res.code === 200) {
        this.setState({
          departmentList: res.data || [],
        });
      }
    });
  };
  /**
   * 函数描述: 查询考勤数据
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} value   考勤
   * @param {string} value   部门
   * @param {string} value   岗位
   * @param {string} value   状态
   * @return {Object}
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
          pageNo,
          pageSize,
        };
        this.$http
          .attendanceList(params)
          .then((res) => {
            if (res.code == 200) {
              this.setState(
                {
                  loading: false,
                  pageNo,
                  pageSize,
                  dataList: res.data.list,
                  total: res.data.total,
                },
                () => {
                  console.log(this.state.dataList);
                }
              );
            }
          })
          .catch((err) => {
            this.setState({
              loading: false,
            });
          });
      }
    );
  };
  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };
  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (pageNo, pageSize) => {
    this.queryTableList(pageNo, pageSize);
  };
  build(url, params) {
    // URL构建方法
    const ps = [];
    if (params) {
      for (let p in params) {
        if (p) {
          ps.push(p + "=" + encodeURIComponent(params[p]));
        }
      }
    }
    return url + "?" + ps.join("&");
  }
  handleExport = () => {
    const { searchVale, pageNo, pageSize } = this.state;
    const params = {
      ...searchVale,
      pageNo,
      pageSize,
    };
    axios({
      method: "get",
      url: this.build(
        this.$http.attendanceExport(),
        searchVale
      ),
      data: searchVale,
      responseType: "blob",
    }).then((res) => {
      let headers = res.headers;
      let contentType = headers["content-type"];
      if (!res.data) {
        return false;
      } else {
        const blob = new Blob([res.data], {
          type: contentType,
        });
        let contentDisposition =
          res.headers["content-disposition"];
        let fileName = "unknown";
        if (contentDisposition) {
          fileName = window.decodeURIComponent(
            res.headers["content-disposition"].split("=")[1]
          );
        }
        if (window.navigator.msSaveOrOpenBlob) {
          // IE10+下载
          window.navigator.msSaveBlob(blob, fileName);
        } else {
          // 非IE下载
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob); // 创建下载的链接
          link.download = fileName; // 下载后文件名
          link.style.display = "none";
          document.body.appendChild(link);
          link.click(); // 点击下载
          window.URL.revokeObjectURL(link.href); // 释放掉blob对象
          document.body.removeChild(link); // 下载完成移除元素
        }
      }
    });
  };
  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
      departmentList,
    } = this.state;
    return (
      <div className="attendance-management">
        <div className="crumbs">考勤管理</div>
        {/* 搜索框 */}
        <SearchForm
          handleSearchBtn={this.handleSearchBtn}
          departmentList={departmentList}
          querydepartmentId={this.querydepartmentId}
        />
        {/* 考勤数据 */}
        <div className="attendance-box wrap-card">
          <Button
            className="addButton"
            type="primary"
            onClick={this.handleExport}
          >
            导出
          </Button>
          <Table
            loading={loading}
            data={dataList}
            total={total}
            pageNo={pageNo}
            pageSize={pageSize}
            handleChangePage={this.handleChangePage} // 切换页码
          />
        </div>
      </div>
    );
  }
}

export default AttendanceManagement;
