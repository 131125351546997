import React,{ Component } from 'react'
import { withRouter } from 'react-router-dom';
import { Button, Table, message } from 'antd';
import './index.less'
import LeftTree from '../../../../../components/leftTreeOne'
import moment from 'moment';
class UnconfirmedList extends Component {
	constructor(props){
		super(props)
		this.state = {
			loading: false,
			pageNo: 1,
			total: 100,
			unconfirmedList: [],	// 未确认人员名单
			selectedRowKeys: [],
			checkedTreeItem: [],    // 左侧tree默认选中
			attendanceMonth: '',	// 考勤月份
			depInfoDTOList: [],		// 左侧树
			searchValue: '',
			sorter: 'desc',			// 入职时间排序（asc：升序；desc：降序,默认desc）
		}
		this.columns = [{
			title: "工号",
			dataIndex: "staffCode"
		},{
			title: "姓名",
			dataIndex: "staffName"
		},{
			title: "部门",
			dataIndex: "departmentName"
		},{
			title: "岗位",
			dataIndex: "postName"
		},{
			title: "工作城市",
			dataIndex: "workCity"
		},{
			title: "是否离职",
			dataIndex: "leaved",
			render: text => <span>{text==false?'否':'是'}</span>,
		
		},{
			title: "入职时间",
			dataIndex: "inductionTime",
            sorter: true
		}]
	}
    componentDidMount(){
		
		this.queryStatisticsDep('first');
    }
	/**
	 * 查询部门列表
	 */
	queryStatisticsDep = (type='') => {
		this.$http.queryStatisticsDep({
			depName: this.state.searchValue
		}).then(res => {
			if (res.code === 200) {
				this.setState({
					depInfoDTOList: res.data.depInfoDTOList || [],
				},() => {
					const params = this.props.location.state;
					let checkedTreeItem = this.state.depInfoDTOList[0] || {};
					let attendanceMonth = params.attendanceMonth || '';
					if (type === 'first') {
						if (params && params.checkedTreeItem && params.attendanceMonth) {
							checkedTreeItem = JSON.parse(params.checkedTreeItem);
							attendanceMonth = params.attendanceMonth;
						}else{
							this.props.history.push({pathname: 'jurisdiction'});
						}
					}
					this.setState({
						checkedTreeItem,
						attendanceMonth
					},() => {
						// console.log(res.data)
						if(JSON.stringify(res.data) == "{}"){
						}else{
							this.queryTableList();
						}
					})
				})
			}
			
		})
	}
	/**
     * 函数描述: 分页查询上月的未确认考勤员工信息接口
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} dicGroup 字典类型 -- 来源于左侧字典类型的dicKey
     * @return {Object} 
     */
    queryTableList = (pageNo=1, pageSize=10) =>{
		this.setState({
			loading: true
		},() => {
			const params = {
				pageNo: pageNo,
				pageSize,
				departmentId: this.state.checkedTreeItem.depId,
				attendanceMonth: this.state.attendanceMonth,
				inductionTimeSort: this.state.sorter
			}
            if (this.state.checkedTreeItem.dicKey) params['dicGroup'] = this.state.checkedTreeItem.dicKey
			this.$http.pageUnConfirmAttendanceEmp(params).then((res) => {
				if(res.code == 200){
					this.setState({
						loading: false,
						unconfirmedList: res.data.list,
						total: res.data.total
					})
				}
			}).catch((err) => {
				this.setState({
					loading: false,
				})
			})
		})
	}
    /**
     * 函数描述: 切换列表页码
     * @param {string} pageNo   页码
     * @param {string} pageSize 条数
     * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
     * @return {Object} 请求pageNo页列表数据
     */
    handleChangePage = (page,sorter) => {
		let sorterOrder = ''
		if (sorter.order) {
			sorterOrder = sorter.order === 'ascend' ? 'asc' : 'desc'
		}
		this.setState({
			pageNo:page.current,

			sorter: sorterOrder
		},() => {
			this.queryTableList(page.current,page.pageSize);
		})
	}
    /**
     * 函数描述: 点击左侧字典类型
     * @param {String} checkedTreeItem  类型key,value
     */
    handleTreeNode = (checkedTreeItem) => {
		this.setState({
			checkedTreeItem
		},() => {
            this.queryTableList();
        })
	}
	onSelectChange = selectedRowKeys => {
		console.log('selectedRowKeys changed: ', selectedRowKeys);
		this.setState({ selectedRowKeys });
	};
	
    /**
     * 函数描述: 列表页左侧字典类型--搜索
     * @param {String} value   搜索的值
     */
    hansleSearch = (value) => {
		console.log(value,'----')
		if (value.match(/\s/)) {
			message.error('搜索值为全空格！')
			return
		}
        this.setState({
            searchValue: value
        },() => {
            this.queryStatisticsDep();
        })
	}
	
	 /**
     * 函数描述: 提醒
     */
    handleTranslate = () => {
		if(this.state.selectedRowKeys.length==0){
			message.error('请选择员工')
			return
		}
        // this.setState({
        //     searchValue: value
        // },() => {
        //     this.queryStatisticsDep();
		// })
		this.$http.sendAttendanceRemind({
			staffIdList: this.state.selectedRowKeys
		}).then(res => {
			if (res.code === 200) {
				console.log(res)
			}
			
		})
	}


    render(){
		const { checkedTreeItem, loading,
			unconfirmedList, pageNo, total, selectedRowKeys,
			depInfoDTOList
		} = this.state;
		const rowSelection = {
			selectedRowKeys,
			onChange: this.onSelectChange,
		};
		return (
            <div className="unconfirmed-list">
                <div className="crumbs">未确认名单-{moment().subtract(1, 'month').format('YYYY-MM')}</div>
				<div className="contract-wrap">
					{/* 左侧树 */}
					<LeftTree
						placeholder={'部门/项目'}
						mapping={{ id: 'depId', value: 'depName' }}
						checkedTreeItem={checkedTreeItem}
						list={depInfoDTOList}
						hansleSearch={this.hansleSearch}
						handleTreeNode={this.handleTreeNode}
					/>
					<div className="right-wrap wrap-card">
						<Button className="translate" type="primary"  onClick={this.handleTranslate}>提醒</Button>
						 <Table
							rowKey={(record) => { return record.staffId }}
							loading={loading}
							columns={this.columns} 
							dataSource={unconfirmedList}
							rowSelection={rowSelection}
							pagination={{
								hideOnSinglePage: true,
								current: pageNo,
								total: total,
								pageSize: 10
							}}
							onChange={(pageNo, {}, sorter) => this.handleChangePage(pageNo, sorter)}
						/>
					</div>
				</div>
            </div>
        )
        
	}
}
export default withRouter(UnconfirmedList)