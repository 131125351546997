/**
 * 封装localStorage
 * 增加对JSON对象的转换
 * @author: Ziv
 * @return {[type]} [description]
 */
const localDb = {
  /**
   * 按key存贮数据value到localStorage
   * @param {String} key   存贮数据的唯一标识
   * @param {String, Object} value 所要存贮的数据
   */
  set(key, value) {
    if (!value) delete window.localStorage[key];
    else {
      const val = typeof value === "object" ? JSON.stringify(value) : value;
      console.log(key, value, "sss");
      window.localStorage[key] = val;
    }
  },

  /**
   * 通过key从localStorage获取数据
   * @param  {String} key  获取数据的可以标识
   * @return {String, Object}  返回空，字符串或者对象
   */
  get(key) {
    const str = window.localStorage[key] || "";
    return this.isJSONStr(str) ? JSON.parse(str) : str;
  },

  /**
   * 判断是否是JSON string
   * @param  {String}  str 所要验证的字符串
   * @return {Boolean}   是否是JSON字符串
   */
  isJSONStr(str) {
    return (
      (str.charAt(0) === "{" && str.charAt(str.length - 1) === "}") ||
      (str.charAt(0) === "[" && str.charAt(str.length - 1) === "]")
    );
  },

  // 设置用户信息
  setUserInfo(userInfoKey, data, persistent = false) {
    if (persistent) {
      this.set(userInfoKey, data);
    } else {
      sessionStorage.setItem(userInfoKey, JSON.stringify(data));
    }
  },
  // 获得用户信息
  getUserInfo(userInfoKey, persistent = false) {
    if (persistent) {
      return this.get(userInfoKey);
    } else {
      const str = window.sessionStorage[userInfoKey] || "";
      return this.isJSONStr(str) ? JSON.parse(str) : str;
    }
  },
  /**
   * 清空localStorage
   * @return 无返回NULL
   */
  clear(key) {
    if (key) {
      window.localStorage.removeItem("key");
    } else {
      window.localStorage.clear();
    }
  },
};
function getAstro(m, d) {
  return "魔羯水瓶双鱼牡羊金牛双子巨蟹狮子处女天秤天蝎射手魔羯".substr(
    m * 2 - (d < "102223444433".charAt(m - 1) - -19) * 2,
    2
  );
}
//更具身份证获取信息
function getInfoFromIdCard(idCard) {
  let res = {
    birthday: "",
    sex: "",
    age: "",
    astro: "",
  };
  let birthday = "";
  if (idCard != null && idCard != "") {
    if (idCard.length == 15) {
      res.birthday = "19" + idCard.substr(6, 6);
      birthday = "19" + idCard.substr(6, 6);
    } else if (idCard.length == 18) {
      res.birthday = idCard.substr(6, 8);
      birthday = idCard.substr(6, 8);
    }

    res.birthday = res.birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    birthday = birthday.replace(/(.{4})(.{2})/, "$1-$2-");
  }
  //时间字符串里，必须是“/”
  // var birthDate = new Date(birthday);
  var yearBirth = idCard.substring(6, 10);
  var monthBirth = idCard.substring(10, 12);
  var dayBirth = idCard.substring(12, 14);
  var myDate = new Date();
  var monthNow = myDate.getMonth() + 1;
  var dayNow = myDate.getDay();
  res.age = myDate.getFullYear() - yearBirth;
  if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
    res.age--;
  }
  // res.age =
  //   myDate.getFullYear() - idCard.substring(6, 10);
  // if (idCard.substring(10, 12) < month || (idCard.substring(10, 12) == month && idCard.substring(12, 14) <= day)) {
  //   res.age--;
  // } //年龄 age
  if (parseInt(idCard.substr(16, 1)) % 2 == 1) {
    res.sex = "男";
  } else {
    res.sex = "女";
  }
  let arr = birthday.split("-");
  res.astro = getAstro(arr[1], arr[2]);

  return res;
}

function get_uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

var HtmlUtil = {
  /*1.用浏览器内部转换器实现html转码*/

  htmlEncode: function(html) {
    //1.首先动态创建一个容器标签元素，如DIV

    var temp = document.createElement("div");

    //2.然后将要转换的字符串设置为这个元素的innerText(ie支持)或者textContent(火狐，google支持)

    temp.textContent != undefined
      ? (temp.textContent = html)
      : (temp.innerText = html);

    //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了

    var output = temp.innerHTML;

    temp = null;

    return output;
  },

  /*2.用浏览器内部转换器实现html解码*/

  htmlDecode: function(text) {
    //1.首先动态创建一个容器标签元素，如DIV

    var temp = document.createElement("div");

    //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)

    temp.innerHTML = text;

    //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。

    var output = temp.innerText || temp.textContent;

    temp = null;

    return output;
  },

  /*3.用正则表达式实现html转码*/

  htmlEncodeByRegExp: function(str) {
    var s = "";

    if (str.length == 0) return "";

    s = str.replace(/&/g, "&amp;");

    s = s.replace(/</g, "&lt;");

    s = s.replace(/>/g, "&gt;");

    s = s.replace(/ /g, "&nbsp;");

    s = s.replace(/\'/g, "'");

    s = s.replace(/\"/g, '"');

    return s;
  },

  /*4.用正则表达式实现html解码*/

  htmlDecodeByRegExp: function(str) {
    var s = "";

    if (str.length == 0) return "";

    s = str.replace(/&amp;/g, "&");

    s = s.replace(/&lt;/g, "<");

    s = s.replace(/&gt;/g, ">");

    s = s.replace(/&nbsp;/g, " ");

    s = s.replace(/'/g, "'");

    s = s.replace(/"/g, '"');

    return s;
  },
};
function getSearchString(key, Url) {
  var str = Url;
  str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
  // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
  var arr = str.split("&");
  var obj = new Object();
  // 将每一个数组元素以=分隔并赋给obj对象
  for (var i = 0; i < arr.length; i++) {
    var tmp_arr = arr[i].split("=");
    obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
  }
  return obj[key];
}

function Age(status, index) {
  var aDate = new Date();

  var thisYear = aDate.getFullYear();
  var bDate = new Date(status);
  var brith = bDate.getFullYear();
  var age = thisYear - brith;
  return age;
}

const limitDecimals = (value) => {
  const reg = /^(\-)*(\d+)\.(\d\d).*$/;
  if (typeof value === "string") {
    return !isNaN(Number(value)) ? value.replace(reg, "$1$2.$3") : "";
  } else if (typeof value === "number") {
    return !isNaN(value) ? String(value).replace(reg, "$1$2.$3") : "";
  } else {
    return "";
  }
};

export default {
  localDb,
  getInfoFromIdCard,
  get_uuid,
  HtmlUtil,
  getSearchString,
  Age,
  limitDecimals,
};
