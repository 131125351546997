import React from "react";
import { Button } from "antd";
import "./index.less";
const TYPE = {
  toBeEmployed: "否",
  overdue: "是",
};
export const listColumns = (callBack1, callBack2) => [
  {
    title: "招聘",
    dataIndex: "staffName",
    key: "staffName",
    render: (text) => <span>{text ? text : "--"}</span>,
  },
  {
    title: "岗位",
    dataIndex: "postName",
    key: "postName",
    render: (text) => <span>{text ? text : "--"}</span>,
  },
  {
    title: "创建数",
    dataIndex: "numberOfOffers",
    key: "numberOfOffers",
    sorter: (a, b) => a.numberOfOffers - b.numberOfOffers,
    render: (text, record) => {
      console.log("record ->", record);
      return (
        <Button
          type="link"
          onClick={() => {
            callBack1({
              title: "offer列表-已创建",
              columns: approvalReportColumns(),
              columnsType: "offerDetailsReqData",
              staffId: record.staffId,
            });
          }}
        >
          {text ? text : 0}
        </Button>
      );
    },
  },
  {
    title: "审批中",
    dataIndex: "quantityUnderApproval",
    key: "quantityUnderApproval",
    sorter: (a, b) =>
      a.quantityUnderApproval - b.quantityUnderApproval,
    render: (text, record) => {
      return (
        <Button
          type="link"
          onClick={() => {
            callBack1({
              title: "offer列表-审批中",
              columns: approvalReportColumns(),
              columnsType: "underApproval",
              staffId: record.staffId,
            });
          }}
        >
          {text ? text : 0}
        </Button>
      );
    },
  },
  {
    title: "审批通过",
    dataIndex: "approvedQuantity",
    key: "approvedQuantity",
    sorter: (a, b) =>
      a.approvedQuantity - b.approvedQuantity,
    render: (text, record) => {
      return (
        <Button
          type="link"
          onClick={() => {
            callBack1({
              title: "offer列表-审批通过",
              columns: approvalReportColumns(),
              columnsType: "approved",
              staffId: record.staffId,
            });
          }}
        >
          {text ? text : 0}
        </Button>
      );
    },
  },
  {
    title: "待入职",
    dataIndex: "numberOfCandidates",
    key: "numberOfCandidates",
    sorter: (a, b) =>
      a.numberOfCandidates - b.numberOfCandidates,
    render: (text, record) => {
      return (
        <Button
          type="link"
          onClick={() => {
            callBack1({
              title: "待入职列表",
              columns: entryColumns(
                callBack2,
                "toBeEmployed"
              ),
              columnsType: "toBeEmployed",
              staffId: record.staffId,
            });
          }}
        >
          {text ? text : 0}
        </Button>
      );
    },
  },
  {
    title: "已入职",
    dataIndex: "numberOfEmployed",
    key: "numberOfEmployed",
    sorter: (a, b) =>
      a.numberOfEmployed - b.numberOfEmployed,
    render: (text, record) => {
      return (
        <Button
          type="link"
          onClick={() => {
            callBack1({
              title: "已入职列表",
              columns: entryColumns(callBack2, "inService"),
              columnsType: "inService",
              staffId: record.staffId,
            });
          }}
        >
          {text ? text : 0}
        </Button>
      );
    },
  },
  {
    title: "过期未入职",
    dataIndex: "numberOfOverdueEmployees",
    key: "numberOfOverdueEmployees",
    sorter: (a, b) =>
      a.numberOfOverdueEmployees -
      b.numberOfOverdueEmployees,
    render: (text, record) => {
      return (
        <Button
          type="link"
          onClick={() => {
            callBack1({
              title: "过期未入职列表",
              columns: entryColumns(callBack2, "overdue"),
              columnsType: "overdue",
              staffId: record.staffId,
            });
          }}
        >
          {text ? text : 0}
        </Button>
      );
    },
  },
];
// const PROC_STATUS = {
//   PASS: "通过",
//   REFUSE: "驳回",
//   INITIATE: "发起",
//   AUDIT: "审核",
//   PIGEONHOLE: "归档",
//   COMPLETE: "完成",
//   STORAGE: "保存",
//   RECALL: "撤回",
// };
const PROC_STATUS = {
  PASS: {
    title: "通过",
    textColor: "#4D91FA",
    bgColor: "#F0F6FF",
    borderColor: "#9EC4FF",
  },
  REFUSE: {
    title: "驳回",
    textColor: "#4D91FA",
    bgColor: "#F0F6FF",
    borderColor: "#9EC4FF",
  },
  INITIATE: {
    title: "发起",
    textColor: "#4D91FA",
    bgColor: "#F0F6FF",
    borderColor: "#9EC4FF",
  },
  AUDIT: {
    title: "审核",
    textColor: "#4D91FA",
    bgColor: "#F0F6FF",
    borderColor: "#9EC4FF",
  },
  PIGEONHOLE: {
    title: "归档",
    textColor: "#91A7BE",
    bgColor: "#EFF1F4",
    borderColor: "#D0D9E3",
  },
  COMPLETE: {
    title: "完成",
    textColor: "#91A7BE",
    bgColor: "#EFF1F4",
    borderColor: "#D0D9E3",
  },
  STORAGE: {
    title: "保存",
    textColor: "#4D91FA",
    bgColor: "#F0F6FF",
    borderColor: "#9EC4FF",
  },
  RECALL: {
    title: "撤回",
    textColor: "#91A7BE",
    bgColor: "#EFF1F4",
    borderColor: "#D0D9E3",
  },
};
export const approvalReportColumns = () => [
  {
    title: "标题",
    dataIndex: "offerName",
    key: "offerName",
    render: (text, record) => {
      const { departmentName, businessKey } = record;
      return (
        <span>{`offer审批-${text}-${departmentName}(单号：${businessKey})`}</span>
      );
    },
  },
  // {
  //   title: "当前节点",
  //   dataIndex: "procStatus",
  //   key: "procStatus",
  //   width: 90,
  //   render: (text, record) => {
  //     let ele;
  //     if (PROC_STATUS[text]) {
  //       const {
  //         borderColor,
  //         bgColor,
  //         textColor,
  //         title,
  //       } = PROC_STATUS[text];
  //       ele = (
  //         <div
  //           className="node-tag"
  //           style={{
  //             borderColor: borderColor,
  //             backgroundColor: bgColor,
  //             color: textColor,
  //           }}
  //         >
  //           <span>{title ? title : "--"}</span>
  //         </div>
  //       );
  //     } else {
  //       ele = "--";
  //     }
  //     return ele;
  //   },
  // },
  {
    title: "当前节点",
    width: 160,
    dataIndex: "taskDefName",
    key: "taskDefName",
    render: (text, record) => {
      return (
        <span
          className={`status-item item-color-${record.taskDefKey}`}
        >
          {text || "待批"}
        </span>
      );
    },
  },
  {
    title: "发起人",
    dataIndex: "nameOfSponsor",
    key: "nameOfSponsor",
    width: 105,
  },
  {
    title: "接收时间",
    dataIndex: "receivingTime",
    key: "receivingTime",
    width: 105,
    render: (text) => {
      return <span>{text ? text : "--"}</span>;
    },
  },
  {
    title: "处理人",
    dataIndex: "staffName",
    key: "staffName",
    width: 105,
  },
];

export const entryColumns = (method, type) => [
  {
    title: "姓名",
    dataIndex: "offerName",
    key: "offerName",
    width: 70,
  },
  {
    title: "手机号",
    dataIndex: "telephone",
    key: "telephone",
    width: 105,
  },
  {
    title: "入职部门",
    dataIndex: "departmentName",
    key: "departmentName",
    width: 90,
  },
  {
    title: "岗位",
    dataIndex: "postName",
    key: "postName",
    width: 80,
  },
  {
    title: "应入职时间",
    dataIndex: "hireDate",
    key: "hireDate",
    width: 120,
  },
  {
    title: "实际入职时间",
    dataIndex: "entryTime",
    key: "entryTime",
    width: 120,
    render: (text) => {
      return <span>{text ? text : "--"}</span>;
    },
  },
  // {
  //   title: "入职状态",
  //   dataIndex: "procTemplateName",
  //   width: 105,
  //   key: "procTemplateName",
  //   render: (text) => {
  //     let ele;
  //     if (PROC_STATUS[text]) {
  //       const { title } = PROC_STATUS[text];
  //       ele = title;
  //     } else {
  //       ele = "--";
  //     }
  //     return <span>{ele}</span>;
  //   },
  // },
  {
    title: "入职状态",
    dataIndex: "procTemplateName",
    width: 105,
    key: "procTemplateName",
    render: (text) => <span>{text ? text : "--"}</span>,
  },
  {
    title: "是否过期",
    dataIndex: "isLate",
    key: "isLate",
    width: 105,
    render: (text) => {
      return (
        <span>
          {TYPE[type]
            ? TYPE[type]
            : text === true
            ? "是"
            : "否"}
        </span>
      );
    },
  },
  {
    title: "操作",
    width: 80,
    render: (text, record) => (
      <Button type="link" onClick={() => method(record)}>
        查看offer
      </Button>
    ),
  },
];
