/**
 * @fileoverview 人事管理---薪资档案
 * @author  黄洪月
 */
import React, { Component } from "react";
import "./index.less";
import SearchForm from "./component/searchForm"; // 搜索条件
import Table from "./component/tableList"; // 数据列表

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      total: 0, // 档案总数
      pageSize: 10, // 分页Size
      pageNo: 1, // 分页页码
      sorter: "", // 排序方式 ASC： 正序 DESC: 倒叙
      searchVale: {}, // 搜索条件
      dataList: [],   // 列表数据
    };
  }
  componentDidMount() {
    this.queryTableList();
  }

  /**
   * 函数描述: 列表数据请求
   */
  queryTableList = (pageNo = 1, pageSize = 10) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        const params = {
          ...this.state.searchVale,
          pageNo,
          pageSize,
        };
        this.$http.salaryFileList(params).then((data) => {
          if (data.code === 200) {
            this.setState({
              dataList: data.data?data.data.list?data.data.list:[]: [],
              total: data.data?data.data.total?data.data.total:0: 0,
              loading: false,
              pageNo,
              pageSize,
            });
          } else {
            this.setState({
              dataList: [],
              loading: false,
            });
          }
        })
          .catch((err) => {
            this.setState({
              loading: false,
              dataList: [],
            });
          });
      }
    );
  };

  /**
   * 函数描述: 高级搜索
   * @param {Object} value   高级搜索条件
   * @return {Object} 请求该条件下第1页列表数据，并记录搜索条件
   */
  handleSearchBtn = (value) => {
    this.setState(
      {
        searchVale: { ...value },
      },
      () => {
        this.queryTableList();
      }
    );
  };

  /**
   * 函数描述: 切换列表页码
   * @param {string} pageNo   页码
   * @param {string} pageSize 条数
   * @param {string} sorter 	排序方式 ASC： 正序 DESC: 倒叙
   * @return {Object} 请求pageNo页列表数据
   */
  handleChangePage = (page, sorter) => {
    let sorterOrder = "";
    if (sorter.order) {
      sorterOrder =
        sorter.order === "ascend" ? "asc" : "desc";
    }
    this.setState(
      {
        sorter: sorterOrder,
      },
      () => {
        this.queryTableList(page,10);
      }
    );
  };
  _refreshList = () => {
    this.queryTableList();
  };
  render() {
    const {
      dataList,
      total,
      pageNo,
      pageSize,
      loading,
    } = this.state;
    return (
      <div className="salar_file">
        <div className="salar-roster">
          <div className="crumbs">薪资档案</div>
          <SearchForm
            handleSearchBtn={this.handleSearchBtn}
          />
          <div className="salar-box wrap-card">
            <Table
              loading={loading}
              history={this.props.history}
              data={dataList}
              total={total}
              pageNo={pageNo}
              pageSize={pageSize}
              refreshList={this._refreshList}
              handleChangePage={this.handleChangePage} // 切换页码
            />
          </div>
        </div>
      </div>
    );
  }
}
